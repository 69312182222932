import store     from '@root/pages/orders/store';
import Modal     from '@components/Modal';
import Loading   from '@components/Loading';
import Datepicker  from 'vue-bulma-datepicker';

import moment   from 'moment';
import {
    addDays,
    deadlineUrgency,
    fullYear,
    dayMonth,
    getValueWithKey,
    time,
    listOfDaysWithoutTime
} from '@utilities/functions';
import { notifyError, notifySuccess } from '@components/Notification';
import { getPlaceholder, editPlaceholder } from '@api';
import omit from 'lodash/omit';
import AgencySelect from '../modal/components/AgencySelect';
import Multiselect from '@components/form/Multiselect';
import { DateTime } from '@components/form';
import Type from '@orders/placeholders/components/forms/HotelType';
import AsyncSelect from "@components/form/AsyncSelect";
import LocationSelect from '@components/form/LocationSelect';
import debounce from "lodash/debounce";

export default {
    store: store,


    props: {
        isModalVisible: { type: Boolean, required: true },
        initialValue:   { type: Object,  required: true },
        passedOptions: {type: Object, required: false },
    },


    computed: {
        hasOwnRouteDescription: function () {
            return false;
        },
        destinationsForLocation: function () {
            return this.onlyFromOrder && this.$store.state.order ? this.$store.state.order.trip.destinations : [];
        },
        listOfDays: function () {
            return listOfDaysWithoutTime(this.localPlaceholder.startAt, this.localPlaceholder.endAt);
        },
        isSaving:        function () {
            return this.$store.state.isLoading.requestSaving;
        },
        modalLoading:    function () {
            return this.$store.state.isLoading.modal;
        },
        today: function () {
            let date = new Date();
            date.setHours(0, 0, 0, 0);
            return date;
        },

    },


    data: function () {
        return {
            localPlaceholder: JSON.parse(JSON.stringify(this.initialValue)),
            isNewRequestVisible: false,
            calculationRequest: null,
            selectedRequest: null,
            placeholderSaved: false,
        };
    },


    methods: {
        loadPlaceholder () {
            this.$store.commit('updateIsLoadingModal', true);

            getPlaceholder({
                type:   this.typeOfPlaceholder,
                id:     this.initialValue.id,
                params: '?_groups[]=modal_read&_groups[]=money_read&_groups[]=modal:providerPrices'
            }).then(response => {
                this.localPlaceholder = response.data;

                if (this.localPlaceholder.requests.length === 0) {
                    this.addNewRequest();
                }

                this.calculationRequest = response.data.calculationRequest;
                this.selectedRequest = response.data.selectedRequest;

                this.initialSetup();

            }, error => {
                this.$emit('closeModal');
                notifyError('Couldn\'t get the Placeholder! Server Error!');
            }).then(() => { this.$store.commit('updateIsLoadingModal', false); });
        },

        savePlaceholder: debounce(function (placeholder, reload = false) {
            editPlaceholder({
                type: this.apiEndpoint,
                id:   placeholder.id,
                data: { ...placeholder, serviceDescription: null, ...!this.hasOwnRouteDescription && {routeDescription: null} },
                params: '?_groups[]=modal_read&_groups[]=info_read'
            })
                .then(response => {
                    this.localPlaceholder.serviceDescription = response.data.serviceDescription;
                    if(!this.hasOwnRouteDescription) {
                        this.localPlaceholder.routeDescription = response.data.routeDescription;
                    }
                    if(reload) {
                        this.loadPlaceholder();
                    }

                }, error => { notifyError('Das Konzeptelement konnte nicht aktualisiert werden! Server Error!'); })
                .then(() => { });
        }, 1000),

        addNewRequest: function () {
            this.localPlaceholder.requests.push(JSON.parse(JSON.stringify(this.emptyRequest)));
            this.isNewRequestVisible = true;
        },

        addDays,


        closeNewRequestModal: function (requestWasAdded = false) {
            if (!requestWasAdded) {
                this.localPlaceholder.requests.splice(this.localPlaceholder.requests.length - 1, 1);
            }
            this.isNewRequestVisible = false;
        },


        closeModal: function () {
            this.$emit('closeModal', {
                ...this.localPlaceholder,
                _saved: this.placeholderSaved
            })
        },


        filteredItems: function () { /* Overriden inside the placeholder */ },


        setCalculationRequest: function (request) {
            this.placeholderSaved = true;
            this.calculationRequest = request;
        },

        setSelectRequest: function (request) {
            this.placeholderSaved = true;
            this.selectedRequest = request;
        },


        initialSetup: function () { /* Overriden inside the placeholder */ },


        editPlaceholder: function (data) {
            this.placeholderSaved = true;
            return editPlaceholder(data);
        },


        handleRequestSaved: function (calculationRequest) {
            this.calculationRequest = calculationRequest ? calculationRequest : this.calculationRequest;
            this.placeholderSaved = true;
        },

        deadlineUrgency,
        getValueWithKey,
        moment
    },


    created: function () {
        this.loadPlaceholder();

        this.filteredItems();
    },


    filters: {
        fullYear,
        time,
        dayMonth,
        moment
    },


    components: {
        AgencySelect,
        Loading,
        Datepicker,
        Modal,
        DateTime,
        Multiselect,
        AsyncSelect,
        LocationSelect
    }
};
