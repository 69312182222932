<template>
    <div style="min-height: 60vh">

        <loading v-if="isLoading"/>

        <div class="columns " v-for="row in rows" v-else>
            <div v-for="col in row.cols" class="column" :class="col.columnClass" :style="col.columnStyle">
                <div class="level is-headline">
                    <div class="level-left">
                        <h2 class="level-item mb-0">{{col.label}}</h2>
                        <button class="level-item" @click="buttonAction({action: row.addAction, element: element})" v-if="row.addAction"><i class=" material-icons" >add_circle</i></button>
                        <button class="level-item" @click="buttonAction({action: row.resetAction, element: element})" v-if="row.resetAction"><i class=" material-icons" >refresh</i></button>
                    </div>
                </div>
                <div>
                    <loading v-if="col.component.ref && componentIsLoading[col.component.ref]"/>
                    <component
                        :is="col.component.type"
                        :component="col.component"
                        @saveItem="saveItem"
                        @saveItemDebounce="saveItemDebounce"
                        @updateData="updateData"
                        :ref="col.component.ref"
                        @buttonAction="buttonAction"
                        :element="element"
                        v-else
                    />

                </div>

            </div>
        </div>
    </div>
</template>

<script>
import ImageCropper from "@components/ImageCropper.vue";

import debounce        from 'lodash/debounce';
import Mixin from '../tab';
import HomepagePublish from './Components/HomepagePublish'
import HomepageImage from './Components/HomepageImage';
import HomepageInput from "./Components/HomepageInput";
import HomepagePriceTable from "./Components/HomepagePriceTable";
import HomepageEditor from "./Components/HomepageEditor";
import HomepageStatus from "./Components/HomepageStatus";
import HomepageReady from "./Components/HomepageReady";
import HomepageMultipleItems from "./Components/HomepageMultipleItems";
import {notifyError, notifySuccess} from "@components/Notification";
import Loading from "@components/Loading";
import {axiosDelete, edit, getAxios, post} from "@api";
import {prepareForBackend} from "@utilities/functions";
import {Toggle} from "@components/form";
import {tagIcons, tags} from "@utilities/variables";
import moment from "moment";
import {union} from "lodash";
import {homepageColorMap} from "@clientSpecific/utilities/variables";


export default {
    mixins: [ Mixin ],

    data: function() {
        return {
            isLoading: false,
            value: false,
            componentIsLoading: {
                prices: false,
                homepageFlyer: false,
            },
        };
    },

    computed: {
        element: function () {
            let element = this.item;
            if(this.api === 'order_concepts') {
                element.dayWebflowLinks = element.webflowLinks.filter(item => item.type === 'day').sort((a,b) => a.position - b.position);
                element.orderWebflowLinks = element.webflowLinks.filter(item => item.type === 'order');
            }
            return element;
        },

        rows: function() {
            if(this.api === 'destinations'){
                return [
                    {
                        cols: [
                            {
                                label: 'Status',
                                columnClass: 'is-one-fifth',
                                columnStyle: {},
                                component: {
                                    type: 'homepageInput',
                                    props: {
                                        rows: [
                                            {
                                                fields: [
                                                    {
                                                        type: 'label',
                                                        label: 'Einstellungen',
                                                    },
                                                    {
                                                        type: 'checklistItem',
                                                        property: 'checklistItems',
                                                        propertyType: 'Einstellungen',
                                                    },
                                                ]
                                            },
                                        ],
                                    }
                                }
                            },
                        ],
                    },
                    {
                        cols: [
                            {
                                label: 'Einstellungen',
                                columnClass: 'is-half',
                                columnStyle: {},
                                component: {
                                    type: 'homepageInput',
                                    props: {
                                        rows: [
                                            {
                                                fields: [
                                                    {
                                                        type: 'label',
                                                        label: 'Anzeigen in folgenden Kategorien',
                                                    },
                                                    {
                                                        type: 'multiselect',
                                                        options: this.options && this.options.homepageCategories ? this.options.homepageCategories : [],
                                                        property: 'categories',
                                                        label: 'value',
                                                        trackBy: 'key',
                                                        getValuesFromOptions: true,
                                                        multiple: true
                                                    },
                                                ]
                                            }
                                        ],
                                    }
                                }
                            },
                            /*{
                                label: 'Titelbild',
                                columnClass: 'is-half',
                                columnStyle: {},
                                component: {
                                    type: 'homepageImage',
                                    props: {
                                        filterParams: {type: 'homepage_main'},
                                        columnStyle: {'padding-top': 0, 'height': '230px','max-width':'240px'},
                                        columnClasses: '',
                                        multiImages: false,
                                        enableZoom: false
                                    }
                                }
                            },*/
                        ]
                    },
                ]
            }
            if(this.api === 'day_concepts'){
                return [
                    {
                        cols: [
                            {
                                label: 'Status',
                                columnClass: 'is-one-fifth',
                                columnStyle: {},
                                component: {
                                    type: 'homepageInput',
                                    props: {
                                        rows: [
                                            {
                                                fields: [
                                                    {
                                                        type: 'label',
                                                        label: 'Route',
                                                    },
                                                    {
                                                        type: 'checklistItem',
                                                        property: 'checklistItems',
                                                        propertyType: 'Route',
                                                    },
                                                ]
                                            },
                                            {
                                                fields: [
                                                    {
                                                        type: 'label',
                                                        label: 'Text',
                                                    },
                                                    {
                                                        type: 'checklistItem',
                                                        property: 'checklistItems',
                                                        propertyType: 'Text',
                                                    },
                                                ]
                                            }
                                        ],
                                    }
                                }
                            },
                        ],
                    },
                    {
                        cols: [
                            /*{
                                label: 'Kurztext',
                                columnClass: 'is-half',
                                columnStyle: {},
                                component: {
                                    type: 'homepageEditor',
                                    props: {
                                        property: 'shortText',
                                        defaultSize: 500,
                                    }
                                }
                            },*/
                            {
                                label: 'Text',
                                columnClass: 'is-half',
                                columnStyle: {},
                                component: {
                                    type: 'homepageEditor',
                                    props: {
                                        property: 'catalogText',
                                        defaultSize: 500,
                                    }
                                }
                            },
                        ]
                    },
                ]
            }
            if(this.api === 'hotels'){
                return [
                    {
                        cols: [
                            {
                                label: 'Status',
                                columnClass: 'is-one-fifth',
                                columnStyle: {},
                                component: {
                                    type: 'homepageInput',
                                    props: {
                                        rows: [
                                            {
                                                fields: [
                                                    {
                                                        type: 'label',
                                                        label: 'Hotelbeschreibung',
                                                    },
                                                    {
                                                        type: 'checklistItem',
                                                        property: 'checklistItems',
                                                        propertyType: 'Hotelbeschreibung',
                                                    },
                                                ]
                                            },
                                            {
                                                fields: [
                                                    {
                                                        type: 'label',
                                                        label: 'Bilder',
                                                    },
                                                    {
                                                        type: 'checklistItem',
                                                        property: 'checklistItems',
                                                        propertyType: 'Bilder',
                                                    },
                                                ]
                                            }
                                        ],
                                    }
                                }
                            },
                        ],
                    },
                    {
                        cols: [
                            {
                                label: 'Hotelbeschreibung',
                                columnClass: '',
                                columnStyle: {},
                                component: {
                                    type: 'homepageEditor',
                                    props: {
                                        property: 'description',
                                        defaultSize: 150,
                                    }
                                }
                            },
                        ],
                    },
                    {
                        cols: [
                            {
                                label: 'Bilder für die Homepage',
                                columnClass: '',
                                columnStyle: {},
                                component: {
                                    type: 'homepageImage',
                                    props: {
                                        addTitle: 'Neues Bild',
                                        filterParams: {type: 'homepage_image'},
                                        columnStyle: {'padding-top': 0, 'height': '300px','max-width':'352px'},
                                        columnClasses: '',
                                        multiImages: true,
                                        enableZoom: false,
                                        isCoverImage: true,
                                        cropRatio: 940/510,
                                        maxFileSize: 4000000,
                                    }
                                }
                            },
                        ],
                    },
                ]
            }
            if(this.api === 'order_concepts'){
                return [
                    {
                        cols: [
                            {
                                label: 'Veröffentlichen',
                                columnClass: '',
                                columnStyle: {},
                                component: {
                                    type: 'homepagePublish',
                                    props: {
                                        collectionName: 'Orders'
                                    }
                                }
                            },
                        ],
                    },
                    {
                        cols: [
                            {
                                label: 'Status',
                                columnClass: 'is-one-fourth',
                                columnStyle: {},
                                component: {
                                    type: 'homepageInput',
                                    props: {
                                        rows: [
                                            {
                                                fields: [
                                                    {
                                                        type: 'label',
                                                        label: 'Alles Ok für Homepage',
                                                    },
                                                    {
                                                        type: 'checklistItem',
                                                        property: 'checklistItems',
                                                        propertyType: 'Alles Ok für Homepage',
                                                    },
                                                ]
                                            },
                                            {
                                                fields: [
                                                    {
                                                        type: 'label',
                                                        label: 'Hotels für Homepage ausgewählt',
                                                    },
                                                    {
                                                        type: 'checklistItem',
                                                        property: 'checklistItems',
                                                        propertyType: 'Hotels für Homepage ausgewählt',
                                                    },
                                                ]
                                            },
                                            {
                                                fields: [
                                                    {
                                                        type: 'label',
                                                        label: 'Titelbild',
                                                    },
                                                    {
                                                        type: 'checklistItem',
                                                        property: 'checklistItems',
                                                        propertyType: 'Titelbild',
                                                    },
                                                ]
                                            },
                                            {
                                                fields: [
                                                    {
                                                        type: 'label',
                                                        label: 'Bild für PDF',
                                                    },
                                                    {
                                                        type: 'checklistItem',
                                                        property: 'checklistItems',
                                                        propertyType: 'Bild für PDF',
                                                    },
                                                ]
                                            },
                                            {
                                                fields: [
                                                    {
                                                        type: 'label',
                                                        label: 'Bild für Liste',
                                                    },
                                                    {
                                                        type: 'checklistItem',
                                                        property: 'checklistItems',
                                                        propertyType: 'Bild für Liste',
                                                    },
                                                ]
                                            },
                                            {
                                                fields: [
                                                    {
                                                        type: 'label',
                                                        label: 'Routenkarte',
                                                    },
                                                    {
                                                        type: 'checklistItem',
                                                        property: 'checklistItems',
                                                        propertyType: 'Routenkarte',
                                                    },
                                                ]
                                            },
                                            {
                                                fields: [
                                                    {
                                                        type: 'label',
                                                        label: 'Einleitungstext',
                                                    },
                                                    {
                                                        type: 'checklistItem',
                                                        property: 'checklistItems',
                                                        propertyType: 'Einleitungstext',
                                                    },
                                                ]
                                            },
                                            {
                                                fields: [
                                                    {
                                                        type: 'label',
                                                        label: 'Highlights',
                                                    },
                                                    {
                                                        type: 'checklistItem',
                                                        property: 'checklistItems',
                                                        propertyType: 'Highlights',
                                                    },
                                                ]
                                            },
                                            {
                                                fields: [
                                                    {
                                                        type: 'label',
                                                        label: 'Tage - Routenstichpunkte',
                                                    },
                                                    {
                                                        type: 'checklistItem',
                                                        property: 'checklistItems',
                                                        propertyType: 'Tage - Routenstichpunkte',
                                                    },
                                                ]
                                            },
                                            {
                                                fields: [
                                                    {
                                                        type: 'label',
                                                        label: 'Tage - Texte',
                                                    },
                                                    {
                                                        type: 'checklistItem',
                                                        property: 'checklistItems',
                                                        propertyType: 'Tage - Texte',
                                                    },
                                                ]
                                            },
                                            {
                                                fields: [
                                                    {
                                                        type: 'label',
                                                        label: 'Impressionen',
                                                    },
                                                    {
                                                        type: 'checklistItem',
                                                        property: 'checklistItems',
                                                        propertyType: 'Impressionen',
                                                    },
                                                ]
                                            },
                                            {
                                                fields: [
                                                    {
                                                        type: 'label',
                                                        label: 'Leistungen',
                                                    },
                                                    {
                                                        type: 'checklistItem',
                                                        property: 'checklistItems',
                                                        propertyType: 'Leistungen',
                                                    },
                                                ]
                                            },
                                            {
                                                fields: [
                                                    {
                                                        type: 'label',
                                                        label: 'Preise',
                                                    },
                                                    {
                                                        type: 'checklistItem',
                                                        property: 'checklistItems',
                                                        propertyType: 'Preise',
                                                    },
                                                ]
                                            },
                                            {
                                                fields: [
                                                    {
                                                        type: 'label',
                                                        label: 'Reise als PDF',
                                                    },
                                                    {
                                                        type: 'checklistItem',
                                                        property: 'checklistItems',
                                                        propertyType: 'Reise als PDF',
                                                    },
                                                ]
                                            },
                                            {
                                                fields: [
                                                    {
                                                        type: 'label',
                                                        label: 'Tags und Experte',
                                                    },
                                                    {
                                                        type: 'checklistItem',
                                                        property: 'checklistItems',
                                                        propertyType: 'Tags und Experte',
                                                    },
                                                ]
                                            },
                                        ],
                                    }
                                }
                            },
                            {
                                label: 'Status verknüpfte Tage',
                                columnClass: 'is-one-fourth',
                                columnStyle: {},
                                component: {
                                    type: 'homepageStatus',
                                    props: {
                                        object: 'day',
                                        checkItems: ['Route', 'Text']
                                    }
                                }
                            },
                            {
                                label: 'Status Hotels',
                                columnClass: 'is-one-fourth',
                                columnStyle: {},
                                component: {
                                    type: 'homepageStatus',
                                    props: {
                                        object: 'hotel',
                                        checkItems: ['Hotelbeschreibung', 'Bilder']
                                    }
                                }
                            },
                        ],
                    },
                    {
                        cols: [
                            {
                                label: 'Sonstige Einstellungen',
                                columnClass: '',
                                component: {
                                    type: 'homepageInput',
                                    props: {
                                        rows: [
                                            {
                                                fields: [
                                                    {
                                                        type: 'label',
                                                        label: 'Destination (neue mit Enter anlegen)',
                                                    },
                                                    {
                                                        type: 'multiselect',
                                                        label: 'name',
                                                        trackBy: 'id',
                                                        taggable: true,
                                                        options: this.options && this.options.homepageDestinations ? this.options.homepageDestinations : [],
                                                        multiple: true,
                                                        property: 'homepageDestinations',
                                                    },
                                                ]
                                            },
                                            {
                                                fields: [
                                                    {
                                                        type: 'label',
                                                        label: 'Kategorie',
                                                    },
                                                    {
                                                        type: 'multiselect',
                                                        options: this.options && this.options.homepageCategories ? this.options.homepageCategories : [],
                                                        property: 'homepageCategory',
                                                        label: 'value',
                                                        trackBy: 'key',
                                                        getValueFromOptions: true,
                                                    },
                                                ]
                                            },
                                            {
                                                fields: [
                                                    {
                                                        type: 'label',
                                                        label: 'Reise-Experte',
                                                    },
                                                    {
                                                        type: 'multiselect',
                                                        options: this.options && this.options.users ? this.options.users : [],
                                                        property: 'user',
                                                        label: 'firstName',
                                                        trackBy: 'id'
                                                    },
                                                ]
                                            },
                                            {
                                                fields: [
                                                    {
                                                        type: 'label',
                                                        label: 'Tags',
                                                    },
                                                    {
                                                        type: 'multiselect',
                                                        options: [...tagIcons, ...tags],
                                                        label: 'name',
                                                        trackBy: 'label',
                                                        property: 'tags',
                                                        getValuesFromOptions: true,
                                                        multiple: true,
                                                    },
                                                ]
                                            },
                                            {
                                                fields: [
                                                    {
                                                        type: 'label',
                                                        label: '# Tage (ohne Alternativen)',
                                                    },
                                                    {
                                                        type: 'numericalInput',
                                                        property: 'realDayCount',
                                                    },
                                                ]
                                            },
                                            {
                                                fields: [
                                                    {
                                                        type: 'label',
                                                        label: 'Zubucherreise',
                                                    },
                                                    {
                                                        type: 'toggleSwitch',
                                                        options: [{value: true, label: 'ja'}, {value: false, label: 'nein'}],
                                                        property: 'hasJoinedBookings',
                                                    },
                                                ]
                                            },
                                        ],
                                    }
                                }
                            },
                        ],
                    },
                    {
                        cols: [
                            {
                                label: 'Titelbild',
                                columnClass: '',
                                columnStyle: {},
                                component: {
                                    type: 'homepageImage',
                                    props: {
                                        addTitle: 'Neues Bild',
                                        dropzoneID: 'dropzone-1',
                                        filterParams: {type: 'homepage_main'},
                                        columnStyle: {'padding-top': 0, 'max-width': '1000px' ,'height': '252px'},
                                        columnClasses: '',
                                        multiImages: false,
                                        enableZoom: false,
                                        isCoverImage: true,
                                        cropRatio: 1903/480, // = width / height
                                        maxFileSize: 4000000,
                                    }
                                }
                            },
                        ],
                    },
                    {
                        cols: [
                            {
                                label: 'Bild für PDF',
                                columnClass: 'is-one-half',
                                columnStyle: {},
                                component: {
                                    type: 'homepageImage',
                                    props: {
                                        addTitle: 'Neues Bild',
                                        dropzoneID: 'dropzone-3',
                                        filterParams: {type: 'homepage_pdf'},
                                        columnStyle: {'padding-top': 0, 'height': '300px', 'max-width': '558px'},
                                        columnClasses: '',
                                        multiImages: false,
                                        enableZoom: false,
                                        isCoverImage: true,
                                        cropRatio: 441/237,
                                        maxFileSize: 4000000,
                                    }
                                }
                            },
                            {
                                label: 'Bild für Liste',
                                columnClass: 'is-one-quarter',
                                columnStyle: {},
                                component: {
                                    type: 'homepageImage',
                                    props: {
                                        addTitle: 'Neues Bild',
                                        dropzoneID: 'dropzone-3',
                                        filterParams: {type: 'homepage_list'},
                                        columnStyle: {'padding-top': 0, 'height': '300px', 'max-width': '300px'},
                                        columnClasses: '',
                                        multiImages: false,
                                        enableZoom: false,
                                        isCoverImage: true,
                                        cropRatio: 1,
                                        maxFileSize: 4000000,
                                    }
                                }
                            },
                            {
                                label: 'Routenkarte',
                                columnClass: 'is-one-quarter',
                                columnStyle: {},
                                component: {
                                    type: 'homepageImage',
                                    props: {
                                        addTitle: 'Neues Bild',
                                        dropzoneID: 'dropzone-4',
                                        filterParams: {type: 'homepage_map'},
                                        columnStyle: {'padding-top': 0, 'height': '300px', 'max-width': '300px'},
                                        columnClasses: '',
                                        multiImages: false,
                                        enableZoom: false,
                                        isCoverImage: false,
                                        cropRatio: 1,
                                        maxFileSize: 4000000,
                                    }
                                }
                            },
                        ]
                    },
                    {
                        cols: [
                            {
                                label: 'Einleitungstext',
                                columnClass: '',
                                columnStyle: {},
                                component: {
                                    type: 'homepageEditor',
                                    props: {
                                        property: 'catalogText',
                                        defaultSize: 305,
                                    }
                                }
                            },
                            {
                                label: 'Highlights',
                                columnClass: '',
                                component: {
                                    type: 'homepageEditor',
                                    props: {
                                        property: 'highlights',
                                        defaultSize: 305,
                                    }
                                }
                            },
                        ],
                    },
                    {
                        resetAction: 'resetAllDays',
                        addAction: 'addDay',
                        cols: [
                            {
                                label: 'Tage',
                                columnClass: '',
                                columnStyle: {},
                                component: {
                                    ref: 'days',
                                    type: 'homepageMultipleItems',
                                    props: {
                                        propertyArray: 'dayWebflowLinks',
                                        rows: [
                                            {
                                                cols: [
                                                    {
                                                        component: {
                                                            type: 'homepageInput',
                                                            props: {
                                                                rows: [
                                                                    {
                                                                        fields: [
                                                                            {
                                                                                type: 'label',
                                                                                label: 'Überschrift',
                                                                            },
                                                                            {
                                                                                type: 'input',
                                                                                property: 'headline',
                                                                            },
                                                                        ]
                                                                    },
                                                                    {
                                                                        fields: [
                                                                            {
                                                                                type: 'label',
                                                                                label: 'Position',
                                                                            },
                                                                            {
                                                                                type: 'input',
                                                                                property: 'position',
                                                                            },
                                                                        ]
                                                                    },
                                                                    {
                                                                        fields: [
                                                                            {
                                                                                type: 'label',
                                                                                label: 'Verknüpft mit Tageskonzept',
                                                                            },
                                                                            {
                                                                                type: 'multiselect',
                                                                                options: this.options && this.element.orderedDays ? this.element.orderedDays : [],
                                                                                property: 'dayConcept',
                                                                                label: 'name',
                                                                                trackBy: 'id',
                                                                                //getValuesFromOptions: true,
                                                                                multiple: false
                                                                            },
                                                                        ]
                                                                    },
                                                                    {
                                                                        fields: [
                                                                            {
                                                                                type: 'label',
                                                                                label: 'Aktionen',
                                                                            },
                                                                            {
                                                                                type: 'buttons',
                                                                                buttons: [
                                                                                    {
                                                                                        property: 'Überschrift zurücksetzen',
                                                                                        buttonAction: 'resetDayHeadline'
                                                                                    },
                                                                                    {
                                                                                        property: 'Tag löschen',
                                                                                        buttonAction: 'deleteDay'
                                                                                    },
                                                                                ],

                                                                            },
                                                                        ]
                                                                    },

                                                                ],
                                                            }
                                                        }
                                                    }
                                                ],
                                            },
                                            {
                                                cols: [
                                                    {
                                                        component: {
                                                            type: 'homepageEditor',
                                                            props: {
                                                                compareWarning: {
                                                                    warning: 'Route weicht von Stammdaten ab!',
                                                                    compareProperty: 'bulletsHaveChanged',
                                                                },
                                                                resetAction: 'resetDayBullets',
                                                                property: 'bullets',
                                                                defaultSize: 305,
                                                                menuItems: ['fullscreen', 'bold', 'italic', 'underline', 'bullet_list', 'color', 'undo', 'strike', 'redo', 'reset'],
                                                            }
                                                        }
                                                    },
                                                    {
                                                        component: {
                                                            type: 'homepageEditor',
                                                            props: {
                                                                compareWarning: {
                                                                    warning: 'Text weicht von Stammdaten ab!',
                                                                    compareProperty: 'textHasChanged',
                                                                },
                                                                resetAction: 'resetDayText',
                                                                property: 'text',
                                                                defaultSize: 305,
                                                                menuItems: ['fullscreen', 'bold', 'italic', 'color', 'undo', 'strike', 'redo', 'reset'],
                                                            }
                                                        }
                                                    }
                                                ]
                                            },
                                        ],
                                    }
                                }
                            },
                        ],
                    },
                    {
                        cols: [
                            {
                                label: 'Reiseimpressionen für die Homepage',
                                columnClass: '',
                                columnStyle: {},
                                component: {
                                    type: 'homepageImage',
                                    props: {
                                        addTitle: 'Neues Bild',
                                        dropzoneID: 'dropzone-2',
                                        filterParams: {type: 'homepage_image'},
                                        columnStyle: {'padding-top': 0, 'height': '230px','max-width':'240px'},
                                        columnClasses: '',
                                        multiImages: true,
                                        isCoverImage: true,
                                        cropRatio: 940/510,
                                        maxFileSize: 4000000,
                                    }
                                }
                            },
                        ],
                    },
                    {
                        resetAction: 'resetServices',
                        cols: [
                            /*{
                                label: 'Kurztext',
                                columnClass: 'is-half',
                                columnStyle: {},
                                component: {
                                    type: 'homepageEditor',
                                    props: {
                                        property: 'shortText',
                                        defaultSize: 500,
                                    }
                                }
                            },*/
                            {
                                label: 'Leistungen',
                                columnClass: 'is-half',
                                columnStyle: {},
                                component: {
                                    type: 'homepageEditor',
                                    props: {
                                        property: 'homepageServices',
                                        defaultSize: 500,
                                        menuItems: ['fullscreen', 'bold', 'italic', 'underline', 'bullet_list', 'color', 'undo', 'strike', 'redo', 'reset'],
                                    }
                                }
                            },
                        ]
                    },
                    {
                        resetAction: 'resetPrices',
                        cols: [
                            {
                                label: 'Preise',
                                columnClass: '',
                                columnStyle: {},
                                component: {
                                    ref: 'prices',
                                    type: 'homepagePriceTable',
                                    props: {
                                        property: 'pricesForHomepage',
                                    }
                                }
                            },
                        ],
                    },
                    {
                        cols: [
                            {
                                label: 'Reisedokument als PDF',
                                columnClass: '',
                                component: {
                                    ref: 'homepageFlyer',
                                    type: 'homepageInput',
                                    props: {
                                        rows: [
                                            {
                                                fields: [
                                                    {
                                                        type: 'buttons',
                                                        buttons: [
                                                            {
                                                                property: 'neu generieren',
                                                                buttonAction: 'generateOrderConcept'
                                                            },
                                                        ],
                                                    },
                                                ]
                                            },
                                            {
                                                fields: [
                                                    /*{
                                                        type: 'label',
                                                        label: 'Dokument',
                                                    },*/
                                                    {
                                                        type: 'dropZone',
                                                        options: {
                                                            maxFiles: 1,
                                                        },
                                                        identifier: 'homepage_Flyer',
                                                        filterParams: {
                                                            'links.orderConcept.id': this.item.id,
                                                            type: 'homepage_Flyer',
                                                        },
                                                        documentData: {
                                                            links: [
                                                                {
                                                                    orderConcept: '/api/order_concepts/' + this.item.id
                                                                }
                                                            ],
                                                            type: 'homepage_flyer',
                                                            name: this.item.name,
                                                        },
                                                    },
                                                ]
                                            },
                                        ],
                                    }
                                }
                            },
                        ]
                    },



                    /*{
                        cols: [
                            {
                                label: 'Select Hotels',
                                columnClass: '',
                                columnStyle: {},
                                component: {
                                    type: 'homepageSelect',
                                    props: {
                                        getPropertyArray: ,
                                        columnStyle: {'padding-top': 0, 'height': '230px','max-width':'240px'},
                                        columnClasses: '',
                                        multiImages: true,
                                        enableZoom: false
                                    }
                                }
                            },
                        ],
                    },*/
                ]
            }
        },

    },

    activated: function () {
        if(this.api === 'order_concepts') {
            if(!this.options.users) {
                getAxios('users').then(response => {
                     this.$store.commit('updateOptions', {users: response.data});
                });
            }
            //if(!this.element.orderedDays || (this.element.orderedDays.length > 0 && !this.element.orderedDays[0].category)) {
                getAxios('day_concepts', {'orderConcepts.id': this.element.id, _groups: ['webflow', 'concept_day:webflow', 'webflow:concept_day', 'concept_day:order_checklist_item', 'order_checklist_item']}).then(response => {
                    this.$store.commit('updateData', {orderedDays: response.data.sort((a,b) => {return this.element.orderOfDays.indexOf(String(a.id)) > this.element.orderOfDays.indexOf(String(b.id)) ? 1 : -1})});
                    if(this.element.dayWebflowLinks.length === 0 ) {
                        this.resetWebLinkDays();
                    }
                });
            //}
            if(!this.element.homepageDestinations || this.element.homepageDestinations.length === 0) {
                this.element.homepageDestinations = this.element.destinations.map(item => {
                    let destination = this.options.homepageDestinations.find(destination => destination.name === item.name);
                    if(!destination) {
                        destination = {name: item.name}
                    }
                    return destination;
                })
                this.saveItem('homepageDestinations', this.element.homepageDestinations);
            }
            if(!this.element.homepageServices) {
                this.resetHomepageServices();
            }
        }
    },


    methods: {
        updateData (data) {
            this.$store.commit('updateData', data);
        },

        buttonAction: function({action, element}) {
            if(action === 'addDay') {
                this.addDay();
            }
            if(action === 'deleteDay') {
                this.deleteDay(element);
            }
            if(action === 'resetAllDays' && confirm('Alle Stichpunkte und Texte der Tage werden zurückgesetzt! Möchtest du fortfahren?')) {
                this.resetWebLinkDays();
            }
            if(action === 'resetDayHeadline' && element) {
                this.resetDay(element, 'headline');
            }
            if(action === 'resetDayText') {
                this.resetDay(element, 'text');
            }
            if(action === 'resetDayBullets') {
                this.resetDay(element, 'bullets');
            }
            if(action === 'resetPrices') {
                this.resetPrices()
            }
            if(action === 'generateOrderConcept') {
                this.generateOrderConcept();
            }
            if(action === 'resetServices') {
                this.resetHomepageServices();
            }
        },

        generateOrderConcept() {
            this.componentIsLoading['homepageFlyer'] = true;
            post('concepts/orders/' + this.item.id + '/generate-document', {}, {
                homepage: true,
                _groups: ['document_read'],
            }).then(response => {
                this.componentIsLoading['homepageFlyer'] = false;
            }, error => {
                this.componentIsLoading['homepageFlyer'] = false;
            })
        },

        addDay() {
            let newData =  JSON.parse(JSON.stringify(this.element.webflowLinks));
            newData.push(
                {
                    type: 'day',
                    headline: 'x. Tag',
                    position: 0,
                    bullets: '',
                    text: '',
                    orderConcept: '/api/order_concepts/' + this.element.id,
                }
            );
            this.saveItem('webflowLinks', this.prepareData('webflowLinks', newData));
            this.$store.commit('updateData', {webflowLinks: newData});
        },

        deleteDay(element) {
            let index = this.element.webflowLinks.findIndex(link => link.id === element.id);
            if(index !== -1) {
                let newData = JSON.parse(JSON.stringify(this.element.webflowLinks));
                newData.splice(index,1);
                this.saveItem('webflowLinks', this.prepareData('webflowLinks', newData));
                this.$store.commit('updateData', {webflowLinks: newData});
            }
        },

        resetDay(element, property) {
            if(!element.dayConcept) {
                notifyError('Dies ist ein Tag mit Freitext. Es gibt keine Vorlage!');
                return;
            }
            let day = this.element.orderedDays.find(day => typeof element.dayConcept === 'object' && element.dayConcept.id === day.id || element.dayConcept === '/api/day_concepts/' + day.id)
            let propertyValue = this.resetWebLinkDay(day)[property];
            this.saveItem('webflowLinks', this.prepareData('webflowLinks', this.element.webflowLinks.map(link => ({
                ...link,
                ...link.id === element.id && {[property]: propertyValue}
            }))))
            this.$store.commit('updateData', {webflowLinks: this.element.webflowLinks.map(link => ({
                    ...link,
                    ...link.id === element.id && {[property]: propertyValue}
                }))});
        },

        resetWebLinkDays: function() {
            let array = [];
            this.element.orderedDays.map(day => {
                array.push(this.resetWebLinkDay(day))
            })
            this.saveItem('dayWebflowLinks', array, true, false);
            this.$store.commit('updateData', {webflowLinks: [...array, ...this.element.orderWebflowLinks]});
        },

        resetWebLinkDay: function (day) {
            let position = (this.element.orderOfDays.findIndex(item => Number(item) === Number(day.id)) + 1);

            let link = this.element.dayWebflowLinks.find(item => {
                return (typeof item.dayConcept === 'object' && item.id === day.id) ||
                    (typeof item.dayConcept === 'string' && '/api/day_concepts/' + day.id === item.dayConcept)
            });
            let bullets = this.getBullets(day);
            return {
                ...link,
                dayConcept: '/api/day_concepts/' + day.id,
                orderConcept: '/api/order_concepts/' + this.element.id,
                headline: position + '. Tag: ' + day.name,
                position: position,
                text: day.catalogText,
                bullets: bullets,
            }
        },

        getBullets(day) {
            let allRouteDescriptions = day.allRouteDescriptions;
            if(!allRouteDescriptions || allRouteDescriptions.length === 0) {
                return '';
            }
            let returnString = '';
            let count = Object.keys(allRouteDescriptions).length;
            let g = 0;
            for(let group in allRouteDescriptions) {
                g++;
                if(g > 1) {
                    returnString += '<p></p>'
                }
                if(count > 1) {
                    returnString += '<p><strong><em>' + group + '</em></strong></p>';
                }
                returnString += '<ul>' + allRouteDescriptions[group].map(item => this.transformRouteDescription(item)).join('') + '</ul>'
            }
            return returnString
        },

        transformRouteDescription(item) {
            item = item.replace(/<[^>]*>?/gm, '');
            let colorDestination = this.item.destinations.length === 1 && this.item.destinations[0].color ? this.item.destinations[0].color : '#E78E7C';
            if(this.item.homepageCategory && homepageColorMap[this.item.homepageCategory]) {
                colorDestination = homepageColorMap[this.item.homepageCategory];
            }
            if(item.includes('Möglichkeit:')) {
                return '<li><p><em><span style="color: ' + colorDestination + '">' + item + '</span></em></p></li>';
            }
            if(item.includes('Schwierigkeitsgrad:')) {
                return '<li><p>' + item.replace('Schwierigkeitsgrad:', '<br><em><span style="color: ' + colorDestination + '">Schwierigkeitsgrad:') + '</span></i></p></li>';
            }
            return '<li><p>' + item + '</p></li>';
        },

        transformColors() {
            let colorDestination = this.item.destinations.length === 1 && this.item.destinations[0].color ? this.item.destinations[0].color : '#E78E7C';
            if(this.item.homepageCategory && homepageColorMap[this.item.homepageCategory]) {
                colorDestination = homepageColorMap[this.item.homepageCategory];
            }
            let array = [];
            const regex = /rgb\(\s*\d+\s*,\s*\d+\s*,\s*\d+\s*\)|#[a-fA-F0-9]{6}\b/g;

            this.element.dayWebflowLinks.map(link => {

                array.push({
                    ...link,
                    bullets: link.bullets.replace(regex, colorDestination)
                })
            })

            this.saveItem('dayWebflowLinks', array, true, false);
            this.$store.commit('updateData', {webflowLinks: [...array, ...this.element.orderWebflowLinks]});
        },

        resetHomepageServices: function () {
            this.saveItem('homepageServices', this.element.servicesHtml, true, false);
            this.$store.commit('updateData', {homepageServices: this.element.servicesHtml});
        },

        resetPrices: function () {
            this.componentIsLoading['prices'] = true;
            getAxios('selling_prices', {
                'orderConcept.id': this.item.id,
                _itemsPerPage: 1,
                '_order[seasonTimes.startAt]': 'DESC',
            }).then(response => {
                if(response.data.length > 0) {
                    let year = moment(response.data[0].seasonTimes[0].startAt, 'DD.MM.YYYY').format('YYYY');
                    getAxios('selling_prices', {
                        'orderConcept.id': this.item.id,
                        '_order[seasonTimes.startAt]': 'ASC',
                        'seasonTimes.startAt[after]': '01.01.' + year,
                        '_groups': ['order_read', 'order_calculation_read', 'money_read'],
                    }).then(response => {
                        let pricesForHomepage = [];
                        let unsortedPricesForHomepage = [];
                        let priceArrayHeadline = [];
                        let priceArrayDouble = [];
                        let priceArraySingle = [];
                        let priceForHomepage = null;
                        let categories = [];
                        let category = null;
                        let periods = [];
                        let period = null;
                        let startAt = null;
                        let endAt = null;
                        let endAt2 = null;
                        let price = null;
                        let seasonTime = null;
                        let seasons = [];
                        for (let priceIndex in response.data) {
                            price = response.data[priceIndex];
                            category = price.subTitle ? price.subTitle : price.hotelTypePlaceholder;
                            categories.push(category);
                            seasons = [];
                            for(let seasonIndex in price.seasonTimes) {
                                seasonTime = price.seasonTimes[seasonIndex];
                                startAt = moment(seasonTime.startAt, 'DD.MM.YYYY');
                                endAt = moment(seasonTime.endAt, 'DD.MM.YYYY');
                                endAt2 = moment(seasonTime.endAt, 'DD.MM.YYYY');
                                endAt2.add('1', 'days');
                                if(startAt.format('D') === '1' && endAt2.format('D') === '1') {
                                    if(startAt.format('M') === endAt.format('M')) {
                                        period = startAt.format('MMMM YYYY');
                                    } else {
                                        period = startAt.format('MMMM') + ' - ' + endAt.format('MMMM YYYY');
                                    }
                                } else {
                                    period = startAt.format('DD. MMMM') + ' - ' + endAt.format('DD. MMMM YYYY')
                                }
                                seasons.push(period)
                            }
                            periods.push(seasons.join(' | '));
                            unsortedPricesForHomepage.push({
                                price: price,
                                category: category,
                                period: seasons.join(' | ')
                            })
                        }
                        periods = union(periods);
                        categories = union(categories);
                        priceArrayHeadline = ['Kategorien'];
                        for(let c in categories) {
                            priceArrayHeadline.push(categories[c]);
                        }
                        pricesForHomepage.push([priceArrayHeadline]);
                        for(let p in periods) {
                            priceArrayHeadline = [periods[p]];
                            priceArrayDouble = ['Preis pro Person im DZ'];
                            priceArraySingle = ['Einzelzimmer-Zuschlag'];
                            for(let c in categories) {
                                priceArrayHeadline.push('');
                                priceForHomepage = unsortedPricesForHomepage.find(item =>
                                    item.category === categories[c] && item.period === periods[p]
                                ).price;
                                priceArrayDouble.push(priceForHomepage.calculationOrder.calculation.contingentPrices.find(cp => cp.priceType === 'double').sellPrice.numberFormatted.replace(',00', '') + ' ' + priceForHomepage.calculationOrder.calculation.contingentPrices.find(cp => cp.priceType === 'double').sellPrice.currencySymbol);
                                priceArraySingle.push(priceForHomepage.calculationOrder.calculation.contingentPrices.find(cp => cp.priceType === 'single').sellPrice.numberFormatted.replace(',00', '') + ' ' + priceForHomepage.calculationOrder.calculation.contingentPrices.find(cp => cp.priceType === 'single').sellPrice.currencySymbol);
                            }
                            pricesForHomepage.push([priceArrayHeadline, priceArrayDouble, priceArraySingle]);
                        }
                        this.saveItem('pricesForHomepage', pricesForHomepage);
                        this.$store.commit('updateData', {pricesForHomepage: pricesForHomepage});
                    }, error => {
                        this.notifyError('Die Preise konnten nicht zurückgesetzt werden')
                    }).then(() => { this.componentIsLoading['prices'] = false; });
                }
            });
        },

        prepareData: function(key, data) {
              if(key === 'webflowLinks') {
                  return Object.values(data).map(link => {
                      if(typeof link === 'string') {
                          return link
                      }
                      return {
                          ...link,
                          position: Number(link.position),
                          ...link.dayConcept && typeof link.dayConcept === 'object' && {dayConcept: '/api/day_concepts/' + link.dayConcept.id},
                      }
                  })
              }
              return data;
        },

        saveItemDebounce: debounce(function (key, data, update = false, publish = false, preview = false) {
            this.saveItem(key, data, update = false, publish = false, preview = false);
        }, 1000),

        saveItem: (function(key, data, update = false, publish = false, preview = false) {
            if(publish || preview){
                this.isLoading = true;
            }
            if(key === 'homepageCategory') {
                this.transformColors();
            }
            if(key === 'dayWebflowLinks') {
                key = 'webflowLinks';
                if(this.element.orderWebflowLinks.length > 0) {
                    data = [...data, ...this.element.orderWebflowLinks.map(item => '/api/webflow_links/' + item.id)];
                }
            }
            if(key === 'orderWebflowLinks') {
                key = 'webflowLinks';
                data = [...data, ...this.element.dayWebflowLinks.map(item => '/api/webflow_links/' + item.id)];
            }

            data = this.prepareData(key, data);

            this.$store.dispatch('storeProperty', {
                key: key,
                data: prepareForBackend(data),
                update: update,
                publish: publish,
                preview: preview
            }).then(response => {
                if(key === 'homepageDestinations') {
                    this.element.homepageDestinations = this.element.homepageDestinations.map(item => {
                        let found = response.homepageDestinations.find(rItem => rItem.name === item.name);
                        return {
                            ...item,
                            ...found && {id: found.id},
                        }
                    })
                }
                notifySuccess('Die Daten wurden gespeichert!')
            }, error => {
                notifyError("Die Daten konnten nicht gespeichert werden! Error happened")
            }).then(() => {this.isLoading = false;})
        }),
    },

    components: {
        Loading,
        HomepageInput,
        HomepageImage,
        HomepageEditor,
        HomepagePriceTable,
        HomepageStatus,
        HomepageReady,
        HomepagePublish,
        HomepageMultipleItems,
        Toggle,
        ImageCropper
    },
}
</script>
