var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "sb-wrapper" }, [
    _c("header", [
      _c("h1", [_vm._v("Dein Travelize AI Assistent")]),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "close",
          attrs: { title: "Schließen" },
          on: {
            click: function ($event) {
              $event.preventDefault()
              $event.stopPropagation()
              return _vm.$emit("hideSideBar")
            },
          },
        },
        [_c("i", { staticClass: "material-icons" }, [_vm._v("close")])]
      ),
    ]),
    _vm._v(" "),
    _vm.aiStore.data
      ? _c(
          "div",
          { staticClass: "sb-content" },
          [
            _vm._l(
              _vm.aiStore.data.messages.filter((message) => message.isVisible),
              function (message) {
                return [
                  _c(
                    "ai-message",
                    {
                      key: message.key,
                      attrs: { "is-user": message.role === "user" },
                    },
                    [
                      _c("div", {
                        domProps: { innerHTML: _vm._s(message.content) },
                      }),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "columns is-multiline ml-5 mr-5" },
                    _vm._l(message.suggestions, function (suggestion) {
                      return _c(
                        "div",
                        { staticClass: "column p-1 is-narrow" },
                        [
                          _c(
                            "button",
                            {
                              staticClass: "button",
                              on: {
                                click: function ($event) {
                                  return _vm.runSuggestion(
                                    _vm.aiStore.suggestions[suggestion]
                                  )
                                },
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.aiStore.suggestions[suggestion].label
                                )
                              ),
                            ]
                          ),
                        ]
                      )
                    }),
                    0
                  ),
                ]
              }
            ),
            _vm._v(" "),
            _vm.aiStore.isSending
              ? _c(
                  "ai-message",
                  { attrs: { "is-user": false } },
                  [
                    _c("loading", {
                      staticClass: "is-small",
                      attrs: { textInFront: "", "text-after": "" },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _c("ai-prompt"),
          ],
          2
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }