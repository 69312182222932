<template>
    <modal
        v-bind:classes  = "'placeholder'"
        v-bind:visible  = "isModalVisible"
        v-on:closeModal = "closeModal"
    >
        <template v-if="modalLoading">
            <div slot="content">
                <loading textInFront="Daten werden aktualisiert"></loading>
            </div>
        </template>


        <template v-else>
            <template slot="header">
                <div class="is-saving">
                    <i class="material-icons" v-if="isSaving === 'ok'">check</i>
                    <i class="material-icons icon-spin" v-else-if="isSaving">refresh</i>
                </div>

                <div class="name">
                    {{ localPlaceholder.startAirport.place.name }} - {{ localPlaceholder.endAirport.place.name }}
                </div>

                <div class="header-extra-info">
                    <div>
                        <i class="material-icons" title="Flug">airplanemode_active</i>
                        <async-select
                            ref                 = "startAirport"
                            class               = "has-margin-right is-medium"
                            v-model             = "localPlaceholder.startAirport"
                            api                 = "airports"
                            v-bind:custom-label = "airport => airport.name + ' - ' + airport.place.name"
                            crudLinkTitle       = "Fügen Sie einen neuen Flughafen hinzu"
                            v-bind:clearable    = "false"
                            v-bind:allow-empty  = "false"
                            placeholder         = "Flughafen finden"
                        ></async-select>

                        <async-select
                            class               = "has-margin-right is-medium"
                            v-model             = "localPlaceholder.endAirport"
                            api                 = "airports"
                            v-bind:custom-label = "airport => airport.name + ' - ' + airport.place.name"
                            crudLinkTitle       = "Fügen Sie einen neuen Flughafen hinzu"
                            v-bind:clearable    = "false"
                            v-bind:allow-empty  = "false"
                            placeholder         = "Flughafen finden"
                        ></async-select>
                    </div>
                    <div>
                        <i class="material-icons">access_time</i>
                        <date-time class="has-margin-right" v-model="localPlaceholder.startAt"></date-time> -
                        <date-time  class="has-margin-left" v-model="localPlaceholder.endAt"></date-time>
                    </div>


                    <div class="is-block">
                        <div class="level mb-1">
                            <i title="Set as Additional" class="material-icons">shopping_cart</i>
                            <div class="select">
                                <select v-model="localPlaceholder.clientOffer.status">
                                    <option value="included">inkludiert</option>
                                    <option value="optional">optional</option>
                                    <option value="additional">gesondert</option>
                                    <option value="atLocation">vor Ort</option>
                                </select>
                            </div>
                        </div>
                        <div class="level mb-1">
                            <i class="material-icons">directions_bus</i>
                            <toggle-switch v-model="localPlaceholder.driverNotParticipating">
                                <option :value="false">mit</option>
                                <option :value="true">ohne Fahrer</option>
                            </toggle-switch>
                        </div>
                    </div>

                </div>


                <button class="button is-large is-success is-add-new" v-on:click="addNewRequest"><i class="fa fa-plus-circle" aria-hidden="true"></i> Leistungsträger hinzufügen</button>
            </template>


            <div slot="content">
                <request
                    v-for="(request, index) in localPlaceholder.requests"
                    v-bind:key="request.id"
                    v-if="!isNewRequestVisible && request.id"
                    v-bind:placeholderInfo="placeholderInfo"
                    v-model="localPlaceholder.requests[index]"
                    v-on:closeModal="$emit('closeModal', localPlaceholder)"
                    v-bind:calculationRequest="calculationRequest"
                    :selected-request="selectedRequest"
                    v-on:setCalculationRequest="setCalculationRequest"
                    v-on:setSelectRequest="setSelectRequest"
                    v-on:saved="handleRequestSaved"
                ></request>


                <new-request
                    v-if="isNewRequestVisible"
                    v-bind:placeholderID="localPlaceholder.id"
                    v-bind:placeholderInfo="placeholderInfo"
                    v-model="localPlaceholder.requests[localPlaceholder.requests.length - 1]"
                    v-on:close="closeNewRequestModal"
                    v-on:closeModal="$emit('closeModal', localPlaceholder)"
                    v-on:saved="handleRequestSaved"
                ></new-request>
            </div>
        </template>
    </modal>
</template>



<script>
import mixins from './mixins';
import Request    from './requests/Airline';
import NewRequest from './newRequests/Airline';
import ToggleSwitch from "@components/form/ToggleSwitch.vue";


export default {
    mixins: [mixins],


    computed: {
        emptyRequest: function () {
            return {
                agency:         null,
                airline:        null,
                startAt:        this.localPlaceholder.startAt,
                endAt:          this.localPlaceholder.endAt,
                startAirport:   this.localPlaceholder.startAirport,
                endAirport:     this.localPlaceholder.endAirport,
                placeholder:    '/api/airline_placeholders/' + this.localPlaceholder.id,
                contingent:     {
                    extraDescription: null,
                    extraAskedAmount: null,
                    extraReservedAmount: null
                },
                flightNumber:   null,
                ticketNr: null,
                foodOnBoard: null,
                optionTodo: {dueDate: null},
                itemPrices: []
            }
        },

        placeholderInfo: function () {
            return {
                startAirport: this.localPlaceholder.startAirport,
                endAirport:   this.localPlaceholder.endAirport
            };
        }
    },


    data: function () {
        return {
            typeOfPlaceholder: 'airline',
            oldValue: null,
            apiEndpoint: 'airline_placeholders',
        }
    },
    watch : {
        'localPlaceholder': {
            deep: true,
            handler: function (value) {
                value=_.pick(value, 'id', 'startAirport', 'endAirport','startAt', 'endAt', 'clientOffer.status', 'driverNotParticipating')
                if (this.oldValue && JSON.stringify(this.oldValue) !== JSON.stringify(value) ) {
                    let reload = this.oldValue.driverNotParticipating !== value.driverNotParticipating;
                    this.savePlaceholder(value, reload)
                }
                this.oldValue= JSON.parse(JSON.stringify(value))
            }
        }
    },


    components: {
        ToggleSwitch,
        NewRequest,
        Request
    }
}
</script>
