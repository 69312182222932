<template>
    <div>
        <button
            v-bind:class = "['btn-single-toggle',  isRouteDescription ? 'is-active' : '']"
            v-on:click   = "isRouteDescription = !isRouteDescription"
            style="border: 1px solid #dddddd"
        ><i class="material-icons">place</i></button>

        <multiselect
            v-model="otherServiceTypeSelected"
            v-bind:options="items"
            v-bind:loading="isLoadingItems"
            v-bind:allow-empty="false"
            v-bind:custom-label="serviceLabel"
            placeholder="Zusatzleistung auswählen"
            track-by="id"
            select-label=""
            deselect-label="Löschen"
            v-on:search-change = "searchOtherServiceTypes"
        >
            <template slot="noResult">Keine passenden Leistungsträger</template>

            <span slot="caret">
                <a
                    class  = "multiselect-crud-link"
                    href   = "/other_service_types?add"
                    title  = "Füge einen neuen Leistungsträger hinzu"
                    target = "_blank"
                ><i class="material-icons">add</i></a>
            </span>
        </multiselect>
    </div>
</template>



<script>
import axios           from 'axios';
import debounce        from 'lodash/debounce';
import Multiselect     from '@components/form/Multiselect';
import { notifyError } from '@components/Notification';
import {getOtherServiceTypes, getPredefinedRoutesList} from '@api'
import mixins from "@orders/placeholders/components/forms/mixins";


export default {
    mixins: [mixins],
    props: {
        value:           { required: true },
        placeholderInfo: { required: true },
        showServiceDayTimes: {default: false},
        locale: {type: String, required: false}
    },


    data: function () {
        return {
            otherServiceTypeSelected: null,
            items:                    [],
            queryString:              '',
            isLoadingItems:           false,
            isRouteDescription:       false
        }
    },


    methods: {
        serviceLabel:  function (otherServiceType) {
            if (this.isRouteDescription) {
                return otherServiceType.routeDescription

            } else {
                return otherServiceType.name;
            }
        },

        searchOtherServiceTypes: debounce(function (query) {
                if (query) {
                    this.otherServiceTypeSearch(query, true);
                }

        }, 900),


        otherServiceTypeSearch: function (query, isTriggerAction = false) {
            if (query || isTriggerAction) {
                var apiQuery = '?';
                var apiQuery2 = '?';

                this.queryString = query;

                apiQuery += '&_itemsPerPage=20'
                apiQuery2 += '&_itemsPerPage=20'

                if(this.placeholderInfo.category){
                    apiQuery += '&category=' + this.placeholderInfo.category;
                    apiQuery2 += '&category=' + this.placeholderInfo.category;
                }

                apiQuery2 += '&area[exists]=false&destination[exists]=false&place[exists]=false';

                if (this.placeholderInfo.area) {
                    apiQuery += '&area.id=' + this.placeholderInfo.area.id;
                } else if (this.placeholderInfo.place) {
                    apiQuery += '&place.id=' + this.placeholderInfo.place.id;

                } else if (this.placeholderInfo.destination) {
                    apiQuery += '&destination.id=' + this.placeholderInfo.destination.id;
                }

                if(this.showServiceDayTimes){
                    apiQuery += '&_groups[]=other_service_type:service_day_time';
                    apiQuery2 += '&_groups[]=other_service_type:service_day_time';
                }

                if(query) {
                    apiQuery += '&_search=' + query;
                    apiQuery2 += '&_search=' + query;
                }

                this.isLoadingItems = true;


                this.callCancelRequest();
                getOtherServiceTypes(apiQuery, {cancelToken: this.cancelSource.token, headers: {'X-LOCALE': this.locale}})
                    .then(response => {

                        this.items = response.data;

                        if (!this.otherServiceTypeSelected && this.items.length > 0) {
                            this.otherServiceTypeSelected = this.items[0];
                        }

                        getOtherServiceTypes(apiQuery2, {cancelToken: this.cancelSource.token, headers: {'X-LOCALE': this.locale}}).then(response2 => {

                            this.items = this.items.concat(response2.data);

                            if (!this.otherServiceTypeSelected && this.items.length > 0) {
                                this.otherServiceTypeSelected = this.items[0];
                            }

                        }, error => {
                            if(!axios.isCancel(error)) {
                                notifyError('Die Zusatzleistungen konnten nicht geladen werden! Server error!');
                            }
                        })

                    }, error => {
                        if(!axios.isCancel(error)) {
                            notifyError('Die Zusatzleistungen konnten nicht geladen werden! Server error!');
                        }
                    })
                    .then(() => { this.isLoadingItems = false; });


            }
        }
    },


    watch: {
        placeholderInfo: {
            handler: function (newValue, oldValue) {
                if (JSON.stringify(newValue) !== JSON.stringify(oldValue)) {
                    this.otherServiceTypeSearch(this.queryString, true);
                }
            },
            deep: true
        },


        value: {
            handler: function (newValue, oldValue) {
                if (JSON.stringify(newValue) !== JSON.stringify(oldValue)) {
                    this.otherServiceTypeSelected = JSON.parse(JSON.stringify(this.value));
                }
            },
            deep: true,
            immediate: true
        },


        otherServiceTypeSelected: {
            handler: function (value) {
                this.$emit('input', value);
            },
            deep: true
        }
    },


    components: {
        Multiselect
    }
}
</script>
