var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.isLoading
        ? _c("loading")
        : _c(
            "div",
            [
              _c("div", { staticClass: "level" }, [
                _c("div"),
                _vm._v(" "),
                _c("div", { staticClass: "buttons has-addons" }, [
                  _c(
                    "button",
                    {
                      class: [
                        "btn-single-toggle",
                        _vm.isDraggableDisabled ? "" : "is-active",
                      ],
                      attrs: {
                        title: _vm.isDraggableDisabled
                          ? "Ziehen aktivieren"
                          : "Ziehen blockieren",
                      },
                      on: {
                        click: function ($event) {
                          _vm.isDraggableDisabled = !_vm.isDraggableDisabled
                        },
                      },
                    },
                    [
                      _c("i", { staticClass: "material-icons" }, [
                        _vm._v("lock"),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      class: [
                        "btn-single-toggle",
                        _vm.isRouteView ? "is-active" : "",
                      ],
                      staticStyle: { "margin-right": "1px" },
                      on: {
                        click: function ($event) {
                          _vm.isRouteView = !_vm.isRouteView
                        },
                      },
                    },
                    [
                      _c("i", { staticClass: "material-icons" }, [
                        _vm._v("place"),
                      ]),
                    ]
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "is-relative" },
                [
                  _c(
                    "draggable",
                    {
                      attrs: {
                        list: _vm.sortedDays,
                        options: {
                          disabled:
                            _vm.draggableDisabled || _vm.isDraggableDisabled,
                        },
                      },
                      on: { change: _vm.changeOrder },
                    },
                    [
                      _vm._l(_vm.sortedDays, function (day, index) {
                        return _c("directions", {
                          key: day.id,
                          attrs: {
                            day: day,
                            options: {
                              ..._vm.options,
                              dayDestinations: _vm.item.destinations,
                            },
                            "count-order-concepts": day.countOrderConcepts,
                            "is-route-view": _vm.isRouteView,
                            "is-draggable-disabled": _vm.isDraggableDisabled,
                            dayCount: index + 1,
                          },
                          on: {
                            updateDay: _vm.updateDay,
                            changeRouteView: function ($event) {
                              _vm.isRouteView = true
                            },
                            deleteDay: function ($event) {
                              return _vm.deleteDay(day.id)
                            },
                            openDayForm: function ($event) {
                              return _vm.openDayConceptForm(day)
                            },
                          },
                        })
                      }),
                      _vm._v(" "),
                      _c("div", { attrs: { slot: "footer" }, slot: "footer" }),
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _vm.draggableDisabled
                    ? _c(
                        "div",
                        { staticClass: "loading-container" },
                        [_vm.draggableDisabled ? _c("loading") : _vm._e()],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              !_vm.showConfigurator
                ? _c(
                    "div",
                    { staticClass: "buttons is-fullwidth has-addons" },
                    [
                      _c(
                        "button",
                        {
                          staticClass: "button is-dashed",
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              _vm.showConfigurator = true
                            },
                          },
                        },
                        [
                          _c("i", { staticClass: "material-icons" }, [
                            _vm._v("library_books"),
                          ]),
                          _vm._v(
                            " Weiteres bestehendes Tageskonzept hinzufügen\n                "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass: "button is-dashed",
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.addDayConcept.apply(null, arguments)
                            },
                          },
                        },
                        [
                          _c("i", { staticClass: "material-icons" }, [
                            _vm._v("add_circle"),
                          ]),
                          _vm._v(
                            "\n                    Neues Tageskonzept erstellen\n                "
                          ),
                        ]
                      ),
                    ]
                  )
                : _vm._e(),
              _vm._v(" "),
              _c("concept-provider-form", {
                ref: "conceptProviderForm",
                attrs: { options: [] },
              }),
              _vm._v(" "),
              _vm.showConfigurator
                ? _c("day-concept", {
                    staticClass: "is-dashed",
                    attrs: {
                      selected: _vm.selectedDayIds,
                      dayCount: _vm.item.days.length,
                      options: _vm.loadedOptions,
                      destinations: _vm.item.destinations,
                    },
                    on: {
                      cancel: function ($event) {
                        _vm.showConfigurator = false
                      },
                      submit: _vm.storeDay,
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              _c("day-concept-form", {
                ref: "dayConceptForm",
                attrs: {
                  formType: "modal",
                  options: _vm.loadedOptions.dayConcept,
                },
                on: { submit: _vm.updateDay },
              }),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }