<template>
    <div class="day accordion has-hidden-arrow">
        <div class="accordion-header is-static">
            <header class="level">
                <div class="level-left">
                    <div class="level-left level-item" v-if="dayCount > 0">
                        <i class="material-icons level-item">date_range</i>
                        <div class="select">
                            <select v-model="conceptIndex">
                                <option
                                    v-for="(placeName, index) in dayNumbers"
                                    v-bind:key="`${placeName}-${index}`"
                                    v-bind:value="index"
                                >{{placeName}}</option>
                            </select>
                        </div>
                    </div>

                    <div class="level-left level-item">
                        <i class="material-icons level-item">search</i>
                        <input
                            type="text"
                            class="input is-medium"
                            placeholder="Suche ein Konzept"
                            v-model="searchTerm"
                        />
                    </div>

                    <div class="level-left">
                        <label class="checkbox level-left">
                            <input
                                type="checkbox"
                                v-model="searchInDestinations"
                            >nur in relevanten Destinationen suchen
                        </label>
                    </div>

                    <div class="level-left">
                        <label class="checkbox level-left">
                            <input
                                type="checkbox"
                                v-model="showHiddenConcepts"
                            >versteckte Konzepte einblenden
                        </label>
                    </div>
                </div>

                <div class="buttons" v-if="!isLoading">
                    <button
                        class="button"
                        v-on:click.prevent="cancel"
                    >Verwerfen</button>

                    <button
                        class="button is-primary"
                        v-on:click.prevent="submit"
                        v-if="conceptSelected"
                    >Speichern</button>
                </div>
            </header>
        </div>

        <div class="accordion-content">
            <search-day-concept
                v-bind:searchTerm    = "searchTerm"
                v-bind:destinations  = "searchInDestinations ? destinations : []"
                :showHiddenConcepts = "showHiddenConcepts"
                v-bind:dayConceptIDs = "selected"
                v-on:input           = "value => conceptSelected = value"
                v-bind:editablePlaceholders= "false"
            ></search-day-concept>

            <loading v-if="isLoading" />
        </div>
    </div>
</template>



<script>
import SearchDayConcept from '@orders/concepts/components/SearchDayConcept';
import Loading from '@components/Loading';


export default {
    props: {
        dropdown:     { type: Boolean, default: false, },
        destinations: { type: Array, required: false, },
        selected:     { type: Array, },
        dayCount:     { type: Number, default: 0 },
        options:      { type: Object }
    },


    computed: {
        dayNumbers: function () {
            let numbers = ['vor Tag 1'];
            for (let i = 0; i < this.dayCount; i++) {
                numbers.push(`nach Tag ${(i+1)}`);
            }
            return numbers;
        }
    },


    data: function () {
        return {
            searchTerm: '',
            searchInDestinations: true,
            showHiddenConcepts: false,

            conceptIndex: this.dayCount,
            conceptSelected: false,

            searchResults: [],
            isLoading: false,
        }
    },


    methods: {
        conceptCategoryName: function (concept) {
            const optionCategory = this.options.dayConcept.categories &&
                this.options.dayConcept.categories.find(c => c.key === concept.category);

            return optionCategory ? optionCategory.value : concept.category;
        },


        cancel: function () {
            this.clear();
            this.$emit('cancel');
        },


        submit: function () {
            this.$emit('submit', {concept: this.conceptSelected, index: this.conceptIndex});
            this.clear();
        },


        clear: function () {
            this.searchTerm      = '';
            this.conceptSelected = {};
        }
    },


    components: {
        Loading,
        SearchDayConcept
    }
}
</script>
