<template>
    <div class="requests-table">
        <label>Leistungen:</label>
        <div class="redesign-table">
            <header>
                <div><input type="checkbox" v-model="checkAll" /></div>
                <div style="width: 55%; padding-right: 10px">Leistung</div>
                <div style="width: 10%">Datum</div>
                <div style="width: 10%">Status</div>
                <div style="width: 10%">Details</div>
                <div style="width: 10%">Deadline</div>
            </header>

            <main>
                <template v-for="orderId in orderIds">
                    <div class="row is-headline"># {{ orderId }}</div>
                    <div
                        v-for      = "request in sortedRequests(orderId)"
                        v-bind:key = "request.id"
                    >
                        <div><input type="checkbox" v-bind:checked="isChecked(request)" v-on:change="check(request)" /></div>
                        <div style="width: 55%; padding-right: 10px">
                            <i v-if="request.requestType === 'hotel'" class="material-icons" title="Hotel">home</i>
                            <i v-else-if="request.requestType === 'ferry'" class="material-icons" title="Fähre">directions_boat</i>
                            <i v-else-if="request.requestType === 'airline'" class="material-icons" title="Flug">airplanemode_active</i>
                            <i v-else-if="request.requestType === 'train'" class="material-icons" title="Zug">directions_train</i>
                            <other-service-icons v-else v-bind:type="request.otherServiceType.category"></other-service-icons>
                            {{ request.info.title }}
                        </div>
                        <div style="width: 10%">{{ request.info.startAt | dayMonth }}  -  {{ request.info.endAt | fullYear }}</div>

                        <!-- Status -->
                        <div v-if="options" style="width: 10%">{{ getValueWithKey({ key: request.requestStatus.status, optionList: options.status }) }}</div>
                        <div v-else>request.requestStatus.status</div>

                        <!-- Details -->
                        <div v-if="request.requestType === 'hotel'" style="width: 10%">
                            <template v-if="options">{{ getValueWithKey({ key: request.board, optionList: options.boardList }) }}</template>
                            <template v-else></template>
                        </div>
                        <div v-else-if="request.requestType === 'ferry'" style="width: 10%">
                            {{ request.type === 'night' ? 'Nachtfähre' : 'Tagfähre' }}
                        </div>
                        <div v-else style="width: 10%"></div>

                        <div style="width: 10%"><div v-if="request.optionTodo && request.optionTodo.dueDate" v-bind:class="['deadline-date', deadlineUrgency(request.optionTodo.dueDate)]"><i class="material-icons">notifications_none</i> {{ request.optionTodo.dueDate | fullYear }}</div></div>
                    </div>
                </template>
            </main>
        </div>
    </div>
</template>



<script>
import uniqBy from 'lodash/uniqBy';
import OtherServiceIcons from '@components/OtherServiceIcons';
import { dayMonth, deadlineUrgency, fullYear, getValueWithKey, compareDates } from '@utilities/functions';


export default {
    props: {
        requests: { type: Array, required: true },
        options:  {}
    },


    computed: {
        orderIds: function () {
            return uniqBy(this.requests, 'orderId').map(request => request.orderId);
        },

        requestsSelected: {
            get: function () {
                return this.$store.state.requestsSelected;
            },
            set: function (value) {
                this.$store.state.requestsSelected = value;
                this.$store.dispatch('fillEmailBody');
            }
        },


        checkAll: {
            get: function () {
                return this.requests.length === this.requestsSelected.length;
            },

            set: function (value) {
                if (value) {
                    this.requestsSelected = JSON.parse(JSON.stringify(this.requests));

                } else {
                    this.requestsSelected = [];
                }
            }
        },
    },





    methods: {
        sortedRequests: function (orderId) {
            return [...this.requests]
                .filter(request => request.orderId === orderId)
                .sort((a, b) => {
                    return compareDates(a.startAt, b.startAt) || a._sort - b._sort;
                })
        },

        isChecked: function (request) {
            const index = this.requestsSelected.findIndex(requestItem => requestItem.id === request.id);
            return index !== -1;
        },


        check: function (request) {
            const index = this.requestsSelected.findIndex(requestItem => requestItem.id === request.id);


            if (index !== -1) {
                // it is selected => need to deselect it
                this.requestsSelected.splice(index, 1);

            } else {
                this.requestsSelected.push(request);
            }
            this.$store.dispatch('fillEmailBody');
        },


        selectAll() {
            this.requestsSelected = JSON.parse(JSON.stringify(this.requests));
        },

        deadlineUrgency,
        getValueWithKey
    },
    mounted: function() {
        this.requestsSelected = this.requestsSelected.filter(request => !['canceled', 'called_off'].includes(request.requestStatus.status));
        this.$store.dispatch('fillEmailBody');
    },


    watch: {
        requests: function () {
            this.selectAll();
        },
    },

    created() {
        this.selectAll();
    },


    filters: {
        dayMonth,
        fullYear
    },


    components: {
        OtherServiceIcons
    }
}
</script>
