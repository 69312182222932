var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "bp-panel",
      class: { "is-hover": _vm.isHover },
      on: {
        mouseenter: function ($event) {
          _vm.isHover = true
        },
        mouseleave: function ($event) {
          _vm.isHover = false
        },
        click: function ($event) {
          _vm.isHover = false
        },
      },
    },
    [
      _vm.label
        ? _c("span", { staticClass: "panel-label" }, [
            _vm._v(_vm._s(_vm.label)),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "panel-actions" },
        [
          _vm._t("actions"),
          _vm._v(" "),
          _c(
            "button",
            {
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  $event.stopPropagation()
                  return _vm.$emit("edit")
                },
              },
            },
            [
              _c("i", { staticClass: "material-icons" }, [_vm._v("edit")]),
              _vm._v("Ändern"),
            ]
          ),
          _vm._v(" "),
          _c(
            "button",
            {
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  $event.stopPropagation()
                  return _vm.$emit("delete")
                },
              },
            },
            [
              _c("i", { staticClass: "material-icons" }, [_vm._v("delete")]),
              _vm._v("Löschen"),
            ]
          ),
          _vm._v(" "),
          _c(
            "update-popover",
            { attrs: { update: _vm.update, api: _vm.api } },
            [
              _c("button", [
                _c("i", { staticClass: "material-icons" }, [_vm._v("update")]),
              ]),
            ]
          ),
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "panel-content" },
        [
          _vm.isHover ? _vm._t("hover") : _vm._e(),
          _vm._v(" "),
          _vm._t("default"),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }