import has            from 'lodash/has';
import get            from 'lodash/get';
import { store }      from '@utilities/store';
import {listOfDays, compareDates} from '@utilities/functions';
import {
    getOrderTripDetails,
    optionsTripDetails,
    getRenderedTripDetailTemplates,
    getDaysTripDetails, getDayTripDetails, getAxios
} from '@api';


const getPlaceholders = function (day) {
    const placeholders = [
        ...day.airlinePlaceholders.map(placeholder => ({
            ...placeholder,
            key: `airline-${placeholder.id}`
        })),
        ...day.ferryPlaceholders.map(placeholder => ({
            ...placeholder,
            key: `ferry-${placeholder.id}`
        })),
        ...day.trainPlaceholders.map(placeholder => ({
            ...placeholder,
            key: `train-${placeholder.id}`
        })),
        ...day.hotelPlaceholders.map(placeholder => ({
            ...placeholder,
            key: `hotel-${placeholder.id}`
        })),
        ...day.otherPlaceholders.map(placeholder => ({
            ...placeholder,
            key: `other-${placeholder.id}`
        })),
        ...day.routeDetails.map(placeholder => ({
            ...placeholder,
            key: `route-${placeholder.id}`
        })),
    ];

    // Removing empty placeholder
    return [...placeholders]
        .filter(item => item.displayedRequest && !item.noOwnTripDetails)
        .sort((a, b) => {
            return day.sortHelper.indexOf(a.key) > day.sortHelper.indexOf(b.key)
                ? 1
                : -1;
        });
};

store.registerModule('tripDetails', {
    namespaced: true,

    state: {
        order: {
            formal: true,
            client: {
                contacts: [],
                addresses: []
            },
            trip: {
                destinations: []
            }
        },

        days: [],

        options: {
            type: []
        },

        intro: {
            attachRouteDescription: null,
            contact: null,
            title: 'sie',
        },
        introInfo: {
            title: '',
            text: '',
            type: 'intro_info'
        },
        generalInfos: [],
        templates: [],
        menus: [],

        selectedPlaceholder: null,
        selectedDay: null,

        isLoading: false,
    },


    mutations: {
        updateLoading: function (state, value) {
            state.isLoading = value;
        },

        updateOrder: function (state, newOrder) {
            const order = { ...state.order, ...JSON.parse(JSON.stringify(newOrder)) };
            state.order = order;

            state.intro = {...state.intro,
                ...order.orderContacts && order.orderContacts.length > 0 && { contact: order.orderContacts[0].contact },
                ...order.client.addresses.length > 0 && { address : order.client.addresses[0] }
            }
        },

        updateDayProgress: function (state, tripDetailProgress) {
            state.selectedDay = {
                ...state.selectedDay,
                tripDetailProgress
            };

            state.days = state.days.map(day => ({
                ...day,
                ...day.id === state.selectedDay.id && {
                    tripDetailProgress
                }
            }))
        },

        updatePlaceholder: function (state, placeholder) {
            let newPlaceholder;

            if (has(placeholder, 'id') && has(placeholder, 'type')) {
                const dayPlaceholder = state.selectedDay[`${placeholder.type}Placeholders`]
                    .find(({ id }) => id === placeholder.id)

                newPlaceholder = {
                    ...dayPlaceholder,
                    ...placeholder,
                    displayedRequest: {
                        ...dayPlaceholder.displayedRequest,
                        ...get(placeholder, 'displayedRequest', {}),
                        placeholder
                    }
                };
            } else if (state.selectedPlaceholder) {
                newPlaceholder = {
                    ...state.selectedPlaceholder,
                    ...placeholder,
                    displayedRequest: {
                        ...state.selectedPlaceholder.displayedRequest,
                        ...get(placeholder, 'displayedRequest', {}),
                        placeholder
                    }
                };

                state.selectedPlaceholder = JSON.parse(JSON.stringify(newPlaceholder));
            }

            const newDay = {
                ...state.selectedDay,
                ...newPlaceholder.info.type === 'ferry' && {
                    ferryPlaceholders: state.selectedDay.ferryPlaceholders.map(placeholder => {
                        if (placeholder.id === newPlaceholder.id) {
                            return newPlaceholder;

                        } else {
                            return placeholder;
                        }
                    })
                },
                ...newPlaceholder.info.type === 'train' && {
                    trainPlaceholders: state.selectedDay.trainPlaceholders.map(placeholder => {
                        if (placeholder.id === newPlaceholder.id) {
                            return newPlaceholder;

                        } else {
                            return placeholder;
                        }
                    })
                },
                ...newPlaceholder.info.type === 'hotel' && {
                    hotelPlaceholders: state.selectedDay.hotelPlaceholders.map(placeholder => {
                        if (placeholder.id === newPlaceholder.id) {
                            return newPlaceholder;

                        } else {
                            return placeholder;
                        }
                    })
                },
                ...newPlaceholder.info.type === 'airline' && {
                    airlinePlaceholders: state.selectedDay.airlinePlaceholders.map(placeholder => {
                        if (placeholder.id === newPlaceholder.id) {
                            return newPlaceholder;
                        } else {
                            return placeholder;
                        }
                    })
                },
                ...newPlaceholder.info.type === 'other' && {
                    otherPlaceholders: state.selectedDay.otherPlaceholders.map(placeholder => {
                        if (placeholder.id === newPlaceholder.id) {
                            return newPlaceholder;

                        } else {
                            return placeholder;
                        }
                    })
                },
                ...!!newPlaceholder.predefinedRouteDetail && {
                    routeDetails: state.selectedDay.routeDetails.map(placeholder => {
                        if (placeholder.id === newPlaceholder.id) {
                            return newPlaceholder;

                        } else {
                            return placeholder;
                        }
                    })
                }
            };

            state.selectedDay = JSON.parse(JSON.stringify(newDay));
            state.days = [...state.days].map(day => {
                if (day.id === newDay.id) {
                    return JSON.parse(JSON.stringify(newDay));

                } else {
                    return day;
                }
            });
        },

        updateIntro: function (state, newIntro) {
            state.intro = {...state.intro, ...newIntro};
        },

        updateIntroInfo: function (state, newinfo) {
            state.introInfo = {...state.introInfo, ...newinfo};
        },

        updateTemplates: function (state, newTemplates) {
            state.templates = [...newTemplates];
        },

        updateGeneralInfos: function (state, infos) {
            state.generalInfos = [...infos].sort((a, b) => {
                return a.positionInDocument > b.positionInDocument ? 1 : -1;
            });
        },

        updateGeneralInfosSequence: function (state, sequence) {
            state.generalInfos = [...state.generalInfos].sort((a, b) => {
                return sequence.indexOf(a.id) > sequence.indexOf(b.id) ? 1 : -1;
            });
        },

        addGeneralInfo: function (state, newInfo) {
            state.generalInfos = [...state.generalInfos, newInfo].sort((a, b) => {
                return a.positionInDocument > b.positionInDocument ? 1 : -1;
            });
        },

        updateGeneralInfo: function (state, newInfo) {
            state.generalInfos = state.generalInfos
                .map(info => {
                    if (info.id !== newInfo.id) {
                        return info;

                    } else {
                        return newInfo;
                    }
                });
        },

        deleteGeneralInfo: function (state, info) {
            state.generalInfos = state.generalInfos
                .filter(({ id }) => id !== info.id);
        },

        updateDays: function (state, newDays) {
            state.days = newDays;
        },

        updateMenus: function (state, data) {
            state.menus = data;
        },


        selectDay: function (state, newDay) {
            state.selectedDay = JSON.parse(JSON.stringify(newDay));
            if (state.days.findIndex(item => item.id === newDay.id) !== -1) {
                state.days[state.days.findIndex(item => item.id === newDay.id)] =  JSON.parse(JSON.stringify(newDay));
            }
        },

        selectPlaceholder: function (state, newPlaceholder) {
            state.selectedPlaceholder = JSON.parse(JSON.stringify(newPlaceholder));
        },

        addTripDetail: function (state, detail) {
            if(detail.type === 'menu') {
                state.menus.push(detail);
            } else {
                if (state.selectedPlaceholder) {
                    state.selectedPlaceholder = {
                        ...state.selectedPlaceholder,
                        displayedRequest: {
                            ...state.selectedPlaceholder.displayedRequest,
                            tripDetails: [
                                ...state.selectedPlaceholder.displayedRequest.tripDetails,
                                detail
                            ]
                        }
                    };
                }
                const types = ['airline', 'ferry', 'train', 'hotel', 'other'];
                state.days[state.days.findIndex(item => item.id === state.selectedDay.id)] = {
                    ...state.selectedDay,
                    ...types.reduce((placeholders, type) => {
                        const placeholderType = `${type}Placeholders`
                        placeholders[placeholderType] = state.selectedDay[placeholderType]
                            .map(placeholder => ({
                                ...placeholder,
                                ...placeholder.displayedRequest && placeholder.displayedRequest.id === detail.request.id && {
                                    displayedRequest: {
                                        ...placeholder.displayedRequest,
                                        tripDetails: [
                                            ...placeholder.displayedRequest.tripDetails,
                                            detail
                                        ]
                                    }
                                }
                            }));
                        return placeholders;
                    }, {})
                };
            }
        },

        editTripDetail: function (state, detail) {
            if(detail.type === 'menu') {
                state.menus = state.menus.map(tripDetail => ({
                    ...tripDetail,
                    ...tripDetail.id === detail.id && detail
                }))
            } else {
                if (state.selectedPlaceholder) {
                    state.selectedPlaceholder = {
                        ...state.selectedPlaceholder,
                        displayedRequest: {
                            ...state.selectedPlaceholder.displayedRequest,
                            tripDetails: [
                                ...state.selectedPlaceholder.displayedRequest.tripDetails
                                    .filter(({ id }) => id !== detail.id),
                                {
                                    ...state.selectedPlaceholder.displayedRequest.tripDetails
                                        .find(({ id }) => id === detail.id),
                                    ...detail
                                }
                            ]
                        }
                    };
                }
                const types = ['airline', 'ferry', 'train', 'hotel', 'other'];
                let index = state.days.findIndex(item => item.id === state.selectedDay.id);
                state.days[index] = {
                    ...state.days[index],
                    ...types.reduce((placeholders, type) => {
                        const placeholderType = `${type}Placeholders`
                        placeholders[placeholderType] = state.days[index][placeholderType]
                            .map(placeholder => ({
                                ...placeholder,
                                ...placeholder.displayedRequest && {
                                    displayedRequest: {
                                        ...placeholder.displayedRequest,
                                        tripDetails: placeholder.displayedRequest.tripDetails.map(tripDetail => ({
                                            ...tripDetail,
                                            ...tripDetail.id === detail.id && detail
                                        }))
                                    }
                                }
                            }));

                        return placeholders;
                    }, {})
                }
            }


           /* state.days = state.days.map(day => ({
                ...day,
                ...types.reduce((placeholders, type) => {
                    const placeholderType = `${type}Placeholders`
                        placeholders[placeholderType] = day[placeholderType]
                            .map(placeholder => ({
                                ...placeholder,
                                ...placeholder.displayedRequest && {
                                    displayedRequest: {
                                        ...placeholder.displayedRequest,
                                        tripDetails: placeholder.displayedRequest.tripDetails.map(tripDetail => ({
                                            ...tripDetail,
                                            ...tripDetail.id === detail.id && detail
                                        }))
                                    }
                                }
                            }));

                    return placeholders;
                }, {})
            }));*/
        },

        deleteTripDetail: function (state, { id, type }) {
            if(type === 'menu') {
                state.menus = state.menus.filter(detail => detail.id !== id);
            } else {
                if (state.selectedPlaceholder) {
                    state.selectedPlaceholder = {
                        ...state.selectedPlaceholder,
                        displayedRequest: {
                            ...state.selectedPlaceholder.displayedRequest,
                            tripDetails: state.selectedPlaceholder.displayedRequest.tripDetails
                                .filter(detail => detail.id !== id)
                        }
                    };
                }

                const types = ['airline', 'ferry', 'train', 'hotel', 'other'];
                state.days[state.days.findIndex(item => item.id === state.selectedDay.id)] = {
                    ...state.selectedDay,
                    ...types.reduce((placeholders, type) => {
                        const placeholderType = `${type}Placeholders`
                        placeholders[placeholderType] = state.selectedDay[placeholderType]
                            .map(placeholder => ({
                                ...placeholder,
                                ...placeholder.displayedRequest && {
                                    displayedRequest: {
                                        ...placeholder.displayedRequest,
                                        tripDetails: placeholder.displayedRequest.tripDetails
                                            .filter(detail => detail.id !== id)
                                    }
                                }
                            }));
                        return placeholders;
                    }, {})
                };
            }

        },

        updateTripDetailSequence: function (state, sequence) {
            state.selectedPlaceholder = {
                ...state.selectedPlaceholder,
                displayedRequest: {
                    ...state.selectedPlaceholder.displayedRequest,
                    tripDetails: state.selectedPlaceholder.displayedRequest.tripDetails
                        .map(tripDetail => {
                            if (sequence.includes(tripDetail.id)) {
                                return {
                                    ...tripDetail,
                                    positionInDocument: sequence.findIndex(id => id === tripDetail.id)
                                };

                            } else {
                                return tripDetail;
                            }
                        })
                }
            };
        },

        updateRequestNotes: function (state, notes) {
            const requestID = state.selectedPlaceholder.displayedRequest.id;

            state.selectedPlaceholder = {
                ...state.selectedPlaceholder,
                displayedRequest: {
                    ...state.selectedPlaceholder.displayedRequest,
                    notes
                }
            };

            state.days = state.days.map(day => ({
                ...day,
                airlinePlaceholders: day.airlinePlaceholders.map(placeholder => ({
                    ...placeholder,
                    ...placeholder.displayedRequest && {
                        displayedRequest: {
                            ...placeholder.displayedRequest,
                            ...placeholder.displayedRequest.id === requestID && { notes }
                        }
                    }
                })),
                ferryPlaceholders: day.ferryPlaceholders.map(placeholder => ({
                    ...placeholder,
                    ...placeholder.displayedRequest && {
                        displayedRequest: {
                            ...placeholder.displayedRequest,
                            ...placeholder.displayedRequest.id === requestID && { notes }
                        }
                    }
                })),
                trainPlaceholders: day.trainPlaceholders.map(placeholder => ({
                    ...placeholder,
                    ...placeholder.displayedRequest && {
                        displayedRequest: {
                            ...placeholder.displayedRequest,
                            ...placeholder.displayedRequest.id === requestID && { notes }
                        }
                    }
                })),
                hotelPlaceholders: day.hotelPlaceholders.map(placeholder => ({
                    ...placeholder,
                    ...placeholder.displayedRequest && {
                        displayedRequest: {
                            ...placeholder.displayedRequest,
                            ...placeholder.displayedRequest.id === requestID && { notes }
                        }
                    }
                })),
                otherPlaceholders: day.otherPlaceholders.map(placeholder => ({
                    ...placeholder,
                    ...placeholder.displayedRequest && {
                        displayedRequest: {
                            ...placeholder.displayedRequest,
                            ...placeholder.displayedRequest.id === requestID && { notes }
                        }
                    }
                }))
            }))
        },

        updateOptions: function (state, newOptions) {
            state.options = {...state.options, ...newOptions};
        }
    },


    actions: {
        fetchMenuTripDetails: function ({ commit, dispatch, state, getters }) {
            return new Promise((resolve, reject) => {
                return getAxios('trip_details',
                    {
                        'order.id': state.order.id,
                        type: 'menu',
                        _groups: ['order_trip_detail:menu'],
                    },
                    ).then(response => {
                        let sortOrder = {
                            'Frühstück': 0,
                            'Mittagessen': 1,
                            'Abendessen': 2,
                        }
                        commit('updateMenus', response.data.sort((a,b) => {
                            return sortOrder[a.subType] - sortOrder[b.subType];
                        }));
                        resolve(response.data);
                    }, error => reject(error));
            });
        },

        fetchCustomDays: function ({ commit, dispatch, state, getters }, order) {
            return new Promise((resolve, reject) => {
                const params = '?order.id=' + order.id + '&_groups[]=order_placeholder_read';
                return getDaysTripDetails(params)
                    .then(response => {
                        const days = response.data
                            .sort((a, b) => compareDates(a.date, b.date));

                        commit('updateDays', days);

                        if (days.length > 0 && !state.selectedDay) {
                            dispatch('selectDay', days[0]).then(response => {})
                        } else if (state.selectedDay || state.selectedPlaceholder) {
                            const day = days.find(({ id }) => id === state.selectedDay.id);
                            commit('selectDay', day);

                            if (state.selectedPlaceholder) {
                                const placeholders = getPlaceholders(day);
                                commit('selectPlaceholder', placeholders.find(placeholder => placeholder.id === state.selectedPlaceholder.id));
                            }
                        }

                        resolve(days);
                    }, error => reject(error));
            });
        },
        getDayData: function ({ commit, state }, day) {
            return new Promise((resolve, reject) => {
            getDayTripDetails(day.id , '?_groups[]=order_request_read&_groups[]=order_placeholder_request_read&_groups[]=order_placeholder_displayed_request_read&_groups[]=order_placeholder_read')
                .then(response => {
                    resolve(response);
                }, error => {
                    reject(error);
                })})
        },
        fetchTemplates: function ({ commit, state }) {
            return new Promise((resolve, reject) => {
                Promise.all([
                    getRenderedTripDetailTemplates({
                        'order': state.order.id,
                        'destinations.id': state.order.trip.destinations
                            .map(destination => destination.id)
                    }),
                    getRenderedTripDetailTemplates({
                        'order': state.order.id,
                        'destinations[exists]': false
                    })
                ]).then(([destinationTemplates, nonDestinationTemplates]) => {
                    const templates = [
                        ...destinationTemplates.data,
                        ...nonDestinationTemplates.data
                    ];
                    commit('updateTemplates', templates);
                    resolve(templates);
                }, error => {
                    reject(error);
                });
            });
        },

        fetchIntroInfo: function ({ commit }, order) {
            return new Promise((resolve, reject) => {
                getOrderTripDetails(order.id, {
                    type: 'intro_info'
                }).then(response => {
                    const infos = response.data;

                    if (infos.length > 0) {
                        commit('updateIntroInfo', infos[0]);
                    }

                    resolve(infos);
                }, error => { reject(error); });
            });
        },

        fetchGeneralInfos: function ({ commit }, order) {
            return new Promise((resolve, reject) => {
                getOrderTripDetails(order.id, {
                    type: 'general_info'
                }).then(response => {
                    commit('updateGeneralInfos', response.data);
                    resolve(response.data);
                }, error => { reject(error); });
            });
        },

        fetchOptions: function ({ commit }) {
            optionsTripDetails()
                .then(response => {
                    commit('updateOptions', response.data);
                });
        },

        selectDay: function ({ commit, dispatch, getters }, day) {

            dispatch('getDayData', day).then(response => {
                if (response.data) {
                    commit('selectDay', response.data);
                }
                commit('selectPlaceholder', null);

            })


            // if (getters.dayPlaceholders.length > 0) {
            //     dispatch('selectPlaceholder', getters.dayPlaceholders[0]);
            // } else {
            //     commit('selectPlaceholder', null)
            // }
            // https://buspaket.atlassian.net/browse/TRAV-3582
        },

        selectPlaceholder: function ({ state, commit }, placeholder) {
            commit('updateLoading', true);

            getDayTripDetails(state.selectedDay.id, '?_groups[]=order_trip_detail_read&_groups[]=image_read')
                    .then(response => {
                        const day = response.data;
                        commit('selectPlaceholder', {
                            ...placeholder,
                            ...placeholder.info.type === 'ferry' && day.ferryPlaceholders.find(({id}) => id === placeholder.id),
                            ...placeholder.info.type === 'train' && day.trainPlaceholders.find(({id}) => id === placeholder.id),
                            ...placeholder.info.type === 'hotel' && day.hotelPlaceholders.find(({id}) => id === placeholder.id),
                            ...placeholder.info.type === 'airline' && day.airlinePlaceholders.find(({id}) => id === placeholder.id),
                            ...placeholder.info.type === 'other' && day.otherPlaceholders.find(({id}) => id === placeholder.id),
                            ...!!placeholder.predefinedRouteDetail && day.routeDetails.find(({id}) => id === placeholder.id),
                        });
                        commit('selectDay', day);
                        commit('updateLoading', false);
                    });
        },
    },


    getters: {
        validIntro: function (state) {
            const { contact, title } = state.intro;

            return !!contact && !!title;
        },

        dayPlaceholders: function (state) {
            if (state.selectedDay) {
                return getPlaceholders(state.selectedDay);
            } else {
                return [];
            }
        },

        tripDetails: function (state) {
            return state.selectedPlaceholder && state.selectedPlaceholder.displayedRequest
                ? state.selectedPlaceholder.displayedRequest.tripDetails
                : [];
        },

        listOfDates: function (state) {
            if (!!state.selectedPlaceholder) {
                const { startAt, endAt } = state.selectedPlaceholder;
                return listOfDays(startAt, endAt);
            } else {
                return [];
            }
        },

        possibleDates: function (state, getters) {
            // Only the dates that are not already in use
            return getters.listOfDates
                .filter(date => !getters.tripDetails.some(detail => detail.type === 'menu' && detail.title === date));
        },

        day: function (state) {
            return state.selectedDay;
        },

        placeholder: function (state) {
            return state.selectedPlaceholder;
        }
    }
});


export default store;
export { store };
