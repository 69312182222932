<template>
    <modal-form
        editTitle="Fährpreise bearbeiten"
        createTitle="Neuen Fährpreis hinzufügen"
        class="is-large"
        :is-loading="isLoading"
    >
        <table>
            <template v-if="initialValue">
                <tr>
                    <th><label class="label">Preis:</label></th>
                    <td>
                        <toggle-switch v-model="priceType">
                            <option value="calculation">Kalkulation</option>
                            <option value="provider">Anbieter</option>
                            <option value="agency">Agentur</option>
                        </toggle-switch>
                    </td>
                </tr>
                <tr v-if="priceType === 'agency'">
                    <th><label class="label">Agentur:</label></th>
                    <td>
                        <async-select
                            api="agencies"
                            v-model="agency"
                        />
                    </td>
                </tr>
                <tr v-if="priceType === 'provider' || priceType === 'agency'">
                    <th><label class="label">Fährgesellschaft:</label></th>
                    <td>
                        <async-select
                            api="ferries"
                            v-model="provider"
                        />
                    </td>
                </tr>
            </template>

            <tr v-if="providerType === 'provider'">
                <th><label class="label">Art:</label></th>
                <td>
                    <toggle-switch v-model="type">
                        <option value="direct">Direktpreis</option>
                        <option value="agency">Preis von Agentur</option>
                    </toggle-switch>
                </td>
            </tr>

            <tr v-if="providerType === 'agency'">
                <th><label class="label">Art:</label></th>
                <td>
                    <toggle-switch v-model="type">
                        <option value="direct">No-Name Preis</option>
                        <option value="hotel">Preis für eine bestimmte Fähre</option>
                    </toggle-switch>
                </td>
            </tr>

            <tr v-if="type === 'agency'">
                <th><label class="label">Agentur:</label></th>
                <td>
                    <async-select
                        api="agencies"
                        v-model="agency"
                    />
                </td>
            </tr>

            <tr v-if="type === 'hotel'">
                <th><label class="label">Fähre:</label></th>
                <td>
                    <async-select
                        api="ferries"
                        v-model="provider"
                    />
                </td>
            </tr>
<!--            TODO: Klasse-->
            <tr v-if="form.route">
                <th><label class="label">Art der Fähre:</label></th>
                <td>
                    <toggle-switch v-model="form.route.type">
                        <option value="day">Tagfähre</option>
                        <option value="night">Nachtfähre</option>
                    </toggle-switch>
                </td>
            </tr>
            <tr v-if="form.route">
                <th><label class="label">Abfahrtshafen:</label></th>
                <td>
                    <async-select
                        api="ports"
                        :customLabel="port => port.place.name"
                        v-model="form.route.startPort"
                    />
                </td>
            </tr>
            <tr v-if="form.route">
                <th><label class="label">Ankunftshafen:</label></th>
                <td>
                    <async-select
                        api="ports"
                        :customLabel="port => port.place.name"
                        v-model="form.route.endPort"
                    />
                </td>
            </tr>

            <!-- Preise -->
            <tr>
                <td colspan="2">
                    <h2>Preise <i class="material-icons" v-on:click="addPrice">add_circle</i></h2>
                </td>
            </tr>

        </table>

        <div>

            <edit-table
                @delete="deletePeriod"
                :filters="filter"
                :rows="rows"
                :load-options="false"
                :footer="false"
                ref="table"
                @updateFilter="value => filter = value"
                :passed-options="options"
                @saveRow="saveRow"
                :columns="columns"
            >
                <template v-slot:days="slotProps">
                    <div>
                        <days :picker=true :is-in-table=true :value="getWeekdays(slotProps.row)" @input="input => setWeekDays(input, slotProps.row)"></days>
                    </div>
                </template>
                <template v-slot:serviceDayTimes="slotProps">
                    <div>
                        <span v-if="slotProps.row.priceGroup.serviceDayTimes.length">{{slotProps.row.priceGroup.serviceDayTimes.length}}</span>
                        <span v-else>alle</span>
                        <button
                            class="btn-edit"
                            v-on:click.prevent.stop="priceToBeEdited = slotProps.row.priceGroup"
                        ><i class="material-icons">edit</i></button>
                    </div>
                </template>
                <template v-slot:linkedGroup="slotProps">
                    <div v-if="slotProps.row.priceGroup.isNew" style="margin-left: 30px">
                        <multiselect :allow-empty="false" :value="slotProps.row.priceGroup.id" @input="input => changePriceGroup(slotProps.row, input)" :options="getNewPriceGroups"/>
                    </div>
                    <div v-else :style="getBackground(slotProps.row)" style="height: 100%; width: 100%; align-content:center;text-align: center">
                        {{slotProps.row.priceGroup.id}}
                    </div>

                </template>


            </edit-table>
            <modal
                class="is-small is-form"
                :visible="!!priceToBeEdited"
            >
                <p slot="header">Preise gültig für folgende Zeiten</p>
                <div slot="content">
                    <service-day-time-field v-if="priceToBeEdited" label="Gültig für" :fields="['startTimeOfDay', 'endTimeOfDay', 'checkInTimeOfDay']" v-model="priceToBeEdited.serviceDayTimes" :predefined-day-times="predefinedDayTimes" />
                </div>
                <div class="level" slot="footer">
                    <button class="button is-primary" v-on:click.prevent.stop="priceToBeEdited=null">
                        Fenster schließen
                    </button>
                </div>
            </modal>
            <pagination v-model="pagination" v-on:input="getPrices" :name="{singluar: 'Preisgruppe', plural: 'Preisgruppen'}" />
        </div>
    </modal-form>
</template>

<script>
import has from 'lodash/has';
import Days from '@components/Days';
import { InputDate, InputPrice, ToggleSwitch, AsyncSelect } from '@components/form';
import {compareDates, dateISOtoView, daysToNumbers, numbersToDays, setStorageFilter} from '@utilities/functions';
import TabForm from '../../form'
import {importCheckedMixin, importCheckedField, serviceDayTimeField} from '@utilities/mixins';
import {
    providerPrices,
    getAxios,
    axiosByMethod,
    axiosDelete,
    getFerryPlaceholder
} from '@api'
import {notifyError, notifySuccess} from "@components/Notification";
import moment from "moment";
import EditTable from "@components/EditTable/Index.vue";
import set from "lodash/set";
import Pagination from "@components/CRUD/Pagination.vue";
import {omit} from "lodash";
import Modal from "@components/Modal.vue";



export default {
    mixins: [ TabForm, importCheckedMixin ],

    props: {
        providerType: {
            type: String,
            required: true
        }
    },

    components: {
        serviceDayTimeField, Modal,
        Pagination,
        EditTable,
        Days,
        InputDate,
        InputPrice,
        ToggleSwitch,
        importCheckedField,
        AsyncSelect,
    },

    form: {
        route: {
            type: 'night',
            startPort: null,
            endPort: null,
            ferry: null,
            agency: null,
        },
    },

    data: function () {
        return {
            type: 'direct',
            priceType: 'calculation',
            prices: [],
            filter: {
                'endAt[after]': '01.01.' + moment().format('YYYY'),
                'startAt[before]': null,
            },
            pagination: {
                current: 1,
                perPage: 100,
                items: 0
            },
            priceGroupIdsToDelete: [],
            priceToBeEdited: null,
        }
    },

    computed: {
        defaultProviderPrices () {
            if(this.form.route && this.form.route.type === 'night') {
                return [
                    {
                        cabinType: {
                            'persons': 2,
                            'type': 'inside',
                            'cabinIndex': '2_inside'
                        },
                        price: {
                            amount: null,
                            currency: this.provider ? this.provider.mainCurrency : this.agency ? this.agency.mainCurrency : 'EUR',
                        }
                    },
                    {
                        cabinType: {
                            'persons': 1,
                            'type': 'inside',
                            'cabinIndex': '1_inside'
                        },
                        price: {
                            amount: null,
                            currency: this.provider ? this.provider.mainCurrency : this.agency ? this.agency.mainCurrency : 'EUR',
                        }
                    },
                    {
                        cabinType: {
                            'persons': 2,
                            'type': 'outside',
                            'cabinIndex': '2_outside'
                        },
                        price: {
                            amount: null,
                            currency: this.provider ? this.provider.mainCurrency : this.agency ? this.agency.mainCurrency : 'EUR',
                        }
                    },
                    {
                        cabinType: {
                            'persons': 1,
                            'type': 'outside',
                            'cabinIndex': '1_outside'
                        },
                        price: {
                            amount: null,
                            currency: this.provider ? this.provider.mainCurrency : this.agency ? this.agency.mainCurrency : 'EUR',
                        }
                    },
                    {
                        cabinType: null,
                        priceType: 'bus_price',
                        price: {
                            amount: null,
                            currency: this.provider ? this.provider.mainCurrency : this.agency ? this.agency.mainCurrency : 'EUR',
                        },
                    },
                    {
                        cabinType: null,
                        priceType: 'ticket_price',
                        price: {
                            amount: null,
                            currency: this.provider ? this.provider.mainCurrency : this.agency ? this.agency.mainCurrency : 'EUR',
                        },
                    },
                ];
            } else {
                return [
                    {
                        cabinType: null,
                        priceType: 'bus_price',
                        price: {
                            amount: null,
                            currency: this.provider ? this.provider.mainCurrency : this.agency ? this.agency.mainCurrency : 'EUR',
                        },
                    },
                    {
                        cabinType: null,
                        priceType: 'ticket_price',
                        price: {
                            amount: null,
                            currency: this.provider ? this.provider.mainCurrency : this.agency ? this.agency.mainCurrency : 'EUR',
                        },
                    },
                ];
            }
        },
        columns () {
            if(this.form.route && this.form.route.type === 'night') {
                return [
                    { key: 'linkedGroup', label:  'Preisgruppe', class: 'has-border is-120px-cell has-text-centered is-editable', formElement: ''},
                    { key: 'period.startAt', label: 'Beginn', class: 'has-border has-text-centered is-date-cell', formElement: 'InputDateTable', props: {onlyDate: true} },
                    { key: 'period.endAt', label: 'Ende', class: 'has-border has-text-centered is-date-cell', formElement: 'InputDateTable', props: {onlyDate: true} },
                    { key: 'double.price.amount', label:  'DK innen', class: 'has-border is-80px-cell is-right', formElement: 'InputPrice', props: {prefix: ''}},
                    { key: 'single.price.amount', label:  'EK innen', class: 'has-border is-80px-cell is-right', formElement: 'InputPrice', props: {prefix: ''}},
                    { key: 'doubleOutside.price.amount', label:  'DK außen', class: 'has-border is-80px-cell is-right', formElement: 'InputPrice', props: {prefix: ''}},
                    { key: 'singleOutside.price.amount', label:  'EK außen', class: 'has-border is-80px-cell is-right', formElement: 'InputPrice', props: {prefix: ''}},
                    { key: 'ticket.price.amount', label:  'Überfahrt', class: 'has-border is-80px-cell is-right', formElement: 'InputPrice', props: {prefix: ''}},
                    { key: 'bus.price.amount', label:  'Bus', class: 'has-border is-90px-cell is-right', formElement: 'InputPrice', props: {prefix: ''}},
                    { key: 'double.price.currency', label: 'CUR', class: 'has-border is-editable is-60px-cell has-text-centered', formElement: 'Multiselect', props: {'allow-empty': false, options: this.options.potentialCurrencies }},
                    { key: 'priceGroup.name', label: 'Bezeichnung Zeitraum (optional)', formElement: 'TextInput', class: 'has-border', style: 'flex-grow:1'},
                    { key: 'days', label: 'Wochentage', formElement: '', class: 'has-border has-text-centered is-editable', style: 'width: 203px; flex-grow:0'},
                    { key: 'serviceDayTimes', label: 'Gültig für Zeiten', formElement: 'TextInput', class: 'has-border is-100px-cell has-text-centered'}
                    /*
                    todo: Maybe put at top or with icon
                    { key: 'checked', label:  'geprüft', class: 'has-border is-editable is-icon-cell',style: 'flex-grow:1', formElement: 'PureText', props: {label: 'value', 'track-by': 'key', options: this.options.boardExtended} },
    */
                ]
            } else {
                return [
                    { key: 'linkedGroup', label:  'Preisgruppe', class: 'has-border is-120px-cell has-text-centered is-editable', formElement: ''},
                    { key: 'period.startAt', label: 'Beginn', class: 'has-border has-text-centered is-date-cell', formElement: 'InputDateTable', props: {onlyDate: true} },
                    { key: 'period.endAt', label: 'Ende', class: 'has-border has-text-centered is-date-cell', formElement: 'InputDateTable', props: {onlyDate: true} },
                    { key: 'ticket.price.amount', label:  'Überfahrt', class: 'has-border is-80px-cell is-right', formElement: 'InputPrice', props: {prefix: ''}},
                    { key: 'bus.price.amount', label:  'Bus', class: 'has-border is-80px-cell is-right', formElement: 'InputPrice', props: {prefix: ''}},
                    { key: 'ticket.price.currency', label: 'CUR', class: 'has-border is-editable is-60px-cell has-text-centered', formElement: 'Multiselect', props: {'allow-empty': false, options: this.options.potentialCurrencies }},
                    { key: 'priceGroup.name', label: 'Bezeichnung Zeitraum (optional)', formElement: 'TextInput', class: 'has-border', style: 'flex-grow:1'},
                    { key: 'days', label: 'Wochentage', formElement: '', class: 'has-border has-text-centered is-editable', style: 'width: 203px; flex-grow:0'},
                    { key: 'serviceDayTimes', label: 'Gültig für Zeiten', formElement: 'TextInput', class: 'has-border is-100px-cell has-text-centered'}
                ];
            }

        },

        rows: {
            get () {
                let rows = [];
                this.prices.forEach(price => {
                    price.timePeriods.forEach(timePeriod => {
                        rows.push(
                            {
                                key: timePeriod.id,
                                priceGroup: price,
                                period: timePeriod,
                                double: price.providerPrices.find(price => price.cabinType && price.cabinType.cabinIndex === '2_inside'),
                                single: price.providerPrices.find(price => price.cabinType && price.cabinType.cabinIndex === '1_inside'),
                                doubleOutside: price.providerPrices.find(price => price.cabinType && price.cabinType.cabinIndex === '2_outside'),
                                singleOutside: price.providerPrices.find(price => price.cabinType && price.cabinType.cabinIndex === '1_outside'),
                                ticket: price.providerPrices.find(price => price.priceType === 'ticket_price'),
                                bus: price.providerPrices.find(price => price.priceType === 'bus_price'),
                            }
                        )
                    })
                })
                return rows.sort((a, b) => {
                    if(a.priceGroup.isNew && !b.priceGroup.isNew) {
                        return -1;
                    }
                    if(b.priceGroup.isNew && !a.priceGroup.isNew) {
                        return 1;
                    }
                    if(a.priceGroup.isNew && b.priceGroup.isNew) {
                        return 0;
                    }
                    return compareDates(a.period.startAt, b.period.startAt);
                })
            },
            set (rows) {
                rows.forEach(row => {
                    let priceGroup = this.prices.find(price => price.id === row.priceGroup.id);
                    this.prices = this.prices.map(price => {
                        if(price.id === priceGroup.id) {
                            return {
                                ...price,
                                ...row.priceGroup,
                                timePeriods: priceGroup.timePeriods.map(tp => {
                                    if(tp.id === row.period.id) {
                                        return {...tp, ...row.period}
                                    } else {
                                        return tp;
                                    }
                                }),
                                providerPrices: [
                                    {...row.double}, {...row.single}, {...row.doubleOutside}, {...row.singleOutside}, {...row.ticket}, {...row.bus}
                                ]
                            }
                        } else {
                            return price;
                        }
                    })
                })
            }
        },

        agency: {
            get: function () { return this.form.route && this.form.route.agency && !!this.form.route.agency.id ? this.form.route.agency : null },
            set: function (agency) { this.form.route.agency = agency }
        },

        provider: {
            get: function () { return this.form.route && this.form.route.ferry && !!this.form.route.ferry.id ? this.form.route.ferry : null },
            set: function (provider) { this.form.route.ferry = provider }
        },

        route: {
            get: function () { return this.form.route && this.form.route.startPort && this.form.route.endPort ? this.form.route.startPort.id + this.form.route.endPort.id + this.form.route.type : null },
            set: function (provider) { this.form.route.startPort = provider }
        },

        endPort: {
            get: function () { return this.form.route && this.form.route.endPort && !!this.form.route.endPort.id ? this.form.route.endPort : null },
            set: function (provider) { this.form.route.endPort = provider }
        },

        ferryType: {
            get: function () { return this.form.route && this.form.route.type && !!this.form.route.type.id ? this.form.route.type : null },
            set: function (provider) { this.form.route.type = provider }
        },


        includeProvider () {
            return this.providerType === 'provider' || this.priceType === 'provider' || (this.priceType === 'agency' && this.provider) || (this.providerType === 'agency' && this.type === 'hotel');
        },

        includeAgency () {
            return this.providerType === 'agency' || this.priceType === 'agency' || (this.providerType === 'provider' && this.type === 'agency');
        },

        computedFilters () {
            return {
                ...this.filter,
                'timePeriods.endAt[after]': this.filter['endAt[after]'],
                'timePeriods.startAt[before]': this.filter['startAt[before]'],
                _itemsPerPage: this.pagination.perPage,
                _page: this.pagination.current,
            }
        },

        getNewPriceGroups () {
            let newPriceGroups = [];
            this.prices.filter(ele => ele.isNew).forEach(price => {
                price.timePeriods.forEach(period => {
                    newPriceGroups.push(period.id)
                })
            });
            return newPriceGroups;
        },

        predefinedDayTimes: function () {
            return this.otherServiceType && this.otherServiceType.serviceDayTimes ? this.otherServiceType.serviceDayTimes.map(detail => {
                detail.key = detail.id;
                delete detail.id;
                return detail;
            }) : [];
        },
    },

    methods: {
        getWeekdays (row) {
            return daysToNumbers(row.period)
        },

        setWeekDays (input, row) {
            this.rows = this.rows.map(myRow => {
                if(myRow.key === row.key) {
                    row.priceGroup.hasChanged = true;
                    let period = {
                        ...row.period,
                        ...numbersToDays(input)
                    }
                    return set(row, 'period', period)
                } else {
                    return row
                }
            })
            row = set(row, 'period', {
                ...row.period,
                ...numbersToDays(input)
            });

        },


        getPrices() {
            if(!this.form || !this.form.route.startPort || !this.form.route.endPort || !this.form.route.type) {
                return;
            }

            this.$refs.table.isLoading = true;

            getAxios('ferry_price_groups', {
                ...this.provider && this.includeProvider && {'route.ferry.id': this.provider.id},
                ...this.agency && this.includeAgency && {'route.agency.id': this.agency.id},
                'route.startPort.id' : this.form.route.startPort.id,
                'route.endPort.id' : this.form.route.endPort.id,
                'route.type' : this.form.route.type,
                ...(!this.includeProvider) && {
                    'route.ferry[exists]': false,
                },
                ...(!this.includeAgency) && {
                    'route.agency[exists]': false,
                },
                ...this.computedFilters,
                _groups: ['provider_price_group:provider_price', 'provider_price', 'provider_price_group:price_time_period', 'price_time_period', 'cabin_type', 'service_day_time', 'ferry_route_read'],
            }).then(response => {
                this.$refs.table.isLoading = false;

                this.pagination.items = Number(response.headers['x-trav-total-items'])
                this.prices = response.data.map(price => {
                    let providerPrices = price.providerPrices;


                    let double =  providerPrices.find(price => price.cabinType && price.cabinType.cabinIndex === '2_inside'),
                        single =  providerPrices.find(price => price.cabinType && price.cabinType.cabinIndex === '1_inside'),
                        doubleOutside =  providerPrices.find(price => price.cabinType && price.cabinType.cabinIndex === '2_outside'),
                        singleOutside = providerPrices.find(price => price.cabinType && price.cabinType.cabinIndex === '1_outside'),
                        ticket = providerPrices.find(price => price.priceType === 'ticket_price'),
                        bus = providerPrices.find(price => price.priceType === 'bus_price');
                    if(this.form.route.type === 'night' && !doubleOutside) {
                        providerPrices.push({
                            cabinType: {
                                'persons': 2,
                                'type': 'outside',
                                'cabinIndex': '2_outside'
                            },
                            price: {
                                amount: null,
                                currency: this.provider ? this.provider.mainCurrency : this.agency ? this.agency.mainCurrency : 'EUR',
                            }
                        })
                    }
                    if(this.form.route.type === 'night' && !singleOutside) {
                        providerPrices.push({
                            cabinType: {
                                'persons': 1,
                                'type': 'outside',
                                'cabinIndex': '1_outside'
                            },
                            price: {
                                amount: null,
                                currency: this.provider ? this.provider.mainCurrency : this.agency ? this.agency.mainCurrency : 'EUR',
                            }
                        })
                    }
                    if(!ticket) {
                        providerPrices.push({
                            priceType: 'ticket_price',
                            price: {
                                amount: null,
                                currency: this.provider ? this.provider.mainCurrency : this.agency ? this.agency.mainCurrency : 'EUR',
                            }
                        })
                    }
                    if(!bus) {
                        providerPrices.push({
                            priceType: 'bus_price',
                            price: {
                                amount: null,
                                currency: this.provider ? this.provider.mainCurrency : this.agency ? this.agency.mainCurrency : 'EUR',
                            }
                        })
                    }
                    return {
                        ...price,
                        hasChanged: false,
                        providerPrices: providerPrices
                    }
                });
            })
        },

        changePriceGroup(row, priceGroupId) {
            //case 1: Existing element in prices needs to be moved to other priceGroup
            //find oldPriceGroup
            let prices = JSON.parse(JSON.stringify(this.prices));

            let oldPrice = prices.find(price => {
                return price.timePeriods.find(period => period.id === row.period.id)
            })
            let timePeriod = oldPrice.timePeriods.find(period => period.id === row.period.id);

            //find newPriceGroup
            let newPrice = prices.find(price => price.id === priceGroupId)
            if(!newPrice) {
                prices.push({
                    ...row.priceGroup,
                    id: priceGroupId,
                    timePeriods: [],
                    providerPrices: [{...row.double}, {...row.single}, {...row.doubleOutside}, {...row.singleOutside}, {...row.ticket}, {...row.bus}],
                });
                newPrice = prices.find(price => price.id === priceGroupId);
            }
            if(oldPrice.id !== newPrice.id) {
                newPrice.timePeriods.push(timePeriod)
            }
            //delete if oldPrice not relevant
            if(oldPrice.timePeriods.length === 1) {
                let index = prices.findIndex(price => price.id === oldPrice.id);
                prices.splice(index, 1);
            } else {
                let index = oldPrice.timePeriods.findIndex(period => period.id === row.period.id);
                oldPrice.timePeriods.splice(index, 1);
            }
            this.prices = prices;



        },

        saveRow(data) {
            let rows = JSON.parse(JSON.stringify(this.rows));

            rows = rows.map(row => {
                if(data.row.key === row.key) {
                    row.priceGroup.hasChanged = true;
                    return set(row, data.colKey, data.value)
                } else {
                    return row
                }
            })

            this.rows = rows;
            data.row = set(data.row, data.colKey, data.value);
        },

        openCallback: function(form) {
            if(this.providerType === 'provider') {
                this.provider = this.item;
            }
            if(this.providerType === 'agency') {
                this.agency = this.item;
            }
        },

        handleInitialValue: function(initialValue) {

            this.open();

            if(this.initialValue.price){
                getFerryPlaceholder(initialValue.price.placeholder.id, '?_groups[]=modal_read&_groups[]=order_placeholder_displayed_request_read').then(response => {
                    const {startPort, endPort, type, displayedRequest} = response.data;
                    this.form.route.startPort = startPort;
                    this.form.route.endPort = endPort;
                    this.form.route.type = type;
                    this.agency = displayedRequest && displayedRequest.agency ? displayedRequest.agency : null;
                    this.provider = displayedRequest && displayedRequest.ferry ? displayedRequest.ferry : null;
                    if(this.agency){
                        this.priceType = 'agency';
                    } else if(this.provider){
                        this.priceType = 'provider';
                    }
                })
            }

            if(this.initialValue.conceptProvider) {
                this.form = {...this.form, ...this.initialValue.conceptProvider}
            }

            if(this.agency){
                this.priceType = 'agency';
            } else if(this.provider){
                this.priceType = 'provider';
            }
        },

        deletePeriod: function (row) {
            let prices = JSON.parse(JSON.stringify(this.prices));
            let priceGroup = prices.find(price => price.id === row.priceGroup.id);
            if(priceGroup.timePeriods.length === 1) {
                if(!priceGroup.isNew) {
                    this.priceGroupIdsToDelete.push(priceGroup.id);
                }
                let index = prices.findIndex(price => price.id === row.priceGroup.id);
                prices.splice(index, 1);
            } else {
                priceGroup.hasChanged = true;
                let index = priceGroup.timePeriods.findIndex(time => time.id === row.period.id);
                priceGroup.timePeriods.splice(index, 1);
            }
            this.prices = prices;
        },

        addPrice: function () {
            let price = {}
            if(this.initialValue) {
                if(this.initialValue.conceptProvider) {
                    price = {timePeriods: this.initialValue.conceptProvider.timePeriods, hasChanged: true};
                }

                if(this.initialValue.price) {
                    price = {
                            ...!this.initialValue.sellingPrices && {
                                timePeriods: [
                                    {
                                        startAt: moment(this.initialValue.price.placeholder.startAt, 'DD.MM.YYYY').format('DD.MM.YYYY'),
                                        endAt: moment(this.initialValue.price.placeholder.endAt, 'DD.MM.YYYY').format('DD.MM.YYYY'),
                                        days: [0, 1, 2, 3, 4, 5, 6]
                                    }
                                ],
                            },
                            ...this.initialValue.sellingPrice && {
                                timePeriods: this.initialValue.sellingPrice.seasonTimes.map(season => {
                                    return {
                                        days: [0,1,2,3,4,5,6],
                                        key: season.id,
                                        startAt: season.startAt,
                                        endAt: season.endAt
                                    }
                                }),
                                name: this.initialValue.sellingPrice.subTitle
                            },
                            hasChanged: true,
                            providerPrices: this.defaultProviderPrices.map(price => {
                                if(price.cabinType && price.cabinType.cabinIndex === '2_inside') {
                                    price.price = this.initialValue.price.insideDoubleCabinBuyPrice;
                                }
                                if(price.cabinType && price.cabinType.cabinIndex === '1_inside') {
                                    price.price = this.initialValue.price.insideSingleCabinPremiumBuyPrice;
                                }
                                if(price.priceType === 'ticket_price') {
                                    price.price = this.initialValue.price.dayTicketBuyPrice;
                                }
                                if(price.priceType === 'bus_price') {
                                    price.price = this.initialValue.price.busBuyPrice;
                                }
                                return price;
                            })
                        };
                }

            }

            let key = 'neu-' + (this.getNewPriceGroups.length + 1);
            let defaultTimePeriod = {
                startAt: null,
                endAt: null,
                ...numbersToDays([0,1,2,3,4,5,6]),
                id: key,
            };
            this.prices.push(
                {
                    timePeriods: [defaultTimePeriod],
                    providerPrices: this.defaultProviderPrices,
                    isNew: true,
                    period: {
                        startAt: null,
                        endAt: null,
                        days: [0,1,2,3,4,5,6],
                    },
                    name: '',
                    serviceDayTimes: [],
                    id: key,
                    ...price,
                    ...price.timePeriods && {
                        timePeriods: price.timePeriods.map(timePeriod => ({
                            ...defaultTimePeriod,
                            ...timePeriod,
                            ...numbersToDays(timePeriod.days),
                        }))
                    },
                }
            )
        },

        getIDFromString: function(apiString) {
            if(typeof apiString === 'object'){
                return apiString.id;
            }
            let stringArray = apiString.split('/');
            return stringArray[stringArray.length - 1];
        },


        prepareForBackend: function(data) {

            //data.dataCheckedAt = this.dataCheckedAtCheck;
            //data.name = this.ownName ? data.name : '';

            if(this.includeAgency && data.route.agency) {
                data.route.agency = data.route.agency.id ? `/api/agencies/${data.route.agency.id}` : data.route.agency;
            } else {
                data.route.agency = null;
            }

            if(this.includeProvider && data.route.ferry) {
                data.route.ferry = data.route.ferry.id ? `/api/ferries/${data.route.ferry.id}` : data.route.ferry;
            } else {
                data.route.ferry = null;
            }

            if(data.route && data.route.id) {
                data.route = `/api/ferry_routes/${data.route.id}`
            } else {
                data.route.startPort = data.route.startPort && data.route.startPort.id ? `/api/ports/${data.route.startPort.id}` : data.route.startPort;
                data.route.endPort = data.route.endPort && data.route.endPort.id ? `/api/ports/${data.route.endPort.id}` : data.route.endPort;
            }

            let double = {}
            if(this.form.route.type === 'night') {
                double = data.providerPrices.find(price => price.cabinType && price.cabinType.cabinIndex === '2_inside');
            } else {
                double = data.providerPrices.find(price => price.priceType === 'ticket_price')
            }

            data.providerPrices = data.providerPrices.filter(price => !!price.price).map(price => {
                return Object.assign({}, price, {
                    price: {
                        amount: price.price.amount,
                        currency: double.price.currency
                    }
                })
            });
            return data;
        },

        handleSubmit: function () {

            if(this.includeProvider && !this.provider) {
                notifyError('Bitte Fähre auswählen!')
                return;
            }
            if(this.includeAgency && !this.agency) {
                notifyError('Bitte Agentur auswählen!')
                return;
            }
            if(!this.form.route.startPort || !this.form.route.endPort || !this.form.route.type) {
                notifyError('Bitte Fährverbindung auswählen!')
                return;
            }


            Promise.all([
                ...this.prices.filter(price => price.hasChanged).map(price => {

                this.isLoading = true;
                let method = price.isNew ? 'post' : 'put';
                let api = 'ferry_price_groups';

                if(price.isNew) {
                    price = omit(price, ['id']);

                    price.route = {
                        ...this.includeProvider && this.provider && {ferry: '/api/ferries/' + this.provider.id},
                        ...this.includeAgency && this.agency && {agency: '/api/agencies/' + this.agency.id},
                        startPort: this.form.route.startPort,
                        endPort: this.form.route.endPort,
                        type: this.form.route.type
                    }

                    price.timePeriods = price.timePeriods.map(period => omit(period, ['id']))
                } else {
                    api += '/' + price.id
                }
                let data = this.prepareForBackend(price);


                return axiosByMethod(method, api, data, {
                    _groups: [
                        'provider_price_group:price_time_period',
                        'provider_price_group:provider_price',
                        'price_time_period',
                        'provider_price',
                        'cabin_type',
                        'data_check_read',
                        ...price.isNew ? ['provider_price_group:provider_ferry_route', 'ferry_route_write'] : []
                    ]
                })
            }),
                ...this.priceGroupIdsToDelete.map(priceGroup => {
                    return axiosDelete('ferry_price_groups/' + priceGroup)
                })
            ]).then(responses => {
                notifySuccess('Die Preise wurden gespeichert!')
                this.$emit('submit')
                this.isLoading = false;
                this.close()
            }, error => {
                this.notifyError("Die Preise konnte nicht gespeichert werden!")
                this.isLoading = false;
            })
        },

        getRandomColor() {
            const letters = '0123456789ABCDEF';
            let color = '#';
            for (let i = 0; i < 6; i++) {
                color += letters[Math.floor(Math.random() * 16)];
            }
            return color;
        },

        getBackground (row) {
            let colors = [
                '#AAD7D9',
                '#EFBC9B',
                '#FBF3D5',
                '#D6DAC8',
                '#9CAFAA',
                '#EED3D9',
                '#B5C0D0',
                '#E5E1DA',
                '#FBF9F1',
                '#BA90C6',
                '#E8A0BF',
                '#FDF4F5',
                '#D8AE7E',
                '#9BABB8'
            ];
            let index = this.prices.findIndex(price => price.id === row.priceGroup.id)
            return 'background:' + (index < 15 ? colors[index] : this.getRandomColor());
        }

    },

    watch: {
        computedFilters: function(oldValue, newValue) {
            if(JSON.stringify(oldValue) !== JSON.stringify(newValue)) {
                this.getPrices();
            }
        },

        provider () {
            if(!this.includeProvider || this.provider) {
                this.getPrices();
            }
        },

        route  () {
            this.getPrices();
        },

        agency () {
            if(!this.includeAgency || this.agency) {
                this.getPrices();
            }
        },

        type () {
            this.getPrices();
        },

        priceType () {
            if(this.priceType === 'calculation') {
                this.getPrices();

            }
            if(this.priceType === 'provider') {
                if(this.provider) {
                    this.getPrices();
                }
            }
            if(this.priceType === 'agency') {
                if(this.agency) {
                    this.getPrices();
                }
            }
        }
    }
}


</script>
