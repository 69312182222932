var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "aside",
    { staticClass: "sb-aside", class: _vm.position },
    [
      _vm.element
        ? _c(_vm.element, {
            tag: "component",
            attrs: { sidebarData: _vm.sidebarData },
            on: { hideSideBar: _vm.hideSideBar },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }