<template>
    <div
        class="bp-panel"
        v-bind:class="{'is-hover': isHover}"
        v-on:mouseenter="isHover = true"
        v-on:mouseleave="isHover = false"
        v-on:click="isHover = false"
    >
        <span class="panel-label" v-if="label">{{label}}</span>

        <div class="panel-actions">
            <slot name="actions" />
            <button v-on:click.prevent.stop="$emit('edit')"><i class="material-icons">edit</i>Ändern</button>
            <button v-on:click.prevent.stop="$emit('delete')"><i class="material-icons">delete</i>Löschen</button>
            <update-popover v-bind:update="update" :api="api"><button><i class="material-icons">update</i></button>
            </update-popover>
        </div>
        <div class="panel-content">
            <slot name="hover" v-if="isHover"></slot>
            <slot />
        </div>
    </div>
</template>

<script>
import UpdatePopover from "@components/UpdatePopover";
import moment from "moment";


export default {

    data: function () {
        return {
            isHover: false,
            dataReady: false,
        }
    },
    props: {
        label: {
            type: String,
            default: ''
        },
        update: {
            type: Object,
            default: () => {
                return {}
            }
        },
        api: {
            type: String,
        }
    },
    methods: {
        moment,
    },
    components: {
        UpdatePopover,
    }
}
</script>

