<template>
    <div>
        <loading v-if="isLoading"/>
        <div v-else>
            <div class="level">
                <div class="level-left">
                    <h2 class="level-item mb-0">Einkaufsjahr:</h2>
                    <div class="level-item ">
                        <multiselect
                            style="width: 65px; height: 35px"
                            v-model="year"
                            v-bind:options="years ? years : []"
                            :allow-empty="false"
                        />
                    </div>
                </div>

                <div class="level-right">
                    <div class="level-item">
                        <toggle-switch v-model="showOptionalServices">
                            <option :value="true">Möglichkeiten einblenden</option>
                            <option :value="false">Möglichkeiten ausblenden</option>
                        </toggle-switch>
                    </div>
<!--                    <div class="buttons level-item">
                        <button class="button is-large" v-on:click="openEmailForm">Neue Email</button>
                    </div>-->
                </div>
            </div>

            <div class="days">
                <div class="days-header pr-0">
                    <div style="width: auto">Leistungsträger</div>
                    <div  style="width: 150px">Angefragt am</div>
                    <div  style="width: 150px">Preise erfasst am</div>
                    <div  style="width: 150px; padding-left:0">Dokumente</div>
                    <div  style="width: 85px"></div>
                </div>
                <div class="is-relative">
                    <div class="accordion day has-hidden-arrow"
                         v-for="(day, index) in sortedDays"
                         v-bind:key="day.id"
                         v-bind:class="{'is-empty': !hasDayPlaceholders(day)}"
                    >
                        <div class="accordion-header">
                            <header>
                                <span>Tag {{ index + 1 }} - {{ day.name }}</span>
                                <div class="day-action-buttons">
                                    <simple-menu material icon="more_vert" title="Menu">
                                        <button v-on:click.stop="editDay(day.id)"><i class="material-icons">mode_edit</i> Bearbeiten</button>
                                        <button v-on:click.stop="deleteDay(day.id)"><i class="material-icons">delete</i> Löschen</button>
                                    </simple-menu>
                                </div>
                            </header>
                        </div>
                        <div class="accordion-content">
                            <template v-if="hasDayPlaceholders(day)">
                                <template
                                    v-for              = "placeholder in dayPlaceholders(day)"
                                >
                                    <div class="placeholder-wrapper" v-if="showOptionalServices || !isOptional(placeholder)">

                                        <placeholder
                                            :placeholder="placeholder"
                                            v-bind:key         = "placeholder.placeholderType + '-' + placeholder.id"
                                        >
                                            <simple-menu material icon="more_vert" title="Menu" v-if="placeholder.placeholderType !== 'route'">
                                                <button
                                                    v-on:click.stop="openConceptProviderForm({providers: placeholder.providers, dayId: day.id, placeholder: placeholder, id: placeholder.id})"
                                                ><i class="material-icons">add</i> Leistungsträger auswählen</button>
                                            </simple-menu>
                                        </placeholder>
                                        <div class="requests" v-if="placeholder.providers.length > 0">
                                            <provider
                                                v-for="provider in placeholder.providers"
                                                @addPrice="addPrice"
                                                @openEmail="openEmailForm"
                                                @markAsRequested="markAsRequested"
                                                @deletePriceRequest="deletePriceRequest"
                                                @documentSaved="documentSaved"
                                                :procurementInfo="procurementInfo"
                                                :placeholder="placeholder"
                                                :provider="provider"
                                                :year="year"
                                                :key="provider.id">
                                            </provider>
                                        </div>
                                    </div>
                                </template>
                            </template>
                            <div class="placeholder" v-else>
                                <span class="text-sm">Das Tageskonzept enthält keine weiteren Elemente</span>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

            <div v-if="modalCRUD">
                <component
                    v-bind:is="`modal-${modalCRUD.component}-CRUD`"
                    v-bind:initialValue="modalCRUD"
                    providerType="calculation"
                    v-on:closeModal="handleCloseModalCRUD"
                    v-on:submit="handleCloseModalCRUD"
                />
            </div>

            <concept-provider-form ref="conceptProviderForm" :options="[]"/>

            <concept-email
                v-if="isModalEmailVisible"
                v-bind:preselectedContact="contact"
                :price-request-year="year"
                :preselected-order-concept="item"
                select-template="procurement"
                :preselected-concept-provider="preselectedConceptProvider"
                v-bind:isModalVisible="isModalEmailVisible"
                v-on:closeModal="handleEmailFormClose"
            ></concept-email>

        </div>
    </div>

</template>



<script>
import Draggable   from 'vuedraggable';
import ModalHotelCRUD from '@components/CRUD/Item/Tabs/ProviderPrices/Forms/HotelEditTable.vue';
import ModalFerryCRUD from '@components/CRUD/Item/Tabs/ProviderPrices/Forms/FerryEditTable.vue';
import ModalOtherCRUD from '@components/CRUD/Item/Tabs/ProviderPrices/Forms/OtherEditTable.vue';
import ModalTrainCRUD from '@components/CRUD/Item/Tabs/ProviderPrices/Forms/Train';

import ConceptProviderForm from "@orders/concepts/days/ConceptProviderForm";

import Mixin       from './../tab';

import Accordion   from '@components/Accordion';
import SimpleMenu  from '@components/SimpleMenu';
import Loading     from '@components/Loading';

import AirlineView from '@orders/placeholders/components/views/Airline';
import FerryView   from '@orders/placeholders/components/views/Ferry';
import HotelView   from '@orders/placeholders/components/views/Hotel';
import OtherView   from '@orders/placeholders/components/views/Other';
import TrainView   from '@orders/placeholders/components/views/Train';


import {
    getAirports,
    getPorts,
    getOtherServiceTypes,
    optionsProvider,
    getDayConcepts,
    getAxios,
    post,
    axiosDelete
} from '@api';
import ConceptEmail from "@components/emails/modals/newEmail/ConceptEmail.vue";
import Placeholder from "@components/CRUD/Item/Tabs/Procurement/Placeholder.vue";
import ToggleSwitch from "@components/form/ToggleSwitch.vue";
import Provider from "@components/CRUD/Item/Tabs/Procurement/Provider.vue";
import {Multiselect} from "@components/form";
import moment from "moment";
import toNumber from "lodash/toNumber";
import {notifyError, notifySuccess} from "@components/Notification";

export default {
    mixins: [Mixin],

    data: function () {
        return {
            year: null,
            modalCRUD: null,
            isLoading: false,
            contact: null,
            isModalEmailVisible: false,
            procurementInfo: [],
            preselectedConceptProvider: null,
            loadedOptions: {
                dayConcept: {},
                airports: [],
                ports: [],
                routes: [],
                serviceTypes: [],

                hotel: {
                    category: [],
                    stars: []
                },
            },

            showOptionalServices: true
        }
    },

    mounted: function () {
        this.year = toNumber(moment().format('YYYY')) + 1;
        this.isLoading = true;
        Promise.all([
            getDayConcepts({
                'orderConcepts.id': this.item.id,
                _groups: ['service_day_time'],
            }),
            getAirports(),
            getPorts(),
            getOtherServiceTypes(),
            optionsProvider('hotels'),
        ]).then(([days, airports, ports, serviceTypes, hotelOptions]) => {
            this.$store.commit('updateData', {
                days: days.data.sort( (a, b) => {
                    if(this.item.orderOfDays.indexOf(String(a.id)) === -1){
                        return 1;
                    }
                    if(this.item.orderOfDays.indexOf(String(b.id)) === -1){
                        return -1;
                    }

                    return this.item.orderOfDays.indexOf(String(a.id)) > this.item.orderOfDays.indexOf(String(b.id)) ? 1 : -1;
                }),
            });
            this.isLoading = false;
            this.loadedOptions.airports     = airports.data;
            this.loadedOptions.ports        = ports.data;
            this.loadedOptions.serviceTypes = serviceTypes.data;

            this.loadedOptions.hotel        = hotelOptions.data;

            this.isOptionsLoaded = true;
        });
    },

    computed: {
        years () {
            const endYear = parseInt(moment().format('YYYY')) + 2;
            const years = [];
            for(let i = 2023; i <= endYear; i++) {
                years.push(i);
            }
            return years;
        },

        orderOfDays: function() {
            const order = [];
            this.sortedDays.forEach(day => {
                order.push(String(day.id));
            });
            return order;
        },

        sortedDays: function () {

            if (this.item.orderOfDays.length === 0) {
                return this.item.days;
            }

            const days = this.item && this.item.days || [];
            return days;
        }
    },


    methods: {
        openEmailForm: function(data) {
            /*if (this.order.orderContacts.length > 0) {
                this.contact = JSON.parse(JSON.stringify(this.order.orderContacts[0].contact))
            }*/
            this.preselectedConceptProvider = data;
            this.isModalEmailVisible = true
        },

        handleEmailFormClose: function() {
            this.isModalEmailVisible = false;
            this.contact = null;
            this.preselectedConceptProvider = null;
            this.getProcurementInfo();
        },

        documentSaved () {
            this.getProcurementInfo();
            notifySuccess('Das Dokument wurde erfolgreich gespeichert!')
        },

        markAsRequested: function(provider) {
            if(provider && provider.provider) {
                post('provider_price_requests', {
                    provider: '/api/providers/' + provider.provider.id,
                    procurementYear: this.year,
                    agency: provider.agency ? '/api/agencies/' + provider.agency.id : null,
                }, {_groups: ['provider_price_request:provider', 'provider_price_request:agency']}).then(response => {
                    notifySuccess('Das Anfragedatum wurde hinzugefügt!')
                    this.getProcurementInfo();
                })
            } else {
                notifyError('Bitte zuerst einen Anbieter auswählen!')
            }
        },

        deletePriceRequest (priceRequest) {
            axiosDelete('provider_price_requests/' + priceRequest.id).then(response => {
                notifySuccess('Das Anfragedatum wurde erfolgreich gelöscht');
                this.getProcurementInfo();
            })
        },

        isOptional(placeholder) {
            return placeholder.clientOffer.status === 'optional' ||
                placeholder.clientOffer.status === 'atLocation';
        },

        handleCloseModalCRUD: function() {
            this.modalCRUD = null;
            this.getProcurementInfo();
        },

        addPrice (data) {
            this.modalCRUD = data;
        },

        editDay: function (id) {
            window.open(`/concepts/days/${id}`, '_blank');
            //window.location.href = `/concepts/days/${id}`;
        },

        deleteDay: function (id) {
            this.deleteItem('days', { id })
        },

        getProcurementInfo () {
            getAxios('concepts/orders/' + this.item.id + '/procurement', {
                year: this.year,
                _groups: ['document_read']
            }).then(response => {
                this.procurementInfo = response.data;
            });
        },

        dayPlaceholders: function (day) {
           const { sortHelper, airlinePlaceholders, hotelPlaceholders, ferryPlaceholders, otherPlaceholders, freeTextItems, routeDetails, trainPlaceholders } = day;


            return [
                ...airlinePlaceholders.map(item => Object.assign({}, item, {
                    placeholderType: `airline`
                })),
                ...hotelPlaceholders.map(item => Object.assign({}, item, {
                    placeholderType: `hotel`,
                    nights: this.item.hotelNights && this.item.hotelNights[item.id] ? this.item.hotelNights[item.id].nights : '',
                })),
                ...ferryPlaceholders.map(item => Object.assign({}, item, {
                    placeholderType: `ferry`
                })),
                ...otherPlaceholders.map(item => Object.assign({}, item, {
                    placeholderType: `other`
                })),
                ...trainPlaceholders.map(item => Object.assign({}, item, {
                    placeholderType: `train`
                }))

            ].sort((a,b) => {
                return sortHelper.indexOf(`${a.placeholderType}-${a.id}`) < sortHelper.indexOf(`${b.placeholderType}-${b.id}`) ? -1 : 1;
            });
        },

        hasDayPlaceholders: function (day) {
            return this.dayPlaceholders(day).length > 0;
        },

        openConceptProviderForm: function (data = {}) {
            this.$refs.conceptProviderForm.open(data);
        },
    },

    watch: {
        year () {
            this.getProcurementInfo();
        }
    },

    components: {
        Multiselect,
        Provider,
        ToggleSwitch,
        Placeholder,
        ConceptEmail,
        Accordion,
        Draggable,
        ConceptProviderForm,
        Loading,
        SimpleMenu,

        // Views
        AirlineView,
        FerryView,
        HotelView,
        OtherView,
        TrainView,

        //Prices
        ModalFerryCRUD,
        ModalOtherCRUD,
        ModalHotelCRUD,
        ModalTrainCRUD
    }
}
</script>
