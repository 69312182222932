var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "ai-prompt" }, [
    _c("div", { staticClass: "columns is-vcentered" }, [
      _c("div", { staticClass: "column" }, [
        _c("div", { staticClass: "control has-icons-right" }, [
          _c("textarea", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.promptText,
                expression: "promptText",
              },
            ],
            staticClass: "textarea has-fixed-size",
            attrs: {
              disabled: _vm.promptIsDisabled,
              placeholder: "Sende eine Nachricht an ChatGPT",
            },
            domProps: { value: _vm.promptText },
            on: {
              keyup: function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                )
                  return null
                return _vm.sendPrompt.apply(null, arguments)
              },
              input: function ($event) {
                if ($event.target.composing) return
                _vm.promptText = $event.target.value
              },
            },
          }),
          _vm._v(" "),
          _c("div", { staticClass: "send-button" }, [
            _c(
              "button",
              {
                staticClass: "btn-single-toggle has-border",
                class: { "is-loading button": _vm.isSending },
                attrs: { disabled: _vm.promptIsDisabled },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.sendPrompt.apply(null, arguments)
                  },
                },
              },
              [
                !_vm.isSending
                  ? _c("i", { staticClass: "material-icons is-pointer" }, [
                      _vm._v("send"),
                    ])
                  : _vm._e(),
              ]
            ),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }