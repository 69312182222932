<template>

    <div class="new-email-modal-form">
        <div class="input-wrapper">
            <label>Telefonat mit:</label>
            <multiselect
                v-model         = "newEmail.contact"
                v-bind:options  = "contactOptions"
                v-bind:multiple = "false"
                v-bind:loading  = "isLoading.toContacts"
                v-bind:internal-search = "true"
                v-bind:taggable = "true"
                v-on:tag        = "item => addContact(item)"
                label           = "fullName"
                track-by        = "id"
                placeholder     = "Kontakt auswählen"
                @input="selectNext"
            >
            </multiselect>
        </div>

        <div class="input-wrapper">
            <label>Betreff:</label>
            <input ref="subject" type="text" class="input" v-model="newEmail.subject" />
        </div>

<!--        <div class="input-wrapper">
            <label>Beschreibung:</label>
            <tag-select
                v-model="tags"
                v-bind:option-tags="optionTags"
                my-class="is-medium"
            />
        </div>-->

<!--        <div class="input-wrapper">
            <label>Neue Aufgabe:</label>
            <input-date v-model="newEmail.todoDate"></input-date>
        </div>-->

        <div class="pell-wrapper">
            <label>Text:</label>

            <div style="z-index:0">
                <loading v-if="isLoading.preview"></loading>
                <div>
                    <div>
                        <tiny-mce v-model="newEmail.textHtml" :minHeight="400"/>
                    </div>
                </div>
            </div>
        </div>

        <div class="buttons-wrapper">
            <div class="is-flex" style="position:relative;margin-left: 84%">
                <div>
                    <button
                        class="button is-primary is-large"
                        v-bind:class="{ 'is-loading' : isLoading.send }"
                        v-on:click="send()"
                        style="margin: 10px"
                    ><i class="material-icons">check</i> Protkoll speichern</button>
                </div>
            </div>
        </div>
    </div>
</template>



<script>
import axios   from 'axios';


import Loading                        from '@components/Loading';
import { TinyMce, Multiselect, InputDate }        from '@components/form';


import { notifyError, notifySuccess } from '@components/Notification';
import {  currentUser }  from '@utilities/functions';
import store          from '../../store';
import Attachments    from './Attachments';
import RequestsSelect from './RequestsSelect';
import TagSelect from "@components/form/TagSelect";
import EmailSelect from "@components/form/EmailSelect";
import moment from "moment";
import set      from 'lodash/set'


export default {
    store: store,
    props: {
        preselectedContact: { default: null },
        preselectedOrder:   { default: null },//preSelects order
    },
    computed: {
        currentUser:        function () { return currentUser() },
        action:             function () { return this.$store.state.action; },
        users:              function () { return this.$store.state.users; },
        emailSelected: function () {
            return this.$store.getters.emailsSelected[0];
        },
        ordersSelected: function () {
            return this.$store.state.ordersSelected;
        },
        newEmail: {
            get: function() {
                return this.$store.state.newEmail;
            },
            set: function (value) {
                this.$store.state.newEmail = value;
            }
        },
        contactOptions: function () {
            return this.$store.state.contacts.filter(item => item.fullName);
        },
    },


    data: function () {
        //todo: new Task
        return {


            formData: {},

            optionTags: [],

            toContacts:         [],

            tags: [],
            options: [],

            isLoading: {
                send:       false,
                toContacts: false,
            }
        }
    },

    methods: {
        prepareForSending: function () {
            if(this.newEmail.date) {
                this.newEmail.date = moment(this.newEmail.date, 'DD.MM.YYYY HH:mm:ss').format('DD.MM.YYYY HH:mm:ss');
            }

            let copyNewEmail = JSON.parse(JSON.stringify(this.newEmail))

            if (!copyNewEmail.contact) {
                notifyError('Kontakt auswählen!');
                return false;
            }

            copyNewEmail.toAddresses = null;

            if(!copyNewEmail.contact.isFake) {
                copyNewEmail.contact = `/api/contacts/${copyNewEmail.contact.id}`
            } else {
                copyNewEmail.fromName = copyNewEmail.contact.fullName;
                copyNewEmail.contact = null;
            }

            copyNewEmail.type = 'phone';


            if (!copyNewEmail.subject) {
                notifyError('Der Betreff darf nicht leer sein!');
                return false;
            }

            copyNewEmail.subject = copyNewEmail.subject.replace("\n", '');


            if (!copyNewEmail.textHtml) {
                notifyError('Die Nachricht darf nicht leer sein!');
                return false;
            }

            copyNewEmail.links = this.ordersSelected.map(order => ({ order: '/api/orders/' + order.id }));


            copyNewEmail.user = '/api/users/' + currentUser().id


            copyNewEmail['tags'] = this.tags.map(({name}) => name);

            this.formData = copyNewEmail;


            return true;
        },


        send: function () {
            if (this.prepareForSending()) {
                this.isLoading.send = true;
                let request = null;
                 if (this.action === "edit") {
                    request = axios.put(`/api/emails/${this.emailSelected.id}`, this.formData)
                 } else {
                    request = axios.post('/api/emails', this.formData)
                 }

                request
                    .then(response => {
                        notifySuccess('Protokoll wurde gespeichert!');
                        this.formData = new FormData();
                        this.$emit('updateEmails');
                        this.$emit('closeModal');
                    }, error => { notifyError('Das Protokoll konnte nicht gespeichert werden! Server Error!'); })
                    .then(() => {
                        // Always executed (success or error)
                        this.isLoading.send = false;
                    });
            }
        },




        addContact: function (contact) {
            set(this.newEmail, 'contact', {
                id: new Date(),
                fullName: contact,
                isFake: true,
            })

        },

        selectNext () {
            this.$refs.subject.focus();
        }



    },


    created: function () {
        this.$store.state.isPhone = true;
        let preselectedOrder = this.preselectedOrder;
        this.$store.dispatch('preselectOrders', {preselectedOrder}).then(response => {
            this.$store.dispatch('resetPhoneForm');
        });

    },


    components: {
        InputDate,
        EmailSelect,
        TagSelect,
        Attachments,
        TinyMce,
        Loading,
        Multiselect,
        RequestsSelect
    }
}

</script>
