<template>
    <tiny-mce id="tinyMCE"
              api-key="gat3zfl4v22k6b7byailjzzqxkh05fv22o9pjuh1sgf2p9u7"
              :value="value"
              :init="this.options"
              v-on:input="updateValue($event)"
              @keyup="$emit('keyup', value);
"
    >
    </tiny-mce>
</template>

<script>

import TinyMce from '@tinymce/tinymce-vue'
import {tinyMceOptions} from "@clientSpecific/utilities/defaultValues";

export default {
    props: {
        value: {
            required: true,
            //validator: prop => typeof prop === 'string' || prop === null
        },
        minHeight: {
            type: Number,
            required: false,
        }
    },

    components: {
        TinyMce,
    },

    data() {
        return {
            options: this.minHeight ? { ...tinyMceOptions, min_height: this.minHeight,  setup: (editor) => {
                    editor.on('keyup', (e) => {
                        this.$emit('keyup')
                    })
                }, } : {...tinyMceOptions,  setup: (editor) => {
                editor.on('keyup', (e) => {
                    this.$emit('keyup')
                })}}
        }
    },

    methods: {
        updateValue: function (value) {
            this.$emit('input', value);
        }
    },

}
</script>
