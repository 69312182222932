var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "sb-wrapper" }, [
    _c("header", [
      _c("h1", [_vm._v("Dein Travelize AI Assistent")]),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "close",
          attrs: { title: "Schließen" },
          on: {
            click: function ($event) {
              $event.preventDefault()
              $event.stopPropagation()
              return _vm.$emit("hideSideBar")
            },
          },
        },
        [_c("i", { staticClass: "material-icons" }, [_vm._v("close")])]
      ),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "sb-content" },
      [
        _c("ai-message", { attrs: { "is-user": false } }, [
          _vm._v(
            "\n            Hallo " +
              _vm._s(_vm.currentUser.firstName) +
              "! Ich sehe du möchtest einen Anbieter hinzufügen. Hier eine Übersicht über Anbieter in Vorgängen:\n        "
          ),
        ]),
        _vm._v(" "),
        _c("crud-table", {
          key: _vm.api,
          ref: "table",
          attrs: {
            api: _vm.api,
            url: _vm.rowURL,
            target: "_blank",
            simplePagination: true,
            deleteRow: false,
            filter: _vm.params,
            loadOptions: "orders",
            columns: _vm.columns,
            name: { singular: "Element", plural: "Elementen" },
          },
          on: { loaded: _vm.saveOptions },
          scopedSlots: _vm._u([
            {
              key: "provider",
              fn: function (slotProps) {
                return [
                  _vm.placeholder.placeholderType === "hotel"
                    ? _c("span", [
                        _vm._v(
                          _vm._s(
                            _vm.options.boardList
                              ? _vm.options.boardList
                                  .find(
                                    (board) =>
                                      board.key === _vm.placeholder.board
                                  )
                                  .value.replace("+", "")
                              : _vm.placeholder.board
                          ) + ": "
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  slotProps.row.agency
                    ? _c(
                        "span",
                        { attrs: { title: slotProps.row.agency.name } },
                        [_vm._v("[A] ")]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  slotProps.row.provider
                    ? _c(
                        "span",
                        { attrs: { title: slotProps.row.provider.name } },
                        [_vm._v(_vm._s(slotProps.row.provider.name))]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  slotProps.row.agency && !slotProps.row.provider
                    ? _c(
                        "span",
                        { attrs: { title: slotProps.row.agency.name } },
                        [
                          _vm._v(
                            "No Name - " + _vm._s(slotProps.row.agency.name)
                          ),
                        ]
                      )
                    : _vm._e(),
                ]
              },
            },
            {
              key: "requestStatus.status",
              fn: function (slotProps) {
                return [
                  _c("span", [
                    _vm._v(
                      _vm._s(
                        _vm.options.requestStatuses
                          ? _vm.options.requestStatuses.find(
                              (status) =>
                                status.key ===
                                slotProps.row.requestStatus.status
                            ).value
                          : slotProps.row.requestStatus.status
                      )
                    ),
                  ]),
                ]
              },
            },
          ]),
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }