<template>
    <loading v-if="isLoading" />
    <div v-else>
        <div class="level">
            <div>
                <year-tab-filter
                    v-if="!sellingPrice"
                    v-model="filters"
                    v-bind:filter-start-end-keys="filterStartEndKeys"
                    :section-default="sectionDefault"
                />
            </div>
            <div class="level level-right">
                <div class="level-item level-left">
                    <multiselect
                        class="is-shorter"
                        style="width: 70px"
                        v-bind:options="availableYears"
                        v-model="copyYear"
                        :allowEmpty = false
                    />
                    <button class="button level-item is-success" v-on:click.prevent.stop="copySellingPrices">Kalkulationen von {{copyYear }} kopieren</button>
                </div>
                <button class="button level-item is-danger" v-on:click.prevent.stop="resetCalculations">Kalkulationen zurücksetzen</button>
            </div>
        </div>


        <calculation-order
            v-if="sellingPrice"
            v-bind:key="sellingPrice.id"
            v-bind:sellingPrice="sellingPrice"
            v-bind:sellingPrices="sortedRows"
            v-on:close="sellingPrice = null"
            v-on:update="updateCalculation"
            v-on:delete="deleteSellingPrice(sellingPrice)"
            v-on:input="setSellingPrice"
            v-on:edit="openForm(sellingPrice)"
            v-on:copy="v => copySellingPrice(sellingPrice, v)"
            v-on:reset="resetCalculations(null, sellingPrice.id)"
        />

        <div v-else class="calculation">
            <loading v-if="isLoading2"></loading>
            <contingent-prices
                v-else
                v-for="item in sortedRows"
                v-bind:key="item.id"
                v-bind:item="item"
                v-on:open="expandSellingPrice(item)"
                v-on:delete="deleteSellingPrice(item)"
                v-on:edit="openForm(item)"
                v-on:editContingentPrice="editContingentPrice"
                v-on:toggleMarginTax="toggleMarginTax"
                v-on:copy="v=> copySellingPrice(item, v)"
                v-on:reset="resetCalculations(null, item.id)"
            />

            <div class="notification is-small has-text-centered" v-if="rows.length === 0">
                Noch keine Verkaufspreise kalkuliert
            </div>

            <button class="button is-headline" v-on:click.prevent.stop="openForm">
                <i class="material-icons">add</i>
            </button>

        </div>
        <item-form ref="form" v-on:submit="handleFormSubmit" :hotel-type-placeholder="hotelTypePlaceholder"/>
    </div>
</template>

<script>
import moment                         from 'moment';
import has                            from 'lodash/has';
import {Multiselect} from "@components/form";

import Mixin                          from '../tab';

import {getAxios, getConceptSellingPrices} from '@api';
import Loading                        from '@components/Loading'
import { notifySuccess, notifyError } from '@components/Notification';
import {
    fullYear,
    currentUser,
    conceptDays,
    conceptNights,
    compareDates,
    setStorageFilter,
    getStorageFilter
} from '@utilities/functions'

import YearTabFilter from "@components/CRUD/Table/YearTabFilter";

import ContingentPrices               from './ContingentPrices'
import CalculationOrder               from './CalculationOrder'
import ItemForm                       from './Form';
import {
    post,
    getCalculation,
    addSellingPrice,
    editSellingPrice,
    deleteSellingPrice,
    addOrderFromConcept
} from '@api';
import {defaultConceptClientId} from "@clientSpecific/utilities/defaultValues";

const _groups = ['order_read', 'order_calculation_read', 'order_contingent_item_read', 'order_contingent_provider_read', 'order_placeholder_read'];



    export default {
    mixins: [ Mixin ],

    data: function () {
        return {
            filterStartEndKeys: ['seasonTimes.endAt[after]', 'seasonTimes.startAt[before]'],
            filters: {
                'seasonTimes.startAt[before]': null,
                'seasonTimes.endAt[after]': null,
                ...getStorageFilter('selling_prices'),
            },
            sellingPrice: null,
            rows: [],
            isLoading: false,
            isLoading2: false,
            hotelTypePlaceholder: 'Nicht ändern',
            copyYear: moment().format('YYYY')
        }
    },

    mounted: function () {
        this.getPrices();
    },

    computed: {
        availableYears: function() {
            const endYear = parseInt(moment().format('YYYY')) + 2;
            const years = [];
            for(let i = 2019; i <= endYear; i++) {
                years.push(i);
            }
            return years;
        },

        orderConceptData: function () {
            return {
                client: `/api/clients/${defaultConceptClientId}` ,
                user: `/api/users/${currentUser('id')}`,
                trip: {
                    destinations: this.item.destinations
                        .map(({id}) => `/api/destinations/${id}`)
                },
                currentStatus: {
                    status: 'deleted'
                },
                hotelContingent,
                ferryContingent
            }
        },

        sortedRows: function () {
            return [...this.rows]
                .sort((a,b) => {
                    if (a.subTitle < b.subTitle) {
                        return -1
                    } else if (a.subTitle > b.subTitle) {
                        return 1
                    } else {
                        let aSeasonTime = a.seasonTimes.sort((a,b) => compareDates(a.startAt, b.startAt))[0];
                        let bSeasonTime = b.seasonTimes.sort((a,b) => compareDates(a.startAt, b.startAt))[0];
                        return compareDates(aSeasonTime.startAt, bSeasonTime.startAt);
                    }
                })
        },

        sectionDefault: function () {
            return parseInt(moment(this.filters['seasonTimes.startAt[before]'], 'DD.MM.YYYY').format('YYYY'));
        },
    },

    methods: {
        toggleMarginTax: function (v) {
            this.$refs.calculationOrder.toggleMarginTax(v);
        },

        editContingentPrice: function (item, price) {
            this.rows = this.rows.map(row => ({
                ...row,
                ...row.id === item.id && {
                    calculationOrder: {
                        ...row.calculationOrder,
                        calculation: {
                            ...row.calculationOrder.calculation,
                            contingentPrices: row.calculationOrder.calculation.contingentPrices.map(contingentPrice => ({
                                ...contingentPrice,
                                ...contingentPrice.id === price.id && {
                                    ...price
                                }
                            }))
                        }
                    }
                }
            }))
        },

        getPrices: function () {
            this.isLoading2 = true;

            getConceptSellingPrices({
                'orderConcept.id' : this.item.id,
                '_groups': ['order_read', 'order_calculation_read', 'order_contingent_item_read', 'order_contingent_provider_read', 'money_read', 'currency_exchange_rate'],
                ...this.filters['seasonTimes.startAt[before]'] && {'seasonTimes.startAt[before]': this.filters['seasonTimes.startAt[before]']},
                ...this.filters['seasonTimes.endAt[after]'] && {'seasonTimes.endAt[after]': this.filters['seasonTimes.endAt[after]']},

            }).then(response => {
                this.rows = response.data;
                this.isLoading2 = false;

            })
        },

        expandSellingPrice: function (item) {
            this.sellingPrice = JSON.parse(JSON.stringify(item));
        },

        setSellingPrice: function (sellingPrice) {
            this.sellingPrice = sellingPrice;
        },

        copySellingPrice: function(sellingPrice, year) {
            post('selling_prices/' + sellingPrice.id + '/copy?year=' + year, {
                calculationOrder: this.orderConceptData
            }, {'_groups': ['order_write', 'trip_write', 'order_status_write', 'order_contingent_provider_write', 'order_contingent_item_write']}

            ).then(response => {
                this.notifySuccess('Die Kalkulation wurde erfolgreich kopiert!');
            }, error => {
                this.notifyError('Die Reise konnte nicht kopiert werden!');
            })
        },

        updateCalculation: function (calculation) {
            const sellingPriceID = this.sellingPrice.id;

            this.rows = this.rows.map(sellingPrice => ({
                ...sellingPrice,
                calculationOrder: {
                    ...sellingPrice.calculationOrder,
                    ...sellingPrice.id === sellingPriceID && {
                        calculation
                    }
                }
            }))
        },

        openForm: function (data = {}) {
            this.hotelTypePlaceholder = data.hotelTypePlaceholder;
            this.$refs.form.open({
                ...data,
                concept: this.item,
            });
        },

        deleteSellingPrice: function (sellingPrice, confirmNeeded = true) {
            if (confirm('Willst du den Preise wirklich löschen?')) {
                deleteSellingPrice(sellingPrice.id)
                    .then(response => {
                        notifySuccess('Verkaufpreis wurde erfolgreich gelöscht!')
                        this.rows = this.rows.filter(row => row.id !== sellingPrice.id)
                    }, error => {
                        notifyError('Error while deleting verkaufpreis!')
                    })
            }
        },

        handleFormSubmit: function (data = {}) {
            if (has(data, 'id')) {
                this.editSellingPrice(data)
            } else {
                if (data.periodType === 'months') {
                    this.addOrdersFromConcept(data);
                } else if(data.periodType === 'dates') {
                    this.addOrderFromConcept(data);
                } else {
                    this.addOrderMonthFromConcept(data);
                }
            }
        },

        editSellingPrice: function (data = {}) {
            editSellingPrice({
                    id: data.id,
                    data: {
                        seasonTimes: data.seasonTimes,
                        subTitle: data.subTitle
                    }
                }).then(response => {
                    notifySuccess('Verkaufpreis added successfully!')
                    this.$refs.form.close();

                    this.rows = this.rows.map(row => {
                        if (row.id === data.id) {
                            return {
                                ...row,
                                seasonTimes: data.seasonTimes,
                                subTitle: data.subTitle
                            }
                        } else {
                            return row;
                        }
                    })
                }, error => {
                    notifyError('Error while adding verkaufpreis!')
                })
        },

        addOrderMonthFromConcept: function (data = {}) {
            const startDate = moment(`01.${data.startMonth}.${data.year}`, 'DD.MM.YYYY')
            const endDate = moment(`01.${data.endMonth}.${data.year}`, 'DD.MM.YYYY').endOf('month')
            addOrderFromConcept({
                id: this.item.id,
                data: this.orderConceptData,
                params: {
                    ...orderConceptParams(data),
                    month: `${data.year}-${data.startMonth}`,
                    start_weekday: data.startDay,
                    ...data.hotelType && {
                        stars: data.hotelType.stars,
                        category: data.hotelType.category
                    }
                }
            }).then(response => {
                this.addSellingPrice({
                    ...data,
                    seasonTimes: [
                        {
                            startAt: startDate.format('DD.MM.YYYY'),
                            endAt: endDate.clone().endOf('month').format('DD.MM.YYYY'),
                        }
                    ],
                }, response.data)
                    .then(() => {
                        notifySuccess('Verkaufpreis added successfully!')
                        this.$refs.form.close();
                    }, error => {
                        notifyError('Error while adding verkaufpreis!')
                    })
            }, error => {
                notifyError('Error while adding verkaufpreis!')
            })
        },

        addOrdersFromConcept: function (data = {}) {
            Promise.all(
                data.months.map(month => {
                    return addOrderFromConcept({
                        id: this.item.id,
                        data: this.orderConceptData,
                        params: {
                            ...orderConceptParams(data),
                            month: `${data.year}-${month}`,
                            start_weekday: data.startDay,
                            ...data.hotelType && {
                                stars: data.hotelType.stars,
                                category: data.hotelType.category
                            }
                        }
                    })
                })
            ).then(responses => {
                Promise.all(responses.map((response, index) => {
                    const month = data.months[index];
                    const date = moment(`01.${month}.${data.year}`, 'DD.MM.YYYY')

                    return this.addSellingPrice({
                        ...data,
                        seasonTimes: [{
                            startAt: date.format('DD.MM.YYYY'),
                            endAt: date.clone().endOf('month').format('DD.MM.YYYY'),
                        }],
                    }, response.data)
                })).then(() => {
                    notifySuccess('Verkaufpreis added successfully!')
                    this.$refs.form.close();
                }, error => {
                    notifyError('Error while adding verkaufpreis!')
                })
            }, error => {
                notifyError('Error while adding verkaufpreis!')
            })
        },

        addOrderFromConcept: function (data = {}) {
            addOrderFromConcept({
                id: this.item.id,
                data: {
                    startAt: data.refStartAt + ' 00:00:00',
                    endAt: data.refEndAt + ' 00:00:00',
                    ...this.orderConceptData,
                },
                params: {
                    ...orderConceptParams(data),
                    ...data.hotelType && {
                        stars: data.hotelType.stars,
                        category: data.hotelType.category
                    }
                }
            }).then(response => {
                this.addSellingPrice(data, response.data)
                    .then((sellingPriceResponse) => {
                        if(this.sellingPrice) {
                            this.sellingPrice = sellingPriceResponse;
                        }
                        notifySuccess('Verkaufpreis gespeichert!')
                        this.$refs.form.close();
                    }, error => {
                        notifyError('Error while adding verkaufpreis!')
                    })
            }, error => {
                notifyError('Error while adding verkaufpreis!')
            })
        },

        addSellingPrice: function (data = {}, order) {
            return new Promise((resolve, reject) => {
                addSellingPrice({
                    seasonTimes: data.seasonTimes,
                    calculationOrder: `/api/orders/${order.id}`,
                    orderConcept: `/api/order_concepts/${this.item.id}`,
                    subTitle: data.subTitle
                }, {
                    _groups
                }).then(response => {
                    const sellingPrice = response.data;
                    const calculationID = sellingPrice.calculationOrder.calculation.id

                    getCalculation(calculationID) // Fetch calculation to show
                        .then(response => {
                            this.rows = [...this.rows, {
                                ...sellingPrice,
                                calculationOrder: {
                                    ...sellingPrice.calculationOrder,
                                    calculation: response.data
                                }
                            }]

                            resolve(sellingPrice)
                        })
                }, error => reject(error))
            })
        },

        copySellingPrices: function () {
            this.loading = true;
            getConceptSellingPrices({
                    'orderConcept.id' : this.item.id,
                    'seasonTimes.startAt[before]': '31.12.' + this.copyYear,
                    'seasonTimes.endAt[after]': '01.01.' + this.copyYear,
            }).then(response => {
                Promise.all(
                    response.data.map(sellingPrice =>
                        this.copySellingPrice(sellingPrice, moment(this.filters['seasonTimes.endAt[after]'], 'DD.MM.YYYY').format('YYYY'))
                    )
                ).then(() => {
                    this.loading = false
                    this.getPrices();
                })
            })
        },

        resetCalculations: function (event, id = null) {
            if (confirm ('Willst du die Kalkulation(en) wirklich zurücksetzen (Preise gehen verloren)?')) {
                this.isLoading = true;
                const rows = JSON.parse(JSON.stringify(this.rows))
                this.rows = []

                Promise.all([
                    ...rows.map(row => {
                            if(!id || row.id === id) {
                                return deleteSellingPrice(row.id)
                            }
                            return row;
                        }),
                    new Promise((resolve, reject) => {
                        if(this.item.days) {
                            resolve();
                        } else {
                            getAxios('order_concepts/' + this.item.id, {
                                _groups: ['concept_order:days', 'concept_day_read'],
                            }).then(response => {
                                this.item.days = response.data.days;
                                resolve()
                            })
                        }
                    })
                ]

                ).then(() => {
                    Promise.all(
                        rows.map(row => {
                            if(!id || row.id === id) {
                                return this.addOrderFromConcept({
                                    seasonTimes: row.seasonTimes.map(({startAt, endAt}) => ({
                                        startAt: startAt,
                                        endAt: endAt,
                                    })),
                                    concept:  this.item,
                                    requests: true,
                                    refStartAt: row.calculationOrder.startAt,
                                    refEndAt: row.calculationOrder.endAt,
                                    subTitle: row.sub
                                })
                            }
                            return row;
                        })
                    ).then(() => {
                        this.isLoading = false
                        this.getPrices();
                    }, error => {
                        notifyError('Problem! Kalkulation konnte nicht zurückgesetzt werden')
                    })
                }, error => {
                    notifyError('Problem! Kalkulation konnte nicht zurückgesetzt werden')
                })
            }
        },

    },

    watch: {
        filters: {
            deep: true,
            handler: function (filters) {
                this.getPrices();
                setStorageFilter('selling_prices', filters);
            }
        }
    },

    filters: {
        fullYear
    },

    components: {
        CalculationOrder,
        ContingentPrices,
        Multiselect,
        ItemForm,
        Loading,
        YearTabFilter
    }
}

const ferryContingent = {
    "all" : [
        {
            "type" : {
                "persons" : 1,
                "type" : "inside"
            },
            "askedAmount": 0
        },
        {
            "type" : {
                "persons" : 2,
                "type" : "inside"
            },
            "askedAmount": 0
        },
        {
            "type" : {
                "persons" : 2,
                "type" : "outside"
            },
            "askedAmount" : 0
        },
        {
            "type" : {
                "persons" : 1,
                "type" : "outside"
            },
            "askedAmount" : 0
        }
    ]
}

const hotelContingent = {
    "all": [
        {
            "type": {
                "persons": 1,
                "type": "standard"
            },
            "askedAmount": 0
        },
        {
            "type": {
                "persons": 2,
                "type": "standard"
            },
            "askedAmount": 0
        }
    ]
}

const orderConceptParams = function (data) {
    return {
        _groups: ['order_status_write'],
        requests: data.requests,
        ...conceptNights(data.concept),
        ...conceptDays(data.concept),
    }
}
</script>
