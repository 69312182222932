var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "requests-table" }, [
    _c("label", [_vm._v("Leistungen:")]),
    _vm._v(" "),
    _c("div", { staticClass: "redesign-table" }, [
      _c("header", [
        _c("div", [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.checkAll,
                expression: "checkAll",
              },
            ],
            attrs: { type: "checkbox" },
            domProps: {
              checked: Array.isArray(_vm.checkAll)
                ? _vm._i(_vm.checkAll, null) > -1
                : _vm.checkAll,
            },
            on: {
              change: function ($event) {
                var $$a = _vm.checkAll,
                  $$el = $event.target,
                  $$c = $$el.checked ? true : false
                if (Array.isArray($$a)) {
                  var $$v = null,
                    $$i = _vm._i($$a, $$v)
                  if ($$el.checked) {
                    $$i < 0 && (_vm.checkAll = $$a.concat([$$v]))
                  } else {
                    $$i > -1 &&
                      (_vm.checkAll = $$a
                        .slice(0, $$i)
                        .concat($$a.slice($$i + 1)))
                  }
                } else {
                  _vm.checkAll = $$c
                }
              },
            },
          }),
        ]),
        _vm._v(" "),
        _c("div", { staticStyle: { width: "55%", "padding-right": "10px" } }, [
          _vm._v("Leistung"),
        ]),
        _vm._v(" "),
        _c("div", { staticStyle: { width: "10%" } }, [_vm._v("Datum")]),
        _vm._v(" "),
        _c("div", { staticStyle: { width: "10%" } }, [_vm._v("Status")]),
        _vm._v(" "),
        _c("div", { staticStyle: { width: "10%" } }, [_vm._v("Details")]),
        _vm._v(" "),
        _c("div", { staticStyle: { width: "10%" } }, [_vm._v("Deadline")]),
      ]),
      _vm._v(" "),
      _c(
        "main",
        [
          _vm._l(_vm.orderIds, function (orderId) {
            return [
              _c("div", { staticClass: "row is-headline" }, [
                _vm._v("# " + _vm._s(orderId)),
              ]),
              _vm._v(" "),
              _vm._l(_vm.sortedRequests(orderId), function (request) {
                return _c("div", { key: request.id }, [
                  _c("div", [
                    _c("input", {
                      attrs: { type: "checkbox" },
                      domProps: { checked: _vm.isChecked(request) },
                      on: {
                        change: function ($event) {
                          return _vm.check(request)
                        },
                      },
                    }),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticStyle: { width: "55%", "padding-right": "10px" } },
                    [
                      request.requestType === "hotel"
                        ? _c(
                            "i",
                            {
                              staticClass: "material-icons",
                              attrs: { title: "Hotel" },
                            },
                            [_vm._v("home")]
                          )
                        : request.requestType === "ferry"
                        ? _c(
                            "i",
                            {
                              staticClass: "material-icons",
                              attrs: { title: "Fähre" },
                            },
                            [_vm._v("directions_boat")]
                          )
                        : request.requestType === "airline"
                        ? _c(
                            "i",
                            {
                              staticClass: "material-icons",
                              attrs: { title: "Flug" },
                            },
                            [_vm._v("airplanemode_active")]
                          )
                        : request.requestType === "train"
                        ? _c(
                            "i",
                            {
                              staticClass: "material-icons",
                              attrs: { title: "Zug" },
                            },
                            [_vm._v("directions_train")]
                          )
                        : _c("other-service-icons", {
                            attrs: { type: request.otherServiceType.category },
                          }),
                      _vm._v(
                        "\n                        " +
                          _vm._s(request.info.title) +
                          "\n                    "
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("div", { staticStyle: { width: "10%" } }, [
                    _vm._v(
                      _vm._s(_vm._f("dayMonth")(request.info.startAt)) +
                        "  -  " +
                        _vm._s(_vm._f("fullYear")(request.info.endAt))
                    ),
                  ]),
                  _vm._v(" "),
                  _vm.options
                    ? _c("div", { staticStyle: { width: "10%" } }, [
                        _vm._v(
                          _vm._s(
                            _vm.getValueWithKey({
                              key: request.requestStatus.status,
                              optionList: _vm.options.status,
                            })
                          )
                        ),
                      ])
                    : _c("div", [_vm._v("request.requestStatus.status")]),
                  _vm._v(" "),
                  request.requestType === "hotel"
                    ? _c(
                        "div",
                        { staticStyle: { width: "10%" } },
                        [
                          _vm.options
                            ? [
                                _vm._v(
                                  _vm._s(
                                    _vm.getValueWithKey({
                                      key: request.board,
                                      optionList: _vm.options.boardList,
                                    })
                                  )
                                ),
                              ]
                            : void 0,
                        ],
                        2
                      )
                    : request.requestType === "ferry"
                    ? _c("div", { staticStyle: { width: "10%" } }, [
                        _vm._v(
                          "\n                        " +
                            _vm._s(
                              request.type === "night"
                                ? "Nachtfähre"
                                : "Tagfähre"
                            ) +
                            "\n                    "
                        ),
                      ])
                    : _c("div", { staticStyle: { width: "10%" } }),
                  _vm._v(" "),
                  _c("div", { staticStyle: { width: "10%" } }, [
                    request.optionTodo && request.optionTodo.dueDate
                      ? _c(
                          "div",
                          {
                            class: [
                              "deadline-date",
                              _vm.deadlineUrgency(request.optionTodo.dueDate),
                            ],
                          },
                          [
                            _c("i", { staticClass: "material-icons" }, [
                              _vm._v("notifications_none"),
                            ]),
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm._f("fullYear")(request.optionTodo.dueDate)
                                )
                            ),
                          ]
                        )
                      : _vm._e(),
                  ]),
                ])
              }),
            ]
          }),
        ],
        2
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }