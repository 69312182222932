import moment from 'moment';
import Top    from './TopPart';
import Bottom from './BottomPart';


moment.locale('de');


const baseURL = window.location.origin;

const emailParse = function (email) {
    if (email) {
        let htmlText = `<p>&nbsp;</p><p>&nbsp;</p><div style="border:none;border-top:solid #E1E1E1 1.0pt;padding:3.0pt 0 0 0">`;
        htmlText    +=      `<p class=MsoNormal><span style="mso-fareast-font-family:Times New Roman;mso-fareast-language:ZH-CN">`
        htmlText    +=          '<b>Von:</b> ' + (email.fromName ? email.fromName : email.fromAddress) + ' &lt;' + email.fromAddress + '&gt;' + '<br>'
        htmlText    +=          '<b>Gesendet:</b> ' + moment(email.date, 'DD.MM.YYYY HH:mm:ss').format('LLLL') + ' <br>'
        htmlText    +=          '<b>An:</b> ' + email.toAddresses.map(contact =>
                (contact.name ? contact.name : contact.email) + ' &lt;' + contact.email + '&gt;').join(', ')
            + '<br>';
        htmlText    += '        <b>Betreff:</b> ' + email.subject;
        htmlText    +=      '</span></p>'
        htmlText    +=  '</div>'

        if (email.textHtml) {
            let content = new DOMParser().parseFromString(email.textHtml, 'text/html');
            //     Removing base because it interferes with our js code   <base href="some_url">
            // TODO - this will break the email links - find a better solution
            htmlText += '<div dir="ltr"><br></div>' + content.body.innerHTML;

            //clean up old wrong code
            htmlText = htmlText.replace(
                new RegExp('../../', 'g'),
                '/'
            );

            if(email.attachments) {
                for (const attachment of email.attachments) {
                    if ((attachment.disposition === 'inline' || !attachment.disposition) && attachment.type === 'image') {
                        htmlText = htmlText.replace(
                            new RegExp('cid:' + attachment.id, 'g'),
                            '/files/' + attachment.filePath
                        );
                    }
                }
            }


        } else {
            htmlText += '<div dir="ltr"><br></div>' + email.textPlain;
        }

        return htmlText;

    } else {
        return '';
    }
};


export default function (body = '', phone = '', email = null) {
    return Top + body + `
     </span></p>
     <!-- bodyContentEnd -->
          </td>
          <span style='mso-bookmark:_MailAutoSig'></span>
         </tr>
        </table>
        </div>
        <span style='mso-bookmark:_MailAutoSig'></span></td>
        <span style='mso-bookmark:_MailAutoSig'></span>
       </tr>
      </table>
      <span style='mso-bookmark:_MailAutoSig'></span></td>
      <span style='mso-bookmark:_MailAutoSig'></span>
     </tr>
     <tr style='mso-yfti-irow:1'>
      <td style='padding:0cm 15.0pt 7.5pt 15.0pt'>
      <table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0
       width="100%" style='width:100.0%;border-collapse:collapse;mso-yfti-tbllook:
       1184;mso-padding-alt:0cm 0cm 0cm 0cm'>
       <tr style='mso-yfti-irow:0;mso-yfti-firstrow:yes;mso-yfti-lastrow:yes'>
        <td width=560 valign=top style='width:420.0pt;padding:0cm 0cm 0cm 0cm'>
        <div align=center>
        <table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0
         width="100%" style='width:100.0%;border-collapse:collapse;mso-yfti-tbllook:
         1184;mso-padding-alt:0cm 0cm 0cm 0cm'>
         <tr style='mso-yfti-irow:0;mso-yfti-firstrow:yes;mso-yfti-lastrow:
          yes'>
          <td style='padding:11.25pt 0cm 0cm 0cm'>
          <div align=center>
          <table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0
           width="100%" style='width:100.0%;border-collapse:collapse;
           mso-yfti-tbllook:1184;mso-padding-alt:0cm 0cm 0cm 0cm'>
           <tr style='mso-yfti-irow:0;mso-yfti-firstrow:yes;mso-yfti-lastrow:
            yes;height:.75pt'>
            <td width="100%" style='width:100.0%;border:none;border-bottom:
            solid #CCCCCC 1.0pt;mso-border-bottom-alt:solid #CCCCCC .75pt;
            padding:0cm 0cm 0cm 0cm;height:.75pt'><span style='mso-bookmark:
            _MailAutoSig'></span></td>
            <span style='mso-bookmark:_MailAutoSig'></span>
           </tr>
          </table>
          </div>
          <span style='mso-bookmark:_MailAutoSig'></span></td>
          <span style='mso-bookmark:_MailAutoSig'></span>
         </tr>
        </table>
        </div>
        <span style='mso-bookmark:_MailAutoSig'></span></td>
        <span style='mso-bookmark:_MailAutoSig'></span>
       </tr>
      </table>
      <span style='mso-bookmark:_MailAutoSig'></span></td>
      <span style='mso-bookmark:_MailAutoSig'></span>
     </tr>
     <tr style='mso-yfti-irow:2'>
      <td style='padding:0cm 15.0pt 0cm 15.0pt'>
      <table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0
       width=560 style='width:420.0pt;mso-cellspacing:0cm;mso-yfti-tbllook:
       1184;mso-padding-alt:0cm 0cm 0cm 0cm'>
       <tr style='mso-yfti-irow:0;mso-yfti-firstrow:yes;mso-yfti-lastrow:yes'>
        <td width=300 valign=top style='width:225.0pt;padding:0cm 0cm 0cm 0cm'>
        <table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0
         align=left style='border-collapse:collapse;mso-yfti-tbllook:1184;
         margin-left:-2.25pt;margin-right:-2.25pt;mso-table-anchor-vertical:
         paragraph;mso-table-anchor-horizontal:column;mso-table-left:left;
         mso-padding-alt:0cm 0cm 0cm 0cm'>
         <tr style='mso-yfti-irow:0;mso-yfti-firstrow:yes;mso-yfti-lastrow:
          yes'>
          <td width=280 valign=top style='width:210.0pt;padding:0cm 0cm 0cm 0cm'>
          <div align=center>
          <table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0
           width="100%" style='width:100.0%;border-collapse:collapse;
           mso-yfti-tbllook:1184;mso-padding-alt:0cm 0cm 0cm 0cm'>
           <tr style='mso-yfti-irow:0;mso-yfti-firstrow:yes'>
            <td style='padding:0cm 0cm 0cm 0cm'>
            <p class=MsoNormal><span style='mso-bookmark:_MailAutoSig'><span
            style='font-size:1.0pt;mso-fareast-font-family:"Times New Roman";
            mso-fareast-language:DE;mso-no-proof:yes'><!--[if gte vml 1]><v:shapetype
             id="_x0000_t75" coordsize="21600,21600" o:spt="75"
             o:preferrelative="t" path="m@4@5l@4@11@9@11@9@5xe" filled="f"
             stroked="f">
             <v:stroke joinstyle="miter"/>
             <v:formulas>
              <v:f eqn="if lineDrawn pixelLineWidth 0"/>
              <v:f eqn="sum @0 1 0"/>
              <v:f eqn="sum 0 0 @1"/>
              <v:f eqn="prod @2 1 2"/>
              <v:f eqn="prod @3 21600 pixelWidth"/>
              <v:f eqn="prod @3 21600 pixelHeight"/>
              <v:f eqn="sum @0 0 1"/>
              <v:f eqn="prod @6 1 2"/>
              <v:f eqn="prod @7 21600 pixelWidth"/>
              <v:f eqn="sum @8 21600 0"/>
              <v:f eqn="prod @7 21600 pixelHeight"/>
              <v:f eqn="sum @10 21600 0"/>
             </v:formulas>
             <v:path o:extrusionok="f" gradientshapeok="t" o:connecttype="rect"/>
             <o:lock v:ext="edit" aspectratio="t"/>
            </v:shapetype><v:shape id="Grafik_x0020_64" o:spid="_x0000_i1027"
             type="#_x0000_t75" style='width:135pt;height:48pt;visibility:visible;
             mso-wrap-style:square'>
             <v:imagedata src="/clientSpecific/email/wetours001.png"
              o:title=""/>
            </v:shape><![endif]--><![if !vml]><img width=180 height=64
            src="/clientSpecific/email/wetours001.png"
            v:shapes="Grafik_x0020_64"><![endif]></span></span><span
            style='mso-bookmark:_MailAutoSig'><span style='font-size:1.0pt;
            font-family:"Open Sans";mso-fareast-font-family:"Open Sans";
            mso-fareast-language:DE;mso-no-proof:yes'><o:p></o:p></span></span></p>
            </td>
            <span style='mso-bookmark:_MailAutoSig'></span>
           </tr>
           <tr style='mso-yfti-irow:1'>
            <td style='padding:3.75pt 0cm 3.75pt 0cm'>
            <p class=MsoNormal><span style='mso-bookmark:_MailAutoSig'><b><span
            style='font-size:10.0pt;font-family:"Open Sans";mso-fareast-font-family:
            "Times New Roman";color:#6A6C6F;mso-fareast-language:DE;mso-no-proof:
            yes'>{{user.firstName}} {{user.lastName}}</span></b></span><span style='mso-bookmark:
            _MailAutoSig'><span style='font-family:"Open Sans";mso-fareast-font-family:
            "Times New Roman";mso-fareast-language:DE;mso-no-proof:yes'><o:p></o:p></span></span></p>
            </td>
            <span style='mso-bookmark:_MailAutoSig'></span>
           </tr>
           <tr style='mso-yfti-irow:2'>
            <td style='padding:3.75pt 0cm 0cm 0cm'>
            <p class=MsoNormal style='line-height:12.0pt;mso-line-height-rule:
            exactly'><span style='mso-bookmark:_MailAutoSig'><span
            style='font-size:8.5pt;font-family:"Open Sans";color:#000;
            mso-fareast-language:ZH-CN;mso-no-proof:yes'>Tel: +49 30 400 535 –
            410 ${phone}</span></span><span style='mso-bookmark:_MailAutoSig'><span
            style='font-size:10.0pt;font-family:"Open Sans";color:#000;
            display:none;mso-hide:all;mso-fareast-language:ZH-CN;mso-no-proof:
            yes'>&nbsp;</span></span><span style='mso-bookmark:_MailAutoSig'><span
            style='font-size:10.0pt;font-family:"Open Sans";color:#000;
            mso-fareast-language:ZH-CN;mso-no-proof:yes'><o:p></o:p></span></span></p>
            </td>
            <span style='mso-bookmark:_MailAutoSig'></span>
           </tr>
           <tr style='mso-yfti-irow:3'>
            <td style='padding:3.75pt 0cm 0cm 0cm'>
            <p class=MsoNormal style='line-height:12.0pt;mso-line-height-rule:
            exactly'><span style='mso-bookmark:_MailAutoSig'><span
            style='font-size:8.5pt;font-family:"Open Sans";color:#000;
            mso-fareast-language:ZH-CN;mso-no-proof:yes'>Fax: +49 30 400 535 -
            411</span></span><span style='mso-bookmark:_MailAutoSig'><span
            style='font-size:10.0pt;font-family:"Open Sans";color:#000;
            display:none;mso-hide:all;mso-fareast-language:ZH-CN;mso-no-proof:
            yes'>&nbsp;</span></span><span style='mso-bookmark:_MailAutoSig'><span
            style='font-size:10.0pt;font-family:"Open Sans";color:#000;
            mso-fareast-language:ZH-CN;mso-no-proof:yes'><o:p></o:p></span></span></p>
            </td>
            <span style='mso-bookmark:_MailAutoSig'></span>
           </tr>
           <tr style='mso-yfti-irow:4;mso-yfti-lastrow:yes'>
            <td style='padding:3.75pt 0cm 0cm 0cm'>
            <p class=MsoNormal style='line-height:12.0pt;mso-line-height-rule:
            exactly'><span style='mso-bookmark:_MailAutoSig'></span><a
            href="mailto:{{user.getEmailByOrganisationId(2)}}"><span style='mso-bookmark:
            _MailAutoSig'><span style='font-size:8.5pt;font-family:"Open Sans";
            color:#0B5394;mso-fareast-language:ZH-CN;mso-no-proof:yes'>{{user.getEmailByOrganisationId(2)}}</span></span><span
            style='mso-bookmark:_MailAutoSig'></span></a><span
            style='mso-bookmark:_MailAutoSig'><span style='font-size:10.0pt;
            font-family:"Open Sans";color:#000;display:none;mso-hide:all;
            mso-fareast-language:ZH-CN;mso-no-proof:yes'>&nbsp;</span></span><span
            style='mso-bookmark:_MailAutoSig'><span style='font-size:10.0pt;
            font-family:"Open Sans";color:#000;mso-fareast-language:ZH-CN;
            mso-no-proof:yes'><o:p></o:p></span></span></p>
            </td>
            <span style='mso-bookmark:_MailAutoSig'></span>
           </tr>
          </table>
          </div>
          <span style='mso-bookmark:_MailAutoSig'></span></td>
          <span style='mso-bookmark:_MailAutoSig'></span>
          <td width=20 style='width:15.0pt;padding:0cm 0cm 0cm 0cm'><span
          style='mso-bookmark:_MailAutoSig'></span></td>
          <span style='mso-bookmark:_MailAutoSig'></span>
         </tr>
        </table>
        <span style='mso-bookmark:_MailAutoSig'></span></td>
        <span style='mso-bookmark:_MailAutoSig'></span>
        <td width=120 valign=top style='width:90.0pt;padding:0cm 0cm 0cm 0cm'>
        <table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0
         align=left style='border-collapse:collapse;mso-yfti-tbllook:1184;
         margin-left:-2.25pt;margin-right:-2.25pt;mso-table-anchor-vertical:
         paragraph;mso-table-anchor-horizontal:column;mso-table-left:left;
         mso-padding-alt:0cm 0cm 0cm 0cm'>
         <tr style='mso-yfti-irow:0;mso-yfti-firstrow:yes;mso-yfti-lastrow:
          yes'>
          <td width=120 style='width:90.0pt;padding:0cm 0cm 0cm 0cm'>
          <table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0
           width="100%" style='width:100.0%;border-collapse:collapse;
           mso-yfti-tbllook:1184;mso-padding-alt:0cm 0cm 0cm 0cm'>
           <tr style='mso-yfti-irow:0;mso-yfti-firstrow:yes'>
            <td style='padding:0cm 0cm 0cm 0cm'>
            <p class=MsoNormal align=center style='text-align:center'><span
            style='mso-bookmark:_MailAutoSig'></span><a
            href="https://buspaketbln.sharepoint.com/:b:/s/Public/ERC8Xes64wJDtfz2upqoIFUBDMJLQRjPDfMfrqASHIMsuA?e=lhM7I4&amp;download=1"
            target="_blank"><span style='mso-bookmark:_MailAutoSig'><span
            style='font-size:10.0pt;mso-fareast-font-family:"Times New Roman";
            color:#0B5394;mso-no-proof:yes;text-decoration:none;text-underline:
            none'><!--[if gte vml 1]><v:shape id="Grafik_x0020_65" o:spid="_x0000_i1032"
             type="#_x0000_t75"
             href="https://buspaketbln.sharepoint.com/:b:/s/Public/ERC8Xes64wJDtfz2upqoIFUBDMJLQRjPDfMfrqASHIMsuA?e=lhM7I4&amp;download=1"
             target="&quot;_blank&quot;" style='width:91.8pt;height:126pt;
             visibility:visible;mso-wrap-style:square' o:button="t">
             <v:imagedata src="/clientSpecific/email/image002.jpg"
              o:title=""/>
            </v:shape><![endif]--><![if !vml]><span style='mso-ignore:vglayout'><img
            border=0 width=122 height=168
            src="/clientSpecific/email/image002.jpg"
            v:shapes="Grafik_x0020_65"></span><![endif]></span></span><span
            style='mso-bookmark:_MailAutoSig'></span></a><span
            style='mso-bookmark:_MailAutoSig'><span style='font-size:1.0pt;
            font-family:"Open Sans";mso-fareast-font-family:"Times New Roman";
            mso-no-proof:yes'><o:p></o:p></span></span></p>
            </td>
            <span style='mso-bookmark:_MailAutoSig'></span>
           </tr>
           <tr style='mso-yfti-irow:1;mso-yfti-lastrow:yes'>
            <td style='padding:0cm 0cm 0cm 0cm'>
            <p class=MsoNormal align=center style='text-align:center;
            line-height:12.0pt;mso-line-height-rule:exactly'><span
            style='mso-bookmark:_MailAutoSig'></span><a
            href="https://buspaketbln.sharepoint.com/:b:/s/Public/ERC8Xes64wJDtfz2upqoIFUBDMJLQRjPDfMfrqASHIMsuA?e=lhM7I4&amp;download=1"
            target="_blank"><span style='mso-bookmark:_MailAutoSig'><span
            style='font-size:8.5pt;font-family:"Open Sans";color:#0B5394;
            mso-fareast-language:ZH-CN;mso-no-proof:yes'>Buspaket Katalog 25</span></span><span
            style='mso-bookmark:_MailAutoSig'></span></a><span
            style='mso-bookmark:_MailAutoSig'><span style='font-size:10.0pt;
            font-family:"Open Sans";color:#6A6C6F;mso-fareast-language:ZH-CN;
            mso-no-proof:yes'><o:p></o:p></span></span></p>
            </td>
            <span style='mso-bookmark:_MailAutoSig'></span>
           </tr>
          </table>
          <span style='mso-bookmark:_MailAutoSig'></span></td>
          <span style='mso-bookmark:_MailAutoSig'></span>
         </tr>
        </table>
        <span style='mso-bookmark:_MailAutoSig'></span></td>
        <span style='mso-bookmark:_MailAutoSig'></span>
        <td width=20 style='width:15.0pt;padding:0cm 0cm 0cm 0cm'><span
        style='mso-bookmark:_MailAutoSig'></span></td>
        <span style='mso-bookmark:_MailAutoSig'></span>
        <td width=120 valign=top style='width:90.0pt;padding:0cm 0cm 0cm 0cm'>
        <table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0
         align=right style='border-collapse:collapse;mso-yfti-tbllook:1184;
         margin-left:-2.25pt;margin-right:-2.25pt;mso-table-anchor-vertical:
         paragraph;mso-table-anchor-horizontal:column;mso-table-left:right;
         mso-table-top:middle;mso-padding-alt:0cm 0cm 0cm 0cm'>
         <tr style='mso-yfti-irow:0;mso-yfti-firstrow:yes;mso-yfti-lastrow:
          yes'>
          <td width=120 style='width:90.0pt;padding:0cm 0cm 0cm 0cm'>
          <table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0
           width="100%" style='width:100.0%;border-collapse:collapse;
           mso-yfti-tbllook:1184;mso-padding-alt:0cm 0cm 0cm 0cm'>
           <tr style='mso-yfti-irow:0;mso-yfti-firstrow:yes'>
            <td style='padding:0cm 0cm 0cm 0cm'>
            <p class=MsoNormal align=center style='text-align:center'><span
            style='mso-bookmark:_MailAutoSig'></span><a
            href="https://buspaketbln.sharepoint.com/:b:/s/Public/EacVTgzmrwpAqNFEB8Z3Bj4BrmBWeQjR9UcnfgbQjt3Snw?e=90kCsR&amp;download=1"
            target="_blank"><span style='mso-bookmark:_MailAutoSig'><span
            style='font-size:10.0pt;mso-fareast-font-family:"Times New Roman";
            color:#0B5394;mso-no-proof:yes;text-decoration:none;text-underline:
            none'><!--[if gte vml 1]><v:shape id="Grafik_x0020_66" o:spid="_x0000_i1033"
             type="#_x0000_t75"
             href="https://buspaketbln.sharepoint.com/:b:/s/Public/EacVTgzmrwpAqNFEB8Z3Bj4BrmBWeQjR9UcnfgbQjt3Snw?e=90kCsR&amp;download=1"
             target="&quot;_blank&quot;" style='width:89.4pt;height:126pt;
             visibility:visible;mso-wrap-style:square' o:button="t">
             <v:imagedata src="/clientSpecific/email/image003.jpg"
              o:title=""/>
            </v:shape><![endif]--><![if !vml]><span style='mso-ignore:vglayout'><img
            border=0 width=119 height=168
            src="/clientSpecific/email/image003.jpg"
            v:shapes="Grafik_x0020_66"></span><![endif]></span></span><span
            style='mso-bookmark:_MailAutoSig'></span></a><span
            style='mso-bookmark:_MailAutoSig'><span style='font-size:1.0pt;
            font-family:"Open Sans";mso-fareast-font-family:"Times New Roman";
            mso-no-proof:yes'><o:p></o:p></span></span></p>
            </td>
            <span style='mso-bookmark:_MailAutoSig'></span>
           </tr>
           <tr style='mso-yfti-irow:1;mso-yfti-lastrow:yes'>
            <td style='padding:0cm 0cm 0cm 0cm'>
            <p class=MsoNormal align=center style='text-align:center;
            line-height:12.0pt;mso-line-height-rule:exactly'><span
            style='mso-bookmark:_MailAutoSig'></span><a
            href="https://buspaketbln.sharepoint.com/:b:/s/Public/EacVTgzmrwpAqNFEB8Z3Bj4BrmBWeQjR9UcnfgbQjt3Snw?e=90kCsR&amp;download=1"
            target="_blank"><span style='mso-bookmark:_MailAutoSig'><span
            style='font-size:8.5pt;font-family:"Open Sans";color:#0B5394;
            mso-fareast-language:ZH-CN;mso-no-proof:yes'>we tours Katalog 25</span></span><span
            style='mso-bookmark:_MailAutoSig'></span></a><span
            style='mso-bookmark:_MailAutoSig'><u><span style='font-size:10.0pt;
            font-family:"Open Sans";color:#6A6C6F;mso-fareast-language:ZH-CN;
            mso-no-proof:yes'><o:p></o:p></span></u></span></p>
            </td>
            <span style='mso-bookmark:_MailAutoSig'></span>
           </tr>
          </table>
          <span style='mso-bookmark:_MailAutoSig'></span></td>
          <span style='mso-bookmark:_MailAutoSig'></span>
         </tr>
        </table>
        <span style='mso-bookmark:_MailAutoSig'></span></td>
        <span style='mso-bookmark:_MailAutoSig'></span>
       </tr>
      </table>
      <span style='mso-bookmark:_MailAutoSig'></span></td>
      <span style='mso-bookmark:_MailAutoSig'></span>
     </tr>
     <tr style='mso-yfti-irow:3;mso-yfti-lastrow:yes'>
      <td style='padding:0cm 0cm 0cm 0cm'>
      <table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0
       width="100%" style='width:100.0%;border-collapse:collapse;mso-yfti-tbllook:
       1184;mso-padding-alt:0cm 0cm 0cm 0cm'>
       <tr style='mso-yfti-irow:0;mso-yfti-firstrow:yes;mso-yfti-lastrow:yes'>
        <td width=600 valign=top style='width:450.0pt;padding:0cm 0cm 0cm 0cm'>
        <div align=center>
        <table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0
         width="100%" style='width:100.0%;border-collapse:collapse;mso-yfti-tbllook:
         1184;mso-padding-alt:0cm 0cm 0cm 0cm'>
         <tr style='mso-yfti-irow:0;mso-yfti-firstrow:yes;mso-yfti-lastrow:
          yes'>
          <td style='padding:7.5pt 15.0pt 7.5pt 15.0pt'>
          <div align=center>
          <table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0
           width="100%" style='width:100.0%;border-collapse:collapse;
           mso-yfti-tbllook:1184;mso-padding-alt:0cm 0cm 0cm 0cm'>
           <tr style='mso-yfti-irow:0;mso-yfti-firstrow:yes;mso-yfti-lastrow:
            yes;height:.75pt'>
            <td width="100%" style='width:100.0%;border:none;border-bottom:
            solid #CCCCCC 1.0pt;mso-border-bottom-alt:solid #CCCCCC .75pt;
            padding:0cm 0cm 0cm 0cm;height:.75pt'><span style='mso-bookmark:
            _MailAutoSig'></span></td>
            <span style='mso-bookmark:_MailAutoSig'></span>
           </tr>
          </table>
          </div>
          <span style='mso-bookmark:_MailAutoSig'></span></td>
          <span style='mso-bookmark:_MailAutoSig'></span>
         </tr>
        </table>
        </div>
        <span style='mso-bookmark:_MailAutoSig'></span></td>
        <span style='mso-bookmark:_MailAutoSig'></span>
       </tr>
      </table>
      <span style='mso-bookmark:_MailAutoSig'></span></td>
      <span style='mso-bookmark:_MailAutoSig'></span>
     </tr>
    </table>
    <span style='mso-bookmark:_MailAutoSig'></span></td>
    <span style='mso-bookmark:_MailAutoSig'></span>
   </tr>
   <tr style='mso-yfti-irow:1;mso-yfti-lastrow:yes'>
    <td style='padding:0cm 0cm 0cm 0cm'>
    <table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0
     align=left width=600 style='width:450.0pt;border-collapse:collapse;
     mso-yfti-tbllook:1184;margin-left:-2.25pt;margin-right:-2.25pt;mso-table-anchor-vertical:
     paragraph;mso-table-anchor-horizontal:column;mso-table-left:left;
     mso-padding-alt:0cm 0cm 0cm 0cm'>
     <tr style='mso-yfti-irow:0;mso-yfti-firstrow:yes'>
      <td style='padding:0cm 15.0pt 0cm 15.0pt'>
      <table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0
       width="100%" style='width:100.0%;border-collapse:collapse;mso-yfti-tbllook:
       1184;mso-padding-alt:0cm 0cm 0cm 0cm'>
       <tr style='mso-yfti-irow:0;mso-yfti-firstrow:yes;mso-yfti-lastrow:yes'>
        <td width=560 valign=top style='width:420.0pt;padding:0cm 0cm 0cm 0cm'>
        <div align=center>
        <table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0
         width="100%" style='width:100.0%;border-collapse:collapse;mso-yfti-tbllook:
         1184;mso-padding-alt:0cm 0cm 0cm 0cm'>
         <tr style='mso-yfti-irow:0;mso-yfti-firstrow:yes'>
          <td style='padding:0cm 0cm 0cm 0cm'>
          <p class=MsoNormal align=center style='text-align:center;line-height:
          9.75pt;mso-line-height-rule:exactly'><span style='mso-bookmark:_MailAutoSig'></span><a
          href="http://www.we-tours.com/"><span style='mso-bookmark:_MailAutoSig'><span
          style='font-size:10.0pt;font-family:"Open Sans";color:#234D91;
          mso-fareast-language:ZH-CN;mso-no-proof:yes'>www.we-tours.com</span></span><span
          style='mso-bookmark:_MailAutoSig'></span></a><span style='mso-bookmark:
          _MailAutoSig'><span style='font-size:10.0pt;font-family:"Open Sans";
          color:#000;mso-fareast-language:ZH-CN;mso-no-proof:yes'> - Ihr
          Partner für das Wir-Gefühl</span></span><span style='mso-bookmark:
          _MailAutoSig'><span style='font-size:10.0pt;font-family:"Open Sans";
          color:#6A6C6F;mso-fareast-language:ZH-CN;mso-no-proof:yes'><o:p></o:p></span></span></p>
          </td>
          <span style='mso-bookmark:_MailAutoSig'></span>
         </tr>
         <tr style='mso-yfti-irow:1;mso-yfti-lastrow:yes'>
          <td style='padding:7.5pt 15.0pt 7.5pt 15.0pt'>
          <div align=center>
          <table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0
           width="75%" style='width:75.0%;border-collapse:collapse;mso-yfti-tbllook:
           1184;mso-padding-alt:0cm 0cm 0cm 0cm'>
           <tr style='mso-yfti-irow:0;mso-yfti-firstrow:yes;mso-yfti-lastrow:
            yes;height:.75pt'>
            <td width="100%" style='width:100.0%;border:none;border-bottom:
            solid #CCCCCC 1.0pt;mso-border-bottom-alt:solid #CCCCCC .75pt;
            padding:0cm 0cm 0cm 0cm;height:.75pt'><span style='mso-bookmark:
            _MailAutoSig'></span></td>
            <span style='mso-bookmark:_MailAutoSig'></span>
           </tr>
          </table>
          </div>
          <span style='mso-bookmark:_MailAutoSig'></span></td>
          <span style='mso-bookmark:_MailAutoSig'></span>
         </tr>
        </table>
        </div>
        <span style='mso-bookmark:_MailAutoSig'></span></td>
        <span style='mso-bookmark:_MailAutoSig'></span>
       </tr>
      </table>
      <span style='mso-bookmark:_MailAutoSig'></span></td>
      <span style='mso-bookmark:_MailAutoSig'></span>
     </tr>
     <tr style='mso-yfti-irow:1;mso-yfti-lastrow:yes'>
      <td style='padding:0cm 15.0pt 0cm 15.0pt'>
      <table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0
       width="100%" style='width:100.0%;border-collapse:collapse;mso-yfti-tbllook:
       1184;mso-padding-alt:0cm 0cm 0cm 0cm'>
       <tr style='mso-yfti-irow:0;mso-yfti-firstrow:yes;mso-yfti-lastrow:yes'>
        <td width=560 valign=top style='width:420.0pt;padding:0cm 0cm 0cm 0cm'>
        <div align=center>
        <table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0
         width="100%" style='width:100.0%;border-collapse:collapse;mso-yfti-tbllook:
         1184;mso-padding-alt:0cm 0cm 0cm 0cm'>
         <tr style='mso-yfti-irow:0;mso-yfti-firstrow:yes;mso-yfti-lastrow:
          yes'>
          <td style='padding:0cm 0cm 0cm 0cm'>
          <p class=MsoNormal align=center style='text-align:center;line-height:
          9.75pt;mso-line-height-rule:exactly'><span style='mso-bookmark:_MailAutoSig'><span
          style='font-size:8.5pt;font-family:"Open Sans";color:#000;
          mso-fareast-language:ZH-CN;mso-no-proof:yes'>Sredzkistraße 65&nbsp;|
          10405 Berlin |</span></span><span style='mso-bookmark:_MailAutoSig'><span
          style='font-size:8.5pt;font-family:"Open Sans";color:#6A6C6F;
          mso-fareast-language:ZH-CN;mso-no-proof:yes'> </span></span><a
          href="mailto:we@we-tours.com" target="_blank"><span
          style='mso-bookmark:_MailAutoSig'><span style='font-size:8.5pt;
          font-family:"Open Sans";color:#234D91;mso-fareast-language:ZH-CN;
          mso-no-proof:yes'>we@we-tours.com</span></span><span
          style='mso-bookmark:_MailAutoSig'></span></a><span style='mso-bookmark:
          _MailAutoSig'><span style='font-size:8.5pt;font-family:"Open Sans";
          color:#6A6C6F;mso-fareast-language:ZH-CN;mso-no-proof:yes'> </span></span><span
          style='mso-bookmark:_MailAutoSig'><span style='font-size:8.5pt;
          font-family:"Open Sans";color:#000;mso-fareast-language:ZH-CN;
          mso-no-proof:yes'>| +49 30 400535410</span></span><span
          style='mso-bookmark:_MailAutoSig'><span style='font-size:8.5pt;
          font-family:"Open Sans";color:#6A6C6F;mso-fareast-language:ZH-CN;
          mso-no-proof:yes'><o:p></o:p></span></span></p>
          </td>
          <span style='mso-bookmark:_MailAutoSig'></span>
         </tr>
        </table>
        </div>
        <span style='mso-bookmark:_MailAutoSig'></span></td>
        <span style='mso-bookmark:_MailAutoSig'></span>
       </tr>
      </table>
      <span style='mso-bookmark:_MailAutoSig'></span></td>
      <span style='mso-bookmark:_MailAutoSig'></span>
     </tr>
    </table>
    <span style='mso-bookmark:_MailAutoSig'></span></td>
    <span style='mso-bookmark:_MailAutoSig'></span>
   </tr>
  </table>
  </div>
  <span style='mso-bookmark:_MailAutoSig'></span></td>
  <span style='mso-bookmark:_MailAutoSig'></span>
 </tr>
</table>

<span style='mso-bookmark:_MailAutoSig'></span>

<p class=MsoNormal><o:p>&nbsp;</o:p></p>

</div>
     ` +
    emailParse(email) +

    Bottom;
}
