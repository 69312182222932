var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticStyle: {
        "justify-content": "right",
        display: "flex",
        "align-items": "center",
        "flex-shrink": "1",
      },
    },
    [
      _vm._l(_vm.sortedProviders, function (provider) {
        return [
          _c(
            "button",
            {
              staticClass: "concept-provider-btn",
              class: "is-" + provider.classification,
              attrs: { title: _vm.getLabel(provider) },
              on: {
                click: function ($event) {
                  return _vm.openUrl(provider)
                },
              },
            },
            [_vm._v(_vm._s(provider.classification))]
          ),
        ]
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }