<template>
    <select v-model="timeOfDay">
        <option v-for="type in localPeriodsOfDay" v-bind:value="type.key">{{ type.value }}</option>
    </select>
</template>



<script>
import axios from 'axios';
import throttle from 'lodash/throttle';
import {getOptionStorageIfNoUpdate, setOptionStorageAndLastUpdate} from "@utilities/functions";


export default {
    props: {
        value:        { required: true },
        nullable:     { type: Boolean, default: true }
    },


    data: function () {
        return {
            nullItem: {
                key: null,
                value: 'keine Zeit'
            },

            timeOfDay:    null,
            periodsOfDay: []
        }
    },


    computed: {
        localPeriodsOfDay: function () {
            if (this.nullable) {
                return [].concat(this.nullItem, this.periodsOfDay);

            } else {
                return [].concat(this.periodsOfDay);
            }
        }
    },


    methods: {
        getFirst: function () {
            if (this.localPeriodsOfDay.length > 0) {
                return this.localPeriodsOfDay[0];

            } else {
                return null;
            }
        },


        outSideUpdate: function () {
            if (this.value) {
                this.timeOfDay = this.value;

            } else if (this.nullable) {
                this.timeOfDay = null;

            } else {
                this.timeOfDay = this.getFirst();
            }
        },


        getOptions: function () {
            let options = getOptionStorageIfNoUpdate('routeDetails') || {}
            if(options && options.timeOfDay) {
                this.periodsOfDay = options.timeOfDay;
            } else {
                axios.options('/api/predefined_route_details').then(response => {
                    this.periodsOfDay = response.data.timeOfDay;
                    options.timeOfDay = this.periodsOfDay ? this.periodsOfDay : null;
                    setOptionStorageAndLastUpdate('routeDetails', options)
                });
            }
        }
    },


    created: function () {
        this.getOptions();
    },


    beforeMount: function () {
        this.outSideUpdate();
    },


    watch: {
        value: throttle(function () {
            this.outSideUpdate();
        }, 1000),


        timeOfDay: throttle(function () {
            if (this.timeOfDay === null) {
                this.timeOfDay = this.nullable ? null : this.getFirst();
            }

            this.$emit('input', this.timeOfDay);
        }, 1000),
    }
}
</script>
