<template>
    <div>
        <div class="level">
            <div class="buttons">
                <button
                    v-bind:class = "['btn-single-toggle',  isDraggableDisabled ? '' : 'is-active']"
                    v-on:click   = "isDraggableDisabled = !isDraggableDisabled"
                    v-bind:title = "isDraggableDisabled ? 'Ziehen aktivieren' : 'Ziehen blockieren'"
                ><i class="material-icons">lock</i></button>

                <button
                    v-bind:class = "['btn-single-toggle',  isRouteView ? 'is-active' : '']"
                    v-on:click   = "isRouteView = !isRouteView"
                ><i class="material-icons">place</i></button>
            </div>
        </div>
            <directions
                @openForm="$emit('openForm')"
                @updateDay="updateDay"
                v-bind:day="item"
                :options="{...options, dayDestinations: item.destinations}"
                :is-draggable-disabled="isDraggableDisabled"
                :is-route-view="isRouteView"
                @changeRouteView="isRouteView = true"
            />
    </div>

</template>

<script>
import Mixin from './tab';
import directions from '@orders/concepts/days/Day';

export default {
    mixins: [ Mixin ],

    data () {
        return {
            isRouteView: true,
            isDraggableDisabled: true
        }
    },

    methods: {
        updateDay(day) {
            this.$store.commit('updateData', day);
        }
    },

    components: {
        directions
    },
}
</script>
