var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("main-object", {
        ref: "mainObject",
        attrs: {
          headline: _vm.headline,
          levels: _vm.levels,
          priceType: _vm.priceType,
          providerType: _vm.providerType,
          "show-filters": _vm.showFilters,
          "filter-start-end-keys": _vm.filterStartEndKeys,
          buttons: _vm.buttons,
        },
        on: {
          openForm: _vm.openPriceForm,
          openOtherServiceForm: _vm.openOtherServiceForm,
          deleteOtherService: _vm.openDeleteOtherService,
          openEditTable: _vm.openEditTable,
        },
      }),
      _vm._v(" "),
      _c("item-form", {
        ref: "form",
        attrs: { "provider-type": _vm.providerType },
        on: { submit: _vm.handleSubmit },
      }),
      _vm._v(" "),
      _c("other-form", {
        ref: "other",
        attrs: { "provider-type": _vm.providerType },
        on: { submit: _vm.handleSubmit },
      }),
      _vm._v(" "),
      _c("edit-table", {
        ref: "editTable",
        attrs: { "provider-type": _vm.providerType },
        on: { submit: _vm.handleSubmit },
      }),
      _vm._v(" "),
      _vm.deleteOtherService
        ? _c("delete-other-service", {
            attrs: {
              item: _vm.deleteOtherService,
              "filter-params": _vm.item
                ? {
                    "provider.id": _vm.item.id,
                    _groups: ["provider_read", "agency_read"],
                  }
                : {},
            },
            on: {
              close: function ($event) {
                _vm.deleteOtherService = null
              },
              deleted: function ($event) {
                return _vm.$refs.mainObject.getPrices()
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }