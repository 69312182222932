import Vuex from 'vuex';
import Vue from 'vue';
import {edit, getAxios, post} from "@api";
import {prepareForBackend} from "@utilities/functions";
import reduce from "lodash/reduce";
import isEmpty from "lodash/isEmpty";
import pickBy from "lodash/pickBy";
import isBoolean from "lodash/isBoolean";
Vue.use(Vuex);

const store = new Vuex.Store({
    state: {
        dataObjects: {

        },

    },


    mutations: {
        updateIsLoading: function (state, value) {
            state.isLoading = value;
        },
    },

    actions: {
        update ({ commit, state, getters }, {formKey, data}) {
            let dataObject = state.dataObjects[formKey]
            return edit(dataObject.api + '/' + data.id, data, dataObject.params);
        },
        create ({ commit, state, getters }, {formKey, data}) {
            let dataObject = state.dataObjects[formKey]
            return post(dataObject.api, data, dataObject.params);
        },
        onError ({ commit, state, getters }, {formKey, error}) {
            let dataObject = state.dataObjects[formKey]
            let violations = error.response.data.violations;

            let errors = reduce(violations, function (obj, param) {
                obj[param.propertyPath] = param.message
                return obj;
            }, {});

            dataObject.errors = Object.assign({}, errors);
            dataObject.isLoading = false;
        },
        assignForm ({ commit, state, getters }, {formKey, emptyForm, data, reloadData = true}) {
            let dataObject = state.dataObjects[formKey]
            const formKeys = [...Object.keys(emptyForm), 'id']

            let form = {
                ...emptyForm,
                ...!isEmpty(data) && pickBy(data, (value, key) => {
                    return formKeys.includes(key) && (isBoolean(value) || value)
                })
            };

            dataObject.form = JSON.parse(JSON.stringify(form))

            if(reloadData && data.id) {
                getAxios(dataObject.api + '/' + data.id, dataObject.params).then(response => {
                    dataObject.form = JSON.parse(JSON.stringify({
                        ...form,
                        ...response.data
                    }))
                })
            }


            dataObject.errors = {};
        },
    },

    getters: {
        formData: (state) => (formKey) => {
            return prepareForBackend(state.dataObjects[formKey].form);
        },
        form: (state) => (formKey) => {
            return state.dataObjects[formKey].form;
        },
        errors: (state) => (formKey) => {
            return state.dataObjects[formKey].errors;
        },
    }
});
/*const store = new Vuex.Store({
    modules: {
        emailStore: emailStore,
        aiStore:aiStore
    }
});*/




export default store;
