<template>
    <i
        v-if="isChecked"
        class="material-icons is-green"
        v-tooltip="{delay: {show: 1000}, content: fullYear(value)}"
    >check</i>
</template>

<script>
import { fullYear } from '@utilities/functions';
import Cell from './cell';

export default {
    mixins: [ Cell ],

    methods: {
        fullYear
    },

    computed: {
        isChecked: function () {
            return !!this.value
        }
    }
}
</script>

