<template>
    <div id="app" :class="{'is-menu-opened': isMenuOpened, 'locked': isLocked, 'is-right-aside-hidden': isRightAsideHidden, 'is-left-aside-hidden': isLeftAsideHidden}">
<!--
        <app-header ref="header" v-on:capture="takeScreenshot" />
-->
        <app-header ref="header"/>

        <app-nav />

        <keep-alive>
            <faqs v-if="isFaqOpen" />
            <user-settings v-if="isUserSettingsOpen"/>
            <side-bar v-if="rightSideBar" :sidebarData="sidebarData" position="is-right" :element="rightSideBar"></side-bar>
            <side-bar v-if="leftSideBar" :sidebarData="sidebarData"  position="is-left" :element="rightSideBar"></side-bar>

        </keep-alive>

        <div class="wrapper">
            <slot />
        </div>
<!--        <screenshot ref="screenshotModal" />-->
    </div>
</template>

<script>
import Faqs from '@components/Faqs'
import UserSettings from '@components/UserSettings'

import AppHeader from './Header'
import AppNav from '@clientSpecific/layout/Nav'
import SideBar from "@components/sidebar/SideBar.vue";

/*import Screenshot from "./Screenshot";*/
/*const domToImage = require("dom-to-image");*/
export default {
    name: 'layout',
    components: {
        Faqs,
        UserSettings,
        SideBar,
        AppHeader,
        AppNav,
        //Screenshot
    },
    data: function() {
        return {
            isLoading : false,
            isVisible : false,
        }
    },

    computed: {
        sidebarData() {
            return this.$layout.sidebarData
        },

        rightSideBar() {
            return this.$layout.rightSideBar
        },

        leftSideBar() {
            return this.$layout.leftSideBar
        },

        isFaqOpen() {
            return this.$layout.faq
        },

        isUserSettingsOpen() {
            return this.$layout.userSettings
        },

        isRightSideBarOpen() {
            return this.$layout.rightSideBar
        },

        isLeftSideBarOpen() {
            return this.$layout.leftSideBar
        },

        isMenuOpened() {
            return this.$layout.menu
        },

        isLocked() {
            return this.$layout.locked
        },

        isRightAsideHidden() {
            return this.$layout.hiddenRightAside
        },

        isLeftAsideHidden() {
            return this.$layout.hiddenLeftAside
        },
    },
    methods: {
        /*takeScreenshot: function() {
            domToImage.toPng(document.body)
                .then(dataUrl => {
                    let e = {target: {files: [{}]}};
                    e.target.files[0]= this.dataURItoBlob(dataUrl);
                    this.$refs.screenshotModal.uploadImage(e);
                   this.$refs.header.disableLoading();
                });


        },
        dataURItoBlob: function(dataUrl) {
            if(typeof dataUrl !== 'string'){
                throw new Error('Invalid argument: dataURI must be a string');
            }
            dataUrl = dataUrl.split(',');
            let type = dataUrl[0].split(':')[1].split(';')[0],
                byteString = atob(dataUrl[1]),
                byteStringLength = byteString.length,
                arrayBuffer = new ArrayBuffer(byteStringLength),
                intArray = new Uint8Array(arrayBuffer);
            for (let i = 0; i < byteStringLength; i++) {
                intArray[i] = byteString.charCodeAt(i);
            }
            return new Blob([intArray], {
                type: type
            });
        },*/
    }
}
</script>
