<template>
    <modal-form
        editTitle="Hotelpreis bearbeiten"
        createTitle="Neuen Hotelpreis hinzufügen"
        class="is-large"
        :is-loading="isLoading"
    >
        <table>
            <template v-if="initialValue">
                <tr>
                    <th><label class="label">Preis:</label></th>
                    <td>
                        <toggle-switch v-model="priceType">
                            <option value="calculation">Kalkulation</option>
                            <option value="provider">Anbieter</option>
                            <option value="agency">Agentur</option>
                        </toggle-switch>
                    </td>
                </tr>
                <tr v-if="priceType === 'agency'">
                    <th><label class="label">Agentur:</label></th>
                    <td>
                        <async-select
                            api="agencies"
                            v-model="agency"
                        />
                    </td>
                </tr>
                <tr v-if="priceType === 'provider' || priceType === 'agency'">
                    <th><label class="label">Hotel:</label></th>
                    <td>
                        <async-select
                            api="hotels"
                            v-model="provider"
                        />
                    </td>
                </tr>
            </template>

            <tr v-if="providerType === 'provider'">
                <th><label class="label">Art:</label></th>
                <td>
                    <toggle-switch v-model="type">
                        <option value="direct">Direktpreis</option>
                        <option value="agency">Preis von Agentur</option>
                    </toggle-switch>
                </td>
            </tr>

            <tr v-if="providerType === 'agency'">
                <th><label class="label">Art:</label></th>
                <td>
                    <toggle-switch v-model="type">
                        <option value="direct">No-Name Preis</option>
                        <option value="hotel">Preis für ein bestimmtes Hotel</option>
                    </toggle-switch>
                </td>
            </tr>

            <tr v-if="type === 'agency'">
                <th><label class="label">Agentur:</label></th>
                <td>
                    <async-select
                        api="agencies"
                        v-model="agency"
                    />
                </td>
            </tr>

            <tr v-if="type === 'hotel'">
                <th><label class="label">Hotel:</label></th>
                <td>
                    <async-select
                        api="hotels"
                        v-model="provider"
                    />
                </td>
            </tr>

            <tr v-if="(type === 'direct' && providerType === 'agency') || !includeProvider || (priceType === 'agency' && !provider)">
                <th><label class="label">Hotelkategorie:</label></th>
                <td>
                    <multiselect
                        v-if="options.hotelTypes"
                        v-model="hotelType"
                        track-by="name"
                        label="name"
                        v-bind:options="options.hotelTypes"
                    />
                </td>
            </tr>

            <tr v-if="(type === 'direct' && providerType === 'agency') ||  !includeProvider || (priceType === 'agency' && !provider)">
                <th><label class="label">Region:</label></th>
                <td>
                    <area-select v-model="form.area" />
                </td>
            </tr>

            <!-- Preise -->
            <tr>
                <td colspan="2">
                    <h2>Preise <i class="material-icons" v-on:click="addPrice">add_circle</i></h2>
                </td>
            </tr>

        </table>

        <div>

            <edit-table
                @delete="deletePeriod"
                :filters="filter"
                :rows="rows"
                :load-options="false"
                :footer="false"
                ref="table"
                @updateFilter="value => filter = value"
                :passed-options="options"
                @saveRow="saveRow"
                :columns="columns"
            >
                <template v-slot:days="slotProps">
                    <div>
                        <days :picker=true :is-in-table=true :value="getWeekdays(slotProps.row)" @input="input => setWeekDays(input, slotProps.row)"></days>
                    </div>
                </template>
                <template v-slot:linkedGroup="slotProps">
                    <div v-if="slotProps.row.priceGroup.isNew" style="margin-left: 30px">
                        <multiselect :allow-empty="false" :value="slotProps.row.priceGroup.id" @input="input => changePriceGroup(slotProps.row, input)" :options="getNewPriceGroups"/>
                    </div>
                    <div v-else :style="getBackground(slotProps.row)" style="height: 100%; width: 100%; align-content:center;text-align: center">
                        {{slotProps.row.priceGroup.id}}
                    </div>

                </template>


            </edit-table>
            <pagination v-model="pagination" v-on:input="getPrices" :name="{singluar: 'Preisgruppe', plural: 'Preisgruppen'}" />
        </div>
    </modal-form>
</template>

<script>
import has from 'lodash/has';
import Days from '@components/Days';
import { InputDate, InputPrice, ToggleSwitch, AreaSelect, AsyncSelect } from '@components/form';
import {compareDates, dateISOtoView, daysToNumbers, numbersToDays, setStorageFilter} from '@utilities/functions';
import TabForm from '../../form'
import { importCheckedMixin, importCheckedField } from '@utilities/mixins';
import {getHotelPlaceholder, providerPrices, getHotelPriceGroups, getAxios, axiosByMethod, axiosDelete} from '@api'
import {notifyError, notifySuccess} from "@components/Notification";
import moment from "moment";
import EditTable from "@components/EditTable/Index.vue";
import set from "lodash/set";
import Pagination from "@components/CRUD/Pagination.vue";
import {omit} from "lodash";



export default {
    mixins: [ TabForm, importCheckedMixin ],

    props: {
        providerType: {
            type: String,
            required: true
        }
    },

    components: {
        Pagination,
        EditTable,
        Days,
        InputDate,
        InputPrice,
        ToggleSwitch,
        importCheckedField,
        AreaSelect,
        AsyncSelect,
    },

    form: {
        area: null,
        hotelType: {
            stars: '0',
            category: 'standard'
        },
        provider: null,
        agency: null,
    },

    data: function () {
        return {
            type: 'direct',
            priceType: 'calculation',
            prices: [],
            filter: {
                'board': null,
                'endAt[after]': '01.01.' + moment().format('YYYY'),
                'startAt[before]': null,
            },
            pagination: {
                current: 1,
                perPage: 100,
                items: 0
            },
            priceGroupIdsToDelete: [],
            defaultProviderPrices: [
                {
                    roomType: {
                        'persons': 2,
                        'type': 'standard',
                        'roomIndex': '2_standard'
                    },
                    price: {
                        amount: null,
                        currency: this.provider ? this.provider.mainCurrency : this.agency ? this.agency.mainCurrency : 'EUR',
                    }
                },
                {
                    roomType: {
                        'persons': 1,
                        'type': 'standard',
                        'roomIndex': '1_standard'
                    },
                    price: {
                        amount: null,
                        currency: this.provider ? this.provider.mainCurrency : this.agency ? this.agency.mainCurrency : 'EUR',
                    }
                },
                {
                    roomType: {
                        'persons': 3,
                        'type': 'standard',
                        'roomIndex': '3_standard'
                    },
                    price: {
                        amount: null,
                        currency: this.provider ? this.provider.mainCurrency : this.agency ? this.agency.mainCurrency : 'EUR',
                    }
                },
                {
                    roomType: {
                        'persons': 4,
                        'type': 'standard',
                        'roomIndex': '4_standard'
                    },
                    price: {
                        amount: null,
                        currency: this.provider ? this.provider.mainCurrency : this.agency ? this.agency.mainCurrency : 'EUR',
                    }
                },
            ],
        }
    },

    computed: {
        columns () {
            return [
                { key: 'linkedGroup', label:  'Preisgruppe', class: 'has-border is-120px-cell has-text-centered is-editable', formElement: ''},
                { key: 'period.startAt', label: 'Beginn', class: 'has-border has-text-centered is-date-cell', formElement: 'InputDateTable', props: {onlyDate: true} },
                { key: 'period.endAt', label: 'Ende', class: 'has-border has-text-centered is-date-cell', formElement: 'InputDateTable', props: {onlyDate: true} },
                { key: 'double.price.amount', label:  'DZ', class: 'has-border is-60px-cell is-right', formElement: 'InputPrice', props: {prefix: ''}},
                { key: 'single.price.amount', label:  'EZ', class: 'has-border is-60px-cell is-right', formElement: 'InputPrice', props: {prefix: ''}},
                { key: 'triple.price.amount', label:  '3Z', class: 'has-border is-60px-cell is-right', formElement: 'InputPrice', props: {prefix: ''}},
                { key: 'quadruple.price.amount', label:  '4Z', class: 'has-border is-60px-cell is-right', formElement: 'InputPrice', props: {prefix: ''}},
                { key: 'priceGroup.board', label:  'Verpflegung', class: 'has-border is-editable',style: 'width: 262px; flex-grow:0', formElement: 'Multiselect', props: {'allow-empty': false, label: 'value', 'track-by': 'key', options: this.options.boardExtended} },
                { key: 'double.price.currency', label: 'CUR', class: 'has-border is-editable is-60px-cell has-text-centered', formElement: 'Multiselect', props: {'allow-empty': false, options: this.options.potentialCurrencies }},
                { key: 'priceGroup.name', label: 'Bezeichnung Zeitraum (optional)', formElement: 'TextInput', class: 'has-border', style: 'flex-grow:1'},
                { key: 'days', label: 'Wochentage', formElement: '', class: 'has-border has-text-centered is-editable', style: 'width: 203px; flex-grow:0'},
                /*
                todo: Maybe put at top or with icon
                { key: 'checked', label:  'geprüft', class: 'has-border is-editable is-icon-cell',style: 'flex-grow:1', formElement: 'PureText', props: {label: 'value', 'track-by': 'key', options: this.options.boardExtended} },
*/
            ]
        },

        rows: {
            get () {
                let rows = [];
                this.prices.forEach(price => {
                    price.timePeriods.forEach(timePeriod => {
                        rows.push(
                            {
                                key: timePeriod.id,
                                priceGroup: price,
                                period: timePeriod,
                                double: price.providerPrices.find(price => price.roomType.roomIndex === '2_standard'),
                                single: price.providerPrices.find(price => price.roomType.roomIndex === '1_standard'),
                                triple: price.providerPrices.find(price => price.roomType.roomIndex === '3_standard'),
                                quadruple: price.providerPrices.find(price => price.roomType.roomIndex === '4_standard')
                            }
                        )
                    })
                })
                return rows.sort((a, b) => {
                    if(a.priceGroup.isNew && !b.priceGroup.isNew) {
                        return -1;
                    }
                    if(b.priceGroup.isNew && !a.priceGroup.isNew) {
                        return 1;
                    }
                    if(a.priceGroup.isNew && b.priceGroup.isNew) {
                        return 0;
                    }
                    return compareDates(a.period.startAt, b.period.startAt);
                })
            },
            set (rows) {
                let prices = [];
                rows.forEach(row => {
                    let priceGroup = this.prices.find(price => price.id === row.priceGroup.id);
                    this.prices = this.prices.map(price => {
                        if(price.id === priceGroup.id) {
                            return {
                                ...price,
                                ...row.priceGroup,
                                timePeriods: priceGroup.timePeriods.map(tp => {
                                    if(tp.id === row.period.id) {
                                        return {...tp, ...row.period}
                                    } else {
                                        return tp;
                                    }
                                }),
                                providerPrices: [
                                    {...row.double}, {...row.single}, {...row.triple}, {...row.quadruple}
                                ]
                            }
                        } else {
                            return price;
                        }
                    })
                })
            }
        },

        agency: {
            get: function () { return this.form.agency && !!this.form.agency.id ? this.form.agency : null },
            set: function (agency) { this.form.agency = agency }
        },

        provider: {
            get: function () { return this.form.provider && !!this.form.provider.id ? this.form.provider : null },
            set: function (provider) { this.form.provider = provider }
        },

        hotelTypes: function () {
            return (this.options && this.options.hotelTypes)
                ? this.options.hotelTypes
                : []
        },

        hotelType: {
            get: function () {

                if(!this.form.hotelType || !this.hotelTypes){
                    return {stars: 0, category: 'standard'}
                }

                let hotelType = this.hotelTypes
                    .find(({stars, category}) => stars === this.form.hotelType.stars && category === this.form.hotelType.category);

                return hotelType ? hotelType : {stars: 0, category: 'standard'}
            },

            set: function (hotelType) {
                this.form.hotelType = !!hotelType ? { stars: hotelType.stars, category: hotelType.category } : {stars: 0, category: 'standard'}
            }
        },

        includeProvider () {
            return this.providerType === 'provider' || this.priceType === 'provider' || (this.priceType === 'agency' && this.provider) || (this.providerType === 'agency' && this.type === 'hotel');
        },

        includeAgency () {
            return this.providerType === 'agency' || this.priceType === 'agency' || (this.providerType === 'provider' && this.type === 'agency');
        },

        includeArea () {
            return this.priceType === 'calculation' || !this.provider;
        },

        computedFilters () {
            return {
                ...this.filter,
                'timePeriods.endAt[after]': this.filter['endAt[after]'],
                'timePeriods.startAt[before]': this.filter['startAt[before]'],
                _itemsPerPage: this.pagination.perPage,
                _page: this.pagination.current,
            }
        },

        getNewPriceGroups () {
            let newPriceGroups = [];
            this.prices.filter(ele => ele.isNew).forEach(price => {
                price.timePeriods.forEach(period => {
                    newPriceGroups.push(period.id)
                })
            });
            return newPriceGroups;
        },
    },

    methods: {
        getWeekdays (row) {

            return daysToNumbers(row.period)
        },

        setWeekDays (input, row) {
            this.rows = this.rows.map(myRow => {
                if(myRow.key === row.key) {
                    row.priceGroup.hasChanged = true;
                    let period = {
                        ...row.period,
                        ...numbersToDays(input)
                    }
                    return set(row, 'period', period)
                } else {
                    return row
                }
            })
            row = set(row, 'period', {
                ...row.period,
                ...numbersToDays(input)
            });

        },


        getPrices() {
            this.$refs.table.isLoading = true;

            getAxios('hotel_price_groups', {
                ...this.provider && this.includeProvider && {'provider.id': this.provider.id},
                ...this.agency && this.includeAgency && {'agency.id': this.agency.id},
                ...(this.includeArea && this.form.area) && {
                    'area.id': this.form.area.id,
                },
                ...(this.includeArea && this.form.hotelType && this.form.hotelType.stars) && {
                    'hotelType.stars': this.hotelType.stars,
                },
                ...(!this.includeProvider) && {
                    'provider[exists]': false,
                },
                ...(!this.includeAgency) && {
                    'agency[exists]': false,
                },
                ...this.computedFilters,
                _groups: ['provider_price_group:provider_price', 'provider_price', 'provider_price_group:price_time_period', 'price_time_period', 'room_type'],
            }).then(response => {
                this.$refs.table.isLoading = false;

                this.pagination.items = Number(response.headers['x-trav-total-items'])
                this.prices = response.data.map(price => {
                    let providerPrices = price.providerPrices;

                    let triple = providerPrices.find(price => price.roomType.roomIndex === '3_standard')
                    let quadruple = providerPrices.find(price => price.roomType.roomIndex === '4_standard')
                    if(!triple) {
                        providerPrices.push({
                            roomType: {
                                'persons': 3,
                                'type': 'standard',
                                'roomIndex': '3_standard'
                            },
                            price: {
                                amount: null,
                                currency: this.provider ? this.provider.mainCurrency : this.agency ? this.agency.mainCurrency : 'EUR',
                            }
                        })
                    }
                    if(!quadruple) {
                        providerPrices.push({
                            roomType: {
                                'persons': 4,
                                'type': 'standard',
                                'roomIndex': '4_standard'
                            },
                            price: {
                                amount: null,
                                currency: this.provider ? this.provider.mainCurrency : this.agency ? this.agency.mainCurrency : 'EUR',
                            }
                        })
                    }
                    return {
                        ...price,
                        hasChanged: false,
                        providerPrices: providerPrices
                    }
                });
            })
        },

        changePriceGroup(row, priceGroupId) {
            //case 1: Existing element in prices needs to be moved to other priceGroup
            //find oldPriceGroup
            let prices = JSON.parse(JSON.stringify(this.prices));

            let oldPrice = prices.find(price => {
                return price.timePeriods.find(period => period.id === row.period.id)
            })
            let timePeriod = oldPrice.timePeriods.find(period => period.id === row.period.id);

            //find newPriceGroup
            let newPrice = prices.find(price => price.id === priceGroupId)
            if(!newPrice) {
                prices.push({
                    ...row.priceGroup,
                    id: priceGroupId,
                    timePeriods: [],
                    providerPrices: [{...row.double}, {...row.single}, {...row.triple}, {...row.quadruple}],
                });
                newPrice = prices.find(price => price.id === priceGroupId);
            }
            if(oldPrice.id !== newPrice.id) {
                newPrice.timePeriods.push(timePeriod)
            }
            //delete if oldPrice not relevant
            if(oldPrice.timePeriods.length === 1) {
                let index = prices.findIndex(price => price.id === oldPrice.id);
                prices.splice(index, 1);
            } else {
                let index = oldPrice.timePeriods.findIndex(period => period.id === row.period.id);
                oldPrice.timePeriods.splice(index, 1);
            }
            this.prices = prices;



        },

        saveRow(data) {
            if(data.colKey === 'priceGroup.board') {
                data.value = data.value.key
            }
            let rows = JSON.parse(JSON.stringify(this.rows));

            rows = rows.map(row => {
                if(data.row.key === row.key) {
                    row.priceGroup.hasChanged = true;
                    return set(row, data.colKey, data.value)
                } else {
                    return row
                }
            })

            this.rows = rows;
            data.row = set(data.row, data.colKey, data.value);
        },

        openCallback: function(form) {
            if(this.providerType === 'provider') {
                this.provider = this.item;
            }
            if(this.providerType === 'agency') {
                this.agency = this.item;
            }
        },

        handleInitialValue: function(initialValue) {

            this.open();

            if(this.initialValue.price){
                getHotelPlaceholder(initialValue.price.placeholder.id, '?_groups[]=modal_read&_groups[]=order_placeholder_displayed_request_read&_groups[]=place_read').then(response => {
                    const {hotelType, board, area, place, displayedRequest} = response.data;
                    this.initialValue.price.board = board;
                    this.hotelType = hotelType;
                    this.form.area = area ? area : place.area;
                    this.agency = displayedRequest && displayedRequest.agency ? displayedRequest.agency : null;
                    this.provider = displayedRequest && displayedRequest.hotel ? displayedRequest.hotel : null;
                    if(this.agency){
                        this.priceType = 'agency';
                    } else if(this.provider){
                        this.priceType = 'provider';
                    }
                })
            }

            if(this.initialValue.conceptProvider) {
                this.form = {...this.form, ...this.initialValue.conceptProvider}
            }

            if(this.agency){
                this.priceType = 'agency';
            } else if(this.provider){
                this.priceType = 'provider';
            }
        },

        deletePeriod: function (row) {
            let prices = JSON.parse(JSON.stringify(this.prices));
            let priceGroup = prices.find(price => price.id === row.priceGroup.id);
            if(priceGroup.timePeriods.length === 1) {
                if(!priceGroup.isNew) {
                    this.priceGroupIdsToDelete.push(priceGroup.id);
                }
                let index = prices.findIndex(price => price.id === row.priceGroup.id);
                prices.splice(index, 1);
            } else {
                priceGroup.hasChanged = true;
                let index = priceGroup.timePeriods.findIndex(time => time.id === row.period.id);
                priceGroup.timePeriods.splice(index, 1);
            }
            this.prices = prices;
        },

        addPrice: function () {
            let price = {}
            if(this.initialValue) {
                if(this.initialValue.conceptProvider) {
                    price = {board: this.initialValue.conceptProvider.board, timePeriods: this.initialValue.conceptProvider.timePeriods, hasChanged: true};
                }

                if(this.initialValue.price) {
                    price = {
                            board: this.initialValue.price.board,
                            ...!this.initialValue.sellingPrices && {
                                timePeriods: [
                                    {
                                        startAt: moment(this.initialValue.price.placeholder.startAt, 'DD.MM.YYYY').format('DD.MM.YYYY'),
                                        endAt: moment(this.initialValue.price.placeholder.endAt, 'DD.MM.YYYY').format('DD.MM.YYYY'),
                                        days: [0, 1, 2, 3, 4, 5, 6]
                                    }
                                ],
                            },
                            ...this.initialValue.sellingPrice && {
                                timePeriods: this.initialValue.sellingPrice.seasonTimes.map(season => {
                                    return {
                                        days: [0,1,2,3,4,5,6],
                                        key: season.id,
                                        startAt: season.startAt,
                                        endAt: season.endAt
                                    }
                                }),
                                name: this.initialValue.sellingPrice.subTitle
                            },
                            hasChanged: true,
                            providerPrices: this.defaultProviderPrices.map(price => {
                                if(price.roomType.roomIndex === '2_standard') {
                                    price.price = this.initialValue.price.doubleRoomBuyPrice;
                                }
                                if(price.roomType.roomIndex === '1_standard') {
                                    price.price = this.initialValue.price.singleRoomPremiumBuyPrice;
                                }
                                return price;
                            })
                        };
                }

            }

            let key = 'neu-' + (this.getNewPriceGroups.length + 1);
            let defaultTimePeriod = {
                startAt: null,
                endAt: null,
                ...numbersToDays([0,1,2,3,4,5,6]),
                id: key,
            };
            this.prices.push(
                {
                    timePeriods: [defaultTimePeriod],
                    providerPrices: this.defaultProviderPrices,
                    isNew: true,
                    period: {
                        startAt: null,
                        endAt: null,
                        days: [0,1,2,3,4,5,6],
                    },
                    name: '',
                    board: 'half_extra_breakfast',
                    id: key,
                    ...price,
                    ...price.timePeriods && {
                        timePeriods: price.timePeriods.map(timePeriod => ({
                            ...defaultTimePeriod,
                            ...timePeriod,
                            ...numbersToDays(timePeriod.days),
                        }))
                    },
                }
            )
        },

        getIDFromString: function(apiString) {
            if(typeof apiString === 'object'){
                return apiString.id;
            }
            let stringArray = apiString.split('/');
            return stringArray[stringArray.length - 1];
        },


        prepareForBackend: function(data) {

            //data.dataCheckedAt = this.dataCheckedAtCheck;
            //data.name = this.ownName ? data.name : '';

            if (this.form.area && this.form.area.id && this.includeArea) {
                data.area = `/api/areas/${this.form.area.id}`
            } else {
                data.area = null;
            }

            if(this.hotelType && this.hotelType.stars && this.includeArea) {
                data.hotelType = this.hotelType;
            } else {
                data.hotelType = null;
            }

            if(this.includeAgency && data.agency) {
                data.agency = data.agency.id ? `/api/agencies/${data.agency.id}` : data.agency;
            } else {
                data.agency = null;
            }

            if(this.includeProvider && data.provider) {
                data.provider = data.provider.id ? `/api/hotels/${data.provider.id}` : data.provider;
            } else {
                data.provider = null;
            }

            let double = data.providerPrices.find(price => price.roomType.roomIndex === '2_standard');

            data.providerPrices = data.providerPrices.map(price => {
                return Object.assign({}, price, {
                    price: {
                        amount: price.price.amount,
                        currency: double.price.currency
                    }
                })
            });
            return data;
        },

        handleSubmit: function () {

            if(this.includeProvider && !this.provider) {
                notifyError('Bitte Hotel auswählen!')
                return;
            }
            if(this.includeAgency && !this.agency) {
                notifyError('Bitte Agentur auswählen!')
                return;
            }
            if(this.providerType === 'calculation' && !this.form.area) {
                notifyError('Bitte Region auswählen!')
                return;
            }
            if(this.providerType === 'calculation' && !this.hotelType.stars) {
                notifyError('Bitte Sterne auswählen!')
                return;
            }

            Promise.all([
                ...this.prices.filter(price => price.hasChanged).map(price => {
                this.isLoading = true;
                let method = price.isNew ? 'post' : 'put';
                let api = 'hotel_price_groups';

                if(price.isNew) {
                    price = omit(price, ['id']);
                    price.provider = this.provider;
                    price.agency = this.agency;
                    price.area = this.form.area;
                    price.hotelType = this.hotelType;
                    price.timePeriods = price.timePeriods.map(period => omit(period, ['id']))
                } else {
                    api += '/' + price.id
                }
                let data = this.prepareForBackend(price)
                return axiosByMethod(method, api, data, {
                    _groups: [
                        'provider_price_group:price_time_period',
                        'provider_price_group:provider_price',
                        'price_time_period',
                        'provider_price',
                        'room_type',
                        'data_check_read',
                        'hotel_type_read',
                        'area_read',
                    ]
                })
            }),
                ...this.priceGroupIdsToDelete.map(priceGroup => {
                    return axiosDelete('hotel_price_groups/' + priceGroup)
                })
            ]).then(responses => {
                notifySuccess('Die Preise wurden gespeichert!')
                this.$emit('submit')
                this.isLoading = false;
                this.close()
            }, error => {
                this.notifyError("Die Preise konnte nicht gespeichert werden!")
                this.isLoading = false;
            })
        },

        getRandomColor() {
            const letters = '0123456789ABCDEF';
            let color = '#';
            for (let i = 0; i < 6; i++) {
                color += letters[Math.floor(Math.random() * 16)];
            }
            return color;
        },

        getBackground (row) {
            let colors = [
                '#AAD7D9',
                '#EFBC9B',
                '#FBF3D5',
                '#D6DAC8',
                '#9CAFAA',
                '#EED3D9',
                '#B5C0D0',
                '#E5E1DA',
                '#FBF9F1',
                '#BA90C6',
                '#E8A0BF',
                '#FDF4F5',
                '#D8AE7E',
                '#9BABB8'
            ];
            let index = this.prices.findIndex(price => price.id === row.priceGroup.id)
            return 'background:' + (index < 15 ? colors[index] : this.getRandomColor());
        }

    },

    watch: {
        computedFilters: function(oldValue, newValue) {
            if(JSON.stringify(oldValue) !== JSON.stringify(newValue)) {
                this.getPrices();
            }
        },

        provider () {
            if(!this.includeProvider || this.provider) {
                this.getPrices();
            }
        },

        agency () {
            if(!this.includeAgency || this.agency) {
                this.getPrices();
            }
        },

        hotelType () {
            if(this.form.area && this.hotelType && this.hotelType.stars) {
                this.getPrices();
            }
        },

        type () {
            this.getPrices();
        },

        'form.area' () {
            if(this.form.area && this.hotelType && this.hotelType.stars) {
                this.getPrices();
            }
        },

        priceType () {
            if(this.priceType === 'calculation') {
                if(this.form.area && this.hotelType && this.hotelType.stars) {
                    this.getPrices();
                }
            }
            if(this.priceType === 'provider') {
                if(this.provider) {
                    this.getPrices();
                }
            }
            if(this.priceType === 'agency') {
                if(this.agency) {
                    this.getPrices();
                }
            }
        }
    }
}


</script>
