var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "modal-form",
    {
      staticClass: "is-large",
      attrs: {
        editTitle: "Preise für Zusatzleistung bearbeiten",
        createTitle: "Preise für Zusatzleistung hinzufügen",
        "is-loading": _vm.isLoading,
      },
    },
    [
      _c(
        "table",
        [
          _vm.initialValue
            ? [
                _c("tr", [
                  _c("th", [
                    _c("label", { staticClass: "label" }, [_vm._v("Preis:")]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "td",
                    [
                      _c(
                        "toggle-switch",
                        {
                          model: {
                            value: _vm.priceType,
                            callback: function ($$v) {
                              _vm.priceType = $$v
                            },
                            expression: "priceType",
                          },
                        },
                        [
                          _c("option", { attrs: { value: "calculation" } }, [
                            _vm._v("Kalkulation"),
                          ]),
                          _vm._v(" "),
                          _c("option", { attrs: { value: "provider" } }, [
                            _vm._v("Anbieter"),
                          ]),
                          _vm._v(" "),
                          _c("option", { attrs: { value: "agency" } }, [
                            _vm._v("Agentur"),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _vm.priceType === "agency"
                  ? _c("tr", [
                      _c("th", [
                        _c("label", { staticClass: "label" }, [
                          _vm._v("Agentur:"),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c(
                        "td",
                        [
                          _c("async-select", {
                            attrs: { api: "agencies" },
                            model: {
                              value: _vm.agency,
                              callback: function ($$v) {
                                _vm.agency = $$v
                              },
                              expression: "agency",
                            },
                          }),
                        ],
                        1
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.priceType === "provider" || _vm.priceType === "agency"
                  ? _c("tr", [
                      _c("th", [
                        _c("label", { staticClass: "label" }, [
                          _vm._v("Leistungsträger:"),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c(
                        "td",
                        [
                          _c("async-select", {
                            attrs: { api: "providers" },
                            model: {
                              value: _vm.provider,
                              callback: function ($$v) {
                                _vm.provider = $$v
                              },
                              expression: "provider",
                            },
                          }),
                        ],
                        1
                      ),
                    ])
                  : _vm._e(),
              ]
            : _vm._e(),
          _vm._v(" "),
          _vm.providerType === "provider"
            ? _c("tr", [
                _c("th", [
                  _c("label", { staticClass: "label" }, [_vm._v("Art:")]),
                ]),
                _vm._v(" "),
                _c(
                  "td",
                  [
                    _c(
                      "toggle-switch",
                      {
                        model: {
                          value: _vm.type,
                          callback: function ($$v) {
                            _vm.type = $$v
                          },
                          expression: "type",
                        },
                      },
                      [
                        _c("option", { attrs: { value: "direct" } }, [
                          _vm._v("Direktpreis"),
                        ]),
                        _vm._v(" "),
                        _c("option", { attrs: { value: "agency" } }, [
                          _vm._v("Preis von Agentur"),
                        ]),
                      ]
                    ),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.providerType === "agency"
            ? _c("tr", [
                _c("th", [
                  _c("label", { staticClass: "label" }, [_vm._v("Art:")]),
                ]),
                _vm._v(" "),
                _c(
                  "td",
                  [
                    _c(
                      "toggle-switch",
                      {
                        model: {
                          value: _vm.type,
                          callback: function ($$v) {
                            _vm.type = $$v
                          },
                          expression: "type",
                        },
                      },
                      [
                        _c("option", { attrs: { value: "direct" } }, [
                          _vm._v("No-Name Preis"),
                        ]),
                        _vm._v(" "),
                        _c("option", { attrs: { value: "hotel" } }, [
                          _vm._v("Preis für einen bestimmten Leistungsträger"),
                        ]),
                      ]
                    ),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.type === "agency"
            ? _c("tr", [
                _c("th", [
                  _c("label", { staticClass: "label" }, [_vm._v("Agentur:")]),
                ]),
                _vm._v(" "),
                _c(
                  "td",
                  [
                    _c("async-select", {
                      attrs: { api: "agencies" },
                      model: {
                        value: _vm.agency,
                        callback: function ($$v) {
                          _vm.agency = $$v
                        },
                        expression: "agency",
                      },
                    }),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.type === "hotel"
            ? _c("tr", [
                _c("th", [
                  _c("label", { staticClass: "label" }, [
                    _vm._v("Leistungsträger:"),
                  ]),
                ]),
                _vm._v(" "),
                _c(
                  "td",
                  [
                    _c("async-select", {
                      attrs: { api: "others" },
                      model: {
                        value: _vm.provider,
                        callback: function ($$v) {
                          _vm.provider = $$v
                        },
                        expression: "provider",
                      },
                    }),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("tr", [
            _c("th", [
              _c("label", { staticClass: "label" }, [_vm._v("Leistung:")]),
            ]),
            _vm._v(" "),
            _c(
              "td",
              [
                _c(
                  "toggle-switch",
                  {
                    model: {
                      value: _vm.existingService,
                      callback: function ($$v) {
                        _vm.existingService = $$v
                      },
                      expression: "existingService",
                    },
                  },
                  [
                    _c("option", { domProps: { value: true } }, [
                      _vm._v("Bestehende Leistung"),
                    ]),
                    _vm._v(" "),
                    _c("option", { domProps: { value: false } }, [
                      _vm._v("Neue Leistung"),
                    ]),
                  ]
                ),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("th", [
            _c("label", { staticClass: "label" }, [_vm._v("Zusatzleistung:")]),
          ]),
          _vm._v(" "),
          _c(
            "td",
            [
              _c("async-select", {
                attrs: {
                  api: "other_service_types",
                  params: {
                    ...(this.provider &&
                      this.existingService &&
                      this.includeProvider && {
                        "otherServices.provider.id": this.provider.id,
                      }),
                    ...(this.agency &&
                      this.existingService &&
                      this.includeAgency && {
                        "otherServices.agency.id": this.agency.id,
                      }),
                    ...(!this.includeProvider &&
                      this.existingService && {
                        "otherServices.provider[exists]": false,
                      }),
                    ...(!this.includeAgency &&
                      this.existingService && {
                        "otherServices.agency[exists]": false,
                      }),
                    _groups: ["service_day_time"],
                  },
                },
                model: {
                  value: _vm.otherServiceType,
                  callback: function ($$v) {
                    _vm.otherServiceType = $$v
                  },
                  expression: "otherServiceType",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c("tr", [
            _c("td", { attrs: { colspan: "2" } }, [
              _c("h2", [
                _vm._v("Preise "),
                _c(
                  "i",
                  {
                    staticClass: "material-icons",
                    on: { click: _vm.addPrice },
                  },
                  [_vm._v("add_circle")]
                ),
              ]),
            ]),
          ]),
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "div",
        [
          _c("edit-table", {
            ref: "table",
            attrs: {
              filters: _vm.filter,
              rows: _vm.rows,
              "load-options": false,
              footer: false,
              "passed-options": _vm.options,
              columns: _vm.columns,
            },
            on: {
              delete: _vm.deletePeriod,
              updateFilter: (value) => (_vm.filter = value),
              saveRow: _vm.saveRow,
            },
            scopedSlots: _vm._u([
              {
                key: "days",
                fn: function (slotProps) {
                  return [
                    _c(
                      "div",
                      [
                        _c("days", {
                          attrs: {
                            picker: true,
                            "is-in-table": true,
                            value: _vm.getWeekdays(slotProps.row),
                          },
                          on: {
                            input: (input) =>
                              _vm.setWeekDays(input, slotProps.row),
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                },
              },
              {
                key: "serviceDayTimes",
                fn: function (slotProps) {
                  return [
                    _c("div", [
                      slotProps.row.priceGroup.serviceDayTimes.length
                        ? _c("span", [
                            _vm._v(
                              _vm._s(
                                slotProps.row.priceGroup.serviceDayTimes.length
                              )
                            ),
                          ])
                        : _c("span", [_vm._v("alle")]),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass: "btn-edit",
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              $event.stopPropagation()
                              _vm.priceToBeEdited = slotProps.row.priceGroup
                            },
                          },
                        },
                        [
                          _c("i", { staticClass: "material-icons" }, [
                            _vm._v("edit"),
                          ]),
                        ]
                      ),
                    ]),
                  ]
                },
              },
              {
                key: "linkedGroup",
                fn: function (slotProps) {
                  return [
                    slotProps.row.priceGroup.isNew
                      ? _c(
                          "div",
                          { staticStyle: { "margin-left": "30px" } },
                          [
                            _c("multiselect", {
                              attrs: {
                                "allow-empty": false,
                                value: slotProps.row.priceGroup.id,
                                options: _vm.getNewPriceGroups,
                              },
                              on: {
                                input: (input) =>
                                  _vm.changePriceGroup(slotProps.row, input),
                              },
                            }),
                          ],
                          1
                        )
                      : _c(
                          "div",
                          {
                            staticStyle: {
                              height: "100%",
                              width: "100%",
                              "align-content": "center",
                              "text-align": "center",
                            },
                            style: _vm.getBackground(slotProps.row),
                          },
                          [
                            _vm._v(
                              "\n                    " +
                                _vm._s(slotProps.row.priceGroup.id) +
                                "\n                "
                            ),
                          ]
                        ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c(
            "modal",
            {
              staticClass: "is-small is-form",
              attrs: { visible: !!_vm.priceToBeEdited },
            },
            [
              _c("p", { attrs: { slot: "header" }, slot: "header" }, [
                _vm._v("Preise gültig für folgende Zeiten"),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { attrs: { slot: "content" }, slot: "content" },
                [
                  _vm.priceToBeEdited
                    ? _c("service-day-time-field", {
                        attrs: {
                          label: "Gültig für",
                          fields: [
                            "startTimeOfDay",
                            "endTimeOfDay",
                            "checkInTimeOfDay",
                          ],
                          "predefined-day-times": _vm.predefinedDayTimes,
                        },
                        model: {
                          value: _vm.priceToBeEdited.serviceDayTimes,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.priceToBeEdited,
                              "serviceDayTimes",
                              $$v
                            )
                          },
                          expression: "priceToBeEdited.serviceDayTimes",
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                {
                  staticClass: "level",
                  attrs: { slot: "footer" },
                  slot: "footer",
                },
                [
                  _c(
                    "button",
                    {
                      staticClass: "button is-primary",
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          $event.stopPropagation()
                          _vm.priceToBeEdited = null
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n                    Fenster schließen\n                "
                      ),
                    ]
                  ),
                ]
              ),
            ]
          ),
          _vm._v(" "),
          _c("pagination", {
            attrs: {
              name: { singluar: "Preisgruppe", plural: "Preisgruppen" },
            },
            on: { input: _vm.getPrices },
            model: {
              value: _vm.pagination,
              callback: function ($$v) {
                _vm.pagination = $$v
              },
              expression: "pagination",
            },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }