<template>
    <div class="bp-row-form">
        <div class="is-flex-grow">
            <div class="bp-row-form__title">Routenstichpunkt</div>

            <div>
                <button
                    v-bind:class = "['btn-single-toggle',  hasNoSports ? '' : 'is-active']"
                    v-on:click   = "toggleSports"
                    title        = "Streckenprofil"
                ><i class="material-icons">directions_bike</i></button>

                <button
                    class        = "btn-single-toggle has-margin-right"
                    v-bind:class = "[onlyFromOrder ? '' : 'is-active']"
                    v-on:click   = "onlyFromOrder = !onlyFromOrder"
                    v-bind:title = "onlyFromOrder ? 'Nur von Ausflugszielen' : 'Alles'"
                ><i class="material-icons">flag</i></button>

                <error-wrapper v-bind:message="isEmpty">

                <multiselect
                    ref                    = "route"
                    v-model                = "routeModel"
                    v-bind:options         = "predefinedRoutes"
                    v-bind:loading         = "isLoading"
                    v-bind:allow-empty     = "false"
                    v-bind:taggable        = "isTaggable"
                    v-bind:internal-search = "false"

                    placeholder     = "tippen, um Routenbeschreibung zu finden"
                    label           = "routeDescription"
                    track-by        = "routeDescription"
                    tag-placeholder = "Add this as new tag"
                    select-label    = ""

                    v-on:tag           = "addTag"
                    v-on:select        = "predefinedSelected"
                    v-on:search-change = "getPredefinedRoutesList"
                >
                    <span slot="caret">
                        <a
                            class  = "multiselect-crud-link"
                            href   = "/routes?add"
                            title  = "füge eine neue Route hinzu"
                            target = "_blank"
                        ><i class="material-icons">add</i></a>
                    </span>
                </multiselect>
                </error-wrapper>

            </div>
        </div>


        <div>
            <div class="bp-row-form__title">Zeitangaben</div>

            <div>
                <i title="Uhrzeit" class="material-icons">access_time</i>
                <div class="select">
                    <time-of-day-select v-model="copyPlaceholder.timeOfDay"></time-of-day-select>
                </div>
            </div>
        </div>


        <div class="is-160" v-if="!isConcept">
            <div class="bp-row-form__title">Eintritt</div>

            <div>
                <i class="material-icons">account_balance</i>
                <input-price :prefix="copyPlaceholder.pricePerPerson.currency" v-model="copyPlaceholder.pricePerPerson.amount"></input-price>
            </div>
        </div>

        <div v-if="!isConcept">
            <div class="bp-row-form__title">Währung</div>

            <div>
                <multiselect
                    v-model="copyPlaceholder.pricePerPerson.currency"
                    :options="potentialCurrencies ? potentialCurrencies : ['EUR']"
                    placeholder="EUR"
                    class="is-small"
                    :allow-empty="false"
                />
            </div>
        </div>




        <div class="is-flex-grow" v-if="!isConcept">
            <div class="bp-row-form__title">Gebühr</div>

            <div>
                <i class="material-icons">account_balance</i>
                <async-select
                    api="location_charges"
                    multiple
                    v-model="copyPlaceholder.locationCharges"
                    v-on:create="$emit('newLocationCharges', copyPlaceholder.predefinedRouteDetail)"
                />
            </div>
        </div>


        <div v-if="!hasNoSports && !isConcept" class="is-flex-grow">
            <div class="bp-row-form__title">Streckenprofil</div>

            <div>
                <i class="material-icons">signal_cellular_4_bar</i>
                <input
                    v-model="copyPlaceholder.additionalDescription"
                    class="input"
                    placeholder="Streckenprofil"
                    type="text"
                />
            </div>

            <div class="is-160">
                <i class="fa fa-signal" aria-hidden="true"></i>
                <div class="select">
                    <select v-model="copyPlaceholder.difficulty">
                        <option value="simple">einfach</option>
                        <option value="medium">mittel</option>
                        <option value="hard">schwer</option>
                    </select>
                </div>
            </div>
        </div>


        <!-- action buttons -->
        <slot></slot>
    </div>
</template>



<script>
import get          from 'lodash/get';
import has          from 'lodash/has';
import debounce     from 'lodash/debounce';
import { InputPrice, TimeOfDaySelect, Multiselect, AsyncSelect, ErrorWrapper } from '@components/form';
import { getPredefinedRoutesList } from '@api';
import store     from '@orders/store';
import mixins from "@orders/placeholders/components/forms/mixins";



export default {
    mixins: [mixins],
    store,

    props: {
        value:               { type: Object,  required: true },
        isConcept:           { type: Boolean, default: false },
        limitedDestinations: { type: Array,   required: true },
        isTaggable:          { default:  true }
    },


    data: function () {
        return {
            copyPlaceholder:  JSON.parse(JSON.stringify(this.value)),
            routeModel:       {},
            predefinedRoutes: [],
            hasNoSports:      true,
            searchQuery:      '',
            onlyFromOrder:    true,
            isLoading:        false,
            isEmptyResults:   false,

            locationChargesOptions: {
                types: []
            },
        }
    },


    methods: {
        // Multiselect adding new tags
        addTag: function (newTag) {
            this.copyPlaceholder.predefinedRouteDetail = null;
            this.routeModel                             = { routeDescription: newTag };
            this.copyPlaceholder.routeDescription      = newTag;
        },


        predefinedSelected: function (option) {
            this.routeModel                            = option;
            this.copyPlaceholder.predefinedRouteDetail = option;
            this.copyPlaceholder.routeDescription      = option.routeDescription;
            this.copyPlaceholder.pricePerPerson        = option.pricePerPerson
            this.copyPlaceholder.additionalDescription = option.additionalDescription;
            this.copyPlaceholder.difficulty            = option.difficulty;

            this.copyPlaceholder.locationCharges        = option.locationCharges ? option.locationCharges : []
        },


        getPredefinedRoutesList: debounce(function (query) {
            if (query) {
                this.searchQuery = query;

                let apiQuery = '?routeDescription=' + query;
                apiQuery    += this.hasNoSports ? '&difficulty[exists]=false' : '&difficulty[exists]=true';

                if (this.onlyFromOrder) {
                    apiQuery += this.limitedDestinations.reduce((currentString, destination) => {
                            return currentString + '&destination.id[]=' + destination.id;
                        }, '');
                }

                this.isLoading = true;

                this.callCancelRequest();
                getPredefinedRoutesList(apiQuery, {cancelToken: this.cancelSource.token}).then(response => {
                    this.predefinedRoutes = response.data;

                    this.isEmptyResults = response.data.length === 0;

                    // If it is a new Route, set it to the first (if any)
                    if (this.copyPlaceholder.isBrandNew && this.predefinedRoutes.length > 0) {
                        this.predefinedSelected(this.predefinedRoutes[0]);
                    }

                    this.isLoading = false;
                });
            }
        }, 900),


        toggleSports: function () {
            if (this.hasNoSports) {
                this.copyPlaceholder.difficulty = 'simple';
                this.hasNoSports = false;

            } else {
                this.copyPlaceholder.additionalDescription = null;
                this.copyPlaceholder.difficulty = null;
                this.hasNoSports = true;
            }
        },

        update() {
            this.copyPlaceholder = JSON.parse(JSON.stringify(this.value));
        }
    },


    created: function () {
        if (this.copyPlaceholder.routeDescription) {
            if(this.copyPlaceholder.predefinedRouteDetail){
                this.routeModel = { routeDescription: this.copyPlaceholder.routeDescription, id: this.copyPlaceholder.predefinedRouteDetail.id };
            } else {
                this.routeModel = { routeDescription: this.copyPlaceholder.routeDescription };

            }
        }

        this.hasNoSports = !this.copyPlaceholder.difficulty;
    },

    computed: {
        potentialCurrencies: function() {
            return  this.$store.state.options.potentialCurrencies;
        },
        isEmpty: function () {
            return this.routeModel.id ? '' : 'Freitext - wird nicht im Tageskonzept gespeichert'
        }
    },

    mounted: function () {
        if (this.value && !this.value.predefinedRouteDetail) {
            this.$refs.route.$el.querySelector('input').focus();
        }

        if (this.value && this.value.predefinedRouteDetail && !this.value.locationCharges) {
            this.copyPlaceholder.locationCharges = get(this.value, 'predefinedRouteDetail.locationCharges', [])
        }

    },


    watch: {
        value: function (value) {
            if (JSON.stringify(value) !== JSON.stringify(this.copyPlaceholder)) {
                this.copyPlaceholder = JSON.parse(JSON.stringify(value));
            }
        },

        copyPlaceholder: {
            handler: function (value) {
                this.$emit('input', value);
            },
            deep: true
        },


        onlyFromOrder: function () { this.getPredefinedRoutesList(this.searchQuery); },
        hasNoSports:   function () { this.getPredefinedRoutesList(this.searchQuery); }
    },


    components: {
        InputPrice,
        Multiselect,
        AsyncSelect,
        ErrorWrapper,
        TimeOfDaySelect
    }
}
</script>
