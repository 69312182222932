var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "new-request" },
    [
      _c("div", { staticClass: "overall-buttons" }, [
        _c(
          "button",
          {
            staticClass: "button is-large",
            on: {
              click: function ($event) {
                return _vm.$emit("close")
              },
            },
          },
          [
            _c("i", {
              staticClass: "fa fa-long-arrow-left",
              attrs: { "aria-hidden": "true" },
            }),
            _vm._v(" Zurück zur Übersicht"),
          ]
        ),
      ]),
      _vm._v(" "),
      _c("accordion", { attrs: { isCollapsible: false } }, [
        _c("div", { attrs: { slot: "header" }, slot: "header" }, [
          _c(
            "div",
            [
              _vm.newRequest.hotel
                ? _c("request-score", {
                    attrs: { rating: _vm.newRequest.hotel.buspaketRating },
                  })
                : _c("i", {
                    staticClass: "fa fa-plus-circle",
                    attrs: { "aria-hidden": "true" },
                  }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            [
              _vm._v(
                "\n                LEISTUNGSTRÄGER HINZUFÜGEN\n\n                "
              ),
              _c(
                "provider-info",
                {
                  staticClass: "header-extra-info",
                  attrs: { provider: _vm.newRequest.hotel },
                },
                [
                  _c("div", [
                    _c("i", { staticClass: "material-icons spacing-left" }, [
                      _vm._v("perm_identity"),
                    ]),
                    _vm._v(" Kundenkontingent:\n                        "),
                    _c("i", { staticClass: "material-icons no-spacing" }, [
                      _vm._v("local_hotel"),
                    ]),
                    _vm._v(" "),
                    _c("i", { staticClass: "material-icons" }, [
                      _vm._v("local_hotel"),
                    ]),
                    _vm._v(
                      "\n                        " +
                        _vm._s(
                          _vm.hotelContingent.info
                            ? _vm.hotelContingent.info.doubleRooms.totalAsked
                            : 0
                        ) +
                        " Doppelzimmer\n                    "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", [
                    _c("i", { staticClass: "material-icons" }, [
                      _vm._v("local_hotel"),
                    ]),
                    _vm._v(
                      "\n                        " +
                        _vm._s(
                          _vm.hotelContingent.info
                            ? _vm.hotelContingent.info.singleRooms.totalAsked
                            : 9
                        ) +
                        " Einzelzimmer\n                    "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", [
                    _c("i", { staticClass: "material-icons no-spacing" }, [
                      _vm._v("local_hotel"),
                    ]),
                    _vm._v(" "),
                    _c("i", { staticClass: "material-icons no-spacing" }, [
                      _vm._v("local_hotel"),
                    ]),
                    _vm._v(" "),
                    _c("i", { staticClass: "material-icons" }, [
                      _vm._v("local_hotel"),
                    ]),
                    _vm._v(
                      "\n                        " +
                        _vm._s(
                          _vm.hotelContingent.info
                            ? _vm.hotelContingent.info.otherRooms.totalAsked
                            : 0
                        ) +
                        " Mehrbettzimmer\n                    "
                    ),
                  ]),
                ]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", [
            _c(
              "label",
              {
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                  },
                },
              },
              [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.isMainRequest,
                      expression: "isMainRequest",
                    },
                  ],
                  attrs: { type: "checkbox" },
                  domProps: {
                    checked: Array.isArray(_vm.isMainRequest)
                      ? _vm._i(_vm.isMainRequest, null) > -1
                      : _vm.isMainRequest,
                  },
                  on: {
                    change: function ($event) {
                      var $$a = _vm.isMainRequest,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 && (_vm.isMainRequest = $$a.concat([$$v]))
                        } else {
                          $$i > -1 &&
                            (_vm.isMainRequest = $$a
                              .slice(0, $$i)
                              .concat($$a.slice($$i + 1)))
                        }
                      } else {
                        _vm.isMainRequest = $$c
                      }
                    },
                  },
                }),
                _vm._v(
                  "\n                    als Leistung festlegen\n                "
                ),
              ]
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { attrs: { slot: "content" }, slot: "content" }, [
          _c("div", { staticClass: "bp-box is-info" }, [
            _c(
              "main",
              [
                _c(
                  "button",
                  {
                    class: [
                      "btn-single-toggle",
                      _vm.onlyFromOrder ? "" : "is-active",
                    ],
                    staticStyle: {
                      border: "1px solid #dddddd",
                      "margin-right": "5px",
                      "min-width": "22px",
                      "min-height": "22px",
                    },
                    attrs: {
                      title: _vm.onlyFromOrder
                        ? "Suche ist auf obigen Ort eingeschränkt"
                        : "Suche ist nicht eingeschränkt",
                    },
                    on: {
                      click: function ($event) {
                        _vm.onlyFromOrder = !_vm.onlyFromOrder
                      },
                    },
                  },
                  [
                    _c(
                      "i",
                      {
                        staticClass: "material-icons",
                        staticStyle: { margin: "0" },
                      },
                      [_vm._v("flag")]
                    ),
                  ]
                ),
                _vm._v(" "),
                _c("hotel-select", {
                  attrs: {
                    placeholderInfo: _vm.onlyFromOrder
                      ? _vm.localPlaceholderInfo
                      : { area: null, place: null },
                    isTriggerSearch: true,
                    "show-icon": false,
                    "multi-select-style": "width: 400px",
                  },
                  model: {
                    value: _vm.newRequest.hotel,
                    callback: function ($$v) {
                      _vm.$set(_vm.newRequest, "hotel", $$v)
                    },
                    expression: "newRequest.hotel",
                  },
                }),
                _vm._v(" "),
                _c("agency-select", {
                  attrs: {
                    provider: _vm.newRequest.hotel,
                    text: "Agentur",
                    prefill: "",
                  },
                  on: { update: (agency) => (_vm.newRequest.agency = agency) },
                }),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "columns" }, [
            _c(
              "div",
              { staticClass: "column" },
              [
                _vm.newRequest.contingent
                  ? _c("simple-hotel-contingent", {
                      attrs: {
                        type: "hotel",
                        nrOfPersons: 2,
                        types: _vm.hotelTypes,
                        isOnlyAsked: true,
                      },
                      model: {
                        value: _vm.newRequest.contingent.doubleRooms,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.newRequest.contingent,
                            "doubleRooms",
                            $$v
                          )
                        },
                        expression: "newRequest.contingent.doubleRooms",
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "column" },
              [
                _vm.newRequest.contingent
                  ? _c("simple-hotel-contingent", {
                      attrs: {
                        type: "hotel",
                        nrOfPersons: 1,
                        types: _vm.hotelTypes,
                        isOnlyAsked: true,
                      },
                      model: {
                        value: _vm.newRequest.contingent.singleRooms,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.newRequest.contingent,
                            "singleRooms",
                            $$v
                          )
                        },
                        expression: "newRequest.contingent.singleRooms",
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "column" },
              [
                _vm.newRequest.contingent
                  ? _c("hotel-contingent", {
                      attrs: {
                        type: "hotel",
                        types: _vm.hotelTypes,
                        isOnlyAsked: true,
                      },
                      model: {
                        value: _vm.newRequest.contingent.otherRooms,
                        callback: function ($$v) {
                          _vm.$set(_vm.newRequest.contingent, "otherRooms", $$v)
                        },
                        expression: "newRequest.contingent.otherRooms",
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "columns" }, [
            _c(
              "div",
              { staticClass: "column is-offset-6" },
              [
                _c("item-prices", {
                  attrs: { isWithoutPrice: true, provider: _vm.provider },
                  model: {
                    value: _vm.newRequest.itemPrices,
                    callback: function ($$v) {
                      _vm.$set(_vm.newRequest, "itemPrices", $$v)
                    },
                    expression: "newRequest.itemPrices",
                  },
                }),
              ],
              1
            ),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "button is-large",
          on: {
            click: function ($event) {
              return _vm.$emit("close")
            },
          },
        },
        [
          _c("i", { staticClass: "material-icons" }, [_vm._v("close")]),
          _vm._v(" Abbrechen"),
        ]
      ),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "button is-primary is-large",
          class: { "is-loading": _vm.isLoading },
          on: { click: _vm.saveNewRequest },
        },
        [
          _c("i", { staticClass: "material-icons" }, [_vm._v("check")]),
          _vm._v(" speichern und zur Email"),
        ]
      ),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "button is-primary is-large",
          class: { "is-loading": _vm.isLoading },
          on: {
            click: function ($event) {
              return _vm.saveNewRequest("close")
            },
          },
        },
        [
          _c("i", { staticClass: "material-icons" }, [
            _vm._v("keyboard_arrow_right"),
          ]),
          _vm._v(" speichern und schließen"),
        ]
      ),
      _vm._v(" "),
      _c("confirmation", {
        attrs: {
          isVisible: _vm.isVisibleConfirmation,
          "is-local": "",
          text: "Soll die Beschreibung der Anfrage in den Platzhalter kopiert werden?",
        },
        on: { input: (value) => _vm.$emit("confirm", value) },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }