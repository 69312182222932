var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isLoading
    ? _c("loading")
    : _c(
        "div",
        [
          _c("div", { staticClass: "level" }, [
            _c(
              "div",
              [
                !_vm.sellingPrice
                  ? _c("year-tab-filter", {
                      attrs: {
                        "filter-start-end-keys": _vm.filterStartEndKeys,
                        "section-default": _vm.sectionDefault,
                      },
                      model: {
                        value: _vm.filters,
                        callback: function ($$v) {
                          _vm.filters = $$v
                        },
                        expression: "filters",
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "level level-right" }, [
              _c(
                "div",
                { staticClass: "level-item level-left" },
                [
                  _c("multiselect", {
                    staticClass: "is-shorter",
                    staticStyle: { width: "70px" },
                    attrs: { options: _vm.availableYears, allowEmpty: false },
                    model: {
                      value: _vm.copyYear,
                      callback: function ($$v) {
                        _vm.copyYear = $$v
                      },
                      expression: "copyYear",
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "button level-item is-success",
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          $event.stopPropagation()
                          return _vm.copySellingPrices.apply(null, arguments)
                        },
                      },
                    },
                    [
                      _vm._v(
                        "Kalkulationen von " +
                          _vm._s(_vm.copyYear) +
                          " kopieren"
                      ),
                    ]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "button level-item is-danger",
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      $event.stopPropagation()
                      return _vm.resetCalculations.apply(null, arguments)
                    },
                  },
                },
                [_vm._v("Kalkulationen zurücksetzen")]
              ),
            ]),
          ]),
          _vm._v(" "),
          _vm.sellingPrice
            ? _c("calculation-order", {
                key: _vm.sellingPrice.id,
                attrs: {
                  sellingPrice: _vm.sellingPrice,
                  sellingPrices: _vm.sortedRows,
                },
                on: {
                  close: function ($event) {
                    _vm.sellingPrice = null
                  },
                  update: _vm.updateCalculation,
                  delete: function ($event) {
                    return _vm.deleteSellingPrice(_vm.sellingPrice)
                  },
                  input: _vm.setSellingPrice,
                  edit: function ($event) {
                    return _vm.openForm(_vm.sellingPrice)
                  },
                  copy: (v) => _vm.copySellingPrice(_vm.sellingPrice, v),
                  reset: function ($event) {
                    return _vm.resetCalculations(null, _vm.sellingPrice.id)
                  },
                },
              })
            : _c(
                "div",
                { staticClass: "calculation" },
                [
                  _vm.isLoading2
                    ? _c("loading")
                    : _vm._l(_vm.sortedRows, function (item) {
                        return _c("contingent-prices", {
                          key: item.id,
                          attrs: { item: item },
                          on: {
                            open: function ($event) {
                              return _vm.expandSellingPrice(item)
                            },
                            delete: function ($event) {
                              return _vm.deleteSellingPrice(item)
                            },
                            edit: function ($event) {
                              return _vm.openForm(item)
                            },
                            editContingentPrice: _vm.editContingentPrice,
                            toggleMarginTax: _vm.toggleMarginTax,
                            copy: (v) => _vm.copySellingPrice(item, v),
                            reset: function ($event) {
                              return _vm.resetCalculations(null, item.id)
                            },
                          },
                        })
                      }),
                  _vm._v(" "),
                  _vm.rows.length === 0
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "notification is-small has-text-centered",
                        },
                        [
                          _vm._v(
                            "\n            Noch keine Verkaufspreise kalkuliert\n        "
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "button is-headline",
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          $event.stopPropagation()
                          return _vm.openForm.apply(null, arguments)
                        },
                      },
                    },
                    [
                      _c("i", { staticClass: "material-icons" }, [
                        _vm._v("add"),
                      ]),
                    ]
                  ),
                ],
                2
              ),
          _vm._v(" "),
          _c("item-form", {
            ref: "form",
            attrs: { "hotel-type-placeholder": _vm.hotelTypePlaceholder },
            on: { submit: _vm.handleFormSubmit },
          }),
        ],
        1
      )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }