<template>
    <div
        v-bind:key="provider.id"
        class="placeholder-view has-requests is-request"
    >
        <div style="width: auto">

            <a :href="'/agencies/' + provider.agency.id" target="_blank" v-if="provider.agency">{{provider.agency.name}}</a>
            <span v-if="provider.agency"> - </span>
            <a :href="providerUrl(provider.provider)" target="_blank" v-if="provider.provider">{{provider.provider.name}}</a>
            <span v-else>ohne Anbieter</span>
        </div>
        <div style="width: 150px" class="is-flex is-align-items-center">
            <div class="is-flex is-align-items-center is-fullwidth" >
                <template v-if="info && info.priceRequest">
                    <user-image class="mr-2" v-bind:user="info.priceRequest.user" small/>
                    <span>{{ moment(info.priceRequest.updatedAt, 'DD.MM.YYYY').format('DD.MM.YYYY')}}</span>
                </template>
                <template v-else>
                    <button class="button is-smaller is-gray is-fullwidth" v-on:click="openEmail">jetzt anfragen</button>
                </template>
            </div>
        </div>
        <div style="width: 150px" class="is-flex is-align-items-center">
            <div class="is-flex is-align-items-center is-fullwidth" >
                <template v-if="info && info.price">
                    <user-image class="mr-2" v-bind:user="info.price.user" small/>
                    <span>{{ moment(info.price.updatedAt, 'DD.MM.YYYY').format('DD.MM.YYYY')}}</span>
                </template>
                <template v-else>
                    <button class="button is-smaller is-gray is-fullwidth" v-on:click="addPrice">jetzt erfassen</button>
                </template>
            </div>
        </div>
        <div class="is-flex is-align-items-center" style="width: 150px; padding:0px">
            <drop-zone-in-row
                :doc-id="provider.id"
                :documents="info ? info.documents : []"
                :upload-data="uploadData"
                class="is-flex is-align-items-center"
                style="width: 100%;"
                @documentSaved="$emit('documentSaved')"
            >
            </drop-zone-in-row>
        </div>

        <div style="width: 50px" class="action-buttons">
            <simple-menu material icon="more_vert" title="Menu">
                <button v-on:click.stop="openEmail"><i class="material-icons">mail</i> Emailanfrage</button>
                <button v-on:click.stop="markAsRequested"><i class="material-icons">check</i> als angefragt markieren</button>

                <button v-on:click.stop="addPrice"><i class="material-icons">add</i> Preis hinzufügen</button>

                <!--
                                <button v-on:click.stop=""><i class="material-icons">mode_edit</i> Anfragedatum bearbeiten</button>
                -->
<!--                <button v-on:click.stop=""><i class="material-icons">delete</i> Preis löschen</button>-->
                <button v-on:click.stop="deletePriceRequest" v-if="info && info.priceRequest"><i class="material-icons">delete</i> Anfragedatum löschen</button>
<!--
                <button v-on:click.stop=""><i class="material-icons">delete</i> Dokument löschen</button>
-->
            </simple-menu>
        </div>

        <button
            class        = "nr-of-requests"
            :class="info && info.price ? 'is-ok' : 'is-high'"
            title        = "Anfragen"
        >{{ provider.classification }}</button>
    </div>
</template>



<script>

import SimpleMenu from "@components/SimpleMenu.vue";
import UserImage from "@components/UserImage.vue";
import {currentUser} from "@utilities/functions";
import DropZoneInRow from "@components/form/DropZoneInRow.vue";
import moment from "moment";
import toNumber from "lodash/toNumber";


export default {
    components: {
        DropZoneInRow,
        UserImage,
        SimpleMenu
    },

    props: {
        provider: { type: Object,  required: true },
        placeholder: { type: Object,  required: true },
        showOptionalServices: { type: Boolean, default: true},
        procurementInfo: {type: Array, default: () => []},
        year: {type: Number}
    },

    data () {
        return {
            uploadData: {
                ...this.provider.provider && {links: [{
                        provider: `/api/providers/${this.provider.provider.id}`
                }]},
                ...this.provider.agency && {links: [{
                        provider: `/api/providers/${this.provider.agency.id}`
                }]},
                type: 'prices',
                year: this.year
            }
        }
    },

    computed: {
        moment() {
            return moment
        },
        info () {
            return this.procurementInfo.find(item => item.id === this.provider.id);
        }
    },

    methods: {
        currentUser,

        openEmail () {
            this.$emit('openEmail', {
                ...this.provider,
                placeholder: this.placeholder,
            })
        },

        markAsRequested () {
            this.$emit('markAsRequested', this.provider);
        },

        deletePriceRequest () {
            this.$emit('deletePriceRequest', this.info.priceRequest);
        },

        addPrice () {
            let year = toNumber(moment().format('YYYY')) + 1;
              this.$emit('addPrice', {
                  conceptProvider: {
                      ...this.placeholder,
                      ...this.provider,
                      ...this.placeholder.place && {area: this.placeholder.place.area},
                      timePeriods: [{
                          days: [0,1,2,3,4,5,6],
                          startAt: '01.01.' + year,
                          endAt: '31.12.' + year,
                          key: 1
                      }],
                  },
                  component: this.placeholder.placeholderType
              }  );
        },

        providerUrl: function(provider) {
            if(provider.providerType === 'hotel'){
                return '/hotels/' + provider.id;
            }
            else if(provider.providerType === 'ferry'){
                return '/ferries/' + provider.id;
            }
            else if(provider.providerType === 'other'){
                return '/others/' + provider.id;
            }
            else if(provider.providerType === 'train'){
                return '/trains/' + provider.id;
            }
            if(provider.providerType === 'airline'){
                return '/airlines/' + provider.id;
            }
            return '';
        }
    }
}
</script>
