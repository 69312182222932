<template>
    <div class="is-full-width is-full-height">
        <div
            v-if="showDeadlineUrgency"
            class="bp-deadline is-edit-table is-full-height is-full-width"
            v-bind:class="[deadlineUrgency(internalValue)]"
        >
        <input-date placeholder="" ref="date" v-model="internalValue" v-if="!readOnly"></input-date>
            <span class="is-full-width is-full-height is-flex is-align-content-center is-align-items-center is-justify-content-center" v-else >{{formatDate(internalValue)}}</span>
        </div>
        <div
            v-if="!showDeadlineUrgency"
            class="is-edit-table is-full-height is-full-width"
        >
            <input-date placeholder="" ref="date" v-model="internalValue" v-if="!readOnly"></input-date>
            <span class="is-full-width is-full-height is-flex is-justify-content-center is-align-items-center" v-else >{{formatDate(internalValue)}}</span>
        </div>
    </div>


</template>

<script>


import { InputDate } from '@components/form';
import {deadlineUrgency} from "@utilities/functions";
import moment from "moment";

export default {
    props: {
        value: { required: true },
        readOnly: { type: Boolean, default: false},
        onlyDate: { type: Boolean, default: false},
        showDeadlineUrgency: {type: Boolean, default: false}
    },

    computed: {
        internalValue: {
            get: function() {
                return this.value
            },
            set: function (value) {
                const onlyDate = this.internalValue ? this.internalValue.split('00:00:00')[0] : ''
                if(value) {
                    if(value.trim() !== onlyDate.trim()) {
                        this.$emit('input', this.onlyDate ? value : value + ' 00:00:00')
                    }
                }
                else if(!value && onlyDate !== '') {
                    this.$emit('input', value)
                }
            }
        },
    },
    methods: {
        deadlineUrgency,
        focus: function () {
            this.$refs.date.select();
        },
        formatDate(date) {
            if(!date) {
              return '';
            }
            return moment(date, 'DD.MM.YYYY').format('DD.MM.YYYY')
        },
    },
    components: {
        InputDate
    },


}
</script>
