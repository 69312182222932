<template>
    <div class="sb-wrapper">
        <header>
            <h1>Dein Travelize AI Assistent</h1>
            <button class="close" v-on:click.prevent.stop="$emit('hideSideBar')" title="Schließen"><i class="material-icons">close</i></button>
        </header>
        <div class="sb-content" v-if="aiStore.data">
            <template v-for="message in aiStore.data.messages.filter(message => message.isVisible)">
                <ai-message :is-user="message.role === 'user'"  :key="message.key">
                    <div v-html="message.content"></div>
                </ai-message>
                <div class="columns is-multiline ml-5 mr-5">
                    <div class="column p-1 is-narrow" v-for="suggestion in message.suggestions">
                        <button class="button" @click="runSuggestion(aiStore.suggestions[suggestion])">{{aiStore.suggestions[suggestion].label}}</button>
                    </div>
                </div>
            </template>

<!--            <ai-message :is-user="true">Schreibe eine Antwort auf meine Email</ai-message>
            <ai-message :is-user="false">Das ist eine tolle Antwort von ChatGpt</ai-message>
            <ai-message :is-user="true">Schreibe eine Antwort auf meine Email, die jetzt etwaslänger ist und eine längere Antwort proudiert</ai-message>-->
            <ai-message :is-user="false" v-if="aiStore.isSending"><loading class="is-small" textInFront="" text-after=""/></ai-message>

            <ai-prompt/>
        </div>
    </div>
</template>
<script>


import DropZoneInRow from "@components/form/DropZoneInRow.vue";
import AiPrompt from "@components/AiChat/AiPrompt.vue";
import UserImage from "@components/UserImage.vue";
import AiMessage from "@components/AiChat/AiMessage.vue";
import Loading from "@components/Loading.vue";
import store from "@components/AiChat/store";

import {currentUser} from "@utilities/functions";

export default {
    store: store,

    data: function() {
        return {

        }
    },

    computed: {
        aiStore: {
            get () {
                return this.$store && this.$store.state && this.$store.state.aiStore
            },
        },
        emailStore: {
            get () {
                return this.$store && this.$store.state && this.$store.state.emailStore
            },
        }
    },

    methods: {
        runSuggestion(suggestion) {
            if(suggestion.clear) {
                this.$store.state.aiStore.data.messages = [];
                this.$store.state.aiStore.data.messages.push(...this.getInitChat().messages)
                //this.aiStore.data = {model: suggestion.model, ...this.aiStore.startHelp.email};
            }
            this.aiStore.data.messages.push(...suggestion.messages)
            if(suggestion.callAction) {
                suggestion.callAction.forEach(action => {
                    this.$store.dispatch(action);
                })
            }
        },

        getInitChat () {
            if(this.emailStore.isOpen) {
                return this.aiStore.startHelp.email;
            } else {
                return this.aiStore.startHelp.default;
                let url = window.location.pathname.replace(/\d+/g, '');

            }
        },

        initChat () {
            this.aiStore.data = {...this.aiStore.data, ...this.getInitChat()}
            this.aiStore.promptIsDisabled = true;
        }
    },

    activated () {
        this.initChat()
    },

    mounted () {
        this.initChat()
    },

    components: {
        Loading,
        AiMessage,
        UserImage,
        AiPrompt,
        DropZoneInRow
    }
}

</script>
