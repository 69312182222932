var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "modal-form",
        {
          attrs: {
            editTitle: "Aufgabe bearbeiten",
            createTitle: "Neuen Aufgabe hinzufügen",
          },
        },
        [
          _c("table", [
            _c("tr", [
              _c("th", [
                _c("label", { staticClass: "label" }, [
                  _vm._v("Tage vor Leistungsbeginn:"),
                ]),
              ]),
              _vm._v(" "),
              _c("td", [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model.number",
                      value: _vm.form.daysBeforeStartAt,
                      expression: "form.daysBeforeStartAt",
                      modifiers: { number: true },
                    },
                  ],
                  staticClass: "input is-small",
                  attrs: { type: "text" },
                  domProps: { value: _vm.form.daysBeforeStartAt },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(
                        _vm.form,
                        "daysBeforeStartAt",
                        _vm._n($event.target.value)
                      )
                    },
                    blur: function ($event) {
                      return _vm.$forceUpdate()
                    },
                  },
                }),
              ]),
            ]),
            _vm._v(" "),
            _c("tr", [
              _c("th", [
                _c("label", { staticClass: "label" }, [_vm._v("Aufgabe:")]),
              ]),
              _vm._v(" "),
              _c(
                "td",
                [
                  _c(
                    "multiselect",
                    {
                      attrs: { options: _vm.todoOptions, taggable: true },
                      on: {
                        tag: _vm.addName,
                        "search-change": _vm.searchName,
                        close: _vm.closeName,
                      },
                      model: {
                        value: _vm.form.name,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "name", $$v)
                        },
                        expression: "form.name",
                      },
                    },
                    [_c("span", { attrs: { slot: "caret" }, slot: "caret" })]
                  ),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("tr", [
              _c("th", [
                _c("label", { staticClass: "label" }, [_vm._v("Art:")]),
              ]),
              _vm._v(" "),
              _c(
                "td",
                [
                  _c(
                    "toggle-switch",
                    {
                      model: {
                        value: _vm.form.type,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "type", $$v)
                        },
                        expression: "form.type",
                      },
                    },
                    [
                      _c("option", { attrs: { value: "external" } }, [
                        _vm._v("Strenge Deadline"),
                      ]),
                      _vm._v(" "),
                      _c("option", { attrs: { value: "internal" } }, [
                        _vm._v("Weiche Deadline"),
                      ]),
                    ]
                  ),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("tr", [
              _c("th", { staticClass: "is-top" }, [
                _c("label", { staticClass: "label" }, [_vm._v("Gültig für:")]),
              ]),
              _vm._v(" "),
              _c(
                "td",
                [
                  _c("color-switch", { on: { input: _vm.form.type } }),
                  _vm._v(" "),
                  _c("div", { staticClass: "control" }, [
                    _c(
                      "div",
                      { staticClass: "checkboxes columns" },
                      [
                        _vm._l(_vm.options.requestStatuses, function (status) {
                          return _c(
                            "div",
                            {
                              key: status.value,
                              staticClass: "column is-one-third",
                            },
                            [
                              _c("label", { staticClass: "checkbox" }, [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.validForRequestStatuses,
                                      expression: "validForRequestStatuses",
                                    },
                                  ],
                                  attrs: { type: "checkbox" },
                                  domProps: {
                                    value: status.key,
                                    checked: Array.isArray(
                                      _vm.validForRequestStatuses
                                    )
                                      ? _vm._i(
                                          _vm.validForRequestStatuses,
                                          status.key
                                        ) > -1
                                      : _vm.validForRequestStatuses,
                                  },
                                  on: {
                                    change: function ($event) {
                                      var $$a = _vm.validForRequestStatuses,
                                        $$el = $event.target,
                                        $$c = $$el.checked ? true : false
                                      if (Array.isArray($$a)) {
                                        var $$v = status.key,
                                          $$i = _vm._i($$a, $$v)
                                        if ($$el.checked) {
                                          $$i < 0 &&
                                            (_vm.validForRequestStatuses =
                                              $$a.concat([$$v]))
                                        } else {
                                          $$i > -1 &&
                                            (_vm.validForRequestStatuses = $$a
                                              .slice(0, $$i)
                                              .concat($$a.slice($$i + 1)))
                                        }
                                      } else {
                                        _vm.validForRequestStatuses = $$c
                                      }
                                    },
                                  },
                                }),
                                _vm._v(
                                  "\n                                    " +
                                    _vm._s(status.value) +
                                    "\n                                "
                                ),
                              ]),
                            ]
                          )
                        }),
                        _vm._v(" "),
                        _c("div", { staticClass: "column is-one-third" }, [
                          _c("label", { staticClass: "checkbox" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.allStatuses,
                                  expression: "allStatuses",
                                },
                              ],
                              attrs: { type: "checkbox", value: "all" },
                              domProps: {
                                checked: Array.isArray(_vm.allStatuses)
                                  ? _vm._i(_vm.allStatuses, "all") > -1
                                  : _vm.allStatuses,
                              },
                              on: {
                                change: [
                                  function ($event) {
                                    var $$a = _vm.allStatuses,
                                      $$el = $event.target,
                                      $$c = $$el.checked ? true : false
                                    if (Array.isArray($$a)) {
                                      var $$v = "all",
                                        $$i = _vm._i($$a, $$v)
                                      if ($$el.checked) {
                                        $$i < 0 &&
                                          (_vm.allStatuses = $$a.concat([$$v]))
                                      } else {
                                        $$i > -1 &&
                                          (_vm.allStatuses = $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1)))
                                      }
                                    } else {
                                      _vm.allStatuses = $$c
                                    }
                                  },
                                  _vm.toggleCheckboxes,
                                ],
                              },
                            }),
                            _vm._v(
                              "\n                                    alle\n                                "
                            ),
                          ]),
                        ]),
                      ],
                      2
                    ),
                  ]),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _c("tr", [
              _c("th", { staticClass: "is-top" }, [
                _c("label", { staticClass: "label" }, [_vm._v("Hinzufügen:")]),
              ]),
              _vm._v(" "),
              _c("td", [
                _c(
                  "div",
                  { staticClass: "control" },
                  [
                    _c(
                      "toggle-switch",
                      {
                        model: {
                          value: _vm.addToExistingOrders,
                          callback: function ($$v) {
                            _vm.addToExistingOrders = $$v
                          },
                          expression: "addToExistingOrders",
                        },
                      },
                      [
                        _c("option", { domProps: { value: false } }, [
                          _vm._v("nur in neue Reisen"),
                        ]),
                        _vm._v(" "),
                        _c("option", { domProps: { value: true } }, [
                          _vm._v("in bestehende Reisen"),
                        ]),
                      ]
                    ),
                  ],
                  1
                ),
              ]),
            ]),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }