<template>
    <accordion v-bind:isOpen="modalToRequest.type === 'ferry' && modalToRequest.id === request.id" :is-collapsible="false" :class-names="request.isSelectedRequest ? 'header-is-highlighted' : ''">
        <div slot="header">
            <request-score v-if="request.ferry" v-bind:rating="request.ferry.buspaketRating" />

            <div v-else>
                <div class="no-name">No Name</div>
            </div>

            <div>
                <div class="level-left">
                    <span class="level-item">
                        <a v-if="request.ferry" v-bind:href="'/ferries/' + request.ferry.id" target="_blank" title="Gehe zum Anbieter">{{request.ferry.name}}</a>
                        <template v-else>{{ request.info.title }}</template>
                    </span>
                    <i
                        class="material-icons is-pointer"
                        :class="{'has-text-active': editMode}"
                        v-on:click.prevent.stop="editMode = !editMode"
                    >edit</i>
                </div>

                <provider-info v-bind:provider="request.ferry" class="header-extra-info">
                    <div>
                        <i class="fa fa-book"></i>
                        <a v-if="request.agency" v-bind:href="'/agencies/' + request.agency.id" target="_blank" title="Zur Agentur gehen">{{request.agency.name}}</a>
                        <template v-else>direkt</template>
                    </div>

                    <div>
                        <i class="material-icons">check_circle</i>
                        <template v-if="request.type === 'day'">
                            {{ request.contingent.ticketsReserved }}
                            Tickets
                        </template>
                        <template v-else>
                            {{
                                request.contingent.info.doubleCabins.totalReserved +
                                request.contingent.info.singleCabins.totalReserved +
                                request.contingent.info.otherCabins.totalReserved
                            }}
                            Kabine
                        </template>
                    </div>

                    <div>
                        <i class="material-icons">help</i>
                        <template v-if="request.type === 'day'">
                            {{ request.contingent.ticketsAsked }}
                            Tickets
                        </template>
                        <template v-else>
                            {{
                                request.contingent.info.doubleCabins.totalAsked +
                                request.contingent.info.singleCabins.totalAsked +
                                request.contingent.info.otherCabins.totalAsked
                            }}
                            Kabine
                        </template>
                    </div>

                    <div><i class="material-icons">import_contacts</i> {{ getValueWithKey({ key: request.requestStatus.status, optionList: requestStatuses }) }}</div>

                    <div v-if="request.optionTodo && request.optionTodo.dueDate">
                        <div v-bind:class="['deadline-date', deadlineUrgency(request.optionTodo.dueDate)]"><i class="material-icons">notifications_none</i> {{ request.optionTodo.dueDate | fullYear }}</div>
                    </div>
                </provider-info>
            </div>

            <div>
                <request-settings
                    v-on:reloadPrices="reloadPrices"
                    :request="request"
                    :calculation-request="calculationRequest"
                    v-on:setCalculationRequest="setCalculationRequest"
                    v-on:setSelectRequest="setSelectRequest"
                    :selected-request="selectedRequest"
                />
            </div>
        </div>

        <div slot="content">
            <div class="buttons has-addons">
                <button
                class="button is-large"
                v-bind:class="[sectionSelection === 'overview' ? 'is-primary' : 'is-gray']"
                v-on:click="changeTab('overview')"
                ><i class="material-icons">help</i> Anfragedetails</button>

                <button
                class="button is-large"
                v-bind:class="[sectionSelection === 'price' ? 'is-primary' : 'is-gray']"
                v-on:click="changeTab('price')"
                ><i class="material-icons">euro_symbol</i> Preise</button>

                <button
                class="button is-large"
                v-bind:class="[sectionSelection === 'cost' ? 'is-primary' : 'is-gray']"
                v-on:click="changeTab('cost')"
                ><i class="material-icons">view_list</i> Gesamtkosten</button>

                <button
                class="button is-large"
                v-bind:class="[sectionSelection === 'email' ? 'is-primary' : 'is-gray']"
                v-on:click="changeTab('email')"
                ><i class="material-icons">email</i> Emails/Telefonate</button>
            </div>


            <loading v-if="isLoading" textInFront="Die Änderungen werden gespeichert..."></loading>

            <template v-else>
                <request
                    v-if="sectionSelection === 'overview'"
                    v-model="request"
                    v-bind:placeholderInfo="placeholderInfo"
                    v-bind:editMode="editMode"
                    :calculation-request="calculationRequest"
                    :selected-request="selectedRequest"
                ></request>

                <price
                    v-if="sectionSelection === 'price'"
                    v-model="request"

                    v-bind:calculationRequest="calculationRequest"
                ></price>

                <cost
                    v-if="sectionSelection === 'cost'"
                    v-bind:requestID="request.id"
                    v-bind:packageID="packageID"
                ></cost>

                <emails
                    v-if="sectionSelection === 'email'"
                    v-bind:preselected-order  = "order"
                    v-bind:preselected-request       = "request"
                    :preselected-contact="request.requestContact"
                    :show-request-select="false"
                    v-on:close             = "$emit('closeModal')"
                ></emails>
            </template>

            <!-- <button
                v-if="sectionSelection !== 'cost' && sectionSelection !== 'email'"
                class="button is-primary is-large"
                v-bind:class="{ 'is-loading': isLoading }"
                v-on:click="saveRequestChanges(sectionSelection)"
            ><i class="material-icons">check</i> Änderungen speichern</button> -->
        </div>
    </accordion>
</template>



<script>
import mixins     from './mixins';
import Cost       from '../costs/Ferry';
import Price      from '../prices/ferries';
import Request    from '../overviews/Ferry';
import Template from "@orders/subpages/TripDetails/General/Template";


export default {
    mixins: [mixins],


    data: function () {
        return {
            apiEndpoint: 'ferry_requests'
        }
    },


    methods: {
        getRequestBuild: function (request) {
            request.ferry = request.ferry && request.ferry.id ? '/api/ferries/' + request.ferry.id : null;

            return request;
        },
    },


    components: {
        Template,
        Cost,
        Price,
        Request
    }
}
</script>
