var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("tiny-mce", {
    attrs: {
      id: "tinyMCE",
      "api-key": "gat3zfl4v22k6b7byailjzzqxkh05fv22o9pjuh1sgf2p9u7",
      value: _vm.value,
      init: this.options,
    },
    on: {
      input: function ($event) {
        return _vm.updateValue($event)
      },
      keyup: function ($event) {
        return _vm.$emit("keyup", _vm.value)
      },
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }