<template>
    <modal
        :classes="(aiOpen ? 'new-email ai-open' : 'new-email')"
        v-bind:visible="isModalVisible"
        v-on:closeModal="closeModal"
    >
        <template slot="header">
            <div class="name" v-if="!action">NEUE EMAIL</div>
            <div class="name" v-else-if="action === 'reply'">Antworten</div>
            <div class="name" v-else-if="action === 'replyAll'">Allen Antworten</div>
            <div class="name" v-else-if="action === 'forward'">Weiterleiten</div>

            <div>
                <toggle-switch v-model="emailType">
                    <option value="client">Email an Kunde</option>
                    <option value="provider">Email an Leistungsträger</option>
                </toggle-switch>
            </div>
        </template>


        <template slot="content">
            <orders-select
                :params="{_groups: ['client_read']}"
            ></orders-select>


            <email-form
                v-bind:preselected-order     = "preselectedOrder"
                v-bind:preselected-request   = "preselectedRequest"
                v-bind:preselected-contact   = "isReply ? null : preselectedContact"
                v-bind:select-template    = "selectTemplate"
                v-bind:attach-document    = "attachDocument"
                v-on:closeModal = "refresh => $emit('closeModal', refresh)"
                ref="form"
            ></email-form>
        </template>
    </modal>
</template>



<script>
import Modal    from '@components/Modal';
import store        from '@components/emails/store';
import EmailForm    from './EmailForm';
import OrdersSelect from './OrdersSelect';
import ToggleSwitch from "@components/form/ToggleSwitch";
import {settings} from "@clientSpecific/utilities/settings";
export default {
    store: store,
    props: {
        isModalVisible:     { type: Boolean, required: true }, //controls if modal is visible
        preselectedOrder:   { default: null }, //preselects Orders
        preselectedRequest: { default: null }, //preSelects requests
        preselectedContact: { default: null }, //preSelects Contact
        selectTemplate:     { default: null}, //preSelects a template
        attachDocument:     { type: Object, default: null} //attaches a document
    },
    data: function() {
        return {
            settings: settings
        }
    },
    computed: {
        aiOpen () {
            return this.$layout.rightSideBar;
        },

        emailType: {
            get: function() {
                return this.$store.state.emailType;
            },
            set: function(value) {
                this.$store.state.emailType = value;
                if(value === 'provider') {
                    if(this.settings.emails.isFormal['provider']) {
                        this.$store.state.isFormal = settings.emails.selectedIsFormal.provider;
                    }
                    this.$store.dispatch('getRequests');
                } else {
                    this.$store.state.isFormal = settings.emails.selectedIsFormal.client;
                }
                this.$store.dispatch('resetToContacts', {contactToSelect: null, selectContact: true});
                this.$store.dispatch('resetTemplates');
            }
        },

        action: function () { return this.$store.state.action; },

        isReply: function () {
            return this.$store.state.action === 'reply' ||
                this.$store.state.action === 'replyAll' ||
                this.$store.state.action === 'forward';
        }
    },

    methods: {
        closeModal () {
            this.$store.state.isOpen = false;
            this.$emit('closeModal');
        }
    },

    components: {
        ToggleSwitch,
        EmailForm,
        Modal,
        OrdersSelect
    }
}
</script>
