<template>
    <div
       class="simple-menu simple-menu--export"
       v-click-outside="() => { visible = false }"
       v-bind:class="[visible ? 'is-open' : '']"
       v-on:click.stop=""
    >
        <i
            class="fa fa-file-excel-o"
            v-on:click.prevent.stop="visible = !visible"
        />

        <transition name="scale">
            <div
                class="simple-menu-content"
                v-if="visible"
            >
                <multiselect
                    style="min-width: 100px;"

                    v-bind:options="exportLanguages"
                    v-model="language"
                    placeholder="Sprache auswählen"
                    label="label"
                    track-by="value"
                    :allow-empty="false"
                    class="is-shorter"
                />
                <button
                    class="button ml-2 is-success"
                    v-on:click="handleSelect"
                >
                    exportieren
                </button>
            </div>
        </transition>
    </div>
</template>

<script>
import vClickOutside from 'v-click-outside'
import { Multiselect } from '@components/form';
import { exportLanguages } from '@utilities/variables';


export default {
    data() {
        return {
            language: exportLanguages[0],
            exportLanguages,
            visible: false
        }
    },

    components: {
        Multiselect
    },

    methods: {
        handleSelect() {
            this.$emit('export', this.language.value)
            this.visible = false;
        }
    },

    directives: {
        clickOutside: vClickOutside.directive
    }
}
</script>
