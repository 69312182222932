var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "tab-addition is-small" }, [
    _c("div", { staticClass: "tab-addition__header" }, [
      _c("header", [
        _c(
          "button",
          {
            class: { "is-active": _vm.activeTab === "first" },
            on: {
              click: function ($event) {
                $event.preventDefault()
                _vm.activeTab = "first"
              },
            },
          },
          [_vm._v("Soll-Kalkulation")]
        ),
        _vm._v(" "),
        false
          ? _c(
              "button",
              {
                class: { "is-active": _vm.activeTab === "second" },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    _vm.activeTab = "second"
                  },
                },
              },
              [_vm._v("Ist-Kalkulation")]
            )
          : _vm._e(),
      ]),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "main is-relative" },
      [
        _vm.isLoading
          ? _c("loading")
          : _vm.isSaving
          ? _c(
              "div",
              { staticClass: "loading-container" },
              [
                _c("loading", {
                  attrs: { textInFront: "Die Eingaben werden gespeichert ..." },
                }),
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        !_vm.isLoading
          ? _c(
              "div",
              [
                _c(
                  "div",
                  {
                    staticClass: "is-flex",
                    staticStyle: { "place-content": "space-between" },
                  },
                  [
                    _c(
                      "toggle-switch",
                      {
                        model: {
                          value: _vm.startView,
                          callback: function ($$v) {
                            _vm.startView = $$v
                          },
                          expression: "startView",
                        },
                      },
                      [
                        _c("option", { attrs: { value: "table" } }, [
                          _vm._v("Tabellenansicht"),
                        ]),
                        _vm._v(" "),
                        _c("option", { attrs: { value: "day" } }, [
                          _vm._v("Tagesansicht"),
                        ]),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass: "button is-primary",
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.saveData.apply(null, arguments)
                          },
                        },
                      },
                      [_vm._v("Kalkulation speichern")]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("loaded-search", { on: { submit: _vm.handleSearch } }),
                _vm._v(" "),
                _c("included-prices"),
                _vm._v(" "),
                _c("selling-prices"),
                _vm._v(" "),
                _c("div", { staticClass: "level-right" }, [
                  _c(
                    "button",
                    {
                      staticClass: "level-item button is-primary",
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.saveData.apply(null, arguments)
                        },
                      },
                    },
                    [_vm._v("Kalkulation speichern")]
                  ),
                ]),
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.modal && _vm.modal.component !== "route-detail"
          ? _c(
              "div",
              { staticClass: "day" },
              [
                _c(`modal-${_vm.modal.component}-placeholder`, {
                  tag: "component",
                  attrs: {
                    isModalVisible: _vm.modal !== null,
                    "passed-options": _vm.passedOptions,
                    initialValue: _vm.modal.placeholder,
                  },
                  on: { closeModal: _vm.handleCloseModal },
                }),
              ],
              1
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.modalCRUD
          ? _c(
              "div",
              [
                _c(`modal-${_vm.modalCRUD.component}-CRUD`, {
                  tag: "component",
                  attrs: {
                    initialValue: _vm.modalCRUD,
                    providerType: "calculation",
                  },
                  on: {
                    closeModal: _vm.handleCloseModalCRUD,
                    submit: _vm.handleCloseModalCRUD,
                  },
                }),
              ],
              1
            )
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }