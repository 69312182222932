<template>
    <div>
        <loading v-if="isLoading">

        </loading>
        <columns property="menus" v-on:add="openForm" v-bind:load-options="false" v-else>
            <column-item
                v-bind:update="{updatedBy: item.updatedBy, updatedAt: item.updatedAt}"
                :api="'/api/menus/' + item.id"
                slot-scope="{item}"
                v-on:edit="openForm(item)"
                v-on:delete="deleteMenu('menus', item)"
            >
                <div>
                    <div class="content mb-0">
                        <div class="level">
                            <strong class="level-left">{{item.title}}</strong>
                            <div class="level-right">
                                <span class="has-text-right" v-html="price(item)"></span>
                            </div>
                        </div>
                    </div>
                    <div class="content mb-0">
                        <div class="level">
                            <div class="level-left is-small-text">
                                {{dateLabel(item)}}
                            </div>


                        </div>

                    </div>
                    <div class="nl2br content mt-2" v-html="item.content"></div>
                </div>
            </column-item>

            <menu-form ref="form" slot="form" @success="handleTranslations" :provider="item" :options-array="options" v-on:updateMenus="updateMenus" />
        </columns>
        <delete-other-service
            v-if="deleteOtherService"
            v-bind:item="deleteOtherService"
            :filter-params="item ? {'provider.id': item.id, '_groups': ['provider_read', 'agency_read']} : {}"
            v-on:close="deleteOtherService = null;"
            @deleted="updateMenus"
        />
    </div>

</template>

<script>
import Mixin from '../tab';
import MenuForm from '@components/form/Menu.vue';
import {getTranslationOptions} from "@utilities/functions";
import {getAxios} from "@api";
import toNumber from "lodash/toNumber";
import DeleteOtherService from "@components/CRUD/Item/Tabs/ProviderPrices/Forms/DeleteOtherService.vue";
import Loading from "@components/Loading.vue";

export default {
    mixins: [ Mixin ],

    components: {Loading, DeleteOtherService, MenuForm },

    data: function () {
        return {
            deleteOtherService: null,
            deleteMenuObject: null,
            isLoading: false,
        }
    },

    activated() {
        this.updateMenus();
    },

    methods: {
        deleteMenu (key, item) {
            if(item.otherService && item.otherService.id) {
                this.deleteOtherService = JSON.parse(JSON.stringify(item.otherService));
                this.deleteMenuObject = item;
            } else {
                this.deleteItem(key, item);
            }
        },
        price: function(item) {
            let array = [];
            if(item.requestTypes.includes('other') && item.price && toNumber(item.price.amount)) {
                array.push(item.price.formatted);
            }
            if(item.requestTypes.includes('hotel') && item.supplementPrice && toNumber(item.supplementPrice.amount) ) {
                array.push('HP +' + item.supplementPrice.formatted);
            }
            return array.join('<br>');
        },
        dateLabel: function(item) {
            if (item.startAt && item.endAt) {
                return item.startAt.substring(0, 6) + ' - ' + item.endAt.substring(0, 10)
            } else if (item.startAt && !item.endAt) {
                return item.startAt.substring(0, 10);
            } else if (item.endAt && !item.startAt) {
                return item.endAt.substring(0, 10);
            }
        },
        handleTranslations(data) {
            let translationsOptions = getTranslationOptions();
            const translation = translationsOptions.find(item => item.isDefault);
            if(translation && data && data.data && data.data.translations && data.data.translations[translation.code]) {
                data.data.content = data.data.translations[translation.code].content;
                this.$store.commit('updateDataElement', { key: 'menus', data: data.data });
            }
        },
        updateMenus: function() {
            this.isLoading = true;
            getAxios('menus', {
                'provider.id' : this.item.id,
                '_groups': ['translations', 'menu:provider_other_service', 'other_service_read', 'other_service_type_read']
            }).then(response => {
                this.isLoading = false;
                this.item.menus = response.data
            })
        },
    }
}
</script>
