<template>
    <div>
        <loading v-if="isLoading || !dataObjects[formKey]" ></loading>
        <div class="columns" v-for="row in dataObjects[formKey].rows" v-else>
            <div class="column pt-2 pb-2 is-align-self-center" :class="col.class || []" v-for="col in row">
                <label :for="col.props.for" class="label" v-if="col.component === 'label'">{{col.value}}{{col.required ? ' *' : ''}}</label>
                <div style="border-top: 1px #ccc solid" v-if="col.component === 'hr'"/>
                <template v-if="(col.component === 'input' || componentExists(col.component))">
                    <div class="field">
                        <div class="control is-full-width">
                            <error-wrapper v-bind:message="errors[col.value]">
                                <input type="text" :id="col.value" class="input" :value="dataObjects[formKey].form[col.value]" @input="input => setValue(col, input.target.value)" v-if="col.component === 'input'">

                                <component v-else :is="col.component" :value="getValue(col, dataObjects[formKey].form[col.value])" @input="input => setValue(col, input)" v-bind="col.props"></component>
                            </error-wrapper>
                        </div>
                    </div>
                </template>
            </div>
        </div>
    </div>
</template>
<script>


import store from './store';
import FormElements from "@components/form";
import Loading from "@components/Loading.vue";
import Vue from "vue";


export default {
    store: store,

    props: {
        formKey: {
            type: String,
            required: true,
        }
    },

    data: function () {
        return {

        }
    },

    computed: {
        isLoading: {
            get () {
                return this.$store.state.dataObjects[this.formKey].isLoading;
            },
        },

        dataObjects: {
            get () {
                return this.$store.state.dataObjects;
            },
            set (value) {
                this.$store.state.dataObjects = value;
            }
        },

        errors: {
            get () {
                return this.$store.state.dataObjects[this.formKey].errors || {};
            },
        },
    },

    methods: {
        getValue(col, value) {
            if(col.component === 'Multiselect' && col.props && col.props['track-by'] && col.props.options && !col.props.keepObject) {
                return col.props.options.find(item => item[col.props['track-by']] === value);
            }
            return value;
        },

        setValue(col, value) {
            if(col.component === 'Multiselect' && col.props && col.props['track-by'] && col.props.options && !col.props.keepObject) {
                value = value[col.props['track-by']];
            }
            Vue.set(this.dataObjects, this.formKey, {
                ...this.dataObjects[this.formKey],
                form: {
                    ...this.dataObjects[this.formKey].form,
                    [col.value]: value,
                }

            });

            this.dataObjects = {
                ...this.dataObjects,
                [this.formKey]: {
                    ...this.dataObjects[this.formKey],
                    form: {
                        ...this.dataObjects[this.formKey].form,
                        [col.value]: value,
                    }

                }
            }
        },

        componentExists: function (component) {
            return !!this.$options.components[component];
        },
    },

    components: {
        Loading,
        ...FormElements,
    }
}

</script>
