import moment   from 'moment';
import { fullYear } from '@utilities/functions';


moment.locale('de', {
    months : 'Januar_Februar_März_April_Mai_Juni_Juli_August_September_Oktober_November_Dezember'.split('_'),
    weekdays : 'Sonntag_Montag_Dienstag_Mittwoch_Donnerstag_Freitag_Samstag'.split('_'),
    weekdaysShort : 'Son_Mon_Die_Mit_Don_Fre_San'.split('_'),
    weekdaysMin : 'So_Mo_Di_Mi_Do_Fr_Sa'.split('_'),
});

export const months = ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'];

export const tinyMceOptions = {
    selector: '#tinyMCE',
    plugins:
        'advlist ' +
        'autolink ' +
        'autoresize ' +
        'code ' +
        'fullscreen ' +
        'help ' +
        'hr ' +
        //'image imagetools ' +
        'link ' +
        'lists ' +
        'noneditable ' +
        'paste ' +
        'preview ' +
        'print ' +
        'searchreplace ' +
        //'spellchecker ' +
        'table ' +
        'template ' +
        'textpattern ' +
        'wordcount',
    toolbar:
        'fullscreen | ' +
        'fontsizeselect | ' +
        'bold italic underline | ' +
        'forecolor backcolor | ' +
        'alignleft aligncenter alignright alignjustify | ' +
        'indent outdent | ' +
        'numlist bullist | ' +
        'table template | ' +
        'removeformat undo redo',
    font_formats:
        'Open Sans=Open Sans, Helvetica Neue, sans-serif;' +
        'Andale Mono=andale mono,times; ' +
        'Arial=arial,helvetica,sans-serif; ' +
        'Arial Black=arial black,avant garde; ' +
        'Book Antiqua=book antiqua,palatino; ' +
        'Comic Sans MS=comic sans ms,sans-serif; ' +
        'Courier New=courier new,courier; ' +
        'Georgia=georgia,palatino; ' +
        'Helvetica=helvetica; ' +
        'Impact=impact,chicago; ' +
        'Symbol=symbol; ' +
        'Tahoma=tahoma,arial,helvetica,sans-serif; ' +
        'Terminal=terminal,monaco; ' +
        'Times New Roman=times new roman,times; ' +
        'Trebuchet MS=trebuchet ms,geneva; ' +
        'Verdana=verdana,geneva; ' +
        'Webdings=webdings; ' +
        'Wingdings=wingdings,zapf dingbats',
    fontsize_formats:
        '1pt 8pt 10pt 11pt 12pt 13pt 14pt 15pt 16pt 18pt 20pt ' +
        '22pt 24pt 26pt 28pt 30pt 34pt 38pt 42pt 48pt 54pt 60pt',
    color_map: [
        '6A6C6F', 'Gray',
        'FFFFFF', 'White',
        'E78E7C', 'Red',
        'D6B874', 'Yellow',
        '87A873', 'Green',
        '0066cc', 'Blue',
        '60A4AB', 'Turquoise',
        '9588AF', 'Lila',
    ],
    formats: {
        catalog_format: { inline: 'span', styles: { color: '#0066cc' } }
    },
    style_formats: [
        { title: 'Katalogtext', format: 'catalog_format' },
    ],
    templates: [
        // eslint-disable-next-line max-len
        { title: 'New Table', description: 'creates a new table', content: '<div class="mceTmpl"><table width="98%%"  border="0" cellspacing="0" cellpadding="0"><tr><th scope="col"> </th><th scope="col"> </th></tr><tr><td> </td><td> </td></tr></table></div>' },
        { title: 'Starting my story', description: 'A cure for writers block', content: 'Once upon a time...' },
        // eslint-disable-next-line max-len
        { title: 'New list with dates', description: 'New List with dates', content: '<div class="mceTmpl"><span class="cdate">cdate</span><br /><span class="mdate">mdate</span><h2>My List</h2><ul><li></li><li></li></ul></div>' }
    ],
    min_height: 750,
    forced_root_block : false,
    indent_use_margin: true,
    style_formats_merge: true,
    visual: false,
    object_resizing : 'img',
    skin_url: '/assets/tinyMce/skins/ui/travelize',
    skin: 'travelize',
    content_css: '/assets/tinyMce/skins/content/travelize/content.min.css',
    language: 'de',
    spellchecker_language: 'de,fr_FR,en',
    browser_spellcheck: true,
    contextmenu: false,
    valid_children : '+body[style]',
    //spellchecker_rpc_url: '/assets/tinyMce/plugins/spellchecker/spellchecker.php',
    table_toolbar: false,
    // eslint-disable-next-line max-len
    //images_upload_url: '/api/images/uploadtest?bearer=eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJpYXQiOjE1ODQ4MzIzMTYsImV4cCI6MTU4NTQzNzExNiwicm9sZXMiOlsiUk9MRV9BRE1JTiIsIlJPTEVfVVNFUiJdLCJ1c2VybmFtZSI6IkxhdXJlbnoifQ.e92sjqdAzu-_E0DFOLZs3CLh2ioYS2CdD3mWSVrltcomSfGoMkbPMN9Y_rf5igHXdD5dP2oOAcKdFHaqdaL0ZhrVASn2p6G44O7FYS7E43ZANk9Va2Ze8zq1vFsHUbSc5YbGsmljz5WLk1P9QV9mklANMSDKbNUekEjMJGcRLp5ponxr-Yx9UddzdIe3i2RmmOqKENqjNl8Hhwy8JtQGCsggqFQZK4-bLTW5H5vY-AQqDte_wkQtxAkhD7q5DSndGlA5m1fD0pK5Xzufi292Ne9EE6MS9zwqC0c9IVUlo42IsZILLvqX-dKJH-7BP7v_X2ir9G9b8XnUTzRjFbVdZPtAk109_Bjbok4djEaN1O-qZ_TAxh9aVbZyQ8JAICfMJcn1MLytNPmxZznVLPtX-z6BRK9nxct8wlm743c5ztD9LC3K74F7-GwSyUobdEQQVxKJDFiaa-vawivGc0QtnRZVbyBombi9NBO1R8VCbOs4wTCDHFLC3Qo1zndXQRcI-K8cTL8q0lkngMNQtAnrhPGu3Y91MWo5eT1XCkJYppu1i6Ij0r9pAUXbAgPpeCLeYsUU1kmWPottLLxTTJuOJ3bRnB848Y85iDJf7O2FpvfpCwUkdUvYWyxtSOZ4uffuI4P9kCikFbdUMkkUQnBz3KsgrWKShU1qaYKEqlkKlgw'
    // noteditable part needs to have class='mceNonEditable'
    //toolbar_groups -> to get more space on toolbar
    // eslint-disable-next-line max-len
    extended_valid_elements: 'span[style],#p[class:MsoNormal|style],#td[class:MsoNormal|nowrap|valign|colspan|width|style|height|align],#li[class:MsoNormal],#div[class:MsoNormal|style]',

};

export const defaultAddConditionText = function (state, condition) {
    return '';
};

export const defaultConditions = function (state) {
    const offerdate = new Date();
    offerdate.setDate(offerdate.getDate() + 14);

    const startAt = moment(state.order.startAt, 'DD.MM.YYYY');

    let conditions = [];
    conditions.de_DE = [
        {
            category: 'Angebotsoption',
            subcategory: 'zum Paketpreis:',
            days: null,
            conditionText: '',
            type: 'date',
            key: `${+new Date()}-offer-1`,
            deadlineDate: offerdate.toLocaleDateString('de-DE'),
            deadlineText: ''
        },
        {
            category: 'Zahlungen',
            subcategory: 'von Paketleistungen:',
            days: 60,
            conditionText: '15% Anzahlung vom Paketpreis',
            type: 'days',
            key: `${+new Date}-payment-1`,
            deadlineDate: startAt.clone().subtract(60, 'day').format('DD.MM.YYYY'),
            deadlineText: ''
        },
        {
            category: 'Zahlungen',
            subcategory: 'von Paketleistungen:',
            days: 7,
            conditionText: 'Zahlung des Gesamtpreises',
            type: 'days',
            key: `${+new Date()}-payment-2`,
            deadlineDate: startAt.clone().subtract(7, 'day').format('DD.MM.YYYY'),
            deadlineText: ''
        },
        {
            category: 'Stornokosten',
            subcategory: 'bei Stornierung von Paketleistungen:',
            days: 60,
            conditionText: 'kostenfrei',
            type: 'days',
            key: `${+new Date()}-cancelation-1`,
            deadlineDate: startAt.clone().subtract(60, 'day').format('DD.MM.YYYY'),
            deadlineText: ''
        },
        {
            category: 'Stornokosten',
            subcategory: 'bei Stornierung von Paketleistungen:',
            days: 28,
            conditionText: '30% des Paketpreises',
            type: 'days',
            key: `${+new Date}-cancelation-2`,
            deadlineDate: startAt.clone().subtract(28, 'day').format('DD.MM.YYYY'),
            deadlineText: ''
        },
        {
            category: 'Stornokosten',
            subcategory: 'bei Stornierung von Paketleistungen:',
            days: 14,
            conditionText: '50% des Paketpreises',
            type: 'days',
            key: `${+new Date}-cancelation-3`,
            deadlineDate: startAt.clone().subtract(14, 'day').format('DD.MM.YYYY'),
            deadlineText: ''
        },
        {
            category: 'Stornokosten',
            subcategory: 'bei Stornierung von Paketleistungen:',
            days: 7,
            conditionText: '75% des Paketpreises',
            type: 'days',
            key: `${+new Date}-cancelation-4`,
            deadlineDate: startAt.clone().subtract(7, 'day').format('DD.MM.YYYY'),
            deadlineText: ''
        },
        {
            category: 'Stornokosten',
            subcategory: 'bei Stornierung von Paketleistungen:',
            days: 6,
            conditionText: '95% des Paketpreises',
            type: 'days',
            key: `${+new Date()}-cancelation-5`,
            deadlineDate: startAt.clone().subtract(6, 'day').format('DD.MM.YYYY'),
            deadlineText: ''
        },
    ];

    if (!state.order.orderLocale || !conditions[state.order.orderLocale]) {
        state.order.orderLocale = 'de_DE';
    }

    return conditions[state.order.orderLocale];
};

export const defaultFreeplaces = function () {
    return [
        {
            freePlaces: 1,
            numberOfPersons: 21,
            type: 'double',
            key: 1
        },
        {
            freePlaces: 1,
            numberOfPersons: 42,
            type: 'double',
            key: 2
        }
    ];
};

export const defaultOrderContingent = function () {
    return {
        hotelContingent: {
            single: '5',
            double: '20'
        },
        ferryContingent: {
            singleCabins: '5',
            doubleCabins: '20'
        },
    };
};

export const defaultHotelPlaceholderValues = {
    hotelType: { category: '', stars: '3' },
    board:     'half_extra_breakfast',
    defaultHotelTimes: [
        { type: 'dinner', time: '20:00:00', firstNightOnly: false},
        { type: 'checkIn', time: '18:00:00', firstNightOnly: true },
        { type: 'checkIn', time: '00:00:00' , firstNightOnly: false },
        { type: 'breakfast', time: '07:30:00', firstNightOnly: false}
    ],
};

export const defaultDayValues = {
    isAutomaticName:     false,
    //addDayWithoutForm: true,
};

export const defaultEmailTags = [
        { id: 1, name: 'Kunde Anfrage', class: '', icon: 'person_outline' },
        { id: 2, name: 'Kunde Angebot', class: '', icon: 'person_outline' },
        { id: 3, name: 'Kunde Angebotserinnerung', class: '', icon: 'person_outline' },
        { id: 4, name: 'Kunde Absage', class: '', icon: 'person_outline' },
        { id: 5, name: 'Kunde Reservierung', class: '', icon: 'person_outline' },
        { id: 6, name: 'Kunde Auftragsbestätigung', class: '', icon: 'person_outline' },
        { id: 7, name: 'Kunde Buchungsstand', class: '', icon: 'person_outline' },
        { id: 8, name: 'Kunde Stornierung', class: '', icon: 'person_outline' },
        { id: 9, name: 'Kunde Allgemein', class: '', icon: 'person_outline' },
        { id: 10, name: 'Hotel Anfrage', class: 'is-blue-dark', icon: 'local_hotel' },
        { id: 11, name: 'Hotel Angebot', class: 'is-blue-dark', icon: 'local_hotel' },
        { id: 12, name: 'Hotel refus', class: 'is-blue-dark', icon: 'local_hotel' },
        { id: 13, name: 'Hotel Option', class: 'is-blue-dark', icon: 'local_hotel' },
        { id: 14, name: 'Hotel Absage', class: 'is-blue-dark', icon: 'local_hotel' },
        { id: 15, name: 'Hotel Reservierung', class: 'is-blue-dark', icon: 'local_hotel' },
        { id: 16, name: 'Hotel Rückbestätigung', class: 'is-blue-dark', icon: 'local_hotel' },
        { id: 17, name: 'Hotel Buchungsstand', class: 'is-blue-dark', icon: 'local_hotel' },
        { id: 18, name: 'Hotel Stornierung', class: 'is-blue-dark', icon: 'local_hotel' },
        { id: 19, name: 'Hotel Allgemein', class: 'is-blue-dark', icon: 'local_hotel' },
        { id: 20, name: 'Zusatzleistung Anfrage', class: '', icon: 'more_horiz' },
        { id: 21, name: 'Zusatzleistung Angebot', class: '', icon: 'more_horiz' },
        { id: 22, name: 'Zusatzleistung refus', class: '', icon: 'more_horiz' },
        { id: 23, name: 'Zusatzleistung Option', class: '', icon: 'more_horiz' },
        { id: 24, name: 'Zusatzleistung Absage', class: '', icon: 'more_horiz' },
        { id: 25, name: 'Zusatzleistung Reservierung', class: '', icon: 'more_horiz' },
        { id: 26, name: 'Zusatzleistung Rückbestätigung', class: '', icon: 'more_horiz' },
        { id: 27, name: 'Zusatzleistung Buchungsstand', class: '', icon: 'more_horiz' },
        { id: 28, name: 'Zusatzleistung Stornierung', class: '', icon: 'more_horiz' },
        { id: 29, name: 'Zusatzleistung Allgemein', class: '', icon: 'more_horiz' },
    ];

export const defaultRoomListTypes = [
    {
        value: 'hotel',
        name: 'Hotels (Excel mit Passdaten)'
    },
    {
        value: 'hotel_short_excel',
        name: 'Hotels (Excel kurz ohne Passdaten)'
    },
    {
        value: 'hotel_short',
        name: 'Hotels (Word)'
    },
    {
        value: 'hotel_pdf',
        name: 'Hotels (PDF)'
    },
    {
        value: 'moby',
        name: 'Moby Lines'
    },
    {
        value: 'corsica',
        name: 'Corsica Ferries'
    },
    {
        value: 'corsica_motorbike',
        name: 'Corsica Ferries mit Motorrad'
    },
    {
        value: 'gnv',
        name: 'Grandi Navi Veloci'
    },
    {
        value: 'gmd',
        name: 'Grimaldi'
    }
];

export const defaultRoomListPage = {
    type: 'hotel_pdf'
};

export const clientInvoiceIntroEndText = function (form, oldInvoiceId, currency, organisation) {
    const { amount, type } = form;
    const dueDate = fullYear(form.dueDate);

    return `
                <p>
                    <strong>Zur Information:</strong>
                    <br />
                    <span>${(organisation.id === 2) ? 'Steuernummer: 37/588/50578 | USt-idNr: DE332316045' : 'Steuernummer: 31/259/00257 | USt-idNr: DE259174509'}</span>
                    ${ type !== 'cancelation' ? `<br /><span>Es handelt sich um Reiseleistungen i.S.d. § 25 UStG-Deutschland; ein Vorsteuerabzug ist ausgeschlossen.</span>` : ''}
                    <br />
                    <br />
                        ${[0, null, '0.00', '0,00'].includes(amount) ? '<span>Die Rechnungssumme wurde bereits beglichen, sodass keine weitere Überweisung nötig ist.</span>' : ''}
                        ${(amount > 0 && type === 'prepayment') ? `<span>Wir bitten um Überweisung des Anzahlungsbetrags auf unser unten genanntes Konto bis zum ${dueDate} und danken für den erteilten Auftrag.</span>` : ''}
                        ${(amount > 0 && (type === 'normal' || type === 'final')) ? `<span>Wir bitten um Überweisung des Rechnungsbetrags auf unser unten genanntes Konto bis zum ${dueDate} und danken für den erteilten Auftrag.</span>`: ''}
                        ${(amount > 0 && type === 'cancelation') ? `<span>Wir bedauern, dass Sie ihre Reise stornieren und wir Ihnen Stornokosten weiterberechnen mussten. Bitte überweisen Sie die Stornokosten auf unser unten genanntes Konto bis zum ${dueDate}.</span>`: ''}
                        ${amount < 0 ? `<span>Wir werden die Gutschrift auf das uns vorliegende Konto überweisen.</span>` : ''}
                    <br />
                    <br />
                    Es grüßt freundlich
                </p>
            `;
};

export const clientInvoiceIntroText = function (form, oldInvoiceId, currency) {
    const { type } = form;

    if(type === 'cancelation'){
        return `<p>nachfolgend übersende ich die Rechnung für die Stornierung folgender Reise:</p>`;
    } else {
        return `<p>nachfolgend übersende ich die Rechnung für folgende Fahrt:</p>`;
    }
};

export const defaultTripDetailGenerateSettings = {
    _route: true,
    _generalInfos: true,
    _questionaire: true,
    print: true,
};

export const defaultItemPrice = {
    name: 'Zuschlag',
    quantityType: 'per_person',
    limitType: 'validIfLimit',
    quantity: null,
    billingType: 'additional'
};

export const defaultConceptClientId = 4715;

export const otherTypesExcluded = [
    'financial',
];
export const defaultTags = {
    hotels: [
        'Hotel im Stadtzentrum',
        'Hotel am Stadtrand',
        'Hotel außerhalb',
        'Hotel im Gewerbegebiet',
        'Strandhotel',
        'Strandnähe',
        'Klimaanlage',
        'Minibar',
        'Safe',
        'Deutsches TV',
        'Schwimmbad (innen)',
        'Schwimmbad (außen)',
        'Spa',
        'Fahrstuhl',
        'Restaurant',
        'Bar'
    ]
};

export const additionalProviderProperties = [
];

export const driverRoomType = null;
export const driverCabinType = null;

export const defaultUserDashboardFilters = function () {
    return {
        isRequests: true,
        type: 'orders',

        // Filters
        _search: '',
        tripName: '',
        users: [],

        hotel: {
            destination: null
        },
        ferry: {
            startPort: null,
            endPort: null,
        },
        train: {
            startTrainStation: null,
            endTrainStation: null,
        },
        airline: {
            startAirport: null,
            endAirport: null
        },
        other: {
            otherServiceTypeCategory: null,
        },

        // Orders
        destinations: [],
        country: null,
        organisation: null,
        durationDays: null,

        // Request
        agency: null,
        orderStatus: [],
        startAt: null,
        endAt: null,
        requestStatus: [],
        traitTags: [],

        // Placeholder
        notRequestStatus: null,
        clientOfferStatus: null,
        location: {
            area: null,
            destination: null
        },
    }
}
