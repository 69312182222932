import {getStorageFilter} from "@utilities/functions";
import {omit} from "lodash";


export default {

    data: function() {
        return {
        }
    },




    methods : {
        openPriceForm: function (data = {}) {
            this.$refs.form.open({
                ...!data.timePeriods && {timePeriods: [{
                        days: [0,1,2,3,4,5,6],
                        startAt: null,
                        endAt: null,
                        key: 1
                    }]},
                ...omit(data, ['updatedAt', 'updatedBy']),
            });
        },

        openEditTable () {
            if(this.$refs.editTable) {
                this.$refs.editTable.open();
            }
        },

        handleSubmit(){
            this.$refs.mainObject.getPrices()
        }
    },
};
