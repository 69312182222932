var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      class: {
        "is-menu-opened": _vm.isMenuOpened,
        locked: _vm.isLocked,
        "is-right-aside-hidden": _vm.isRightAsideHidden,
        "is-left-aside-hidden": _vm.isLeftAsideHidden,
      },
      attrs: { id: "app" },
    },
    [
      _c("app-header", { ref: "header" }),
      _vm._v(" "),
      _c("app-nav"),
      _vm._v(" "),
      _c(
        "keep-alive",
        [
          _vm.isFaqOpen ? _c("faqs") : _vm._e(),
          _vm._v(" "),
          _vm.isUserSettingsOpen ? _c("user-settings") : _vm._e(),
          _vm._v(" "),
          _vm.rightSideBar
            ? _c("side-bar", {
                attrs: {
                  sidebarData: _vm.sidebarData,
                  position: "is-right",
                  element: _vm.rightSideBar,
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm.leftSideBar
            ? _c("side-bar", {
                attrs: {
                  sidebarData: _vm.sidebarData,
                  position: "is-left",
                  element: _vm.rightSideBar,
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "wrapper" }, [_vm._t("default")], 2),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }