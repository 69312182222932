export const settings = {
    travelizeLanguage: 'de_DE',
    modules: {
        websiteExport: false,
        webflow: true,
        ai: true,
    },
    general: {
      resetFilter : false,
    },
    pagination: {
        order_concepts: {
            perPage: 80
        }
    },
    crud: {
        menus: {
            tipTap: {
                maxLines: 0,
                maxCharacters: 0,
            },
            defaultOtherServiceTypeId: 41
        },
        clients: {
            list: {
                general: {
                    //[
                    //                     { key: 'id', label: 'Nr', class: 'is-small-cell' },
                    //                     { key: 'companyName', label: 'Kundenname', class: 'is-one-fifth' },
                    //                     { key: 'zip', label: 'PLZ', sortable: 'addresses.zip', filter: 'addresses.zip'},
                    //                     { key: 'addresses', label: 'Ort', filter: 'addresses', sortable: 'addresses.city'},
                    //                     { key: 'addressCountry', label: 'Land', filter: 'addresses.country', sortable: 'addresses.country'},
                    //                     { key: 'importance', sortable: 'importance', filter: 'importance'},
                    //                     'url',
                    //                     { key: 'traitTags', sortable: false, label: 'Tags', class: 'is-one-tenth', filter: 'traitTags'},
                    //                     { key: 'mainUsers', filter: 'mainUsers.id'},
                    //                     { key: 'receivesCatalog', filter:  'receivesCatalog'},
                    //                     { key: 'dataCheckedAt',},
                    //                 ]
                    columns: [
                        { key: 'id', label: 'Nr', class: 'is-small-cell' },
                        { key: 'companyName', label: 'Kundenname', class: 'is-one-fifth' },
                        { key: 'zip', label: 'PLZ', sortable: 'addresses.zip', filter: 'addresses.zip'},
                        { key: 'addresses', label: 'Ort', filter: 'addresses', sortable: 'addresses.city'},
                        { key: 'addressCountry', label: 'Land', filter: 'addresses.country', sortable: 'addresses.country'},
                        { key: 'importance', sortable: 'importance', filter: 'importance'},
                        'url',
                        { key: 'traitTags', sortable: false, label: 'Tags', class: 'is-one-tenth', filter: 'traitTags'},
                        { key: 'mainUsers', filter: 'mainUsers.id'},
                        { key: 'receivesCatalog', filter:  'receivesCatalog'},
                    ]
                },
                sales: {
                    columns: [
                        { key: 'id', label: 'Nr', class: 'is-small-cell' },
                        { key: 'companyName', label: 'Kundenname', class: 'is-one-fifth' },
                        { key: 'importance[-1]', label: 'Wichtigkeit [t]', sortable: false},
                        { key: 'importance[0]', label: 'Wichtigkeit [t]', sortable: false},
                        { key: 'importance[1]', label: 'Wichtigkeit [t+1]', sortable: false},
                        { key: 'revenues[-1]', label: 'Umsatz [t-1]', sortable: false},
                        { key: 'revenues[0]', label: 'Umsatz [t]', sortable: false},
                        { key: 'revenues[1]', label: 'Umsatz [t+1]', sortable: false},
                        { key: 'bookingRatio[-1]', label: 'Conversion Rate [t-1]', sortable: false},
                        { key: 'bookingRatio[0]', label: 'Conversion Rate [t]', sortable: false},
                        { key: 'bookingRatio[1]', label: 'Conversion Rate [t+1]', sortable: false},
                        { key: 'lastClientVisit', label: 'Letzter Kundenbesuch'},
                    ]
                }
            }

        }
    },
    order: {
        tripDashboard: {
            showRouteDetailStatuses: [],
            rightPanel: {
                additionalFields: []
            }
        },
        contacts:  {
            hideBus: true,
        },
        modal: {
            assignedContactAddress: false,
        },
        placeholderForms: {
            other: {
                requiresDriverIsNotParticipating: []
            },
            ferry: {
                requiresDriverIsNotParticipating: []
            },
            train: {
                requiresDriverIsNotParticipating: []
            }
        },
        navigation: [
            {url: '', label: 'Reisemaske', showOnSmall : true},
            {url: 'calculations', label:  'Kalkulation', showOnSmall : true},
            {url: 'offer', label: 'Angebot'},
            {url: 'catalog', label: 'Katalogtext'},
/*            {url: 'menus', label: 'Menüs'},*/
            {url: 'roomlist', label: 'Zimmerliste'},
            {url: 'trip-details', label: 'Reiseunterlagen'},
            {url: 'voucher', label: 'Voucher'},
            {url: 'client-invoice', label: 'Kundenrechnungen'},
            {url: 'invoices', label: 'Zahlungen an LT'},
            {url: 'documents', label: 'Dokumente'},
            {url: 'emails', label: 'Emails'},
        ],
    },
    offer: {
        tabs: {
            offer: true,
            freePlaces : true,
            cancelation: true,
            payments: true,
            conditions: false,
            other: true,
        },
        freePlaces: {
            showRoomType: true
        },
        bullets: {
            show: true
        },
        generationTypes: {},
    },
    userDashboard: {
        resetFilterToToday: true,
        todoSorting: {
            //'_custom_order[todoType]': ['phone', 'visit'],
            '_custom_order[dueDate]': 'asc',
            //'_custom_order[type]': ['external', 'internal'],
            //'_custom_order[order.startAt]': 'asc',
        },
    },
    emails: {
        organisationHosts : ['@buspaket.de', '@we-tours.com', '@we-tours.de'],
        isFormal: {
            client: false,
            provider: true,
        },
        selectedIsFormal: {
            client: true,
            provider: false,
        },
        categories: {
            client : {
                icon: "folder",
                value: "client",
                label: "client",
                standardClient : true,
                standardProvider: false,
            },
            provider: {
                icon: "hotel",
                value: "provider",
                label: "provider",
                standardClient : false,
                standardProvider: true,
            }
        }
    },
    calculation: {
        singleRoomLimit: 'always',
        startView : 'table',
        extraBlocks: [
            {
                offerTextBlock: 'hotelSuplements',
                label: 'Mehrbettzimmer und sonstige Zimmer',
            },
            {
                offerTextBlock: 'ferrySuplements',
                label: 'Außenkabinen',
            },
            {
                offerTextBlock: 'trainSuplements',
                label: 'Zugfahrten 1. Klasse',
            },
            {
                offerTextBlock: 'ferryOther',
                label: 'zusätzliche Leistungen an Bord der Fähre',
            },
            {
                offerTextBlock: 'foodOnBoard',
                label: 'zusätzliche Leistungen an Bord der Fähre',
            },
            {
                offerTextBlock: 'tourGuides',
                label: 'zusätzliche Reiseleiter-Leistungen',
            },
            {
                offerTextBlock: 'groupServices',
                label: 'zusätzliche Gruppen-Leistungen',
            },
            {
                offerTextBlock: 'services',
                label: 'zusätzliche Leistungen pro Person',
            },
            {
                offerTextBlock: 'mainPriceDates',
                label: 'Paketpreise',
            },
            {
                offerTextBlock: 'tax',
                label: 'Kurtaxe',
            },

        ],
    },
    providerInvoice: {
        hasInvoiceDetails: true,
        startSection: 'InvoiceList',
        disableCompleted: true,
        output: 'pdf',
        setStatusAtDownload: null,
        allowedStatusSequence: {
            'sent_for_payment': ['paid', 'cancelled'],
            'paid': ['completed', 'cancelled', 'sent_for_payment'],
            'completed': ['paid'],
            'cancelled': ['sent_for_payment']
        },
        form: {
            issuer: true,
            bankAccount: true,
            type: 'final_payment',
            invoiceCurrency: true,
            invoiceAmount: true,
            invoiceNumber: true,
            invoiceDate: true,
            dueDate: true,
            status: false,
            notes: true,
            document: true,
            organisationBankAccount: false,
            checkNumber: false,
            paymentReceiptRequired: false,
            user: false,
            paymentType: true,
        },
        formDefaultValues: {},
        formValidations: [
            'invoiceNumber',
            'invoiceAmount',
            'invoiceDate',
            'bankAccount',
            'document',
        ],
        paymentForm: {
            paymentDate: true,
            bankAccount: true,
            accountPlanItem: false,
            checkNumber: false,
        },
        createInvoice: {
            filters: {
                requestStatuses: ['pre_booked', 'option_received', 'option', 'rest_request', 'bookings_sent', 'rest_option', 'approved', 'paid']
            }
        },
        invoiceList: {
            statusesSelected: ['sent_for_payment', 'payment_in_process', 'paid', 'completed'],
        },
        tabs: [
            { key: 'OpenAmountList', label: 'Offene Beträge LT'},
            { key: 'PaymentList', label: 'Bezahlte Rechnungen LT'},
            { key: 'InvoiceList', label: 'Rechnungen von LT'},
        ],
        columns: [
            { key: 'checkable', label:  '', class: 'is-icon-cell  is-relative', style:'padding: 0px; justify-content: center;'},
            { key: 'favicon', formElement: 'Favicon', label: 'User', class: 'is-user-cell has-text-centered has-border is-hidden-on-small'},
            { key: 'invoiceNumber', label: 'R-Nr', class: 'is-160px-cell has-border is-pure-text', formElement: 'PureText'},
            { key: 'tripLabel', label:  'Reisen', class: ' has-border is-pure-text', formElement: 'tripLabel'},
            { key: 'info.issuer', label:  'Rechnungssteller', class: 'has-border is-pure-text', formElement: 'PureText'},
            { key: 'bankIcons', label:  'Bankverbindung', class: 'is-160px-cell has-border is-pure-text is-hidden-on-small', formElement: 'bankIcons'},
            { key: 'invoiceAmount.currency', label: 'CUR', class: 'is-40px-cell has-border has-text-centered is-pure-text', formElement: 'PureText' },
            { key: 'invoiceAmount.numberFormatted', label:  'R-Betrag', class: 'is-110px-cell is-pure-text is-right has-border', formElement: 'PureText' },
            { key: 'invoiceDate', label:  'R-Datum', class: 'is-date-cell is-pure-text has-text-centered has-border is-hidden-on-small', formElement: 'PureText', props: {isDate: true} },
            { key: 'dueDateTransformed', label: 'Zahlungsziel', class: 'is-110px-cell has-border has-text-centered', formElement: 'InputDateTable', props: {onlyDate: false, showDeadlineUrgency: true, readOnly: true} },
            { key: 'paymentDate', label: 'Bezahlt am', class: 'is-date-cell is-pure-text has-text-centered has-border is-hidden-on-small', formElement: 'PureText', props: {isDate: true} },
            { key: 'status', label: 'Status', class: 'is-110px-cell is-editable has-border-head', formElement: ''},
            { key: 'paymentType', label:  'Abbuchung', class: 'is-110px-cell has-text-centered has-border is-hidden-on-small', formElement: 'PureText', props: {keyValueOptionPath: 'paymentType'} },
            { key: 'notes', label:  'Notizen', class: 'is-small-cell has-text-centered has-border is-hidden-on-small', formElement: 'PureText' },
        ],

        subColumns: [
            { key: 'empty-checkable', label:  '', class: 'is-icon-cell  is-relative', formElement: '',style:'padding: 0px; justify-content: center;'},
            { key: 'empty-favicon', label: '', class: 'is-user-cell has-text-centered has-border is-hidden-on-small', formElement: ''},
            { key: 'empty-invoiceNumber', label: 'Rechnungspositionen', class: 'is-160px-cell has-border is-pure-text', formElement: 'PureText'},
            { key: 'clientLabel', label:  'Reise', class: 'has-border is-pure-text', formElement: 'tripLabel'},
            { key: 'serviceDescription', label: 'Leistung', class: 'has-border is-pure-text', formElement: 'PureText'},
            { key: 'startEndAt', label:  'Datum', class: 'is-160px-cell has-border is-pure-text is-hidden-on-small', formElement: 'PureText', props: {isDate: true}},
            { key: 'invoiceVars.amountDue.currency', label: 'CUR', class: 'is-40px-cell has-border has-text-centered is-pure-text', formElement: 'PureText'},
            { key: 'invoiceVars.amountDue.numberFormatted', label:  'Betrag', class: 'is-110px-cell is-pure-text is-right has-border', formElement: 'PureText' },
            { key: 'empty-invoiceDate', label:  '', class: 'is-date-cell is-pure-text has-text-centered has-border is-hidden-on-small', formElement: 'PureText'},
            { key: 'empty-dueDateTransformed', label: '', class: 'is-110px-cell has-border has-text-centered', formElement: '' },
            { key: 'empty-paymentDate', label: '', class: 'is-date-cell is-pure-text has-text-centered has-border is-hidden-on-small', formElement: 'PureText' },
            { key: 'empty-status', label: '', class: 'is-110px-cell is-editable has-border-head', formElement: ''},
            { key: 'empty-paymentType', label:  '', class: 'is-110px-cell has-text-centered has-border is-hidden-on-small', formElement: '' },
            { key: 'empty-notes', label:  '', class: 'is-small-cell has-text-centered has-border is-hidden-on-small', formElement: 'PureText' },
        ],
        columnsShorter: [
            { key: 'invoiceNumber', label: 'R-Nr', class: 'is-160px-cell has-border is-pure-text', formElement: 'PureText'},
            { key: 'info.issuer', label:  'Rechnungssteller', class: 'has-border is-pure-text', formElement: 'PureText'},
            { key: 'bankIcons', label:  'Bankverbindung', class: 'is-160px-cell has-border is-pure-text is-hidden-on-small', formElement: 'bankIcons'},
            { key: 'invoiceAmount.currency', label: 'CUR', class: 'is-40px-cell has-border has-text-centered is-pure-text', formElement: 'PureText' },
            { key: 'invoiceAmount.numberFormatted', label:  'R-Betrag', class: 'is-110px-cell is-pure-text is-right has-border', formElement: 'PureText' },
            { key: 'invoiceDate', label:  'R-Datum', class: 'is-date-cell is-pure-text has-text-centered has-border', formElement: 'PureText', props: {isDate: true} },
            { key: 'dueDateTransformed', label: 'Zahlungsziel', class: 'is-110px-cell has-border has-text-centered is-hidden-on-small', formElement: 'InputDateTable', props: {onlyDate: false, showDeadlineUrgency: true, readOnly: true} },
            { key: 'paymentDate', label: 'Bezahlt am', class: 'is-date-cell is-pure-text has-text-centered has-border is-hidden-on-small', formElement: 'PureText', props: {isDate: true} },
            { key: 'status', label: 'Status', class: 'is-110px-cell is-editable has-border-head', formElement: ''},
            { key: 'paymentType', label:  'Abbuchung', class: 'is-110px-cell has-text-centered has-border is-hidden-on-small', formElement: 'PureText', props: {keyValueOptionPath: 'paymentType'} },
            { key: 'notes', label:  'Notizen', class: 'is-small-cell has-text-centered has-border is-hidden-on-small', formElement: 'PureText' },
        ],
        subColumnsShorter: [
            { key: 'empty-invoiceNumber', label: 'Rechnungspositionen', class: 'is-160px-cell has-border is-pure-text', formElement: 'PureText'},
            { key: 'serviceDescription', label: 'Leistung', class: 'has-border is-pure-text', formElement: 'PureText'},
            { key: 'startEndAt', label:  'Datum', class: 'is-160px-cell has-border is-pure-text is-hidden-on-small', formElement: 'PureText', props: {isDate: true}},
            { key: 'invoiceVars.amountDue.currency', label: 'CUR', class: 'is-40px-cell has-border has-text-centered is-pure-text', formElement: 'PureText'},
            { key: 'invoiceVars.amountDue.numberFormatted', label:  'Betrag', class: 'is-110px-cell is-pure-text is-right has-border', formElement: 'PureText' },
            { key: 'empty-invoiceDate', label:  '', class: 'is-date-cell is-pure-text has-text-centered has-border', formElement: 'PureText'},
            { key: 'empty-dueDateTransformed', label: '', class: 'is-110px-cell has-border has-text-centered is-hidden-on-small', formElement: '' },
            { key: 'empty-paymentDate', label: '', class: 'is-date-cell is-pure-text has-text-centered has-border is-hidden-on-small', formElement: 'PureText' },
            { key: 'empty-status', label: '', class: 'is-110px-cell is-editable has-border-head', formElement: ''},
            { key: 'empty-paymentType', label:  '', class: 'is-110px-cell has-text-centered has-border is-hidden-on-small', formElement: '' },
            { key: 'empty-notes', label:  '', class: 'is-small-cell has-text-centered has-border is-hidden-on-small', formElement: 'PureText' },
        ],
        subColumnsShorter2: [
            { key: 'serviceDescription', label: 'Leistung', formElement: 'PureText',  class:"is-250px-cell", style:"padding:0 6px;", props: {baseUrl: '/orders/'}, evalProps: {urlId: 'orderId'} },
            { key: 'invoiceVars.amountDue.currency', label: '', class:"is-70px-cell", style:"padding:0 6px;", formElement: 'PureText' },
            { key: 'invoiceVars.amountDue.numberFormatted', label:  'Gesamtkosten', class:"is-150px-cell", style:"padding:0 6px;", formElement: 'PureText' },
            { key: 'invoiceVars.startAt', label:  'Beginn', class:"is-150px-cell", style:"padding:0 6px;", formElement: 'PureText', props: {isDate: true} },
            { key: 'invoiceVars.endAt', label: 'Ende', class:"is-150px-cell", style:"padding:0 6px;", formElement: 'PureText', props: {isDate: true} },
            { key: 'empty-2', label: '', class:"is-60px-cell", style:"padding:0 6px;", formElement: 'PureText' },
            { key: 'empty-3', label:  '', class:"", formElement: 'PureText' },
            { key: 'empty-4', label:  '', formElement: 'PureText' },
            { key: 'empty-5', label:  '', class: '',style:'max-width: 83px;', formElement: '' },
        ]
    },
    emailTemplate: {
        startSection: 'Templates'
    },
    clientInvoice: {
        disableCompleted: true,
        docType: 'pdf',
        fields: {
            tax: [],
            invoiceDate: true,
            calculationBasis: true,
            dueDate: true,
            contact: true,
            introText: true,
            endText: true,
            signature: true
        },
        columns: [
            { key: 'favicon', label: 'User', class: 'is-user-cell', sortable: 'order.user.username' },
            { key: 'invoiceNumber', filter: 'invoiceNumber', label: 'R-NR' },
            { key: 'order', filter: 'order.id', label: 'Reisenummer' },
            { key: 'order.client.shortName', label: 'Kunde', class: 'is-180px-cell' },
            { key: 'order.trip.name', label: 'Reiseziel', class: 'is-180px-cell' },
            { key: 'order.startAt', label: 'Reisebeginn'},
            { key: 'order.endAt', label: 'Reiseende'},
            { key: 'invoiceDate', filter: 'invoiceDate', label: 'Rechnungsdatum'},
            { key: 'dueDate', filter: 'dueDate', label: 'Zahlungsziel', class: 'is-120px-cell is-editable', showSum: 'Summe'},
/*
            { key: 'order.totalExpectedRevenues.numberFormatted', label: 'Aktuell', class: 'has-text-right', showSum: 'price' },
*/
            { key: 'amount.formatted', filter: 'amount.formatted', label: 'Rechnungsbetrag', showSum: 'price'},
            { key: 'paidAmount.formatted', filter: 'paidAmount.formatted', label: 'Bezahlt', class: 'has-text-right', showSum: 'price' },
            { key: 'openAmount.formatted', label: 'Saldo', class: 'has-text-right', showSum: 'price'},
            { key: 'status', filter: 'status', label: 'Status', hideContent: true, class: 'is-medium-cell is-editable'},
        ],
        orderBy: {
            '_order[invoiceDate]': 'DESC',
        },
        mainCurrency: null,
        exportCurrentInvoice: [],
    },
    voucher: {
        translations: true,
        translationsInOne: false,
        availableTranslations: ['de_DE', 'fr'],
        translationDelimiter: "",
        hasInternalVoucher: false,
        tipTap: {
            maxCharacters: 40,
        }
    },
    roomList: {
        create: {
            fields: {
                notes: true
            }
        },
        types: [
            {key: 'templateA', label: 'Zimmerliste', default: true},
            {key: 'templateB', label: 'Passagierliste', default: false},
            {key: 'templateC', label: 'Passagierliste mit Motorrad', default: false},
        ],
        templateA: {
            fields: {
                extraType: true,
                twin: true,
                gender: false,
                firstName1: true,
                lastName: true,
                firstName2: false,
                nationality: false,
                dateOfBirth: false,
                placeOfBirth: false,
                documentType: false,
                documentNumber: false,
                assistance: false,
                outsideCabin: false,
                notes: true
            }
        },
        templateB: {
            fields: {
                extraType: true,
                twin: false,
                gender: true,
                firstName1: true,
                lastName: true,
                firstName2: false,
                nationality: true,
                dateOfBirth: true,
                placeOfBirth: true,
                documentType: true,
                documentNumber: true,
                assistance: true,
                outsideCabin: true,
                notes: true
            }
        },
        templateC: {
            fields: {
                extraType: true,
                twin: false,
                gender: true,
                firstName1: true,
                lastName: true,
                firstName2: false,
                nationality: true,
                dateOfBirth: true,
                placeOfBirth: true,
                documentType: true,
                documentNumber: true,
                assistance: true,
                outsideCabin: true,
                notes: true,
                motorBikeModel: true,
                licencePlate: true
            }
        },
        sources: [
                {label: 'Buspaket', key: 'buspaket', default: true},
                {label: 'Buspaket kurz', key: 'buspaket_short', default: false},
                {label: 'Toeffreisen', key: 'toeffreisen', default: false},
                {label: 'Reisen & Erleben', key: 'reisenUndErleben', default: false},
            ]
    },
    liquidityPlan: {
        isUsed: false,
        customExports: [
            {
                fileType: '.xlsx',
                active: true,
                type: 'monthly',
                label: 'V & V erstellen',
                url: '/api/finances/custom-statement/generate-excel',
                downloadName: 'Vermietung und Verpachtung Kirchheim'
            },
            {
                fileType: '.xlsx',
                active: true,
                type: 'yearly',
                label: 'UmSt Vergütung',
                url: '/api/finances/custom-tva-statement/generate-excel',
                downloadName: 'Umsatzsteuervergütung'
            },
            {
                fileType: '.xlsx',
                active: true,
                type: 'yearly',
                label: 'UmSt Vergütung CSV',
                url: '/api/finances/custom-tva-statement/generate-csv',
                downloadName: 'Umsatzsteuervergütung'
            }
        ],
    },

};

