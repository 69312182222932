<template>
    <div
        v-if="showElement"
        :tabindex="searchable ? -1 : tabindex"
        :class="{ 'multiselect--active': isOpen, 'multiselect--disabled': disabled, 'multiselect--above': isAbove }"
        @focus="activate()"
        @blur="searchable ? false : deactivate()"
        @keydown.self.down.prevent="pointerForward()"
        @keydown.self.up.prevent="pointerBackward()"
        @keypress.enter.tab.stop.self="addPointerElement($event)"
        @keyup.esc="deactivate()"
        @keydown.tab.stop="addPointerElement($event)"
        class="multiselect"
        role="combobox"
        :aria-owns="'listbox-'+id">
        <slot name="caret" :toggle="toggle">
            <div @mousedown.prevent.stop="toggle()" class="multiselect__select"></div>
        </slot>

        <div
            class="multiselect__copy"
            v-if="toBeCopied && !multiple && valueKeys.length > 0"
        >
            <i class="material-icons is-pointer" @click="copy(currentOptionLabel)">content_copy</i>
        </div>
        <slot name="clear" :search="search"></slot>
        <div ref="tags" class="multiselect__tags">
            <slot
                name="selection"
                :search="search"
                :remove="removeElement"
                :values="visibleValues"
                :is-open="isOpen"
            >
                <div class="multiselect__tags-wrap" v-show="visibleValues.length > 0">
                    <template v-for="(option, index) of visibleValues" @mousedown.prevent>
                        <slot name="tag" :option="option" :search="search" :remove="removeElement">
                <span class="multiselect__tag" :key="index">
                  <i v-if="toBeCopied" class="material-icons is-pointer multiselect__tag-copy-icon" @click="copy(getOptionLabel(option))">content_copy</i>

                  <span v-text="getOptionLabel(option)"></span>

                  <i tabindex="1" @keypress.enter.tab.prevent="removeElement(option)"  @mousedown.prevent="removeElement(option)" class="multiselect__tag-icon"></i>
                </span>
                        </slot>
                    </template>
                </div>
                <template v-if="internalValue && internalValue.length > limit">
                    <slot name="limit">
                        <span class="multiselect__strong" v-text="limitText(internalValue.length - limit)"/>
                    </slot>
                </template>
            </slot>
            <transition name="multiselect__loading">
                <slot name="loading">
                    <div v-show="loading" class="multiselect__spinner"/>
                </slot>
            </transition>
            <input
                ref="search"
                v-if="searchable"
                :name="name"
                :id="id"
                type="text"
                autocomplete="disabled"
                spellcheck="false"
                :style="inputStyle"
                :value="search"
                :disabled="disabled"
                :tabindex="tabindex"
                @input="updateSearch($event.target.value)"
                @focus.prevent="activate()"
                @blur.prevent="deactivate()"
                @keyup.esc="deactivate()"
                @keydown.down.prevent="pointerForward()"
                @keydown.up.prevent="pointerBackward()"
                @keypress.enter.tab.prevent.stop.self="addPointerElement($event)"
                @keydown.delete.stop="removeLastElement()"
                class="multiselect__input"
                :aria-controls="'listbox-'+id"
            />
            <span
                v-if="isSingleLabelVisible"
                class="multiselect__single"
                @mousedown.prevent="toggle"
            >
          <slot name="singleLabel" :option="singleValue">
            <template>{{ currentOptionLabel }}</template>
          </slot>
        </span>
            <span
                v-if="isPlaceholderVisible"
                class="multiselect__placeholder"
                @mousedown.prevent="toggle"
            >
          <slot name="placeholder">
            {{ placeholder }}
          </slot>
        </span>
        </div>
        <transition name="multiselect">
            <div
                class="multiselect__content-wrapper"
                v-show="isOpen"
                @focus="activate"
                tabindex="-1"
                @mousedown.prevent
                :style="{ maxHeight: optimizedHeight + 'px' }"
                ref="list"
            >
                <ul class="multiselect__content" :style="contentStyle" role="listbox" :id="'listbox-'+id">
                    <slot name="beforeList"></slot>
                    <li v-if="multiple && max === internalValue.length">
              <span class="multiselect__option">
                <slot name="maxElements">Maximum of {{ max }} options selected. First remove a selected option to select another.</slot>
              </span>
                    </li>
                    <template v-if="!max || internalValue.length < max">
                        <li class="multiselect__element"
                            v-for="(option, index) of filteredOptions"
                            :key="index"
                            v-bind:id="id + '-' + index"
                            v-bind:role="!(option && (option.$isLabel || option.$isDisabled)) ? 'option' : null">
                <span
                    v-if="!(option && (option.$isLabel || option.$isDisabled))"
                    :class="optionHighlight(index, option)"
                    @click.stop="handleSelect(option)"
                    @mouseenter.self="pointerSet(index)"
                    :data-select="option && option.isTag ? tagPlaceholder : selectLabelText"
                    :data-selected="selectedLabelText"
                    :data-deselect="deselectLabelText"
                    class="multiselect__option">
                    <slot name="option" :option="option" :search="search" :index="index">
                      <span>{{ getOptionLabel(option) }}</span>
                    </slot>
                </span>
                            <span
                                v-if="option && (option.$isLabel || option.$isDisabled)"
                                :data-select="groupSelect && selectGroupLabelText"
                                :data-deselect="groupSelect && deselectGroupLabelText"
                                :class="groupHighlight(index, option)"
                                @mouseenter.self="groupSelect && pointerSet(index)"
                                @mousedown.prevent="selectGroup(option)"
                                class="multiselect__option">
                    <slot name="option" :option="option" :search="search" :index="index">
                      <span>{{ getOptionLabel(option) }}</span>
                    </slot>
                </span>
                        </li>
                    </template>
                    <li v-show="showNoResults && (filteredOptions.length === 0 && search && !loading)">
              <span class="multiselect__option">
                <slot name="noResult" :search="search">No elements found. Consider changing the search query.</slot>
              </span>
                    </li>
                    <li v-show="showNoOptions && (options.length === 0 && !search && !loading)">
              <span class="multiselect__option">
                <slot name="noOptions">List is empty.</slot>
              </span>
                    </li>
                    <slot name="afterList"></slot>
                </ul>
            </div>
        </transition>
    </div>
    <div v-else></div>
</template>


<script>
import Multiselect from 'vue-multiselect'
import {notifyError, notifySuccess} from '@components/Notification';


function not (fun) {
    return (...params) => !fun(...params)
}

function includes (str, query) {
    /* istanbul ignore else */
    if (str === undefined) str = 'undefined'
    if (str === null) str = 'null'
    if (str === false) str = 'false'
    const text = str.toString().toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, '')
    return  text.indexOf(query.trim().normalize('NFD').replace(/[\u0300-\u036f]/g, '')) !== -1 ;

}






function filterOptions (options, search, label, customLabel) {
    return options.filter(option => includes(customLabel(option, label), search))
}

function flattenOptions (values, label) {
    return (options) =>
        options.reduce((prev, curr) => {
            /* istanbul ignore else */
            if (curr[values] && curr[values].length) {
                prev.push({
                    $groupLabel: curr[label],
                    $isLabel: true
                })
                return prev.concat(curr[values])
            }
            return prev
        }, [])
}

export default {
    name: 'vue-multiselect-locale-de',
    extends: Multiselect,
    props: {
        limitText: {
            type: Function,
            default: count => `+ ${count}`
        },
        toBeCopied: {
            type: Boolean,
            default: false
        },
        placeholder: {
            type: String,
            default: 'Option wählen'
        },

        confirm: {
            type: [String, Function],
            default: ''
        },

        emptyOption: {
            default: null,
        },
        showElement: {
            type: Boolean,
            default: true
        },
        updateOnTag: {
            type: Boolean,
            default: false
        }
    },

    computed: {
        filteredOptions () {
            let search = this.search || ''
            if(typeof search === 'object') {
                search = '';
            }
            let normalizedSearch = search.toLowerCase().trim()

            let options = this.options.concat()

            if (this.internalSearch) {
                options = this.groupValues
                    ? this.filterAndFlat(options, normalizedSearch, this.label)
                    : filterOptions(options, normalizedSearch, this.label, this.customLabel)
            } else {
                options = this.groupValues ? flattenOptions(this.groupValues, this.groupLabel)(options) : options
            }

            options = this.hideSelected
                ? options.filter(not(this.isSelected))
                : options

            /* istanbul ignore else */
            if (this.taggable && normalizedSearch.length && !this.isExistingOption(normalizedSearch)) {
                if (this.tagPosition === 'bottom') {
                    options.push({ isTag: true, label: search })
                } else {
                    options.unshift({ isTag: true, label: search })
                }
            }


            return options.slice(0, this.optionsLimit)
        },
    },
    methods: {
        activate () {
            /* istanbul ignore else */
            if (this.isOpen || this.disabled) return


           if(this.taggable && this.internalValue && this.internalValue[0] && !this.multiple) {
               if(typeof this.internalValue[0] === 'object' && this.internalValue[0][this.label]) {
                   this.updateSearch(this.internalValue[0][this.label])
               }
               if(typeof this.internalValue[0] === 'string') {
                   this.updateSearch(this.internalValue[0])
               }
            }

            this.adjustPosition()
            /* istanbul ignore else  */
            if (this.groupValues && this.pointer === 0 && this.filteredOptions.length) {
                this.pointer = 1
            }

            this.isOpen = true
            /* istanbul ignore else  */
            if (this.searchable) {
                if (!this.preserveSearch && !this.taggable) this.search = ''
                this.$nextTick(() => this.$refs.search.focus())
            } else {
                this.$el.focus()
            }
            this.$emit('open', this.id)


        },

        focus: function () {
          this.$refs.search.focus();
        },
        async copy(text) {
            if (text) {
                await navigator.clipboard.writeText(text);
                notifySuccess('In die Zwischenablage kopiert!');
            } else {
                notifyError('kein Element gefunden')
            }
        },
        ignoreAccent: function(elem) {
           return  this.$refs[elem][0].search = this.$refs[elem][0].search.normalize('NFD').replace(/[\u0300-\u036f]/g, "")
        },
        handleSelect: function(option) {
            if (this.confirm && typeof this.confirm === 'string' && confirm(this.confirm)) {
                this.select(option)
            } else if (this.confirm && typeof this.confirm === 'function') {
                this.confirm(option)
                    .then(() => {
                        this.select(option)
                    }, error => {
                        notifyError(error)
                    })
            } else if (this.confirm === '') (
                this.select(option)
            )
        },

        select (option, key) {
            /* istanbul ignore else */
            if (option.$isLabel && this.groupSelect) {
                this.selectGroup(option)
                return
            }
            if (this.blockKeys.indexOf(key) !== -1 ||
                this.disabled ||
                option.$isDisabled ||
                option.$isLabel
            ) return
            /* istanbul ignore else */
            if (this.max && this.multiple && this.internalValue.length === this.max) return
            /* istanbul ignore else */
            if (key === 'Tab' && !this.pointerDirty && !this.search) return
            if (option.isTag) {
                if(this.updateOnTag) {
                    this.$emit('select', option, this.id)
                    this.$emit('input', this.internalValue.concat([option.label]), this.id)
                }
                this.$emit('tag', option.label, this.id)
                this.search = ''
                if (this.closeOnSelect && !this.multiple) this.deactivate()
            } else {
                const isSelected = this.isSelected(option)

                if (isSelected) {
                    if (key !== 'Tab') this.removeElement(option)
                    return
                }

                this.$emit('select', option, this.id)

                if (this.multiple) {
                    this.$emit('input', this.internalValue.concat([option]), this.id)
                } else {
                    this.$emit('input', option, this.id)
                }

                /* istanbul ignore else */
                if (this.clearOnSelect) this.search = ''
            }
            /* istanbul ignore else */
            if(this.closeOnSelect && this.taggable) this.isOpen = false;
            else if (this.closeOnSelect) this.deactivate()
        },

    }
}
</script>

