<template>
    <div class="bp-row-form">
        <div>
            <div class="bp-row-form__title">Leistungskategorie und -ort</div>

            <div>
                <i class="material-icons">explore</i>

                <div class="select">
                    <select v-model="categorySelected" ref="category">
                        <option v-bind:value="null" disabled selected>Kategorie</option>
                        <option v-for="category in categories" v-bind:value="category.key">{{ category.value }}</option>
                    </select>
                </div>
            </div>

            <div>
                <button
                    v-bind:class = "['btn-single-toggle',  onlyFromDay ? '' : 'is-active']"
                    v-on:click   = "onlyFromDay = !onlyFromDay"
                    v-bind:title = "onlyFromDay ? 'Ortsangaben werden nur in der Reisedestination gesucht (klicken zum Deaktivieren)' : 'Alle Orte werden gesucht (zum Filtern nach Reise-Destinationen klicken)' "
                ><i class="material-icons">flag</i></button>

                <location-select
                    v-bind:value = "copyPlaceholder"
                    v-bind:limitedDestinations="destinationsForLocation"
                    isNullable
                    hasDestination
                    v-on:input = "value => {
                        copyPlaceholder.area        = value.area;
                        copyPlaceholder.place       = value.place;
                        copyPlaceholder.destination = value.destination;
                    }"
                ></location-select>
            </div>
        </div>


        <div class="is-flex-grow">
            <div class="bp-row-form__title">Zusatzleistung</div>

            <other-service-type-select
                v-model="copyPlaceholder.otherServiceType"
                v-bind:placeholderInfo="placeholderInfo"
                :show-service-day-times="true"
            ></other-service-type-select>

            <div>
                <i class="material-icons" title="Treffpunkt">my_location</i>
                <input
                    class          = "input"
                    type           = "text"
                    v-model = "copyPlaceholder.meetingPoint"
                    placeholder="Treffpunkt"
                />
            </div>

        </div>


        <div class="is-160 has-time">
            <div class="bp-row-form__title">Zeitangaben</div>

            <div>
                <i class="material-icons">date_range</i>
                <input
                    class          = "input has-margin-right is-30"
                    type           = "text"
                    v-model.number = "copyPlaceholder.durationDays"
                    v-on:focus     = "$event.target.select()"
                />
                Tage
            </div>

            <div>
<!--                <button
                    class        = "btn-single-toggle has-margin-right"
                    v-bind:class = "[onlyTime ? '' : 'is-active']"
                    v-on:click   = "onlyTime = !onlyTime"
                    title = "Uhrzeit"
                    v-if="serviceDayTimes.length > 0"
                ><i class="material-icons">access_time</i></button>-->
                <i class="material-icons">access_time</i>

<!--                <template v-if="onlyTime">-->
                    <date-time class="has-margin-right" v-model="copyPlaceholder.startTime" time-only></date-time> -
                    <date-time class="has-margin-left" v-model="copyPlaceholder.endTime" time-only></date-time>
<!--                </template>-->

<!--                <template v-else>
                    <div class="select">
                        <multiselect
                            v-bind:options="serviceDayTimes"
                            track-by="id"
                            v-bind:custom-label="serviceDayTimeLabel"
                            v-model="serviceDayTimeSelected"
                            v-on:select="selectServiceTime"
                        />
                    </div>
                </template>-->
            </div>
            <div v-if="hasCheckInTime">
                <i class="material-icons">av_timer</i>
                <date-time class="has-margin-right" v-model="copyPlaceholder.checkInTime" time-only></date-time>
            </div>
        </div>


        <div>
            <div class="bp-row-form__title">Abrechnung</div>

            <div>
                <i title="Set as Additional" class="material-icons">shopping_cart</i>
                <div class="select">
                    <select v-model="copyPlaceholder.clientOffer.status">
                        <option value="included">inkludiert</option>
                        <option value="optional">optional</option>
                        <option value="additional">gesondert</option>
                        <option value="atLocation">vor Ort</option>
                    </select>
                </div>
            </div>

            <div>
                <i class="material-icons">people</i>
                <div class="select">
                    <select v-model="copyPlaceholder.clientOffer.priceType">
                        <option value="per_person">Pro Person</option>
                        <option value="per_group">Pro Gruppe</option>
                    </select>
                </div>
            </div>
        </div>


        <button
            class="button is-close"
            v-on:click="$emit('cancel')"
            title="Verwerfen"
        ><i class="material-icons">close</i></button>

        <button
            class        = "button is-save is-primary"
            v-bind:class = "{ 'is-loading': isLoading }"
            v-on:click   = "save"
            title        = "Speichern"
        ><i class="material-icons">check</i></button>
    </div>
</template>



<script>
import axios from 'axios';
import LocationSelect    from '@components/form/LocationSelect';
import TimeOfDaySelect   from '@components/form/TimeOfDaySelect';
import OtherServiceTypeSelect from '@orders/placeholders/components/forms/OtherServiceTypeSelect';
import { getOtherServiceTypes } from '@api';
import mixins from './mixins';
import store       from './../store';
import moment from 'moment'


export default {
    mixins: [mixins],


    computed: {
        placeholderInfo: function () {
            return {
                area:        this.copyPlaceholder.area,
                place:       this.copyPlaceholder.place,
                destination: this.copyPlaceholder.destination,
                category:    this.categorySelected
            };
        },

        hasCheckInTime: function () {
            return !!(this.categoryOptions && this.copyPlaceholder.otherServiceType && this.copyPlaceholder.otherServiceType.category && this.categoryOptions[this.copyPlaceholder.otherServiceType.category][0]);
        },


        destinationsForLocation: function () {
            return this.onlyFromDay && this.options.dayDestinations ? this.options.dayDestinations : [];
        },


        isBrandNew: function () { return !this.placeholder; }
    },


    data: function () {
        return {
            copyPlaceholder: this.placeholder ?
                                JSON.parse(JSON.stringify(this.placeholder)) :
                                {
                                    otherServiceType: null,
                                    area:        null,
                                    place:       null,
                                    destination: null,
                                    durationDays: 0,
                                    startTime: '00:00',
                                    endTime: '00:00',
                                    checkInTime: '00:00',
                                    clientOffer: {
                                        status: 'included',
                                        priceType: null,
                                        price: '0'
                                    },
                                    serviceDescription: null,
                                    routeDescription:   null
                                },
            onlyFromDay:     true,
            onlyTime:        true,
            categories:      [],
            categoryOptions: [],
            serviceDayTimes: [],
            serviceDayTimeSelected: null,

            categorySelected:  this.placeholder && this.placeholder.otherServiceType ? this.placeholder.otherServiceType.category : null,
        }
    },


    methods: {
        toSeconds(timeStr) {
            let [hours, minutes] = timeStr.split(':').map(Number);
            return hours * 3600 + minutes * 60;
        },

        init: function () {},


        getCategoryOptions: function () {
            let options = JSON.parse(localStorage.getItem('options'));

            if (options && options.otherServiceType && options.otherServiceType.category &&
                options.otherServiceType.categoryOptions && options.lastUpdates &&
                options.lastUpdates.otherServiceType && moment(options.lastUpdates.otherServiceType) >= moment('26.11.2020', 'DD.MM.YYYY')) {
                this.categories = options.otherServiceType.category;
                this.categoryOptions = options.otherServiceType.categoryOptions;
            } else {
                axios.options('/api/other_service_types').then(response => {
                    if (!options)                  { options                  = {}; }
                    if (!options.otherServiceType) { options.otherServiceType = {}; }
                    if (!options.categoryOptions) { options.categoryOptions = {}; }
                    if (!options.lastUpdates) { options.lastUpdates = {}; }

                    options.otherServiceType.category = response.data.category;
                    options.otherServiceType.categoryOptions = response.data.categoryOptions
                    options.lastUpdates.otherServiceType = moment().startOf('day');
                    this.categories = options.otherServiceType.category;
                    this.categoryOptions = options.otherServiceType.categoryOptions;
                    localStorage.setItem('options', JSON.stringify(options));
                });
            }
        },


        onKeyDown: function (event) {
            // Initial value save
            if (event.keyCode === 13) {
                this.placeholderStringify = JSON.stringify(this.copyPlaceholder) + JSON.stringify(this.categorySelected) + JSON.stringify(this.locationSelected);
            }
        },


        onKeyUp: function (event) {
            if (event.keyCode === 13 &&
                this.placeholder === undefined &&
                this.categorySelected &&
                this.copyPlaceholder.otherServiceType
                ) {
                this.$nextTick(() => {
                    if (this.placeholderStringify === JSON.stringify(this.copyPlaceholder) + JSON.stringify(this.categorySelected) + JSON.stringify(this.locationSelected)) {
                        this.save();
                    }
                });
            }
        },

        selectServiceTime: function (selectedTime) {
            //setTime
            if(selectedTime){
                const startAt = selectedTime.startTimeOfDay ? selectedTime.startTimeOfDay : '00:00:00';
                const endAt = selectedTime.endTimeOfDay ? selectedTime.endTimeOfDay : '00:00:00';
                const checkInAt = selectedTime.checkInTimeOfDay ? selectedTime.checkInTimeOfDay : '00:00:00';
                this.copyPlaceholder.startTime = startAt;
                this.copyPlaceholder.endTime = endAt;
                this.copyPlaceholder.checkInTime = checkInAt;
                this.onlyTime = true;
            }

        },

        serviceDayTimeLabel: function (serviceDayTime) {
            let customLabel = serviceDayTime.startTimeOfDay.substring(0, serviceDayTime.startTimeOfDay.length - 3);
            customLabel += serviceDayTime.endTimeOfDay ? ' - ' + serviceDayTime.endTimeOfDay.substring(0, serviceDayTime.endTimeOfDay.length - 3) : '';
            customLabel += serviceDayTime.checkInTimeOfDay ? ' (CheckIn: ' + serviceDayTime.checkInTimeOfDay.substring(0, serviceDayTime.checkInTimeOfDay.length - 3) + ')' : '';
            customLabel += ' - ' + serviceDayTime.provider;
            return customLabel;
        },
    },


    created: function () {
        this.getCategoryOptions();

        if (!this.placeholder) {
            getOtherServiceTypes().then(response => {
                this.copyPlaceholder.otherServiceType      = response.data[0];
                this.copyPlaceholder.clientOffer.priceType = response.data[0].priceType;
            });
        }
    },


    mounted: function () {
        if (!this.placeholder) {
            this.$refs.category.focus();
            if(this.$store.state.day.destinations){
                this.copyPlaceholder.destination = this.$store.state.day.destinations[0];
            }
        }
    },


    watch: {
        'copyPlaceholder.otherServiceType': function () {
            if (this.copyPlaceholder.otherServiceType) {
                this.copyPlaceholder.serviceDescription    = null;
                this.copyPlaceholder.routeDescription      = null;
                this.copyPlaceholder.clientOffer.priceType = this.copyPlaceholder.otherServiceType.priceType;
                this.copyPlaceholder.meetingPoint = this.copyPlaceholder.otherServiceType.meetingPoint;

                if(this.copyPlaceholder.otherServiceType.allServiceDayTimes && this.copyPlaceholder.otherServiceType.allServiceDayTimes.length > 0){
                    this.serviceDayTimes = this.copyPlaceholder.otherServiceType.allServiceDayTimes;
                    this.onlyTime = false;
                } else {
                    this.serviceDayTimes = [];
                    this.onlyTime = true;
                }
            }
        },
        'copyPlaceholder.startTime': function () {
            if (this.copyPlaceholder.otherServiceType && this.copyPlaceholder.startTime && this.copyPlaceholder.otherServiceType.duration) {
                let duration = this.toSeconds(this.copyPlaceholder.otherServiceType.duration);
                this.copyPlaceholder.endTime = moment(this.copyPlaceholder.startTime, 'HH:mm:ss').add(duration, 'seconds').format('HH:mm:ss');
            }
        },


        categorySelected: function () { this.copyPlaceholder.otherServiceType = null; }
    },

    store: store,


    components: {
        LocationSelect,
        OtherServiceTypeSelect,
        TimeOfDaySelect
    }
}
</script>
