<template>
    <div class="edit-table redesign-table" :class="tableClasses">

        <div class="table-wrapper">
            <div class="table-label" v-if="label">{{ label }}</div>
            <div class="row is-header" v-if="hasHeader">
                <slot name="header" />
            </div>

            <filters
                class="row is-filters"
                v-model="computedFilters"
                v-bind:options="allOptions"
                v-if="!hasSlotFilters && !hideFilterRow"
            />
            <slot name="filters" v-bind:options="allOptions" />

            <!-- Head row -->
            <slot name="head" v-bind="{visibleCols}">
                <div class="row is-head">

                    <div
                        v-for="col in visibleCols"
                        v-bind:key="`${col.key}-${col.label}`"
                        class="cell"
                        v-bind:class="{
                            [col.class]:     !!col.class
                        }"
                        :style="col.style"
                    >
                        <input type="checkbox" v-if="col.key === 'checkable'" v-model="checkAll" >
                        <span v-else>{{ col.label }}</span>
                    </div>


                    <div class="cell is-actions" v-if="isEditable || deleteRow || hasActions">
                        <i class="material-icons is-pointer" v-if="isAddable"
                            v-on:click.prevent.stop="handleAdd">add_circle</i>
                    </div>
                </div>
            </slot>

            <loading v-if="isLoading" />

            <template v-else>
                <!-- Data Rows -->
                <div :class="isZebra ? 'is-zebra' : ''" v-if="rows.length > 0">
                    <slot v-bind="{rows, allOptions}">
                        <template v-for="(row, index) in rows">
                            <component
                                v-if="row"
                                :is="rowTag"
                                class="row"
                                :class="[...rowClasses, row.subrowVisible ? selectedClass : ''] "
                                :key="row.id"
                                v-bind:href="rowURL(row)"
                                v-bind:target="target"
                            >

                                <template v-for="(col,colIndex) in visibleCols">
                                    <div
                                        v-if="slotExists(col.key)"
                                        :key="`${row.id}-${col.key}-${col.label}`"
                                        class="cell"
                                        :class="col.class"
                                        :style="col.style"
                                        :data-v-step="`table:cell:${col.key}:${index}`"
                                    >
                                        <slot :name="col.key" v-bind="{ row, allOptions }" />
                                    </div>
                                    <div class="cell is-icon-cell has-border is-relative" style="padding: 0px; justify-content: center;" v-else-if="col.key==='checkable'">
                                        <input type="checkbox" v-model="checkedRows" :value="row" />
                                    </div>

                                    <div
                                        v-else-if="componentExists(col.formElement) "
                                        :key="`${row.id}-${col.key}-${col.label}`"
                                        class="cell"
                                        :class="col.class"
                                        :style="col.style"
                                        :data-v-step="`table:cell:${col.key}:${index}`"
                                        @click="$emit('cellClicked', {row, col})"
                                        @dblclick="$emit('cellDoubleClicked', {row, col})"
                                        @keyup="navigatePrevious($event,index, colIndex, col)"
                                    >
                                        <component
                                            :is="col.formElement"
                                            :row="row"
                                            v-bind="evalProps(row, col)"
                                            :value="getValue(row, col)"
                                            :generalOptions="allOptions"
                                            :ref="'cell-' + index + '-' + colIndex"
                                            @input="inputValue => saveRow(row, col, inputValue)"
                                            @enter="inputValue => $emit('enterPressed', {row, col, inputValue})"
                                        />
                                    </div>
                                    <div
                                        v-else-if="col.noHtml"
                                        v-bind:key="`${row.id}-${col.key}-${col.label}`"
                                        class="cell"
                                        v-bind:class="col.class"
                                        :style="col.style"
                                        :data-v-step="`table:cell:${col.key}:${index}`"
                                        v-bind:title="colValue(row, col.key) | stripHtml"
                                    >{{colValue(row, col.key) | stripHtml }}</div>
                                    <div
                                        v-else
                                        v-bind:key="`${row.id}-${col.key}-${col.label}`"
                                        v-html="colValue(row, col.key)"
                                        class="cell"
                                        v-bind:class="col.class"
                                        :style="col.style"
                                        :data-v-step="`table:cell:${col.key}:${index}`"
                                        v-bind:title="colValue(row, col.key)"
                                    ></div>
                                </template>

                                <div class="cell is-actions" v-if="hasActions" :data-v-step="`table:cell:actions:${index}`">
                                    <slot name="actions" v-bind="row" />

                                    <update-popover
                                        v-if="showUpdate"
                                        v-bind:update="{updatedAt: row.updatedAt, updatedBy: row.updatedBy}"
                                        :api="'api/' + api + '/' + row.id"
                                    >
                                        <button
                                            class="btn-edit"
                                            title="Letzte Aktualisierung"
                                        ><i class="material-icons">update</i></button>
                                    </update-popover>

                                    <button
                                        v-if="isEditable"
                                        class="btn-edit"
                                        v-on:click.prevent.stop="handleEdit(row)"
                                    ><i class="material-icons">edit</i></button>

                                    <button
                                        v-if="deleteRow"
                                        class="btn-delete"
                                        v-on:click.prevent.stop="handleDelete(row)"
                                    ><i class="material-icons">delete</i></button>
                                </div>
                            </component>

                            <slot name="subrow" v-bind="{allOptions, row}" />
                        </template>
                    </slot>
                </div>

                <div class="row" :class="rowClasses" v-else>
                    <div class="cell has-text-centered">
                        <template v-if="!load">No data loaded</template>
                        <template v-else>Keine Einträge</template>
                    </div>
                </div>
                <div v-if="showSum" class="row is-head">
                    <div
                        v-for="col in visibleCols"
                        v-bind:key="`${col.key}-${col.label}`"
                        class="cell"
                        v-bind:class="{
                            [col.class]:     !!col.class
                        }"
                        :style="col.showSum === 'price' ? col.style + ';padding: 0;': col.style"
                    >
                        <span :data-prefix="(col.props && col.props.prefix) || (col.evalProps && col.evalProps.prefix) ? evalProps(rows[0], col).prefix : ''" v-if="col.showSum==='price'">{{calculateSum(col)}}</span>
                        <span v-else-if="col.showSum">{{col.showSum}}</span>
                    </div>


                    <div class="cell is-actions" v-if="isEditable || deleteRow || hasActions">
                        <i class="material-icons is-pointer" v-if="isAddable" v-on:click.prevent.stop="handleAdd">add_circle</i>
                    </div>
                </div>

            </template>
        </div>

        <!-- Footer -->
        <div class="redesign-table__footer" v-if="!isLoading && footer">
            <div>
                <slot name="footer" v-bind:checked="value" v-bind:options="allOptions">
                    <a
                        v-if="isAddable"
                        v-on:click="handleAdd"
                        class="button is-primary"
                    >{{ name.singular | capitalize }} hinzufügen</a>
                </slot>
            </div>
            <pagination v-if="pagination" v-model="pagination" v-on:input="updatePagination" :simple="false"/>

        </div>


        <!-- Form slot -->
        <slot name="form" v-bind:options="allOptions" />
    </div>
</template>



<script>
import axios from 'axios';

import isString from 'lodash/isString'
import get from 'lodash/get'
import has from 'lodash/has';

import Loading from '@components/Loading';
import { notifySuccess, notifyError } from '@components/Notification';
import {
    keyToComponent,
    capitalize,
    stripHtml,
    priceView, getValueWithKey
} from '@utilities/functions';
import { TABLE_LABELS } from '@utilities/variables';

import Filters from "@components/CRUD/Table/Filters";
import Pagination from '../CRUD/Pagination';
import UpdatePopover from "@components/UpdatePopover";
import FormElements from './../form'



export default {
    components: { Filters, ...FormElements, Pagination, Loading, UpdatePopover },

    props: {
        rows: {
            type: Array,
            required: true
        },

        value: {
            type: Array,
        },

        api: {
            type: String,
            required: false
        },

        tableClasses: {
            type: Array,
        },

        rowClasses: {
            type: Array,
            default: () => []
        },

        selectedClass: {
            type: String,
            default: ''
        },

        url: {
            type: [Boolean, String, Function],
            default: false
        },

        label: {
            type: String,
        },

        columns: {
            type: Array,
            required: true
        },

        isZebra: {
            type: Boolean,
            required: false,
            default: true
        },

        name: {
            type: Object,
            default: () => {
                return {
                    singular: "Item",
                    plural: "Items"
                }
            }
        },

        deleteRow: {
            type: Boolean,
            default: true
        },

        softDelete: {
            type: Boolean,
            default: false
        },

        load: {
            type: Boolean,
            default: true
        },

        loadOptions: {
            type: [Boolean, String],
            default: true
        },

        passedOptions: {
            type: Object,
            default: () => { }
        },

        target: {
            type: String,
            default: '_self'
        },

        footer: {
            type: Boolean,
            default: true
        },

        showUpdate: {
            type: Boolean,
            default: false
        },

        perPage: {
            type: Number,
            default: 20
        },

        hideFilterRow: {
            type: Boolean,
            default: false
        },

        filters: {
            type: Object,
            default: () => { }
        },
    },

    data: function () {
        return {
            isLoading: false,
            generalOptions: {},
        }
    },

    mounted: function () {
        this.refresh();
        // this.$layout.setPageName(capitalize(this.name.plural))
    },

    computed: {
        pagination () {
            return this.filters && this.filters._itemsPerPage ? {
                current: this.filters._page,
                perPage: this.filters._itemsPerPage,
                items: this.filters._itemsReturned
            } : null
        },

        computedFilters: {
            get () {
                return this.filters;
            },
            set (value) {
                this.$emit('updateFilter', {...value, _page: 1})
            }
        },
        allOptions () {
            return {...this.passedOptions, ...this.generalOptions};
        },
        hasSlotFilters: function () { return this.$scopedSlots.filters },
        hasActions: function () { return this.$scopedSlots.actions || this.isEditable || !!this.deleteRow },
        hasHeader: function () { return !!this.$slots.header },
        isEditable: function () { return !!this.$listeners.edit },
        isAddable: function () { return !!this.$listeners.add },
        hasLabel: function () { return !!this.label },

        columnKeys: function () { return this.visibleCols.map(col => col.key) },
        rowTag: function () { return !!this.url ? 'a' : 'div' },

        cols: function () {
            return this.columns.map(column => {
                if (isString(column)) {
                    return {
                        key: column,
                        label: this.getLabel(column),
                        formElement: keyToComponent('Input'),
                        class: '',
                        props: null,
                        visible: true,
                    }
                } else {
                    return {
                        label: this.getLabel(column.key),
                        formElement: column.key !== 'checkable' ? keyToComponent(column.formElement) : null,
                        class: '',
                        props: null,
                        visible: true,
                        ...column
                    }
                }
            })
        },

        visibleCols: function () {
            return this.cols.filter(col => col.visible)
        },

        subrowsVisible: function () {
            return this.rows.filter(row => row.subrowVisible).length === this.rows.length
        },

        showSum: function () {
            return this.cols.filter(col => col.showSum).length > 0;
        },

        optionsAPI: function () {
            return typeof this.loadOptions === 'string'
                ? `/api/${this.loadOptions}`
                : `/api/${this.api}`
        },

        checkedRows: {
            get: function () { return this.value },
            set: function (rows) { this.$emit('input', rows) }
        },

        checkAll: {
            get: function () { return this.row ? this.rows.length == this.value.length : false },
            set: function (value) { this.$emit('input', value ? JSON.parse(JSON.stringify(this.rows)) : []) }
        },
    },

    created: function () {

        if (this.loadOptions) {
            this.fetchOptions()
                .then(options => {
                    this.$emit('loaded', { options })
                })
        }
    },


    methods: {
        updatePagination (pagination) {
            this.$emit('updateFilter', {
                ...this.computedFilters,
                _page: pagination.perPage !== this.computedFilters._itemsPerPage ? 1 : pagination.current,
                _itemsPerPage: pagination.perPage,
            })
        },

        navigatePrevious: function (event,val, value, col) {
            let keyCode = {
                left: 37,
                up: 38,
                right: 39,
                down: 40
            };
            if((event.keyCode === keyCode.up || event.keyCode === keyCode.down) && (['HotelType', 'Multiselect', 'AsyncSelect', 'RequestContact', 'HotelTime'].includes(col.formElement))){
                return;
            }
            let nextItem = value;
            let row = val;
            let sign = '';
            if (Object.values(keyCode).indexOf(event.keyCode) > -1) {
                switch (event.keyCode) {
                    case keyCode.up:
                        row -= 1;
                        break;
                    case keyCode.down:
                        row += 1;
                        break;
                    case keyCode.left:
                        nextItem -= 1;
                        sign = '-';
                        break;
                    case keyCode.right:
                        nextItem += 1;
                        sign = '+';
                        break;
                }
                if (nextItem > this.visibleCols.length - 1 && row < this.rows.length - 1) {
                    row ++;
                    nextItem = 0;

                } else if(nextItem < 1 && row > 0) {
                    row --;
                    nextItem = this.visibleCols.length  - 1;
                }
                if (this.$refs["cell-" + row + "-" + nextItem] && this.$refs["cell-" + row + "-" + nextItem][0]) {
                    if (row == 0 && nextItem == 0) {
                        return ;
                    }
                    let isDisabled = true;
                    while (isDisabled) {
                        while (this.$refs["cell-" + row + "-" + nextItem][0].$children.length < 1 && Object.keys(this.$refs["cell-" + row + "-" + nextItem][0].$refs).length === 0) {
                            if (sign === '-') {
                                nextItem -=1;
                            } else {
                                nextItem +=1;
                            }
                        }
                        isDisabled = false
                    }
                    if (!isDisabled) {
                        this.$refs["cell-" + row + "-" + nextItem][0].focus();
                    }
                }
            }
        },
        get,

        getValue(row, col) {
            let value = get(row, col.key);
            let props = this.evalProps(row,col);
            if(col.formElement === 'Multiselect' && props && props['track-by'] && props.options) {
                return props.options.find(item => item[props['track-by']] === value);
            }
            return value;
        },

        test: function() {
            if(this.$refs['cell-0-0']) {
                this.$refs['cell-0-0'][0].focus()
            }
        },

        calculateSum: function (col) {
            let amount = 0;
            this.rows.forEach(row => {
                if (this.colValue(row, col.key)) {
                    amount += parseFloat(this.colValue(row, col.key));
                }
            })
            return priceView(amount);
        },

        saveRow: function (row, col, value) {
            //api
            let colKey = col.key;
            let colLabel = col.label;

            this.$emit('saveRow', {row, colKey, value, colLabel});

        },

        addRow: function () {
            if (!this.rows.some(row => row.editing)) {
                this.rows.push({
                    editing: true
                })
            }
        },

        toggleRow: function (toggleRow) {
            this.rows = this.rows.map(row => {
                if (row.id === toggleRow.id) {
                    return {
                        ...row,
                        subrowVisible: !row.subrowVisible
                    }
                } else {
                    return row;
                }
            })
        },

        toggleRows: function () {
            this.rows = this.rows.map(row => ({ ...row, subrowVisible: !this.subrowsVisible }))
        },

        cancelEditing: function () {
            this.rows = this.rows
                .filter(row => has(row, 'id'))
                .map(row => {
                    return { ...row, editing: false }
                })
        },

        fetchOptions: function () {
            const self = this;

            return new Promise((resolve, reject) => {
                axios.options(self.optionsAPI)
                    .then(response => {
                        self.generalOptions = { ...response.data };

                        resolve(response.data)
                    }, error => { })
            })
        },

        refresh: function (loadOptions = false) {
            if (this.load) {

                if (this.loadOptions && loadOptions) {
                    this.fetchOptions();
                }
            }
        },

        handleAdd: function () {
            this.$emit('add');
        },

        handleEdit: function (row) {
            this.$emit('edit', row);
        },


        handleDelete: function (item) {
            if (!!this.$listeners.delete) {
                this.$emit('delete', item)
            } else if (confirm(`Bist du sicher dass du das Element löschen willst?`)) {
                this.callDeleteApi(item)
                    .then(data => { // Success
                        this.refresh();
                        notifySuccess('Das Element wurde gelöscht!');

                    }, error => { // Failed
                        notifyError('Das Element konnte nicht gelöscht werden!');
                    });
            }
        },

        callDeleteApi: function (item) {
            return this.softDelete ? axios.put(`/api/${this.api}/${item.id}`, { deleted: true }) : axios.delete(`/api/${this.api}/${item.id}`);
        },

        // Getters
        componentExists: function (component) {
            return !!this.$options.components[component];
        },

        slotExists: function (key) {
            return !!this.$scopedSlots[key]
        },

        colValue: function (row, key) {
            const value = get(row, key);

            if (Array.isArray(value)) {
                return value.map(v => v.name).join(', ');
            }

            return value;
        },

        evalProps: function (row, col) {
            if (!col.evalProps && !col.evalPropsNegative && !col.evalPropsBool && !col.evalPropsOptions) {
                return col.props
            }
            let evalPropsBool = {};
            for (const prop in col.evalPropsBool) {

                if(typeof col.evalPropsBool[prop] === 'object') {
                    evalPropsBool[prop] = {}
                    for (const subProp in col.evalPropsBool[prop]) {
                        evalPropsBool[prop][subProp] = !!this.colValue(row, col.evalPropsBool[prop][subProp]);
                    }

                } else {
                    evalPropsBool[prop] = !!this.colValue(row, col.evalPropsBool[prop]);
                }
            }

            let evalProps = {};
            for (const prop in col.evalProps) {

                if(typeof col.evalProps[prop] === 'object') {
                    evalProps[prop] = {}
                    for (const subProp in col.evalProps[prop]) {
                        evalProps[prop][subProp] = this.colValue(row, col.evalProps[prop][subProp]);
                    }

                } else {
                    evalProps[prop] = this.colValue(row, col.evalProps[prop]);
                }
            }

            let evalPropsNegative = {};
            for (const prop in col.evalPropsNegative) {
                if(typeof col.evalPropsNegative[prop] === 'object') {
                    evalPropsNegative[prop] = {}
                    for (const subProp in col.evalPropsNegative[prop]) {
                        evalPropsNegative[prop][subProp] = !this.colValue(row, col.evalPropsNegative[prop][subProp]);
                    }
                } else {
                    evalPropsNegative[prop] = !this.colValue(row, col.evalPropsNegative[prop]);
                }
            }

            let evalPropsOptions = {};
            for (const prop in col.evalPropsOptions) {

                if(typeof col.evalPropsOptions[prop] === 'object') {
                    evalPropsOptions[prop] = {}
                    for (const subProp in col.evalPropsOptions[prop]) {
                        evalPropsOptions[prop][subProp] = get(this.passedOptions, col.evalPropsOptions[prop][subProp]) || [];
                    }
                } else {
                    evalPropsOptions[prop] = get(this.passedOptions, col.evalPropsOptions[prop]) || [];
                }
            }

            return { ...col.props, ...evalProps, ...evalPropsNegative, ...evalPropsBool, ...evalPropsOptions };

        },

        getLabel: function (key) {
            if (has(TABLE_LABELS, key)) {
                return TABLE_LABELS[key]
            } else {
                return key;
            }
        },

        rowURL: function (row) {
            if (this.url) {
                if (typeof this.url === 'function') {
                    return this.url(row);
                } else {
                    return `${this.url}/${row.id}`
                }

            } else {
                return '#'
            }
        },

        setLoading: function (value) {
            this.isLoading = value
        }
    },



    filters: {
        capitalize,
        stripHtml
    }
}
</script>
