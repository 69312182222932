<template>
    <div class="tab-addition is-small">
        <div class="tab-addition__header">
            <header>
                <button
                    v-on:click.prevent="activeTab = 'first'"
                    v-bind:class="{'is-active': activeTab === 'first'}"
                >Soll-Kalkulation</button>
                <button
                    v-if="false"
                    v-on:click.prevent="activeTab = 'second'"
                    v-bind:class="{'is-active': activeTab === 'second'}"
                >Ist-Kalkulation</button>
            </header>
        </div>

        <div class="main is-relative">
            <loading v-if="isLoading" />
            <div class="loading-container" v-else-if="isSaving">
                <loading textInFront="Die Eingaben werden gespeichert ..."/>
            </div>

            <div v-if="!isLoading">
                <div class="is-flex" style="place-content: space-between">
                    <toggle-switch v-model="startView">
                        <option value="table">Tabellenansicht</option>
                        <option value="day">Tagesansicht</option>
                    </toggle-switch>
                    <button class="button is-primary" v-on:click.prevent="saveData">Kalkulation speichern</button>
                </div>

                <loaded-search v-on:submit="handleSearch"/>
                <included-prices />
                <selling-prices />

                <div class="level-right">
                    <button class="level-item button is-primary" v-on:click.prevent="saveData">Kalkulation speichern</button>
                </div>
            </div>

            <div v-if="modal && modal.component !== 'route-detail'" class="day">
                <component
                    v-bind:is="`modal-${modal.component}-placeholder`"
                    v-bind:isModalVisible="modal !== null"
                    :passed-options="passedOptions"
                    v-bind:initialValue="modal.placeholder"
                    v-on:closeModal="handleCloseModal"
                />
            </div>
            <div v-if="modalCRUD">
                <component
                    v-bind:is="`modal-${modalCRUD.component}-CRUD`"
                    v-bind:initialValue="modalCRUD"
                    providerType="calculation"
                    v-on:closeModal="handleCloseModalCRUD"
                    v-on:submit="handleCloseModalCRUD"
                />
            </div>
        </div>
    </div>
</template>


<script>
import Loading from '@components/Loading';

import ModalHotelPlaceholder   from '@orders/placeholders/modal/Hotel';
import ModalFerryPlaceholder   from '@orders/placeholders/modal/Ferry';
import ModalTrainPlaceholder from '@orders/placeholders/modal/Train';
import ModalAirlinePlaceholder from '@orders/placeholders/modal/Airline';
import ModalOtherPlaceholder   from '@orders/placeholders/modal/OtherServices';
import ModalHotelCRUD from '@components/CRUD/Item/Tabs/ProviderPrices/Forms/HotelEditTable.vue';
import ModalFerryCRUD from '@components/CRUD/Item/Tabs/ProviderPrices/Forms/FerryEditTable.vue';
import ModalOtherCRUD from '@components/CRUD/Item/Tabs/ProviderPrices/Forms/OtherEditTable';
import ModalTrainCRUD from '@components/CRUD/Item/Tabs/ProviderPrices/Forms/Train';
import {settings} from "@clientSpecific/utilities/settings";



import { prepareForBackend, fullYear } from '@utilities/functions'

import IncludedPrices from './IncludedPrices';
import SellingPrices from './SellingPrices';
import { LoadedSearch } from './components';
import store from './store';
import omit from "lodash/omit";
import ToggleSwitch from "@components/form/ToggleSwitch";
import {getAxios} from "@api";

export default {
    store,

    props: {
        passedOptions: {},
    },

    data: function () {
        return {
            activeTab: 'first',
            shouldSave: false,
            drawerAction: false,
        }
    },


    computed: {
        startView: {
            get () {
                return this.$store.state.startView;
            },
            set (value) {
                this.$store.state.startView = value;
            }
        },

        isSaving: function () {
            return this.$store.state.isSaving && !this.drawerAction;
        },

        isLoading: function () {
            return this.$store.state.isLoading && !this.drawerAction;
        },

        calculationJSON: function () {
            return JSON.stringify(prepareForBackend(omit(this.$store.state, ['shouldSave','isLoading', 'isSaving'])));
        },

        modal: {
            get: function() {
                return this.$store.state.modalPlaceholder;
            },

            set: function(value) {
                this.$store.commit('updateModalPlaceholder', value);
            }
        },

        modalCRUD: {
            get: function() {
                return this.$store.state.modalCRUD;
            },

            set: function(value) {
                this.$store.commit('updateModalCRUD', value);
            }
        }

    },


    methods: {
        saveData: function () {
            this.$store.dispatch('saveCalculation')
                .then(calculation => this.$emit('update', calculation))
        },

        handleSearch: function (params) {
            this.$store.dispatch('fetchLoadedPrices', params);
        },

        handleCloseModal: function({_saved}) {
            if (_saved) {
                this.$store.commit('updateLoading', true)
                this.$store.dispatch('loadCalculation')
            }
            this.modal = null;
        },

        handleCloseModalCRUD: function() {
            let current = JSON.parse(JSON.stringify(this.modalCRUD));
            let typePlural = current.price._type === 'ferry' ? 'ferries' : current.price._type + 's';

            //update price
            getAxios('calculation_price_' + typePlural + '/' + current.price.id, {
                '_groups': ['calculation:providerPrices'],
            }).then(response => {
                this.$store.commit('updatePrice', {
                    [current.price._type + 'Prices']: {
                        id: current.price.id,
                        providerPrices: response.data.providerPrices
                    }
                });
                this.modalCRUD = null;

            })
        }
    },


    mounted: function () {
        const self = this;
        window.addEventListener('beforeunload', function (e) {
            if (!self.$store.state.shouldSave) {
                return undefined;
            }

            var confirmationMessage = `It looks like you have been editing something.	            return true
                                       If you leave before saving, your changes will be lost.`;

            (e || window.event).returnValue = confirmationMessage; //Gecko + IE
            return confirmationMessage; //Gecko + Webkit, Safari, Chrome etc.
        })
    },


    watch: {
        calculationJSON: function () {
            if (!this.isLoading) {
                this.$store.commit('updateShouldSave', true)
            }
        },


        isSaving: function (isSaving) {
            if (!isSaving) {
                this.$store.commit('updateShouldSave', false)
            }
        },
    },


    components: {
        ToggleSwitch,
        IncludedPrices,
        SellingPrices,
        Loading,
        LoadedSearch,

        ModalHotelPlaceholder,
        ModalFerryPlaceholder,
        ModalAirlinePlaceholder,
        ModalOtherPlaceholder,
        ModalTrainPlaceholder,

        ModalHotelCRUD,
        ModalTrainCRUD,
        ModalFerryCRUD,
        ModalOtherCRUD,
    }
}
</script>
