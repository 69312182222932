var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "modal-form",
    {
      staticClass: "is-large",
      attrs: {
        editTitle: "Hotelpreis bearbeiten",
        createTitle: "Neuen Hotelpreis hinzufügen",
        "is-loading": _vm.isLoading,
      },
    },
    [
      _c(
        "table",
        [
          _vm.initialValue
            ? [
                _c("tr", [
                  _c("th", [
                    _c("label", { staticClass: "label" }, [_vm._v("Preis:")]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "td",
                    [
                      _c(
                        "toggle-switch",
                        {
                          model: {
                            value: _vm.priceType,
                            callback: function ($$v) {
                              _vm.priceType = $$v
                            },
                            expression: "priceType",
                          },
                        },
                        [
                          _c("option", { attrs: { value: "calculation" } }, [
                            _vm._v("Kalkulation"),
                          ]),
                          _vm._v(" "),
                          _c("option", { attrs: { value: "provider" } }, [
                            _vm._v("Anbieter"),
                          ]),
                          _vm._v(" "),
                          _c("option", { attrs: { value: "agency" } }, [
                            _vm._v("Agentur"),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _vm.priceType === "agency"
                  ? _c("tr", [
                      _c("th", [
                        _c("label", { staticClass: "label" }, [
                          _vm._v("Agentur:"),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c(
                        "td",
                        [
                          _c("async-select", {
                            attrs: { api: "agencies" },
                            model: {
                              value: _vm.agency,
                              callback: function ($$v) {
                                _vm.agency = $$v
                              },
                              expression: "agency",
                            },
                          }),
                        ],
                        1
                      ),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _vm.priceType === "provider" || _vm.priceType === "agency"
                  ? _c("tr", [
                      _c("th", [
                        _c("label", { staticClass: "label" }, [
                          _vm._v("Hotel:"),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c(
                        "td",
                        [
                          _c("async-select", {
                            attrs: { api: "hotels" },
                            model: {
                              value: _vm.provider,
                              callback: function ($$v) {
                                _vm.provider = $$v
                              },
                              expression: "provider",
                            },
                          }),
                        ],
                        1
                      ),
                    ])
                  : _vm._e(),
              ]
            : _vm._e(),
          _vm._v(" "),
          _vm.providerType === "provider"
            ? _c("tr", [
                _c("th", [
                  _c("label", { staticClass: "label" }, [_vm._v("Art:")]),
                ]),
                _vm._v(" "),
                _c(
                  "td",
                  [
                    _c(
                      "toggle-switch",
                      {
                        model: {
                          value: _vm.type,
                          callback: function ($$v) {
                            _vm.type = $$v
                          },
                          expression: "type",
                        },
                      },
                      [
                        _c("option", { attrs: { value: "direct" } }, [
                          _vm._v("Direktpreis"),
                        ]),
                        _vm._v(" "),
                        _c("option", { attrs: { value: "agency" } }, [
                          _vm._v("Preis von Agentur"),
                        ]),
                      ]
                    ),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.providerType === "agency"
            ? _c("tr", [
                _c("th", [
                  _c("label", { staticClass: "label" }, [_vm._v("Art:")]),
                ]),
                _vm._v(" "),
                _c(
                  "td",
                  [
                    _c(
                      "toggle-switch",
                      {
                        model: {
                          value: _vm.type,
                          callback: function ($$v) {
                            _vm.type = $$v
                          },
                          expression: "type",
                        },
                      },
                      [
                        _c("option", { attrs: { value: "direct" } }, [
                          _vm._v("No-Name Preis"),
                        ]),
                        _vm._v(" "),
                        _c("option", { attrs: { value: "hotel" } }, [
                          _vm._v("Preis für ein bestimmtes Hotel"),
                        ]),
                      ]
                    ),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.type === "agency"
            ? _c("tr", [
                _c("th", [
                  _c("label", { staticClass: "label" }, [_vm._v("Agentur:")]),
                ]),
                _vm._v(" "),
                _c(
                  "td",
                  [
                    _c("async-select", {
                      attrs: { api: "agencies" },
                      model: {
                        value: _vm.agency,
                        callback: function ($$v) {
                          _vm.agency = $$v
                        },
                        expression: "agency",
                      },
                    }),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.type === "hotel"
            ? _c("tr", [
                _c("th", [
                  _c("label", { staticClass: "label" }, [_vm._v("Hotel:")]),
                ]),
                _vm._v(" "),
                _c(
                  "td",
                  [
                    _c("async-select", {
                      attrs: { api: "hotels" },
                      model: {
                        value: _vm.provider,
                        callback: function ($$v) {
                          _vm.provider = $$v
                        },
                        expression: "provider",
                      },
                    }),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          (_vm.type === "direct" && _vm.providerType === "agency") ||
          !_vm.includeProvider ||
          (_vm.priceType === "agency" && !_vm.provider)
            ? _c("tr", [
                _c("th", [
                  _c("label", { staticClass: "label" }, [
                    _vm._v("Hotelkategorie:"),
                  ]),
                ]),
                _vm._v(" "),
                _c(
                  "td",
                  [
                    _vm.options.hotelTypes
                      ? _c("multiselect", {
                          attrs: {
                            "track-by": "name",
                            label: "name",
                            options: _vm.options.hotelTypes,
                          },
                          model: {
                            value: _vm.hotelType,
                            callback: function ($$v) {
                              _vm.hotelType = $$v
                            },
                            expression: "hotelType",
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          (_vm.type === "direct" && _vm.providerType === "agency") ||
          !_vm.includeProvider ||
          (_vm.priceType === "agency" && !_vm.provider)
            ? _c("tr", [
                _c("th", [
                  _c("label", { staticClass: "label" }, [_vm._v("Region:")]),
                ]),
                _vm._v(" "),
                _c(
                  "td",
                  [
                    _c("area-select", {
                      model: {
                        value: _vm.form.area,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "area", $$v)
                        },
                        expression: "form.area",
                      },
                    }),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("tr", [
            _c("td", { attrs: { colspan: "2" } }, [
              _c("h2", [
                _vm._v("Preise "),
                _c(
                  "i",
                  {
                    staticClass: "material-icons",
                    on: { click: _vm.addPrice },
                  },
                  [_vm._v("add_circle")]
                ),
              ]),
            ]),
          ]),
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "div",
        [
          _c("edit-table", {
            ref: "table",
            attrs: {
              filters: _vm.filter,
              rows: _vm.rows,
              "load-options": false,
              footer: false,
              "passed-options": _vm.options,
              columns: _vm.columns,
            },
            on: {
              delete: _vm.deletePeriod,
              updateFilter: (value) => (_vm.filter = value),
              saveRow: _vm.saveRow,
            },
            scopedSlots: _vm._u([
              {
                key: "days",
                fn: function (slotProps) {
                  return [
                    _c(
                      "div",
                      [
                        _c("days", {
                          attrs: {
                            picker: true,
                            "is-in-table": true,
                            value: _vm.getWeekdays(slotProps.row),
                          },
                          on: {
                            input: (input) =>
                              _vm.setWeekDays(input, slotProps.row),
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                },
              },
              {
                key: "linkedGroup",
                fn: function (slotProps) {
                  return [
                    slotProps.row.priceGroup.isNew
                      ? _c(
                          "div",
                          { staticStyle: { "margin-left": "30px" } },
                          [
                            _c("multiselect", {
                              attrs: {
                                "allow-empty": false,
                                value: slotProps.row.priceGroup.id,
                                options: _vm.getNewPriceGroups,
                              },
                              on: {
                                input: (input) =>
                                  _vm.changePriceGroup(slotProps.row, input),
                              },
                            }),
                          ],
                          1
                        )
                      : _c(
                          "div",
                          {
                            staticStyle: {
                              height: "100%",
                              width: "100%",
                              "align-content": "center",
                              "text-align": "center",
                            },
                            style: _vm.getBackground(slotProps.row),
                          },
                          [
                            _vm._v(
                              "\n                    " +
                                _vm._s(slotProps.row.priceGroup.id) +
                                "\n                "
                            ),
                          ]
                        ),
                  ]
                },
              },
            ]),
          }),
          _vm._v(" "),
          _c("pagination", {
            attrs: {
              name: { singluar: "Preisgruppe", plural: "Preisgruppen" },
            },
            on: { input: _vm.getPrices },
            model: {
              value: _vm.pagination,
              callback: function ($$v) {
                _vm.pagination = $$v
              },
              expression: "pagination",
            },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }