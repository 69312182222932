var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "new-email-modal-form" },
    [
      _vm.showRequestSelect &&
      _vm.emailType === "provider" &&
      _vm.providerSelected
        ? _c("h3", [
            _vm._v("EMAIL " + _vm._s(_vm.providerSelected.provider.name)),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.showRequestSelect &&
      _vm.emailType === "provider" &&
      _vm.providerSelected &&
      _vm.providerSelected.requests
        ? [
            _c("requests-select", {
              attrs: {
                requests: _vm.providerSelected.requests,
                options: _vm.requestOptions,
              },
            }),
          ]
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "buttons-wrapper is-flex is-justify-content-right" },
        [
          _c("div", { staticClass: "is-flex" }, [
            _c(
              "button",
              {
                staticClass: "button is-large",
                class: { "is-loading": _vm.isLoading.draft },
                staticStyle: { margin: "10px" },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    $event.stopPropagation()
                    return _vm.saveDraft.apply(null, arguments)
                  },
                },
              },
              [_vm._v("Outlook Entwurf erstellen")]
            ),
            _vm._v(" "),
            _c("div", [
              _c(
                "button",
                {
                  staticClass: "button is-primary is-large mr-0",
                  class: { "is-loading": _vm.isLoading.send },
                  staticStyle: { margin: "10px" },
                  on: {
                    click: function ($event) {
                      return _vm.send("close")
                    },
                  },
                },
                [
                  _c("i", { staticClass: "material-icons" }, [_vm._v("check")]),
                  _vm._v(" Email senden"),
                ]
              ),
            ]),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "input-wrapper" },
        [
          _c("label", [_vm._v("Von:")]),
          _vm._v(" "),
          _c("email-select", {
            on: {
              input: function ($event) {
                return _vm.$store.dispatch("fillEmailBody")
              },
            },
            model: {
              value: _vm.newEmail.fromEmail,
              callback: function ($$v) {
                _vm.$set(_vm.newEmail, "fromEmail", $$v)
              },
              expression: "newEmail.fromEmail",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "input-wrapper" },
        [
          _c("label", [_vm._v("An:")]),
          _vm._v(" "),
          _c(
            "multiselect",
            {
              attrs: {
                toBeCopied: true,
                options: _vm.toContacts,
                multiple: true,
                taggable: true,
                loading: _vm.isLoading.toContacts,
                "internal-search": false,
                label: "email",
                "track-by": "email",
                placeholder: "",
              },
              on: {
                tag: (item) => _vm.addContact(item, "to"),
                open: function ($event) {
                  return _vm.getContacts("", "to")
                },
                "search-change": (search) => _vm.getContacts(search, "to"),
                close: function ($event) {
                  return _vm.tryContactPreselect("to")
                },
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "option",
                    fn: function (props) {
                      return props.option.firstName ||
                        props.option.email ||
                        props.option.lastName
                        ? [
                            props.option.firstName
                              ? _c("span", { staticClass: "option__name" }, [
                                  _c("i", { staticClass: "material-icons" }, [
                                    _vm._v("perm_identity"),
                                  ]),
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        props.option.firstName +
                                          " " +
                                          props.option.lastName +
                                          " - " +
                                          props.option.email
                                      )
                                  ),
                                ])
                              : _c("span", { staticClass: "option__name" }, [
                                  _c("i", { staticClass: "material-icons" }, [
                                    _vm._v("perm_identity"),
                                  ]),
                                  _vm._v(" " + _vm._s(props.option.email)),
                                ]),
                            _vm._v(" "),
                            _c(
                              "span",
                              { staticClass: "option__type" },
                              [
                                props.client
                                  ? [
                                      _c(
                                        "i",
                                        { staticClass: "material-icons" },
                                        [_vm._v("business")]
                                      ),
                                      _vm._v(
                                        " Company: " +
                                          _vm._s(
                                            props.option.client.companyName
                                          )
                                      ),
                                    ]
                                  : _vm._e(),
                              ],
                              2
                            ),
                          ]
                        : undefined
                    },
                  },
                ],
                null,
                true
              ),
              model: {
                value: _vm.newEmail.toAddresses,
                callback: function ($$v) {
                  _vm.$set(_vm.newEmail, "toAddresses", $$v)
                },
                expression: "newEmail.toAddresses",
              },
            },
            [
              _vm._v(" "),
              _vm.toContacts.length === 0
                ? _c("span", { attrs: { slot: "caret" }, slot: "caret" })
                : _vm._e(),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "input-wrapper" },
        [
          _c("label", [_vm._v("CC:")]),
          _vm._v(" "),
          _c(
            "multiselect",
            {
              attrs: {
                options: _vm.ccContacts,
                multiple: true,
                taggable: true,
                loading: _vm.isLoading.ccContacts,
                "internal-search": false,
                label: "email",
                "track-by": "email",
                placeholder: "",
              },
              on: {
                tag: (item) => _vm.addContact(item, "cc"),
                open: function ($event) {
                  return _vm.getContacts("", "cc")
                },
                "search-change": (search) => _vm.getContacts(search, "cc"),
                close: function ($event) {
                  return _vm.tryContactPreselect("cc")
                },
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "option",
                    fn: function (props) {
                      return props.option.firstName ||
                        props.option.email ||
                        props.option.lastName
                        ? [
                            props.option.firstName
                              ? _c("span", { staticClass: "option__name" }, [
                                  _c("i", { staticClass: "material-icons" }, [
                                    _vm._v("perm_identity"),
                                  ]),
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        props.option.firstName +
                                          " " +
                                          props.option.lastName +
                                          " - " +
                                          props.option.email
                                      )
                                  ),
                                ])
                              : _c("span", { staticClass: "option__name" }, [
                                  _c("i", { staticClass: "material-icons" }, [
                                    _vm._v("perm_identity"),
                                  ]),
                                  _vm._v(_vm._s(props.option.email)),
                                ]),
                            _vm._v(" "),
                            _c(
                              "span",
                              { staticClass: "option__type" },
                              [
                                props.client
                                  ? [
                                      _c(
                                        "i",
                                        { staticClass: "material-icons" },
                                        [_vm._v("business")]
                                      ),
                                      _vm._v(
                                        " Company: " +
                                          _vm._s(
                                            props.option.client.companyName
                                          )
                                      ),
                                    ]
                                  : _vm._e(),
                              ],
                              2
                            ),
                          ]
                        : undefined
                    },
                  },
                ],
                null,
                true
              ),
              model: {
                value: _vm.newEmail.ccAddresses,
                callback: function ($$v) {
                  _vm.$set(_vm.newEmail, "ccAddresses", $$v)
                },
                expression: "newEmail.ccAddresses",
              },
            },
            [
              _vm._v(" "),
              _vm.ccContacts.length === 0
                ? _c("span", { attrs: { slot: "caret" }, slot: "caret" })
                : _vm._e(),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "input-wrapper" },
        [
          _c("label", [_vm._v("BCC:")]),
          _vm._v(" "),
          _c(
            "multiselect",
            {
              attrs: {
                options: _vm.bccContacts,
                multiple: true,
                taggable: true,
                loading: _vm.isLoading.bccContacts,
                "internal-search": false,
                label: "email",
                "track-by": "email",
                placeholder: "",
              },
              on: {
                tag: (item) => _vm.addContact(item, "bcc"),
                open: function ($event) {
                  return _vm.getContacts("", "bcc")
                },
                "search-change": (search) => _vm.getContacts(search, "bcc"),
                close: function ($event) {
                  return _vm.tryContactPreselect("bcc")
                },
              },
              scopedSlots: _vm._u(
                [
                  {
                    key: "option",
                    fn: function (props) {
                      return props.option.firstName ||
                        props.option.email ||
                        props.option.lastName
                        ? [
                            props.option.firstName
                              ? _c("span", { staticClass: "option__name" }, [
                                  _c("i", { staticClass: "material-icons" }, [
                                    _vm._v("perm_identity"),
                                  ]),
                                  _vm._v(
                                    " " +
                                      _vm._s(
                                        props.option.firstName +
                                          " " +
                                          props.option.lastName +
                                          " - " +
                                          props.option.email
                                      )
                                  ),
                                ])
                              : _c("span", { staticClass: "option__name" }, [
                                  _c("i", { staticClass: "material-icons" }, [
                                    _vm._v("perm_identity"),
                                  ]),
                                  _vm._v(_vm._s(props.option.email)),
                                ]),
                            _vm._v(" "),
                            _c(
                              "span",
                              { staticClass: "option__type" },
                              [
                                props.client
                                  ? [
                                      _c(
                                        "i",
                                        { staticClass: "material-icons" },
                                        [_vm._v("business")]
                                      ),
                                      _vm._v(
                                        " Company: " +
                                          _vm._s(
                                            props.option.client.companyName
                                          )
                                      ),
                                    ]
                                  : _vm._e(),
                              ],
                              2
                            ),
                          ]
                        : undefined
                    },
                  },
                ],
                null,
                true
              ),
              model: {
                value: _vm.newEmail.bccAddresses,
                callback: function ($$v) {
                  _vm.$set(_vm.newEmail, "bccAddresses", $$v)
                },
                expression: "newEmail.bccAddresses",
              },
            },
            [
              _vm._v(" "),
              _vm.bccContacts.length === 0
                ? _c("span", { attrs: { slot: "caret" }, slot: "caret" })
                : _vm._e(),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "input-wrapper" }, [
        _c("label", [_vm._v("Vorlage:")]),
        _vm._v(" "),
        _c(
          "div",
          [
            _c(
              "div",
              {
                staticClass: "column is-2 has-text-right is-flex pb-0",
                staticStyle: { "margin-top": "-9px" },
              },
              [
                _vm._l(["en", "fr", "de_DE"], function (item) {
                  return [
                    _c(
                      "button",
                      {
                        on: {
                          click: function ($event) {
                            return _vm.changeLocale(item)
                          },
                        },
                      },
                      [
                        item
                          ? _c("gb-flag", {
                              class: { "is-selected": item === _vm.locale },
                              attrs: {
                                code: _vm.getFlag(item),
                                size: "small",
                                iconPath: "/assets/img/flags/",
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]
                }),
              ],
              2
            ),
            _vm._v(" "),
            _c("email-template-select", {
              attrs: { "templates-filtered": _vm.templates },
              model: {
                value: _vm.templateSelected,
                callback: function ($$v) {
                  _vm.templateSelected = $$v
                },
                expression: "templateSelected",
              },
            }),
            _vm._v(" "),
            _vm.templateSelected && _vm.templateSelected.variants.length > 0
              ? _c("multiselect", {
                  attrs: {
                    options: _vm.templateSelected.variants,
                    label: "name",
                    "track-by": "id",
                    "max-height": 600,
                  },
                  model: {
                    value: _vm.variantSelected,
                    callback: function ($$v) {
                      _vm.variantSelected = $$v
                    },
                    expression: "variantSelected",
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.settings.emails.isFormal[_vm.emailType]
              ? _c(
                  "toggle-switch",
                  {
                    staticClass: "mb-0",
                    staticStyle: { "margin-right": "10px" },
                    attrs: { "btn-style": "height: 35px" },
                    model: {
                      value: _vm.isFormal,
                      callback: function ($$v) {
                        _vm.isFormal = $$v
                      },
                      expression: "isFormal",
                    },
                  },
                  [
                    _c("option", { domProps: { value: false } }, [
                      _vm._v("Du"),
                    ]),
                    _vm._v(" "),
                    _c("option", { domProps: { value: true } }, [
                      _vm._v("Sie"),
                    ]),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "button is-large is-icon",
                staticStyle: { height: "35px" },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    $event.stopPropagation()
                    return _vm.fillEmail.apply(null, arguments)
                  },
                },
              },
              [_c("i", { staticClass: "material-icons" }, [_vm._v("loop")])]
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "input-wrapper" }, [
        _c("label", [_vm._v("Betreff:")]),
        _vm._v(" "),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.newEmail.subject,
              expression: "newEmail.subject",
            },
          ],
          staticClass: "input",
          attrs: { type: "text" },
          domProps: { value: _vm.newEmail.subject },
          on: {
            input: function ($event) {
              if ($event.target.composing) return
              _vm.$set(_vm.newEmail, "subject", $event.target.value)
            },
          },
        }),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "input-wrapper" },
        [
          _c("label", [_vm._v("Beschreibung:")]),
          _vm._v(" "),
          _c("tag-select", {
            attrs: { "option-tags": _vm.optionTags, "my-class": "is-medium" },
            model: {
              value: _vm.tags,
              callback: function ($$v) {
                _vm.tags = $$v
              },
              expression: "tags",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _vm.emailType === "provider" &&
      _vm.providerSelected &&
      _vm.providerSelected.requests &&
      _vm.requestOptions &&
      _vm.requestOptions.statuses
        ? _c(
            "div",
            { staticClass: "input-wrapper" },
            [
              _c("label", [_vm._v("Status ändern:")]),
              _vm._v(" "),
              _c("multiselect", {
                attrs: {
                  placeholder: "nicht ändern",
                  options: _vm.requestOptions.statuses,
                  label: "value",
                  "track-by": "key",
                },
                model: {
                  value: _vm.status,
                  callback: function ($$v) {
                    _vm.status = $$v
                  },
                  expression: "status",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "input-wrapper" },
        [
          _c("label", [_vm._v("Neue Aufgabe:")]),
          _vm._v(" "),
          _c("input-date", {
            model: {
              value: _vm.newEmail.todoDate,
              callback: function ($$v) {
                _vm.$set(_vm.newEmail, "todoDate", $$v)
              },
              expression: "newEmail.todoDate",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "attachments" },
        [
          _c("label", [_vm._v("Anhänge:")]),
          _vm._v(" "),
          _c("attachments", {
            attrs: { ordersSelected: _vm.ordersSelected },
            model: {
              value: _vm.newEmail._files,
              callback: function ($$v) {
                _vm.$set(_vm.newEmail, "_files", $$v)
              },
              expression: "newEmail._files",
            },
          }),
          _vm._v(" "),
          _c("br"),
        ],
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "pell-wrapper" }, [
        _c("label", [_vm._v("Text:")]),
        _vm._v(" "),
        _c(
          "div",
          { staticStyle: { "z-index": "0" } },
          [
            _vm.isLoadingPreview ? _c("loading") : _vm._e(),
            _vm._v(" "),
            _c("div", [
              _c(
                "div",
                [
                  _c("tiny-mce", {
                    on: { keyup: _vm.registerKeyUp },
                    model: {
                      value: _vm.newEmail.textHtml,
                      callback: function ($$v) {
                        _vm.$set(_vm.newEmail, "textHtml", $$v)
                      },
                      expression: "newEmail.textHtml",
                    },
                  }),
                ],
                1
              ),
            ]),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "input-wrapper" }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "buttons-wrapper is-flex is-justify-content-right" },
        [
          _c("div", { staticClass: "is-flex" }, [
            _c(
              "button",
              {
                staticClass: "button is-large",
                class: { "is-loading": _vm.isLoading.draft },
                staticStyle: { margin: "10px" },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    $event.stopPropagation()
                    return _vm.saveDraft.apply(null, arguments)
                  },
                },
              },
              [_vm._v("Outlook Entwurf erstellen")]
            ),
            _vm._v(" "),
            _c("div", [
              _c(
                "button",
                {
                  staticClass: "button is-primary is-large mr-0",
                  class: { "is-loading": _vm.isLoading.send },
                  staticStyle: { margin: "10px" },
                  on: {
                    click: function ($event) {
                      return _vm.send("close")
                    },
                  },
                },
                [
                  _c("i", { staticClass: "material-icons" }, [_vm._v("check")]),
                  _vm._v(" Email senden"),
                ]
              ),
            ]),
          ]),
        ]
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }