<template>
    <div class="new-request">
        <div class="overall-buttons">
            <button class="button is-large" v-on:click="$emit('close')"><i aria-hidden="true" class="fa fa-long-arrow-left"></i> Zurück zur Übersicht</button>
        </div>


        <accordion v-bind:isCollapsible="false">
            <div slot="header">
                <div>
                    <request-score v-if="newRequest.hotel" v-bind:rating="newRequest.hotel.buspaketRating" />
                    <i aria-hidden="true" class="fa fa-plus-circle" v-else></i>
                </div>

                <div>
                    LEISTUNGSTRÄGER HINZUFÜGEN

                    <provider-info class="header-extra-info" v-bind:provider="newRequest.hotel">
                        <div>
                            <i class="material-icons spacing-left">perm_identity</i> Kundenkontingent:
                            <i class="material-icons no-spacing">local_hotel</i>
                            <i class="material-icons">local_hotel</i>
                            {{ hotelContingent.info ? hotelContingent.info.doubleRooms.totalAsked : 0 }} Doppelzimmer
                        </div>
                        <div>
                            <i class="material-icons">local_hotel</i>
                            {{ hotelContingent.info ? hotelContingent.info.singleRooms.totalAsked : 9 }} Einzelzimmer
                        </div>
                        <div>
                            <i class="material-icons no-spacing">local_hotel</i>
                            <i class="material-icons no-spacing">local_hotel</i>
                            <i class="material-icons">local_hotel</i>
                            {{ hotelContingent.info ? hotelContingent.info.otherRooms.totalAsked : 0}} Mehrbettzimmer
                        </div>
                    </provider-info>
                </div>

                <div>
                    <label v-on:click.stop>
                        <input type="checkbox" v-model="isMainRequest" />
                        als Leistung festlegen
                    </label>
                </div>
            </div>


            <div slot="content">
                <div class="bp-box is-info">

                    <main>
                        <button
                            v-bind:class="['btn-single-toggle',  onlyFromOrder ? '' : 'is-active']"
                            v-on:click="onlyFromOrder = !onlyFromOrder"
                            v-bind:title="onlyFromOrder ? 'Suche ist auf obigen Ort eingeschränkt' : 'Suche ist nicht eingeschränkt'"
                            style="border: 1px solid #dddddd; margin-right: 5px; min-width: 22px; min-height: 22px;"
                        ><i class="material-icons" style="margin: 0">flag</i></button>

                        <hotel-select
                            v-model                = "newRequest.hotel"
                            v-bind:placeholderInfo = "onlyFromOrder ? localPlaceholderInfo : {area:null, place:null}"
                            v-bind:isTriggerSearch = "true"
                            :show-icon="false"
                            multi-select-style="width: 400px"
                        ></hotel-select>

                        <agency-select
                            v-bind:provider = "newRequest.hotel"
                            text            = "Agentur"
                            v-on:update     = "agency => newRequest.agency = agency"
                            prefill
                        ></agency-select>

                    </main>
                </div>


                <div class="columns">
                    <div class="column">
                        <simple-hotel-contingent
                            v-if="newRequest.contingent"
                            v-model="newRequest.contingent.doubleRooms"
                            type="hotel"
                            v-bind:nrOfPersons="2"
                            v-bind:types="hotelTypes"
                            v-bind:isOnlyAsked="true"
                        ></simple-hotel-contingent>
                    </div>

                    <div class="column">
                        <simple-hotel-contingent
                            v-if="newRequest.contingent"
                            v-model="newRequest.contingent.singleRooms"
                            type="hotel"
                            v-bind:nrOfPersons="1"
                            v-bind:types="hotelTypes"
                            v-bind:isOnlyAsked="true"
                        ></simple-hotel-contingent>
                    </div>

                    <div class="column">
                        <hotel-contingent
                            v-if="newRequest.contingent"
                            v-model="newRequest.contingent.otherRooms"
                            type="hotel"
                            v-bind:types="hotelTypes"
                            v-bind:isOnlyAsked="true"
                        ></hotel-contingent>
                    </div>
                </div>


                <div class="columns">
                    <div class="column is-offset-6">
                        <item-prices
                            v-bind:isWithoutPrice="true"
                            v-model="newRequest.itemPrices"
                            v-bind:provider="provider"
                        ></item-prices>
                    </div>
                </div>
            </div>
        </accordion>


        <button class="button is-large" v-on:click="$emit('close')"><i class="material-icons">close</i> Abbrechen</button>

        <button
            class="button is-primary is-large"
            v-bind:class="{ 'is-loading': isLoading }"
            v-on:click="saveNewRequest"
        ><i class="material-icons">check</i> speichern und zur Email</button>

        <button
            class="button is-primary is-large"
            v-bind:class="{ 'is-loading': isLoading }"
            v-on:click="saveNewRequest('close')"
        ><i class="material-icons">keyboard_arrow_right</i> speichern und schließen</button>


        <confirmation
            v-bind:isVisible="isVisibleConfirmation"
            is-local
            text="Soll die Beschreibung der Anfrage in den Platzhalter kopiert werden?"
            v-on:input="value => $emit('confirm', value)"
        ></confirmation>
    </div>
</template>



<script>
import mixins                from './mixins';
import { prepareForBackend } from '@utilities/functions';
import { notifyError }       from '@components/Notification';
import LocationSelect        from '@components/form/LocationSelect';
import Board                 from '@orders/placeholders/components/forms/HotelBoard';
import HotelSelect           from '@orders/placeholders/components/forms/HotelSelect';
import HotelContingent       from '../components/contingent/Hotel';
import SimpleHotelContingent from '../components/contingent/SimpleHotel';


export default {
    mixins: [mixins],

    computed: {
        hotelContingent: function () { return this.$store.state.hotelContingent; },
        hotelTypes:      function () { return this.$store.state.optionsRoomTypes; },
        provider:        function () { return this.newRequest.hotel ? this.newRequest.hotel : null; }
    },


    data: function () {
        return {
            apiEndpoint: 'hotel_requests',
            localPlaceholderInfo: JSON.parse(JSON.stringify(this.placeholderInfo))
        };
    },


    methods: {
        saveNewRequest: function (close = false) {
            if (this.newRequest.hotel === null && this.newRequest.agency === null) {
                notifyError('Sie müssen einen Anbieter oder eine Agentur auswählen!');
                return false;
            }


            let copyNewRequest = JSON.parse(JSON.stringify(this.newRequest));

            // Transform 'kein Provider' => null OR hotel to its reference
            copyNewRequest.hotel = copyNewRequest.hotel && copyNewRequest.hotel.id ? '/api/hotels/' + copyNewRequest.hotel.id : null;

            // Preparing for backend use
            copyNewRequest = prepareForBackend(copyNewRequest);

            // Remove contingent reference and id from rooms
            for (let room of copyNewRequest.contingent.doubleRooms) { delete room.id; delete room.contingent; }
            for (let room of copyNewRequest.contingent.singleRooms) { delete room.id; delete room.contingent; }
            for (let room of copyNewRequest.contingent.otherRooms)  { delete room.id; delete room.contingent; }

            this.isLoading = true;

            // API call
            this.addRequest(copyNewRequest, close);
        }
    },


    created: function () {
        if(this.$store.state.hotelContingent.doubleRooms) {
            let contingent = JSON.parse(JSON.stringify(this.$store.state.hotelContingent));

            // Setting the reserved to 0 (zero)
            contingent.doubleRooms.forEach(x => x.reservedAmount = 0);
            contingent.singleRooms.forEach(x => x.reservedAmount = 0);
            contingent.otherRooms.forEach( x => x.reservedAmount = 0);

            // Copying global contingent
            this.newRequest.contingent.doubleRooms = contingent.doubleRooms;
            this.newRequest.contingent.singleRooms = contingent.singleRooms;
            this.newRequest.contingent.otherRooms  = contingent.otherRooms;
        }

    },


    watch: {
        'newRequest.hotel': function () {
            if (this.newRequest.hotel) {
                this.newRequest.agency = null;
            }
        }
    },


    components: {
        Board,
        HotelSelect,
        LocationSelect,
        HotelContingent,
        SimpleHotelContingent
    }
}
</script>
