<template>
    <div class="ai-prompt">
        <!-- todo: dropzone component -->
        <div class="columns is-vcentered">
            <div class="column">
                <div class="control has-icons-right">
                            <textarea
                                :disabled="promptIsDisabled"
                                class="textarea has-fixed-size"
                                placeholder="Sende eine Nachricht an ChatGPT"
                                v-model="promptText"
                                @keyup.enter="sendPrompt"
                            />
                    <div class="send-button">
                        <button
                            :class="{'is-loading button': isSending}"
                            class="btn-single-toggle has-border"
                            :disabled="promptIsDisabled"
                            v-on:click.prevent="sendPrompt"
                        ><i class="material-icons is-pointer" v-if="!isSending">send</i></button>
                    </div>

                </div>

            </div>
        </div>
    </div>
</template>
<script>


import store from "@components/AiChat/store";

export default {
    store: store,

    computed: {
        promptIsDisabled () {
            return this.$store.state.aiStore.promptIsDisabled;
        },
        isSending: {
            get () {
                return this.$store.state.aiStore.isSending;
            },
            set (value) {
                this.$store.state.aiStore.isSending = value;
            }
        },

        promptText: {
            get () {
                return this.$store.state.aiStore.promptText;
            },
            set (value) {
                this.$store.state.aiStore.promptText = value;
            }
        }
    },

    methods: {
        sendPrompt () {
            this.$store.dispatch('sendPrompt')
        }
    },

    mounted () {
    },

    components: {


    }
}

</script>

