var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "new-email-modal-form" }, [
    _c(
      "div",
      { staticClass: "input-wrapper" },
      [
        _c("label", [_vm._v("Telefonat mit:")]),
        _vm._v(" "),
        _c("multiselect", {
          attrs: {
            options: _vm.contactOptions,
            multiple: false,
            loading: _vm.isLoading.toContacts,
            "internal-search": true,
            taggable: true,
            label: "fullName",
            "track-by": "id",
            placeholder: "Kontakt auswählen",
          },
          on: { tag: (item) => _vm.addContact(item), input: _vm.selectNext },
          model: {
            value: _vm.newEmail.contact,
            callback: function ($$v) {
              _vm.$set(_vm.newEmail, "contact", $$v)
            },
            expression: "newEmail.contact",
          },
        }),
      ],
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "input-wrapper" }, [
      _c("label", [_vm._v("Betreff:")]),
      _vm._v(" "),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.newEmail.subject,
            expression: "newEmail.subject",
          },
        ],
        ref: "subject",
        staticClass: "input",
        attrs: { type: "text" },
        domProps: { value: _vm.newEmail.subject },
        on: {
          input: function ($event) {
            if ($event.target.composing) return
            _vm.$set(_vm.newEmail, "subject", $event.target.value)
          },
        },
      }),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "pell-wrapper" }, [
      _c("label", [_vm._v("Text:")]),
      _vm._v(" "),
      _c(
        "div",
        { staticStyle: { "z-index": "0" } },
        [
          _vm.isLoading.preview ? _c("loading") : _vm._e(),
          _vm._v(" "),
          _c("div", [
            _c(
              "div",
              [
                _c("tiny-mce", {
                  attrs: { minHeight: 400 },
                  model: {
                    value: _vm.newEmail.textHtml,
                    callback: function ($$v) {
                      _vm.$set(_vm.newEmail, "textHtml", $$v)
                    },
                    expression: "newEmail.textHtml",
                  },
                }),
              ],
              1
            ),
          ]),
        ],
        1
      ),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "buttons-wrapper" }, [
      _c(
        "div",
        {
          staticClass: "is-flex",
          staticStyle: { position: "relative", "margin-left": "84%" },
        },
        [
          _c("div", [
            _c(
              "button",
              {
                staticClass: "button is-primary is-large",
                class: { "is-loading": _vm.isLoading.send },
                staticStyle: { margin: "10px" },
                on: {
                  click: function ($event) {
                    return _vm.send()
                  },
                },
              },
              [
                _c("i", { staticClass: "material-icons" }, [_vm._v("check")]),
                _vm._v(" Protkoll speichern"),
              ]
            ),
          ]),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }