<template>
    <div>
        <modal-form
            v-if="formType === 'modal' && options"
            :editTitle="editTitle"
            :createTitle="createTitle"
            v-bind:isLoading="isLoading"
        >
            <input-fields :formKey="formKey" v-if="visible"/>

        </modal-form>
    </div>
</template>
<script>

import ModalForm from "@components/ModalForm.vue";
import {notifySuccess} from "@components/Notification";
import pick from "lodash/pick";

import InputFields from "@components/CRUD/Form/InputFields.vue";
import store from "@components/CRUD/Form/store";


export default {
    store: store,


    props: {
        formType: {
            type: String,
            required: true,
        },
        options: {
            default: () => {
                return {};
            }
        },
    },

    data: function () {
        return {
            formKey: 'undefined',
            api: '',
            visible: false,
            params: {},
            createTitle: 'Element hinzufügen',
            editTitle: 'Element bearbeiten',

        }
    },

    computed: {
        isLoading: {
            get () {
                return this.$store.state.dataObjects[this.formKey].isLoading;
            },
            set (value) {
                this.$store.commit('updateIsLoading', value);
            }
        },

        dataObjects: {
            get () {
                return this.$store.state.dataObjects;
            },
            set (value) {
                this.$store.state.dataObjects = value;
            }
        },

        form: {
            get () {
                return this.$store.getters.form(this.formKey);
            },
        },

        errors: {
            get () {
                return this.$store.getters.errors(this.formKey);
            },
        },

        editMode: function () {
            return this.form && this.form.id;
        },

        formData: function () {
            return this.$store.getters.formData(this.formKey)
        },

        rows () {
            return {}; //overwride in component
        }
    },

    methods: {
        update: function ({id, data}) {
            return this.$store.dispatch('update', {formKey: this.formKey, data: data});
        }, // Override in component if needed
        create: function (data) {
            return this.$store.dispatch('create', {formKey: this.formKey, data: data});
        }, // Override in component if needed

        mountForm: function () {
            const self = this;

            this.$children[0].$on('create', function () {
                self.handleFormCreate();
            });

            this.$children[0].$on('update', function () {
                self.handleFormUpdate();
            });

            this.$children[0].$on('closeModal', function () {
                self.visible = false;
            });
        },

        openModal: function (data){
            this.$store.dispatch('assignForm', {formKey: this.formKey, data: data, emptyForm: this.emptyForm});
            this.afterDataLoad();
            this.afterDataLoadAddition();
            this.visible = true;
        },

        closeModal: function () {
            this.visible = false;
        },

        afterDataLoad: function () {},
        afterDataLoadAddition: function () {},

        handleFormUpdate: function () {
            const keys = Object.keys(this.form);
            this.$emit('submit', this.form);
            this.$emit('update', this.form);
            this.isLoading = true;

            this.update({id: this.form.id, data: this.formData})
                .then(response => {
                    /*if(this.replaceItemSwitch){
                        this.replace(this.form.id).then(response => {
                            notifySuccess('Das Element wurde überall ersetzt!')
                        }, error => {
                            notifyError('Server Error! Das Element konnte nicht überall ersetzt werden!')
                        })
                    }*/
                    this.isLoading = false;
                    this.visible = false;

                    if (Object.keys(response.data).length > 0) {
                        const data = response.data;
                        if(data.translations && this.defaultTranslation){

                            for (let translation in data.translations) {

                                if(translation === this.defaultTranslation){
                                    for (const prop in data.translations[translation]) {
                                        if (Object.prototype.hasOwnProperty.call(data, prop)) {
                                            data[prop] = data.translations[translation][prop];
                                        }
                                    }
                                }
                            }
                        }
                        this.$emit('updated', pick(data, keys))
                        notifySuccess('Aktualisierung erfolgreich!');
                    }
                }, this.onError)
        },

        handleFormCreate: function () {
            this.$emit('submit', this.form);
            this.$emit('create', this.form);
            this.isLoading = true;

            this.create(this.formData)
                .then(response => {
                    this.isLoading = false
                    this.visible = false;

                    if (Object.keys(response.data).length > 0) {
                        this.$emit('created', response.data)
                        notifySuccess('Aktualisierung erfolgreich!');
                    }
                }, this.onError)
        },

        onError: function (error) {
            this.$store.dispatch('onError', {formKey: this.formKey, error: error})
            this.$emit('error', this.errors);
        }
    },


    created: function () {
        this.dataObjects = {
            ...this.dataObjects,
            [this.formKey]: {
                rows: this.rows,
                isLoading: false,
                form: this.emptyForm,
                errors: {},
                api: this.api,
                params: this.params,
            }
        };

    },


    mounted: function () {
        if(this.formType === 'modal') {
            this.mountForm();
        }
    },

    components: {
        InputFields,
        ModalForm,
    }
}

</script>
