import {getOrganisationsFromStorage} from '@utilities/functions';
import {defaultTags} from '@clientSpecific/utilities/defaultValues'

export default {
    data: function () {
        return {
            traitTagOptions: [],

            emptyForm: {
                traitTags: [],
            }
        }
    },

    methods: {
        /*afterDataLoadAddition: function () {
            if(this.api && defaultTags[this.api]){
                this.traitTagOptions = defaultTags[this.api];
            }
        },*/
    },
}
