<template>
    <aside class="sb-aside" :class="position">
        <component v-if="element" v-bind:is="element" @hideSideBar="hideSideBar" :sidebarData="sidebarData"></component>
    </aside>
</template>
<script>


import sidebars from "@components/sidebar/index";

export default {
    props: {
        element: {

        },
        position: {
            required: true,
        },

        sidebarData: {

        }
    },

    data: function() {
        return {
        }
    },

    computed: {

    },

    methods: {
        hideSideBar() {
            if(this.position === 'is-left') {
                this.$layout.toggleLeftSideBar(null)
            } else {
                this.$layout.toggleRightSideBar(null)
            }
        }
    },

    mounted () {
    },

    components: {...sidebars },
}
</script>
