<template>

    <div class="new-email-modal-form">
        <h3 v-if="showRequestSelect && emailType === 'provider' && providerSelected">EMAIL {{ providerSelected.provider.name }}</h3>
        <template v-if="showRequestSelect && emailType === 'provider' && providerSelected && providerSelected.requests ">
            <requests-select
                v-bind:requests="providerSelected.requests"
                v-bind:options="requestOptions"
            ></requests-select>
        </template>
        <div class="buttons-wrapper is-flex is-justify-content-right">
            <div class="is-flex">
                <button
                    class="button is-large"
                    v-on:click.prevent.stop="saveDraft"
                    v-bind:class="{ 'is-loading' : isLoading.draft }"
                    style="margin: 10px"
                >Outlook Entwurf erstellen</button>
                <div>
                    <button
                        class="button is-primary is-large mr-0"
                        v-bind:class="{ 'is-loading' : isLoading.send }"
                        v-on:click="send('close')"
                        style="margin: 10px"
                    ><i class="material-icons">check</i> Email senden</button>

                    <!--                <button
                                        class="button is-primary is-large"
                                        v-bind:class="{ 'is-loading' : isLoading.send }"
                                        v-on:click="send('close')"
                                    ><i class="material-icons">keyboard_arrow_right</i>Senden und Schließen</button>-->
                </div>

            </div>
        </div>
        <div class="input-wrapper">
            <label>Von:</label>
            <email-select v-model="newEmail.fromEmail" @input="$store.dispatch('fillEmailBody')"/>
        </div>

        <div class="input-wrapper">
            <label>An:</label>
            <multiselect
                :toBeCopied="true"

                v-model         = "newEmail.toAddresses"
                v-bind:options  = "toContacts"
                v-bind:multiple = "true"
                v-bind:taggable = "true"
                v-bind:loading  = "isLoading.toContacts"
                v-bind:internal-search = "false"
                label           = "email"
                track-by        = "email"
                placeholder     = ""
                v-on:tag        = "item => addContact(item, 'to')"
                v-on:open          = "getContacts('', 'to')"
                v-on:search-change = "search => getContacts(search, 'to')"
                v-on:close         = "tryContactPreselect('to')"
            >
                <template slot="option" slot-scope="props" v-if="props.option.firstName || props.option.email || props.option.lastName" >
                        <span class="option__name" v-if="props.option.firstName"><i class="material-icons">perm_identity</i> {{ props.option.firstName + ' ' + props.option.lastName   + " - " +  props.option.email }}</span>
                        <span class="option__name" v-else><i class="material-icons">perm_identity</i> {{ props.option.email }}</span>

                        <span class="option__type">
                        <template v-if="props.client"><i class="material-icons">business</i> Company: {{ props.option.client.companyName }}</template>
                        </span>

                </template>

                <span slot="caret" v-if="toContacts.length === 0"></span>
            </multiselect>
        </div>

        <div class="input-wrapper">
            <label>CC:</label>
            <multiselect
                v-model         = "newEmail.ccAddresses"
                v-bind:options  = "ccContacts"
                v-bind:multiple = "true"
                v-bind:taggable = "true"
                v-bind:loading  = "isLoading.ccContacts"
                v-bind:internal-search = "false"
                label           = "email"
                track-by        = "email"
                placeholder     = ""
                v-on:tag        = "item => addContact(item, 'cc')"
                v-on:open          = "getContacts('', 'cc')"
                v-on:search-change = "search => getContacts(search, 'cc')"
                v-on:close         = "tryContactPreselect('cc')"
            >
                <template slot="option" slot-scope="props" v-if="props.option.firstName || props.option.email || props.option.lastName" >
                    <span class="option__name" v-if="props.option.firstName" ><i class="material-icons">perm_identity</i> {{ props.option.firstName + ' ' + props.option.lastName + " - " + props.option.email }}</span>
                    <span class="option__name" v-else><i class="material-icons">perm_identity</i>{{ props.option.email }}</span>

                    <span class="option__type">
                        <template v-if="props.client"><i class="material-icons">business</i> Company: {{ props.option.client.companyName }}</template>
                    </span>
                </template>

                <span slot="caret" v-if="ccContacts.length === 0"></span>
            </multiselect>
        </div>

        <div class="input-wrapper">
            <label>BCC:</label>
            <multiselect
                v-model         = "newEmail.bccAddresses"
                v-bind:options  = "bccContacts"
                v-bind:multiple = "true"
                v-bind:taggable = "true"
                v-bind:loading  = "isLoading.bccContacts"
                v-bind:internal-search = "false"
                label           = "email"
                track-by        = "email"
                placeholder     = ""
                v-on:tag        = "item => addContact(item, 'bcc')"
                v-on:open          = "getContacts('', 'bcc')"
                v-on:search-change = "search => getContacts(search, 'bcc')"
                v-on:close         = "tryContactPreselect('bcc')"
            >
                <template slot="option" slot-scope="props" v-if="props.option.firstName || props.option.email || props.option.lastName" >
                    <span class="option__name" v-if="props.option.firstName" ><i class="material-icons">perm_identity</i> {{ props.option.firstName + ' ' + props.option.lastName + " - " + props.option.email }}</span>
                    <span class="option__name" v-else><i class="material-icons">perm_identity</i>{{ props.option.email }}</span>

                    <span class="option__type">
                        <template v-if="props.client"><i class="material-icons">business</i> Company: {{ props.option.client.companyName }}</template>
                    </span>
                </template>

                <span slot="caret" v-if="bccContacts.length === 0"></span>
            </multiselect>
        </div>


        <div class="input-wrapper">
            <label>Vorlage:</label>
            <div>
                <div class="column is-2 has-text-right is-flex pb-0" style="margin-top: -9px;">
                    <template v-for="item in ['en', 'fr', 'de_DE']">
                        <button @click="changeLocale(item)">
                            <gb-flag
                                v-if="item"
                                :code="getFlag(item)"
                                :class="{ 'is-selected' : item === locale }"
                                size="small"
                                iconPath="/assets/img/flags/"
                            />
                        </button>



                    </template>
                </div>
                <email-template-select
                    v-model    = "templateSelected"
                    :templates-filtered="templates"
                />

                <multiselect
                    v-if="templateSelected && templateSelected.variants.length > 0"
                    v-model         = "variantSelected"
                    v-bind:options  = "templateSelected.variants"
                    label          = "name"
                    track-by       = "id"
                    :max-height="600"
                />

                <toggle-switch
                    v-model="isFormal"
                    v-if="settings.emails.isFormal[emailType]"
                    btn-style="height: 35px"
                    class="mb-0"
                    style="margin-right: 10px"
                >

                    <option :value="false">Du</option>
                    <option :value="true">Sie</option>
                </toggle-switch>

                <button v-on:click.prevent.stop="fillEmail" class="button is-large is-icon" style="height: 35px">
                    <i class="material-icons">loop</i>
                </button>
            </div>
        </div>


        <div class="input-wrapper">
            <label>Betreff:</label>
            <input type="text" class="input" v-model="newEmail.subject" />
        </div>

        <div class="input-wrapper">
            <label>Beschreibung:</label>

            <tag-select
                v-model="tags"
                v-bind:option-tags="optionTags"
                my-class="is-medium"
            />
        </div>

        <div class="input-wrapper" v-if="((emailType === 'provider' && providerSelected && providerSelected.requests)) && requestOptions && requestOptions.statuses">
            <label>Status ändern:</label>
            <multiselect
                placeholder="nicht ändern"
                v-bind:options="requestOptions.statuses"
                label="value"
                track-by="key"
                v-model="status"
            />
        </div>

        <div class="input-wrapper">
            <label>Neue Aufgabe:</label>
            <input-date v-model="newEmail.todoDate"></input-date>
        </div>
        <div class="attachments">
            <label>Anhänge:</label>
            <attachments v-bind:ordersSelected="ordersSelected" v-model="newEmail._files"></attachments>
            <br>
        </div>

        <div class="pell-wrapper">
            <label>Text:</label>

            <div style="z-index:0">
                <loading v-if="isLoadingPreview"></loading>

                <div>
                    <div>
<!--                        <editor v-model="newEmail.textHtml" defaultParagraphSeparator="p style='Margin:0;-webkit-text-size-adjust:none;-ms-text-size-adjust:none;mso-line-height-rule:exactly;font-size:13px;font-family:'open sans', 'helvetica neue', helvetica, arial, sans-serif;line-height:16px;color:#6A6C6F;overflow-wrap:break-word'"/>-->
                        <tiny-mce v-model="newEmail.textHtml" @keyup="registerKeyUp" />
                    </div>
                </div>
            </div>
        </div>


        <div class="input-wrapper"></div>
        <div class="buttons-wrapper is-flex is-justify-content-right">
            <div class="is-flex">
                <button
                    class="button is-large"
                    v-on:click.prevent.stop="saveDraft"
                    v-bind:class="{ 'is-loading' : isLoading.draft }"
                    style="margin: 10px"
                >Outlook Entwurf erstellen</button>
                <div>
                    <button
                        class="button is-primary is-large mr-0"
                        v-bind:class="{ 'is-loading' : isLoading.send }"
                        v-on:click="send('close')"
                        style="margin: 10px"
                    ><i class="material-icons">check</i> Email senden</button>

                    <!--                <button
                                        class="button is-primary is-large"
                                        v-bind:class="{ 'is-loading' : isLoading.send }"
                                        v-on:click="send('close')"
                                    ><i class="material-icons">keyboard_arrow_right</i>Senden und Schließen</button>-->
                </div>

            </div>
        </div>
    </div>
</template>



<script>
import axios                from 'axios';
import jsonToFormData       from 'json-form-data';
import debounce             from 'lodash/debounce';
import uniq                 from 'lodash/uniq';
import uniqBy               from 'lodash/uniqBy';
import moment               from 'moment'
import {copy, getFlag} from "@utilities/functions";

import Loading                        from '@components/Loading';
import { TinyMce, Multiselect, InputDate }        from '@components/form';
import { defaultEmailTags } from '@clientSpecific/utilities/defaultValues'
import { ToggleSwitch } from "@components/form";
import {settings} from "@clientSpecific/utilities/settings";
import { notifyError, notifySuccess } from '@components/Notification';
import {getContacts, getUsers, optionsHotelRequests, editUser, getEmailTemplates} from '@api';
import { validateEmail, getValueWithKey, currentUser }              from '@utilities/functions';
import store          from '../../store';
import Attachments    from './Attachments';
import RequestsSelect from './RequestsSelect';
import emailBody      from '@clientSpecific/email/template/EmailBody.js';
import TagSelect from "@components/form/TagSelect";
import EmailTemplateSelect from "@components/form/EmailTemplateSelect";
import EmailSelect from "@components/form/EmailSelect";

export default {
    store: store,
    props: {
        preselectedOrderConcept:   { default: null },//preSelects order
        preselectedConceptProvider: { default: null }, //preSelects provider and requests
        preselectedOrder:   { default: null },//preSelects order
        preselectedRequest: { default: null }, //preSelects provider and requests
        preselectedContact: { default: null }, //preSelects Contact
        selectTemplate :    { default: null }, //preSelects template
        attachDocument:     { type: Object, default: null}, //attaches a document
        showRequestSelect:  { type: Boolean, default: true}
    },

    data: function () {
        return {
            settings: settings,
            formData: new FormData(),

            optionTags: defaultEmailTags,

            //contacts that will get updated each time the search string changes
            toContacts:         [],
            bccContacts:         [],
            ccContacts: [],

            tags: [],
            options: [],

            requestStatus: null,
            requestOptions: null,


            isLoading: {
                send:       false,
                preview:    false,
                toContacts: false,
                ccContacts: false,
                bccContacts: false,
                draft: false
            },
            localeIsSetByUser: false
        }
    },

    computed: {
        showStore () {
            return this.$store
        },

        requestsSelected: function () {
            return this.$store.state.requestsSelected;
        },

        ordersSelected: function () {
            return this.$store.state.ordersSelected;
        },

        isLoadingPreview: function () {
            return !!this.$store.state.isLoadingPreview;
        },

        emailType: function () {
            return this.$store.state.emailType;
        },

        providerSelected: function () {
            return this.$store.state.providerSelected;
        },

        newEmail: {
            get: function() {
                return this.$store.state.newEmail;
            },
            set: function (value) {
                this.$store.state.newEmail = value;
            }
        },

        isFormal: {
            get: function() {
                return this.$store.state.isFormal;
            },
            set: function (value) {
                this.$store.state.isFormal = value;
                this.$store.dispatch('resetTemplates');
            }
        },

        locale: {
            get: function() {
                if (!this.localeIsSetByUser) {
                    let availableTranslations =  settings.voucher.availableTranslations;
                    if (this.emailType === 'provider') {
                        if (this.preselectedRequest && this.preselectedRequest.provider && this.preselectedRequest.provider.mainLanguage && availableTranslations.includes(this.preselectedRequest.provider.mainLanguage)) {
                            this.$store.state.localeSelected = this.preselectedRequest.provider.mainLanguage;
                            return this.preselectedRequest.provider.mainLanguage;
                        }
                    }
                    if (this.emailType === 'client') {
                        if (this.preselectedOrder && this.preselectedOrder.client && this.preselectedOrder.client.mainLanguage && availableTranslations.includes(this.preselectedOrder.client.mainLanguage)) {
                            this.$store.state.localeSelected = this.preselectedOrder.client.mainLanguage;
                            return this.preselectedOrder.client.mainLanguage;
                        }
                    }
                }
                return this.$store.state.localeSelected;
            },
            set: function (value) {
                this.localeIsSetByUser = true;
                this.$store.state.localeSelected = value;
                this.$store.dispatch('resetTemplates', null, true);
            }
        },

        emailSelected: function () {
            return this.$store.getters.emailsSelected[0];
        },

        contactOptions: function () {
            return this.$store.state.contacts;
        },

        action:             function () { return this.$store.state.action; },

        templates:          function () {
            let formal = this.isFormal ? 'formal' : 'informal';
            return this.$store.state.templates[this.emailType][this.locale][formal];
        },

        templateSelected: {
            get: function () {
                return this.$store.state.templateSelected;
            },
            set: function (value) {
                this.$store.state.templateSelected = value;
                this.variantSelected = value.variants.find(item => item.variantStandard);
            }
        },

        variantSelected: {
            get: function () {
                return this.$store.state.variantSelected;
            },
            set: function (value) {
                this.$store.state.variantSelected = value;
                this.$store.dispatch('fillEmailBody');
            }
        },

        status: {
            get() {
                return this.requestOptions.statuses.find(status => status.key === this.requestStatus)
            },
            set: function(status) {
                this.requestStatus = !!status ? status.key : null
            }
        },
    },

    methods: {
        copy,
        getFlag,

        registerKeyUp () {
            this.$store.state.emailManuallyChanged = true;
        },

        fillEmail: function () {
            this.$store.dispatch('fillEmailBody');
        },

        changeLocale: function(newValue) {
            this.locale = newValue;
        },

        getFullName: function (contact) {
            let name = contact.firstName || '';

            if (contact.lastName) {
                name += (name ? ' ' : '') + contact.lastName;
            }

            return name;
        },

        prepareForSending: function () {
            let copyNewEmail = JSON.parse(JSON.stringify(this.newEmail)),
                form = {},
                incorrectAddress = false,

                prepareContact = (contact) => {
                        if (!validateEmail(contact.email)) {
                            incorrectAddress = true;
                        }

                        return {
                            email: contact.email.trim(),
                            name:  this.getFullName(contact)
                        }
                    };

            copyNewEmail.toAddresses = copyNewEmail.toAddresses.map(prepareContact);
            copyNewEmail.bccAddresses = copyNewEmail.bccAddresses.map(prepareContact);
            copyNewEmail.ccAddresses = copyNewEmail.ccAddresses.map(prepareContact);


            if (incorrectAddress) {
                notifyError('Falsche email Adresse!');
                return false;
            }


            if (!copyNewEmail.subject) {
                notifyError('Der Betreff darf nicht leer sein!');
                return false;
            }
            copyNewEmail.subject = copyNewEmail.subject.replace("\n", '');


            if (!copyNewEmail.textHtml) {
                notifyError('Die Nachricht darf nicht leer sein!');
                return false;
            }

            if (this.preselectedOrderConcept) {

                copyNewEmail.links = [
                    {
                        orderConcept: '/api/order_concepts/' + this.preselectedOrderConcept.id
                    }
                ]
            } else {
                if (copyNewEmail.links && copyNewEmail.links.length > 0) {
                    // Transforming orders to references
                    copyNewEmail.links = copyNewEmail.links.map(link => Object.assign(
                        link,
                        { order: link.order.id ? '/api/orders/' + link.order.id : link.order })
                    );

                } else {
                    copyNewEmail.links = this.ordersSelected.map(order => ({ order: '/api/orders/' + order.id }));
                }
            }



            copyNewEmail['_files']               = this.newEmail._files.filter(item => !item.id);
            copyNewEmail['_attachedDocumentIds'] = this.newEmail._files
                                                        .filter(item => item.id && !item.disposition)
                                                        .map(item => item.id);
            copyNewEmail['_attachmentsForwarded'] = this.newEmail._files.filter(item => item.disposition);

            if (this.requestStatus) {
                copyNewEmail['requestStatus'] = this.requestStatus;
                copyNewEmail['requests'] = this.requestsSelected.map(({ id }) => `/api/requests/${id}`);
            }
            if (this.$store.state.conceptProvidersSelected.length > 0) {
                copyNewEmail['priceRequestYear'] = this.$store.state.priceRequestYear;
                copyNewEmail['conceptProviders'] = this.$store.state.conceptProvidersSelected.map(({ id }) => `/api/concept_providers/${id}`);
            }

            copyNewEmail['tags'] = this.tags.map(({name}) => name);

            this.formData = jsonToFormData(copyNewEmail);


            return true;
        },

        send: function (close) {
            if (this.prepareForSending()) {
                this.isLoading.send = true;

                axios.post('/api/emails/send', this.formData)
                    .then(response => {
                        notifySuccess('Email gesendet!');
                        if (close === 'close') { this.$emit('close'); }
                        this.formData = new FormData();
                        const requestsChanged = !!this.requestStatus && this.requestsSelected.length > 0;
                        this.$emit('closeModal', requestsChanged);
                    }, error => { notifyError('Konnte email nicht senden! Server Error!'); })
                    .then(() => {
                        // Always executed (success or error)
                        this.isLoading.send = false;
                    });
            }
        },

        addContact: function (contact, type = 'to') {
            type = type + 'Addresses';
            this.newEmail[type].push({ email: contact });
        },

        tryContactPreselect: function (type = 'to') {
            type = type + 'ContactPreselect';
            let addressType = type + 'Addresses';
            // When losing focus add the email written (even without and enter/select)
            if (this[type]) {
                this.newEmail[addressType].push({ email: this[type] });
            }
        },

        searchContacts: function (search, contactsType) {
            search = search.toLowerCase();
            this[contactsType] = [];
            let contacts = this.contactOptions;

            this[contactsType] = contacts.filter(c =>
                c.email && (
                ((c.lastName || c.firstName) && (c.firstName + ' ' + c.lastName).toLowerCase().indexOf(search) !== -1) ||
                (c.email.toLowerCase().indexOf(search) !== -1))
            ).sort((a, b) => {
                if(a.username && !b.username) {
                    return 1;
                }
                if(b.username && !a.username) {
                    return -1;
                }
                if ((a.firstName && b.firstName) ) {
                    if ((a.firstName + ' ' + a.lastName) > (b.firstName + ' ' + b.lastName)) {
                        return 1;
                    }
                    else {
                        return -1;
                    }
                } else {
                    if ((a.email) > (b.email)) {
                        return 1;
                    } else {
                        return -1;
                    }
                }
            });
        },

        getContacts: function (search = '', type = 'to') {
            this.searchContacts(search, type + 'Contacts');
        },

        doReplyOrForward: function () {
            const selectedEmail = JSON.parse(JSON.stringify(this.emailSelected));


            // It is an action (reply, replyAll or forward)
            this.newEmail.subject = (this.action === 'forward' ? 'FW: ' : 'RE: ') + selectedEmail.subject;
            this.newEmail.toAddresses = [{
                email:     selectedEmail.fromAddress,
                firstName: selectedEmail.fromName,
                lastName:  null,
                ...selectedEmail.contact && selectedEmail.contact.emailInfos && selectedEmail.contact.emailInfos[3] && {
                    id: selectedEmail.contact.emailInfos[3]
                }
            }];
            this.newEmail.links       = selectedEmail.links;

            let textPhone = '';
            if(this.userPhoneDirect){
                textPhone = '(Dw. ' + this.userPhoneDirect + ')';
            }
            if(this.action === 'forward') {
                this.newEmail._files = selectedEmail.attachments.filter(item => item.disposition === 'attachment').map(item => ({
                    ...item,
                    name: item.fileName,
                }));
            }

            this.newEmail.textHtml    = emailBody(this.organisation, '', textPhone, selectedEmail);
        },

        saveDraft: function() {
            if (this.prepareForSending()) {
                this.isLoading.draft = true;

                axios.post('/api/emails/draft/save', this.formData)
                    .then(response => {
                        notifySuccess('Enwurf wurde erfolgreich im Outlook Entwürfe Ordner gespeichert');
                    }, error => { notifyError('Der Entwurf konnte nicht erstellt werden! Server Error!'); })
                    .then(() => {
                        // Always executed (success or error)
                        this.isLoading.draft = false;
                        this.formData = new FormData();
                    });
            }
        },


        getValueWithKey
    },


    created: function () {

        this.$store.state.isOpen = true;
        this.$store.state.isPhone = false;

        this.$layout.toggleRightSideBar('AiChat')

        //Handle preselectedOrders and Requests

        if(this.preselectedOrderConcept) {
            let preselectedOrderConcept = this.preselectedOrderConcept;
            let preselectedConceptProvider = this.preselectedConceptProvider;
            this.$store.dispatch('preselectOrderConcepts', {preselectedOrderConcept, preselectedConceptProvider}).then(response => {
                this.$store.dispatch('resetEmailForm', {templateToSelect: this.selectTemplate, contactToSelect: this.preselectedContact, documentToAttach: this.attachDocument});
            });
        } else {
            let preselectedOrder = this.preselectedOrder;
            let preselectedRequest = this.preselectedRequest;
            this.$store.dispatch('preselectOrders', {preselectedOrder, preselectedRequest}).then(response => {
                this.$store.dispatch('resetEmailForm', {templateToSelect: this.selectTemplate, contactToSelect: this.preselectedContact, documentToAttach: this.attachDocument});
            });
        }

    },


    watch: {
        emailType: {
            handler: function () {
                if (!this.requestOptions) {
                    optionsHotelRequests().then(response => {
                        this.requestOptions = response.data;

                    }, error => { notifyError('Couldn\'t load the Request Options'); });
                }
            },
            immediate: true
        },

        'newEmail.toAddresses': function () {
            if (this.newEmail.toAddresses) {
                this.$store.dispatch('fillEmailBody');
                const unique = uniqBy(this.newEmail.toAddresses, item => item.email);
                if (unique.length < this.newEmail.toAddresses.length) {
                    this.newEmail.toAddresses = unique;
                }
            }
        },


        'newEmail.bccAddresses': function () {
            if (this.newEmail.bccAddresses) {
                const unique = uniqBy(this.newEmail.bccAddresses, item => item.email);

                if (unique.length < this.newEmail.bccAddresses.length) {
                    this.newEmail.bccAddresses = unique;
                }
            }
        },

        'newEmail.ccAddresses': function () {
            if (this.newEmail.ccAddresses) {
                const unique = uniqBy(this.newEmail.ccAddresses, item => item.email);

                if (unique.length < this.newEmail.ccAddresses.length) {
                    this.newEmail.ccAddresses = unique;
                }
            }
        },

        'variantSelected': function() {
            this.tags = this.variantSelected ?
                this.variantSelected.tags.map(tag => {
                    return {
                        name: tag
                    }
                }) : []
        },
    },


    components: {
        InputDate,
        EmailSelect,
        EmailTemplateSelect,
        TagSelect,
        Attachments,
        TinyMce,
        Loading,
        Multiselect,
        RequestsSelect,
        ToggleSwitch,
    }
}

</script>
