var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "modal-form",
    {
      staticClass: "is-medium",
      attrs: {
        editTitle: "Verkaufpreis editieren",
        createTitle: "Neuer Verkaufpreis",
      },
    },
    [
      _c(
        "table",
        [
          _c("tr", [
            _c("td", { attrs: { colspan: "2" } }, [
              _c("h2", [_vm._v("GÜLTIKEIT UND REFERENZ-ZEITRAUM FESTLEGEN")]),
            ]),
          ]),
          _vm._v(" "),
          !_vm.editMode
            ? [
                _c("tr", [
                  _c(
                    "td",
                    { attrs: { colspan: "2" } },
                    [
                      _c(
                        "toggle-switch",
                        {
                          model: {
                            value: _vm.periodType,
                            callback: function ($$v) {
                              _vm.periodType = $$v
                            },
                            expression: "periodType",
                          },
                        },
                        [
                          _c("option", { attrs: { value: "months" } }, [
                            _vm._v("Mehrere Monate auswählen"),
                          ]),
                          _vm._v(" "),
                          _c("option", { attrs: { value: "quick" } }, [
                            _vm._v("Zeitraum schnell auswählen"),
                          ]),
                          _vm._v(" "),
                          _c("option", { attrs: { value: "dates" } }, [
                            _vm._v("Genauen Zeitraum auswählen"),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _vm.periodType === "months"
                  ? [
                      _c("tr", [
                        _c("th", [
                          _c("label", { staticClass: "label" }, [
                            _vm._v(
                              "Für folgende Monate eine Kalkulation erstellen:"
                            ),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("td", [
                          _c(
                            "div",
                            { staticClass: "level-left" },
                            [
                              _c(
                                "div",
                                { staticClass: "level-item" },
                                [
                                  _c("month-select", {
                                    attrs: { multiple: "" },
                                    model: {
                                      value: _vm.form.months,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "months", $$v)
                                      },
                                      expression: "form.months",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("multiselect", {
                                staticClass: "is-small",
                                attrs: { options: _vm.years },
                                model: {
                                  value: _vm.form.year,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.form, "year", $$v)
                                  },
                                  expression: "form.year",
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("tr", [
                        _c("th", [
                          _c("label", { staticClass: "label" }, [
                            _vm._v("Start-Wochentag der Reise:"),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c(
                          "td",
                          [
                            _c("multiselect", {
                              staticClass: "is-medium",
                              attrs: {
                                options: _vm.weekDays,
                                label: "label",
                                "track-by": "value",
                              },
                              model: {
                                value: _vm.startDay,
                                callback: function ($$v) {
                                  _vm.startDay = $$v
                                },
                                expression: "startDay",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ]
                  : _vm._e(),
                _vm._v(" "),
                _vm.periodType === "quick"
                  ? [
                      _c("tr", [
                        _c("th", [
                          _c("label", { staticClass: "label" }, [
                            _vm._v("Jahr:"),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c(
                          "td",
                          [
                            _c("multiselect", {
                              staticClass: "is-small",
                              attrs: { options: _vm.years },
                              model: {
                                value: _vm.form.year,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "year", $$v)
                                },
                                expression: "form.year",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _vm._v(" "),
                      _c("tr", [
                        _c("th", [
                          _c("label", { staticClass: "label" }, [
                            _vm._v("Startmonat:"),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("td", [
                          _c("div", { staticClass: "level-left" }, [
                            _c(
                              "div",
                              { staticClass: "level-item" },
                              [
                                _c("month-select", {
                                  model: {
                                    value: _vm.form.startMonth,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "startMonth", $$v)
                                    },
                                    expression: "form.startMonth",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("tr", [
                        _c("th", [
                          _c("label", { staticClass: "label" }, [
                            _vm._v("Endmonat:"),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("td", [
                          _c("div", { staticClass: "level-left" }, [
                            _c(
                              "div",
                              { staticClass: "level-item" },
                              [
                                _c("month-select", {
                                  model: {
                                    value: _vm.form.endMonth,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "endMonth", $$v)
                                    },
                                    expression: "form.endMonth",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c("tr", [
                        _c("th", [
                          _c("label", { staticClass: "label" }, [
                            _vm._v("Start-Wochentag der Reise:"),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c(
                          "td",
                          [
                            _c("multiselect", {
                              staticClass: "is-medium",
                              attrs: {
                                options: _vm.weekDays,
                                label: "label",
                                "track-by": "value",
                              },
                              model: {
                                value: _vm.startDay,
                                callback: function ($$v) {
                                  _vm.startDay = $$v
                                },
                                expression: "startDay",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ]
                  : _vm._e(),
              ]
            : _vm._e(),
          _vm._v(" "),
          _vm.editMode || _vm.periodType === "dates"
            ? _c("tr", [
                _c("th", [
                  _c("label", { staticClass: "label" }, [
                    _vm._v("Gültigkeit der Preise:\n                    "),
                    _vm.canAddPeriod
                      ? _c(
                          "i",
                          {
                            staticClass: "material-icons is-pointer",
                            on: { click: _vm.addPeriod },
                          },
                          [_vm._v("add_circle")]
                        )
                      : _vm._e(),
                  ]),
                ]),
                _vm._v(" "),
                _c(
                  "td",
                  _vm._l(_vm.form.seasonTimes, function (period) {
                    return _c(
                      "div",
                      {
                        key: period.id ? period.id : period.key,
                        staticClass: "level-left",
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "flatpickr-combined level-item" },
                          [
                            _c("input-date", {
                              staticClass: "is-small",
                              model: {
                                value: period.startAt,
                                callback: function ($$v) {
                                  _vm.$set(period, "startAt", $$v)
                                },
                                expression: "period.startAt",
                              },
                            }),
                            _vm._v(" "),
                            _c("span", [_vm._v("-")]),
                            _vm._v(" "),
                            _c("input-date", {
                              staticClass: "is-small",
                              on: {
                                input: function ($event) {
                                  return _vm.fillRef(period)
                                },
                              },
                              model: {
                                value: period.endAt,
                                callback: function ($$v) {
                                  _vm.$set(period, "endAt", $$v)
                                },
                                expression: "period.endAt",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _vm.form.seasonTimes.length > 1
                          ? _c(
                              "i",
                              {
                                staticClass: "material-icons is-pointer",
                                on: {
                                  click: function ($event) {
                                    return _vm.deletePeriod(period)
                                  },
                                },
                              },
                              [_vm._v("delete")]
                            )
                          : _vm._e(),
                      ]
                    )
                  }),
                  0
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          !_vm.editMode && _vm.periodType === "dates"
            ? _c("tr", [
                _c("th", [
                  _c("label", { staticClass: "label" }, [
                    _vm._v("Referenzreise-Datum:"),
                  ]),
                ]),
                _vm._v(" "),
                _c("td", [
                  _c(
                    "div",
                    { staticClass: "flatpickr-combined" },
                    [
                      _c("input-date", {
                        staticClass: "is-small",
                        model: {
                          value: _vm.form.refStartAt,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "refStartAt", $$v)
                          },
                          expression: "form.refStartAt",
                        },
                      }),
                      _vm._v(" "),
                      _c("span", [_vm._v("-")]),
                      _vm._v(" "),
                      _c("input-date", {
                        staticClass: "is-small",
                        model: {
                          value: _vm.form.refEndAt,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "refEndAt", $$v)
                          },
                          expression: "form.refEndAt",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ])
            : _vm._e(),
          _vm._v(" "),
          !_vm.editMode
            ? _c("tr", [
                _c("th", [
                  _c("label", { staticClass: "label" }, [
                    _vm._v("Leistungsträger:"),
                  ]),
                ]),
                _vm._v(" "),
                _c(
                  "td",
                  [
                    _c(
                      "toggle-switch",
                      {
                        model: {
                          value: _vm.requests,
                          callback: function ($$v) {
                            _vm.requests = $$v
                          },
                          expression: "requests",
                        },
                      },
                      [
                        _c("option", { domProps: { value: true } }, [
                          _vm._v("hinzufügen"),
                        ]),
                        _vm._v(" "),
                        _c("option", { domProps: { value: false } }, [
                          _vm._v("nicht hinzufügen "),
                        ]),
                      ]
                    ),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          !_vm.editMode
            ? _c("tr", [
                _c("th", [
                  _c("label", { staticClass: "label" }, [
                    _vm._v("Hotelkategorie ändern:"),
                  ]),
                ]),
                _vm._v(" "),
                _c(
                  "td",
                  [
                    _vm.options.hotelTypes
                      ? _c("multiselect", {
                          attrs: {
                            "track-by": "name",
                            label: "name",
                            options: _vm.options.hotelTypes,
                            placeholder: _vm.hotelTypePlaceholder,
                          },
                          on: { select: _vm.setTitle },
                          model: {
                            value: _vm.form.hotelType,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "hotelType", $$v)
                            },
                            expression: "form.hotelType",
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("tr", [
            _c("th", [
              _c("label", { staticClass: "label" }, [
                _vm._v("Zusatztitel (z.B. 4* Superior):"),
              ]),
            ]),
            _vm._v(" "),
            _c("td", [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.form.subTitle,
                    expression: "form.subTitle",
                  },
                ],
                staticClass: "input",
                attrs: { type: "text" },
                domProps: { value: _vm.form.subTitle },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(_vm.form, "subTitle", $event.target.value)
                  },
                },
              }),
            ]),
          ]),
          _vm._v(" "),
          _c("tr", [
            _c("td", { attrs: { colspan: "2" } }, [
              _c("h2", [
                _vm._v("NÄCHTE UND LÄNGE VON ZUSATZLEISTUNGEN FESTLEGEN"),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("tr", [
            _c(
              "td",
              { attrs: { colspan: "2" } },
              [
                _c("concept-preview", {
                  model: {
                    value: _vm.form.concept,
                    callback: function ($$v) {
                      _vm.$set(_vm.form, "concept", $$v)
                    },
                    expression: "form.concept",
                  },
                }),
              ],
              1
            ),
          ]),
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }