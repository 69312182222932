var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "accordion",
    {
      attrs: {
        isOpen:
          _vm.modalToRequest.type === "other" &&
          _vm.modalToRequest.id === _vm.request.id,
        "is-collapsible": false,
        "class-names": _vm.request.isSelectedRequest
          ? "header-is-highlighted"
          : "",
      },
    },
    [
      _c(
        "div",
        { attrs: { slot: "header" }, slot: "header" },
        [
          _vm.request.otherService && _vm.request.otherService.provider
            ? _c("request-score", {
                attrs: {
                  rating: _vm.request.otherService.provider.buspaketRating,
                },
              })
            : _c("div", [
                _c("div", { staticClass: "no-name" }, [_vm._v("No Name")]),
              ]),
          _vm._v(" "),
          _c(
            "div",
            [
              _c("div", { staticClass: "level-left" }, [
                _c(
                  "span",
                  { staticClass: "level-item" },
                  [
                    _vm.request && _vm.request.provider
                      ? [
                          _c(
                            "a",
                            {
                              attrs: {
                                href:
                                  "/" +
                                  _vm.request.provider.providerType +
                                  "s/" +
                                  _vm.request.provider.id,
                                target: "_blank",
                                title: "Gehe zum Anbieter",
                              },
                            },
                            [_vm._v(_vm._s(_vm.request.provider.name))]
                          ),
                        ]
                      : [_vm._v("Noch kein Anbieter ausgewählt")],
                  ],
                  2
                ),
                _vm._v(" "),
                _c(
                  "i",
                  {
                    staticClass: "material-icons is-pointer",
                    class: { "has-text-active": _vm.editMode },
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        $event.stopPropagation()
                        _vm.editMode = !_vm.editMode
                      },
                    },
                  },
                  [_vm._v("edit")]
                ),
              ]),
              _vm._v(" "),
              _c(
                "provider-info",
                {
                  staticClass: "header-extra-info",
                  attrs: { provider: _vm.request.provider },
                },
                [
                  _c(
                    "div",
                    [
                      _c("i", { staticClass: "fa fa-book" }),
                      _vm._v(" "),
                      _vm.request.agency
                        ? _c(
                            "a",
                            {
                              attrs: {
                                href: "/agencies/" + _vm.request.agency.id,
                                target: "_blank",
                                title: "Zur Agentur gehen",
                              },
                            },
                            [_vm._v(_vm._s(_vm.request.agency.name))]
                          )
                        : [_vm._v("direkt")],
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c("div", [
                    _c("i", { staticClass: "material-icons" }, [
                      _vm._v("check_circle"),
                    ]),
                    _vm._v(
                      "\n                        " +
                        _vm._s(_vm.request.contingent.reservedAmount) +
                        "\n                        Pax\n                    "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", [
                    _c("i", { staticClass: "material-icons" }, [
                      _vm._v("help"),
                    ]),
                    _vm._v(
                      "\n                        " +
                        _vm._s(_vm.request.contingent.askedAmount) +
                        "\n                        Pax\n                    "
                    ),
                  ]),
                  _vm._v(" "),
                  _c("div", [
                    _c("i", { staticClass: "material-icons" }, [
                      _vm._v("import_contacts"),
                    ]),
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.getValueWithKey({
                            key: _vm.request.requestStatus.status,
                            optionList: _vm.requestStatuses,
                          })
                        )
                    ),
                  ]),
                  _vm._v(" "),
                  _vm.request.optionTodo && _vm.request.optionTodo.dueDate
                    ? _c("div", [
                        _c(
                          "div",
                          {
                            class: [
                              "deadline-date",
                              _vm.deadlineUrgency(
                                _vm.request.optionTodo.dueDate
                              ),
                            ],
                          },
                          [
                            _c("i", { staticClass: "material-icons" }, [
                              _vm._v("notifications_none"),
                            ]),
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm._f("fullYear")(
                                    _vm.request.optionTodo.dueDate
                                  )
                                )
                            ),
                          ]
                        ),
                      ])
                    : _vm._e(),
                ]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            [
              _c("request-settings", {
                attrs: {
                  request: _vm.request,
                  "calculation-request": _vm.calculationRequest,
                  "selected-request": _vm.selectedRequest,
                },
                on: {
                  reloadPrices: _vm.reloadPrices,
                  setCalculationRequest: _vm.setCalculationRequest,
                  setSelectRequest: _vm.setSelectRequest,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { attrs: { slot: "content" }, slot: "content" },
        [
          _c("div", { staticClass: "buttons has-addons" }, [
            _c(
              "button",
              {
                staticClass: "button is-large",
                class: [
                  _vm.sectionSelection === "overview"
                    ? "is-primary"
                    : "is-gray",
                ],
                on: {
                  click: function ($event) {
                    return _vm.changeTab("overview")
                  },
                },
              },
              [
                _c("i", { staticClass: "material-icons mr-2" }, [
                  _vm._v("help"),
                ]),
                _vm._v(" Anfragedetails"),
              ]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "button is-large",
                class: [
                  _vm.sectionSelection === "price" ? "is-primary" : "is-gray",
                ],
                on: {
                  click: function ($event) {
                    return _vm.changeTab("price")
                  },
                },
              },
              [
                _c("i", { staticClass: "material-icons mr-2" }, [
                  _vm._v("euro_symbol"),
                ]),
                _vm._v(" Preise"),
              ]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "button is-large",
                class: [
                  _vm.sectionSelection === "cost" ? "is-primary" : "is-gray",
                ],
                on: {
                  click: function ($event) {
                    return _vm.changeTab("cost")
                  },
                },
              },
              [
                _c("i", { staticClass: "material-icons mr-2" }, [
                  _vm._v("view_list"),
                ]),
                _vm._v(" Gesamtkosten"),
              ]
            ),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "button is-large",
                class: [
                  _vm.sectionSelection === "email" ? "is-primary" : "is-gray",
                ],
                on: {
                  click: function ($event) {
                    return _vm.changeTab("email")
                  },
                },
              },
              [
                _c("i", { staticClass: "material-icons mr-2" }, [
                  _vm._v("email"),
                ]),
                _vm._v(" Emails/Telefonate"),
              ]
            ),
            _vm._v(" "),
            _vm.request.provider
              ? _c(
                  "button",
                  {
                    staticClass: "button is-large",
                    class: [
                      _vm.sectionSelection === "menu"
                        ? "is-primary"
                        : "is-gray",
                    ],
                    on: {
                      click: function ($event) {
                        return _vm.changeTab("menu")
                      },
                    },
                  },
                  [
                    _c("i", { staticClass: "material-icons mr-2" }, [
                      _vm._v("restaurant"),
                    ]),
                    _vm._v(" Menüs"),
                  ]
                )
              : _vm._e(),
          ]),
          _vm._v(" "),
          _vm.isLoading
            ? _c("loading", {
                attrs: { textInFront: "Die Änderungen werden gespeichert..." },
              })
            : [
                _vm.sectionSelection === "overview"
                  ? _c("request", {
                      attrs: {
                        placeholderInfo: _vm.placeholderInfo,
                        editMode: _vm.editMode,
                        "calculation-request": _vm.calculationRequest,
                        "selected-request": _vm.selectedRequest,
                      },
                      model: {
                        value: _vm.request,
                        callback: function ($$v) {
                          _vm.request = $$v
                        },
                        expression: "request",
                      },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.sectionSelection === "price"
                  ? _c("price", {
                      attrs: { calculationRequest: _vm.calculationRequest },
                      on: { setCalculationRequest: _vm.setCalculationRequest },
                      model: {
                        value: _vm.request,
                        callback: function ($$v) {
                          _vm.request = $$v
                        },
                        expression: "request",
                      },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.sectionSelection === "cost"
                  ? _c("cost", {
                      attrs: {
                        requestID: _vm.request.id,
                        packageID: _vm.packageID,
                      },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.sectionSelection === "email"
                  ? _c("emails", {
                      attrs: {
                        "preselected-order": _vm.order,
                        "preselected-request": _vm.request,
                        "preselected-contact": _vm.request.requestContact,
                        "show-request-select": false,
                      },
                      on: {
                        close: function ($event) {
                          return _vm.$emit("closeModal")
                        },
                      },
                    })
                  : _vm._e(),
                _vm._v(" "),
                _vm.sectionSelection === "menu"
                  ? _c("menus", {
                      attrs: {
                        "provider-id":
                          _vm.request && _vm.request.provider
                            ? _vm.request.provider.id
                            : null,
                        "other-service-type-id":
                          _vm.request &&
                          _vm.request.otherService &&
                          _vm.request.otherService.otherServiceType
                            ? _vm.request.otherService.otherServiceType.id
                            : null,
                        request: _vm.request,
                      },
                    })
                  : _vm._e(),
              ],
        ],
        2
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }