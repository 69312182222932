var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "modal",
    {
      attrs: { classes: "placeholder", visible: _vm.isModalVisible },
      on: { closeModal: _vm.closeModal },
    },
    [
      _vm.modalLoading
        ? [
            _c(
              "div",
              { attrs: { slot: "content" }, slot: "content" },
              [
                _c("loading", {
                  attrs: { textInFront: "Daten werden aktualisiert" },
                }),
              ],
              1
            ),
          ]
        : [
            _c("template", { slot: "header" }, [
              _c("div", { staticClass: "is-saving" }, [
                _vm.isSaving === "ok"
                  ? _c("i", { staticClass: "material-icons" }, [
                      _vm._v("check"),
                    ])
                  : _vm.isSaving
                  ? _c("i", { staticClass: "material-icons icon-spin" }, [
                      _vm._v("refresh"),
                    ])
                  : _vm._e(),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "name" }, [
                _vm._v(
                  _vm._s(_vm.localPlaceholder.startPort.place.name) +
                    " - " +
                    _vm._s(_vm.localPlaceholder.endPort.place.name)
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "header-extra-info" }, [
                _c("div", [
                  _c("i", { staticClass: "material-icons" }, [
                    _vm._v("directions_boat"),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass:
                        "buttons has-addons toggle-switch has-margin-right",
                    },
                    [
                      _c(
                        "button",
                        {
                          staticClass: "button is-large",
                          class: {
                            "is-primary": _vm.localPlaceholder.type === "night",
                          },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              _vm.localPlaceholder.type = "night"
                            },
                          },
                        },
                        [_vm._v("Nacht")]
                      ),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass: "button is-large",
                          class: {
                            "is-primary": _vm.localPlaceholder.type === "day",
                          },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              _vm.localPlaceholder.type = "day"
                            },
                          },
                        },
                        [_vm._v("Tag")]
                      ),
                    ]
                  ),
                ]),
                _vm._v(" "),
                _c("div", [
                  _vm._v(
                    "\n                    " +
                      _vm._s(_vm.date) +
                      "\n                "
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  [
                    _c("async-select", {
                      ref: "startPort",
                      staticClass: "is-medium",
                      staticStyle: { "margin-right": "10px" },
                      attrs: {
                        api: "ports",
                        "custom-label": (item) => item.place.name,
                        crudLinkTitle: "Fügen Sie einen neuen Port hinzu",
                        clearable: false,
                        "allow-empty": false,
                        placeholder: "Abfahrtshafen",
                      },
                      model: {
                        value: _vm.localPlaceholder.startPort,
                        callback: function ($$v) {
                          _vm.$set(_vm.localPlaceholder, "startPort", $$v)
                        },
                        expression: "localPlaceholder.startPort",
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass: "btn-single-toggle has-margin-right",
                        class: [_vm.endPortRestriction ? "" : "is-active"],
                        staticStyle: { border: "1px solid #dddddd" },
                        attrs: {
                          title:
                            "Nur bestehende Fährverbindungen suchen/alle Häfen suchen",
                        },
                        on: {
                          click: function ($event) {
                            _vm.endPortRestriction = !_vm.endPortRestriction
                          },
                        },
                      },
                      [
                        _c(
                          "i",
                          {
                            staticClass: "material-icons",
                            staticStyle: {
                              "vertical-align": "-2px",
                              margin: "0",
                            },
                          },
                          [_vm._v("timeline")]
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c("async-select", {
                      staticClass: "is-small",
                      attrs: {
                        api: "ports",
                        "custom-label": (item) => item.place.name,
                        crudLinkTitle: "Fügen Sie einen neuen Port hinzu",
                        clearable: false,
                        "allow-empty": false,
                        params:
                          _vm.endPortRestriction &&
                          _vm.localPlaceholder.startPort
                            ? {
                                _groups: ["port:service_day_time"],
                                "endRoutes.startPort.id":
                                  _vm.localPlaceholder.startPort.id,
                              }
                            : { _groups: ["port:service_day_time"] },
                        placeholder: "Ankunftshafen",
                      },
                      model: {
                        value: _vm.localPlaceholder.endPort,
                        callback: function ($$v) {
                          _vm.$set(_vm.localPlaceholder, "endPort", $$v)
                        },
                        expression: "localPlaceholder.endPort",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "is-block" }, [
                  _c(
                    "div",
                    { staticClass: "level mb-1" },
                    [
                      _vm.serviceDayTimes.length > 0
                        ? _c(
                            "button",
                            {
                              staticClass:
                                "btn-single-toggle has-margin-right level-item",
                              class: [_vm.onlyTime ? "" : "is-active"],
                              staticStyle: { "min-width": "20px" },
                              attrs: { title: "Uhrzeit" },
                              on: {
                                click: function ($event) {
                                  _vm.onlyTime = !_vm.onlyTime
                                },
                              },
                            },
                            [
                              _c(
                                "i",
                                {
                                  staticClass: "material-icons",
                                  staticStyle: { margin: "0" },
                                },
                                [_vm._v("access_time")]
                              ),
                            ]
                          )
                        : _c(
                            "button",
                            {
                              staticClass: "has-margin-right",
                              staticStyle: { "min-width": "20px" },
                              attrs: { title: "Einfschiffung" },
                            },
                            [
                              _c(
                                "i",
                                {
                                  staticClass: "material-icons",
                                  staticStyle: { margin: "0" },
                                  attrs: { title: "Einfschiffung" },
                                },
                                [_vm._v("access_time")]
                              ),
                            ]
                          ),
                      _vm._v(" "),
                      _vm.onlyTime
                        ? [
                            _c("date-time", {
                              staticClass: "has-margin-right ferry",
                              model: {
                                value: _vm.localPlaceholder.startAt,
                                callback: function ($$v) {
                                  _vm.$set(_vm.localPlaceholder, "startAt", $$v)
                                },
                                expression: "localPlaceholder.startAt",
                              },
                            }),
                            _vm._v(" -\n                            "),
                            _c("date-time", {
                              staticClass: "has-margin-left ferry",
                              model: {
                                value: _vm.localPlaceholder.endAt,
                                callback: function ($$v) {
                                  _vm.$set(_vm.localPlaceholder, "endAt", $$v)
                                },
                                expression: "localPlaceholder.endAt",
                              },
                            }),
                          ]
                        : [
                            _c("multiselect", {
                              staticClass: "level-item",
                              staticStyle: { width: "150px" },
                              attrs: {
                                options: _vm.serviceDayTimes,
                                "track-by": "id",
                                "custom-label": _vm.serviceDayTimeLabel,
                              },
                              on: { select: _vm.selectServiceTime },
                              model: {
                                value: _vm.serviceDayTimeSelected,
                                callback: function ($$v) {
                                  _vm.serviceDayTimeSelected = $$v
                                },
                                expression: "serviceDayTimeSelected",
                              },
                            }),
                          ],
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _vm.onlyTime
                    ? _c(
                        "div",
                        [
                          _c(
                            "button",
                            {
                              staticClass: "has-margin-right",
                              attrs: { title: "Einfschiffung" },
                            },
                            [
                              _c(
                                "i",
                                {
                                  staticClass: "material-icons",
                                  staticStyle: { margin: "0" },
                                  attrs: { title: "Einfschiffung" },
                                },
                                [_vm._v("av_timer")]
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c("date-time", {
                            staticClass: "ferry",
                            attrs: { "time-only": "" },
                            model: {
                              value: _vm.checkInAt,
                              callback: function ($$v) {
                                _vm.checkInAt = $$v
                              },
                              expression: "checkInAt",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  [
                    _c(
                      "button",
                      {
                        staticClass: "btn-single-toggle has-margin-right",
                        class: [
                          _vm.localPlaceholder.hideClass ? "" : "is-active",
                        ],
                        attrs: { title: "Klasse auswählen" },
                        on: {
                          click: function ($event) {
                            _vm.localPlaceholder.hideClass =
                              !_vm.localPlaceholder.hideClass
                          },
                        },
                      },
                      [
                        _c(
                          "i",
                          {
                            staticClass: "material-icons",
                            staticStyle: {
                              "vertical-align": "-2px",
                              "margin-left": "2px",
                            },
                          },
                          [_vm._v("receipt")]
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    !_vm.localPlaceholder.hideClass &&
                    _vm.options.ferryLabels.categorySelect
                      ? _c("multiselect", {
                          staticStyle: { width: "100px" },
                          attrs: {
                            "track-by": "key",
                            label: "value",
                            placeholder: "Klasse",
                            options: _vm.objectToKeyValueArray(
                              _vm.options.ferryLabels.categorySelect
                            ),
                          },
                          model: {
                            value: _vm.classCategory,
                            callback: function ($$v) {
                              _vm.classCategory = $$v
                            },
                            expression: "classCategory",
                          },
                        })
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.localPlaceholder.hideClass
                      ? _c("multiselect", {
                          staticStyle: { width: "150px" },
                          attrs: {
                            options: [
                              { key: true, value: "ohne Klasse" },
                              { key: false, value: "Klasse festlegen" },
                            ],
                            "track-by": "key",
                            label: "value",
                            placeholder: "Klasse",
                          },
                          model: {
                            value: _vm.hideClass,
                            callback: function ($$v) {
                              _vm.hideClass = $$v
                            },
                            expression: "hideClass",
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("div", { staticClass: "is-block" }, [
                  _c("div", { staticClass: "level mb-1" }, [
                    _c(
                      "i",
                      {
                        staticClass: "material-icons",
                        attrs: { title: "Set as Additional" },
                      },
                      [_vm._v("shopping_cart")]
                    ),
                    _vm._v(" "),
                    _c("div", { staticClass: "select" }, [
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.localPlaceholder.clientOffer.status,
                              expression: "localPlaceholder.clientOffer.status",
                            },
                          ],
                          on: {
                            change: function ($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function (o) {
                                  return o.selected
                                })
                                .map(function (o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.$set(
                                _vm.localPlaceholder.clientOffer,
                                "status",
                                $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              )
                            },
                          },
                        },
                        [
                          _c("option", { attrs: { value: "included" } }, [
                            _vm._v("inkludiert"),
                          ]),
                          _vm._v(" "),
                          _c("option", { attrs: { value: "optional" } }, [
                            _vm._v("optional"),
                          ]),
                          _vm._v(" "),
                          _c("option", { attrs: { value: "additional" } }, [
                            _vm._v("gesondert"),
                          ]),
                          _vm._v(" "),
                          _c("option", { attrs: { value: "atLocation" } }, [
                            _vm._v("vor Ort"),
                          ]),
                        ]
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "level mb-1" },
                    [
                      _c("i", { staticClass: "material-icons" }, [
                        _vm._v("directions_bus"),
                      ]),
                      _vm._v(" "),
                      _c(
                        "toggle-switch",
                        {
                          model: {
                            value: _vm.localPlaceholder.driverNotParticipating,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.localPlaceholder,
                                "driverNotParticipating",
                                $$v
                              )
                            },
                            expression:
                              "localPlaceholder.driverNotParticipating",
                          },
                        },
                        [
                          _c("option", { domProps: { value: false } }, [
                            _vm._v("mit"),
                          ]),
                          _vm._v(" "),
                          _c("option", { domProps: { value: true } }, [
                            _vm._v("ohne Fahrer"),
                          ]),
                        ]
                      ),
                    ],
                    1
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "div",
                  [
                    _c(
                      "i",
                      {
                        staticClass: "material-icons",
                        attrs: { title: "Set as Additional" },
                      },
                      [_vm._v("directions_bus")]
                    ),
                    _vm._v(" "),
                    _c("multiselect", {
                      attrs: {
                        options: _vm.offerTypes,
                        "track-by": "key",
                        label: "label",
                        multiple: "",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "selection",
                          fn: function ({ values, search, isOpen }) {
                            return [
                              values.length && values.length > 2 && !isOpen
                                ? _c(
                                    "span",
                                    { staticClass: "multiselect__single" },
                                    [
                                      _vm._v(
                                        _vm._s(values.length) +
                                          " Optionen ausgewählt"
                                      ),
                                    ]
                                  )
                                : _vm._e(),
                            ]
                          },
                        },
                      ]),
                      model: {
                        value: _vm.transportServices,
                        callback: function ($$v) {
                          _vm.transportServices = $$v
                        },
                        expression: "transportServices",
                      },
                    }),
                  ],
                  1
                ),
              ]),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "button is-large is-success is-add-new",
                  on: { click: _vm.addNewRequest },
                },
                [
                  _c("i", {
                    staticClass: "fa fa-plus-circle",
                    attrs: { "aria-hidden": "true" },
                  }),
                  _vm._v(" Leistungsträger hinzufügen"),
                ]
              ),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { attrs: { slot: "content" }, slot: "content" },
              [
                _vm._l(
                  _vm.localPlaceholder.requests,
                  function (request, index) {
                    return !_vm.isNewRequestVisible && request.id
                      ? _c("request", {
                          key: request.id,
                          attrs: {
                            placeholderInfo: _vm.placeholderInfo,
                            calculationRequest: _vm.calculationRequest,
                            "selected-request": _vm.selectedRequest,
                          },
                          on: {
                            closeModal: function ($event) {
                              return _vm.$emit(
                                "closeModal",
                                _vm.localPlaceholder
                              )
                            },
                            setCalculationRequest: _vm.setCalculationRequest,
                            setSelectRequest: _vm.setSelectRequest,
                            saved: _vm.handleRequestSaved,
                          },
                          model: {
                            value: _vm.localPlaceholder.requests[index],
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.localPlaceholder.requests,
                                index,
                                $$v
                              )
                            },
                            expression: "localPlaceholder.requests[index]",
                          },
                        })
                      : _vm._e()
                  }
                ),
                _vm._v(" "),
                _vm.isNewRequestVisible
                  ? _c("new-request", {
                      attrs: {
                        placeholderID: _vm.localPlaceholder.id,
                        placeholderInfo: _vm.placeholderInfo,
                      },
                      on: {
                        close: _vm.closeNewRequestModal,
                        closeModal: function ($event) {
                          return _vm.$emit("closeModal", _vm.localPlaceholder)
                        },
                        saved: _vm.handleRequestSaved,
                      },
                      model: {
                        value:
                          _vm.localPlaceholder.requests[
                            _vm.localPlaceholder.requests.length - 1
                          ],
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.localPlaceholder.requests,
                            _vm.localPlaceholder.requests.length - 1,
                            $$v
                          )
                        },
                        expression:
                          "localPlaceholder.requests[localPlaceholder.requests.length - 1]",
                      },
                    })
                  : _vm._e(),
              ],
              2
            ),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }