<template>
    <div class="is-zebra">

        <div
            class="row"
            v-for="room in sortedPrices"
        >
            <div class="cell"><span :style="isStandardRoom(room) ? 'font-weight: bold' : ''">{{ roomLabel(room) }}</span></div>
            <div class="cell"></div>
            <div class="cell">{{ roomPersons(room) }}</div>
            <div class="cell">{{ roomPrice(room) | priceView }}</div>
            <div class="cell"><b>{{ roomTotal(room) | priceView }}</b></div>
        </div>

        <div
            class="row"
            v-for="room in sortedPrices"
            v-if="room.personsFreePlaces"
        >
            <div class="cell">{{ freePlaceLabel(room) }}</div>
            <div class="cell"></div>
            <div class="cell">{{ room.personsFreePlaces }}</div>
            <div class="cell">{{ -1 * room.absolutePrice.amount | priceView }}</div>
            <div class="cell"><b>{{ -1 * room.totalPriceFreePlaces.amount | priceView }}</b></div>
        </div>








        <!-- ================ Item Prices ================  -->
        <div
            class="row"
            v-for="item in calculations.itemPrices"
            v-bind:key="'item-' + item.id"
            v-if="item.billingType !== 'info' && item.limitIsValid"
        >
            <div class="cell"> + Zuschlag: {{ item.name }}</div>
            <div class="cell"></div>
            <div class="cell">{{ item.calculatedQuantity }}</div>
            <div class="cell">{{ item.price.amount  | priceView }}</div>
            <div class="cell"><b>{{ item.totalPrice.amount  | priceView }}</b></div>
        </div>

    </div>
</template>



<script>
import { getValueWithKey, getAccommodationLabel, priceView } from '@utilities/functions';
import { store } from '@root/pages/orders/store';
import has from "lodash/has";


export default {
    store: store,

    props: {
        calculations:  { type: Object,  required: true },
        roomTypes:     { type: Array,   required: true },
        cabinTypes:    { type: Array,   required: true },
        trainCabinTypes: { type: Array,   required: true },
        isLoading:     { type: Boolean, required: true },
    },


    computed: {
        sortedPrices: function () {
            let roomTypes = this.roomTypes;
            let cabinTypes = this.cabinTypes;
            return this.calculations.basePrices.sort(function (a, b){
                if(!roomTypes && !cabinTypes){
                    return 0;
                }
                if((!a.roomType || !a.roomType.persons) && b.roomType && b.roomType.persons) {
                    return 1;
                }
                if((!b.roomType || !b.roomType.persons) && a.roomType && a.roomType.persons) {
                    return -1;
                }
                if((!a.cabinType || !a.cabinType.persons) && b.cabinType && b.cabinType.persons) {
                    return 1;
                }
                if((!b.cabinType || !b.cabinType.persons) && a.cabinType && a.cabinType.persons) {
                    return -1;
                }
                if(a.roomType && a.roomType.persons){
                    const roomIndexA = roomTypes.findIndex(item => item.persons === a.roomType.persons && item.type === a.roomType.type);
                    const roomIndexB = roomTypes.findIndex(item => item.persons === b.roomType.persons && item.type === b.roomType.type);
                    return roomIndexA - roomIndexB;
                }
                if(a.cabinType && a.cabinType.persons){
                    const roomIndexA = cabinTypes.findIndex(item => item.persons === a.cabinType.persons && item.type === a.cabinType.type);
                    const roomIndexB = cabinTypes.findIndex(item => item.persons === b.cabinType.persons && item.type === b.cabinType.type);
                    return roomIndexA - roomIndexB;
                }
                return 0;
            });
        },

        packageHasHotel: function () {
            for (let i = 0, len = this.calculations.requests.length; i < len; i++) {
                if (this.calculations.requests[i].requestType === 'hotel') {
                    return true;
                }
            }

            return false;
        },


        packageHasFerry: function () {
            for (let i = 0, len = this.calculations.requests.length; i < len; i++) {
                if (this.calculations.requests[i].requestType === 'ferry' &&
                    this.calculations.requests[i].type  === 'night') {
                    return true;
                }
            }

            return false;
        },


        standardIndex: function () {
            let index = 0;

            if (this.packageHasHotel) {
                index = this.calculations.basePrices.findIndex(
                    x => x.roomType.type === 'standard' && x.roomType.persons === 2
                );

            } else if (this.packageHasFerry) {
                index = this.calculations.basePrices.findIndex(
                    x => x.cabinType.type === 'inside' && x.cabinType.persons === 2
                );

            }

            return index;
        },

        supplementPrices: function () { return this.$store.state.options.clientConfig.calculation.supplementPrices; },
    },


    methods: {
        getValueWithKey,

        getAccommodationLabel,

        isStandardRoom: function (room) {
            return room === this.calculations.basePrices[this.standardIndex];
        },

        isNegative: function(room)
        {
            return room.absolutePrice.amount < 0;
        },

        roomLabel: function (room) {
            if((!room.roomType || !room.roomType.persons) && (!room.cabinType || !room.cabinType.persons)) {
                return 'Paketpreis pro Person';
            }
            let roomTypes = room.roomType && room.roomType.persons ? this.roomTypes : this.cabinTypes;
            let type = room.roomType && room.roomType.persons ? room.roomType : room.cabinType;
            let addition = this.packageHasHotel && type === room.cabinType ? this.getAccommodationLabel('standard', type.persons, this.roomTypes) + ' - ' : '';


            return 'Paketpreis - ' + addition + this.getAccommodationLabel(type.type, type.persons, roomTypes)
        },

        freePlaceLabel: function (room) {
            let text = 'Freiplatz';
            if(room.roomType && room.roomType.persons) {
                text += ' im ';
                text +=  this.isStandardRoom(room) ? 'halben ' : '';
                text += this.getAccommodationLabel(room.roomType.type, room.roomType.persons, this.roomTypes);
            }
            if(room.cabinType && room.cabinType.persons) {
                text += ' in der ';
                text +=  this.isStandardRoom(room) ? 'halben ' : '';
                text += this.getAccommodationLabel(room.cabinType.type, room.cabinType.persons, this.cabinTypes);
            }
            return text;
        },

        roomPersons: function (room) {
            if(this.isStandardRoom(room) && this.supplementPrices){
                return this.calculations.personsReserved;
            }
            return this.supplementPrices ? room.personsReserved : room.absolutePersonsReserved;
        },

        roomPrice: function (room) {
            let negativeAddition = this.isNegative(room) ? -1 : 1;
            return !this.supplementPrices || this.isStandardRoom(room) ? room.absolutePrice.amount : negativeAddition * room.premiumPrice.amount;
        },

        roomTotal: function (room) {
            let negativeAddition = this.isNegative(room) ? -1 : 1;

            return this.supplementPrices ? negativeAddition * room.totalPriceReservedPremium.amount : room.totalAbsolutePriceReserved.amount;
        },
    },


    filters: {
        priceView
    }

}
</script>
