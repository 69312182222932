var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "edit-table redesign-table", class: _vm.tableClasses },
    [
      _c(
        "div",
        { staticClass: "table-wrapper" },
        [
          _vm.label
            ? _c("div", { staticClass: "table-label" }, [
                _vm._v(_vm._s(_vm.label)),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.hasHeader
            ? _c("div", { staticClass: "row is-header" }, [_vm._t("header")], 2)
            : _vm._e(),
          _vm._v(" "),
          !_vm.hasSlotFilters && !_vm.hideFilterRow
            ? _c("filters", {
                staticClass: "row is-filters",
                attrs: { options: _vm.allOptions },
                model: {
                  value: _vm.computedFilters,
                  callback: function ($$v) {
                    _vm.computedFilters = $$v
                  },
                  expression: "computedFilters",
                },
              })
            : _vm._e(),
          _vm._v(" "),
          _vm._t("filters", null, { options: _vm.allOptions }),
          _vm._v(" "),
          _vm._t(
            "head",
            function () {
              return [
                _c(
                  "div",
                  { staticClass: "row is-head" },
                  [
                    _vm._l(_vm.visibleCols, function (col) {
                      return _c(
                        "div",
                        {
                          key: `${col.key}-${col.label}`,
                          staticClass: "cell",
                          class: {
                            [col.class]: !!col.class,
                          },
                          style: col.style,
                        },
                        [
                          col.key === "checkable"
                            ? _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value: _vm.checkAll,
                                    expression: "checkAll",
                                  },
                                ],
                                attrs: { type: "checkbox" },
                                domProps: {
                                  checked: Array.isArray(_vm.checkAll)
                                    ? _vm._i(_vm.checkAll, null) > -1
                                    : _vm.checkAll,
                                },
                                on: {
                                  change: function ($event) {
                                    var $$a = _vm.checkAll,
                                      $$el = $event.target,
                                      $$c = $$el.checked ? true : false
                                    if (Array.isArray($$a)) {
                                      var $$v = null,
                                        $$i = _vm._i($$a, $$v)
                                      if ($$el.checked) {
                                        $$i < 0 &&
                                          (_vm.checkAll = $$a.concat([$$v]))
                                      } else {
                                        $$i > -1 &&
                                          (_vm.checkAll = $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1)))
                                      }
                                    } else {
                                      _vm.checkAll = $$c
                                    }
                                  },
                                },
                              })
                            : _c("span", [_vm._v(_vm._s(col.label))]),
                        ]
                      )
                    }),
                    _vm._v(" "),
                    _vm.isEditable || _vm.deleteRow || _vm.hasActions
                      ? _c("div", { staticClass: "cell is-actions" }, [
                          _vm.isAddable
                            ? _c(
                                "i",
                                {
                                  staticClass: "material-icons is-pointer",
                                  on: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                      $event.stopPropagation()
                                      return _vm.handleAdd.apply(
                                        null,
                                        arguments
                                      )
                                    },
                                  },
                                },
                                [_vm._v("add_circle")]
                              )
                            : _vm._e(),
                        ])
                      : _vm._e(),
                  ],
                  2
                ),
              ]
            },
            null,
            { visibleCols: _vm.visibleCols }
          ),
          _vm._v(" "),
          _vm.isLoading
            ? _c("loading")
            : [
                _vm.rows.length > 0
                  ? _c(
                      "div",
                      { class: _vm.isZebra ? "is-zebra" : "" },
                      [
                        _vm._t(
                          "default",
                          function () {
                            return [
                              _vm._l(_vm.rows, function (row, index) {
                                return [
                                  row
                                    ? _c(
                                        _vm.rowTag,
                                        {
                                          key: row.id,
                                          tag: "component",
                                          staticClass: "row",
                                          class: [
                                            ..._vm.rowClasses,
                                            row.subrowVisible
                                              ? _vm.selectedClass
                                              : "",
                                          ],
                                          attrs: {
                                            href: _vm.rowURL(row),
                                            target: _vm.target,
                                          },
                                        },
                                        [
                                          _vm._l(
                                            _vm.visibleCols,
                                            function (col, colIndex) {
                                              return [
                                                _vm.slotExists(col.key)
                                                  ? _c(
                                                      "div",
                                                      {
                                                        key: `${row.id}-${col.key}-${col.label}`,
                                                        staticClass: "cell",
                                                        class: col.class,
                                                        style: col.style,
                                                        attrs: {
                                                          "data-v-step": `table:cell:${col.key}:${index}`,
                                                        },
                                                      },
                                                      [
                                                        _vm._t(
                                                          col.key,
                                                          null,
                                                          null,
                                                          {
                                                            row,
                                                            allOptions:
                                                              _vm.allOptions,
                                                          }
                                                        ),
                                                      ],
                                                      2
                                                    )
                                                  : col.key === "checkable"
                                                  ? _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "cell is-icon-cell has-border is-relative",
                                                        staticStyle: {
                                                          padding: "0px",
                                                          "justify-content":
                                                            "center",
                                                        },
                                                      },
                                                      [
                                                        _c("input", {
                                                          directives: [
                                                            {
                                                              name: "model",
                                                              rawName:
                                                                "v-model",
                                                              value:
                                                                _vm.checkedRows,
                                                              expression:
                                                                "checkedRows",
                                                            },
                                                          ],
                                                          attrs: {
                                                            type: "checkbox",
                                                          },
                                                          domProps: {
                                                            value: row,
                                                            checked:
                                                              Array.isArray(
                                                                _vm.checkedRows
                                                              )
                                                                ? _vm._i(
                                                                    _vm.checkedRows,
                                                                    row
                                                                  ) > -1
                                                                : _vm.checkedRows,
                                                          },
                                                          on: {
                                                            change: function (
                                                              $event
                                                            ) {
                                                              var $$a =
                                                                  _vm.checkedRows,
                                                                $$el =
                                                                  $event.target,
                                                                $$c =
                                                                  $$el.checked
                                                                    ? true
                                                                    : false
                                                              if (
                                                                Array.isArray(
                                                                  $$a
                                                                )
                                                              ) {
                                                                var $$v = row,
                                                                  $$i = _vm._i(
                                                                    $$a,
                                                                    $$v
                                                                  )
                                                                if (
                                                                  $$el.checked
                                                                ) {
                                                                  $$i < 0 &&
                                                                    (_vm.checkedRows =
                                                                      $$a.concat(
                                                                        [$$v]
                                                                      ))
                                                                } else {
                                                                  $$i > -1 &&
                                                                    (_vm.checkedRows =
                                                                      $$a
                                                                        .slice(
                                                                          0,
                                                                          $$i
                                                                        )
                                                                        .concat(
                                                                          $$a.slice(
                                                                            $$i +
                                                                              1
                                                                          )
                                                                        ))
                                                                }
                                                              } else {
                                                                _vm.checkedRows =
                                                                  $$c
                                                              }
                                                            },
                                                          },
                                                        }),
                                                      ]
                                                    )
                                                  : _vm.componentExists(
                                                      col.formElement
                                                    )
                                                  ? _c(
                                                      "div",
                                                      {
                                                        key: `${row.id}-${col.key}-${col.label}`,
                                                        staticClass: "cell",
                                                        class: col.class,
                                                        style: col.style,
                                                        attrs: {
                                                          "data-v-step": `table:cell:${col.key}:${index}`,
                                                        },
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.$emit(
                                                              "cellClicked",
                                                              { row, col }
                                                            )
                                                          },
                                                          dblclick: function (
                                                            $event
                                                          ) {
                                                            return _vm.$emit(
                                                              "cellDoubleClicked",
                                                              { row, col }
                                                            )
                                                          },
                                                          keyup: function (
                                                            $event
                                                          ) {
                                                            return _vm.navigatePrevious(
                                                              $event,
                                                              index,
                                                              colIndex,
                                                              col
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          col.formElement,
                                                          _vm._b(
                                                            {
                                                              ref:
                                                                "cell-" +
                                                                index +
                                                                "-" +
                                                                colIndex,
                                                              refInFor: true,
                                                              tag: "component",
                                                              attrs: {
                                                                row: row,
                                                                value:
                                                                  _vm.getValue(
                                                                    row,
                                                                    col
                                                                  ),
                                                                generalOptions:
                                                                  _vm.allOptions,
                                                              },
                                                              on: {
                                                                input: (
                                                                  inputValue
                                                                ) =>
                                                                  _vm.saveRow(
                                                                    row,
                                                                    col,
                                                                    inputValue
                                                                  ),
                                                                enter: (
                                                                  inputValue
                                                                ) =>
                                                                  _vm.$emit(
                                                                    "enterPressed",
                                                                    {
                                                                      row,
                                                                      col,
                                                                      inputValue,
                                                                    }
                                                                  ),
                                                              },
                                                            },
                                                            "component",
                                                            _vm.evalProps(
                                                              row,
                                                              col
                                                            ),
                                                            false
                                                          )
                                                        ),
                                                      ],
                                                      1
                                                    )
                                                  : col.noHtml
                                                  ? _c(
                                                      "div",
                                                      {
                                                        key: `${row.id}-${col.key}-${col.label}`,
                                                        staticClass: "cell",
                                                        class: col.class,
                                                        style: col.style,
                                                        attrs: {
                                                          "data-v-step": `table:cell:${col.key}:${index}`,
                                                          title: _vm._f(
                                                            "stripHtml"
                                                          )(
                                                            _vm.colValue(
                                                              row,
                                                              col.key
                                                            )
                                                          ),
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm._f("stripHtml")(
                                                              _vm.colValue(
                                                                row,
                                                                col.key
                                                              )
                                                            )
                                                          )
                                                        ),
                                                      ]
                                                    )
                                                  : _c("div", {
                                                      key: `${row.id}-${col.key}-${col.label}`,
                                                      staticClass: "cell",
                                                      class: col.class,
                                                      style: col.style,
                                                      attrs: {
                                                        "data-v-step": `table:cell:${col.key}:${index}`,
                                                        title: _vm.colValue(
                                                          row,
                                                          col.key
                                                        ),
                                                      },
                                                      domProps: {
                                                        innerHTML: _vm._s(
                                                          _vm.colValue(
                                                            row,
                                                            col.key
                                                          )
                                                        ),
                                                      },
                                                    }),
                                              ]
                                            }
                                          ),
                                          _vm._v(" "),
                                          _vm.hasActions
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "cell is-actions",
                                                  attrs: {
                                                    "data-v-step": `table:cell:actions:${index}`,
                                                  },
                                                },
                                                [
                                                  _vm._t(
                                                    "actions",
                                                    null,
                                                    null,
                                                    row
                                                  ),
                                                  _vm._v(" "),
                                                  _vm.showUpdate
                                                    ? _c(
                                                        "update-popover",
                                                        {
                                                          attrs: {
                                                            update: {
                                                              updatedAt:
                                                                row.updatedAt,
                                                              updatedBy:
                                                                row.updatedBy,
                                                            },
                                                            api:
                                                              "api/" +
                                                              _vm.api +
                                                              "/" +
                                                              row.id,
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "button",
                                                            {
                                                              staticClass:
                                                                "btn-edit",
                                                              attrs: {
                                                                title:
                                                                  "Letzte Aktualisierung",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "i",
                                                                {
                                                                  staticClass:
                                                                    "material-icons",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "update"
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                  _vm._v(" "),
                                                  _vm.isEditable
                                                    ? _c(
                                                        "button",
                                                        {
                                                          staticClass:
                                                            "btn-edit",
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              $event.preventDefault()
                                                              $event.stopPropagation()
                                                              return _vm.handleEdit(
                                                                row
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "i",
                                                            {
                                                              staticClass:
                                                                "material-icons",
                                                            },
                                                            [_vm._v("edit")]
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                  _vm._v(" "),
                                                  _vm.deleteRow
                                                    ? _c(
                                                        "button",
                                                        {
                                                          staticClass:
                                                            "btn-delete",
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              $event.preventDefault()
                                                              $event.stopPropagation()
                                                              return _vm.handleDelete(
                                                                row
                                                              )
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "i",
                                                            {
                                                              staticClass:
                                                                "material-icons",
                                                            },
                                                            [_vm._v("delete")]
                                                          ),
                                                        ]
                                                      )
                                                    : _vm._e(),
                                                ],
                                                2
                                              )
                                            : _vm._e(),
                                        ],
                                        2
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm._t("subrow", null, null, {
                                    allOptions: _vm.allOptions,
                                    row,
                                  }),
                                ]
                              }),
                            ]
                          },
                          null,
                          { rows: _vm.rows, allOptions: _vm.allOptions }
                        ),
                      ],
                      2
                    )
                  : _c("div", { staticClass: "row", class: _vm.rowClasses }, [
                      _c(
                        "div",
                        { staticClass: "cell has-text-centered" },
                        [
                          !_vm.load
                            ? [_vm._v("No data loaded")]
                            : [_vm._v("Keine Einträge")],
                        ],
                        2
                      ),
                    ]),
                _vm._v(" "),
                _vm.showSum
                  ? _c(
                      "div",
                      { staticClass: "row is-head" },
                      [
                        _vm._l(_vm.visibleCols, function (col) {
                          return _c(
                            "div",
                            {
                              key: `${col.key}-${col.label}`,
                              staticClass: "cell",
                              class: {
                                [col.class]: !!col.class,
                              },
                              style:
                                col.showSum === "price"
                                  ? col.style + ";padding: 0;"
                                  : col.style,
                            },
                            [
                              col.showSum === "price"
                                ? _c(
                                    "span",
                                    {
                                      attrs: {
                                        "data-prefix":
                                          (col.props && col.props.prefix) ||
                                          (col.evalProps &&
                                            col.evalProps.prefix)
                                            ? _vm.evalProps(_vm.rows[0], col)
                                                .prefix
                                            : "",
                                      },
                                    },
                                    [_vm._v(_vm._s(_vm.calculateSum(col)))]
                                  )
                                : col.showSum
                                ? _c("span", [_vm._v(_vm._s(col.showSum))])
                                : _vm._e(),
                            ]
                          )
                        }),
                        _vm._v(" "),
                        _vm.isEditable || _vm.deleteRow || _vm.hasActions
                          ? _c("div", { staticClass: "cell is-actions" }, [
                              _vm.isAddable
                                ? _c(
                                    "i",
                                    {
                                      staticClass: "material-icons is-pointer",
                                      on: {
                                        click: function ($event) {
                                          $event.preventDefault()
                                          $event.stopPropagation()
                                          return _vm.handleAdd.apply(
                                            null,
                                            arguments
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("add_circle")]
                                  )
                                : _vm._e(),
                            ])
                          : _vm._e(),
                      ],
                      2
                    )
                  : _vm._e(),
              ],
        ],
        2
      ),
      _vm._v(" "),
      !_vm.isLoading && _vm.footer
        ? _c(
            "div",
            { staticClass: "redesign-table__footer" },
            [
              _c(
                "div",
                [
                  _vm._t(
                    "footer",
                    function () {
                      return [
                        _vm.isAddable
                          ? _c(
                              "a",
                              {
                                staticClass: "button is-primary",
                                on: { click: _vm.handleAdd },
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    _vm._f("capitalize")(_vm.name.singular)
                                  ) + " hinzufügen"
                                ),
                              ]
                            )
                          : _vm._e(),
                      ]
                    },
                    { checked: _vm.value, options: _vm.allOptions }
                  ),
                ],
                2
              ),
              _vm._v(" "),
              _vm.pagination
                ? _c("pagination", {
                    attrs: { simple: false },
                    on: { input: _vm.updatePagination },
                    model: {
                      value: _vm.pagination,
                      callback: function ($$v) {
                        _vm.pagination = $$v
                      },
                      expression: "pagination",
                    },
                  })
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _vm._t("form", null, { options: _vm.allOptions }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }