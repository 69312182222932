<template>
    <div>

        <accordion
            v-if                 = "day"
            class                = "day has-hidden-arrow is-concept"
            v-bind:class         = "{ 'is-empty': placeholders.length === 0 }"
            v-bind:isCollapsible = "false"
            v-bind:isOpen        = "placeholders.length > 0 || !!createNew || showEmptyRow"
        >
            <template slot="header">
                <header>
                    <div class="tags mb-0 mr-2">
                            <button class="is-truncated" @click="toggleIsHidden">
                                <span class="tag is-red mb-0" v-if="day.isHidden">INAKTIV</span>
                                <span class="tag is-white mb-0" v-if="!day.isHidden">AKTIV</span>
                            </button>

                    </div>
                    <span>{{dayCount ? 'Tag ' + dayCount + ' - ' : '' }}{{ day.name }}{{day.distance ? ' (' + day.distance + ' Km)' : ''}}</span>
                    <div class="day-action-buttons">
                        <template v-if="countOrderConcepts-1">
                            <span style="font-size: 10px; text-transform: none" class="mr-1">Vorsicht beim Editieren - in</span>
                            <popover trigger="hover" @show="getOrderConceptsInfo">
                                <button class="button mr-1" style="font-size: 10px; height: 20px">{{countOrderConcepts}}</button>
                                <template v-slot:popover="slotProps">
                                    <div class="todo__popover">
                                        <div v-if="!orderConceptsInfo"><loading class="is-small" text-after="" text-in-front=""/></div>
                                        <ul class="icon-list" v-else>
                                            <li v-for="concept in orderConceptsInfo">
                                                #{{concept.id}} - {{concept.name}} {{concept.years.join(' | ') ? ' (Katalog: ' + concept.years.join(' | ') + ')': ''}}
                                            </li>
                                        </ul>
                                    </div>
                                </template>
                            </popover>
                            <span style="font-size: 10px; text-transform: none"> Konzepten</span>
                        </template>

                        <i class="material-icons icon-spin" v-if="isLoading.initialize || isLoading.deleting">refresh</i>


                        <simple-menu material icon="more_vert" title="Menu">
                            <button v-on:click="create('hotel')"><i class="material-icons">add</i> Hotel</button>
                            <button v-on:click="create('other')"><i class="material-icons">add</i> Zusatzleistung</button>
                            <button v-on:click="create('ferry')"><i class="material-icons">add</i> Fähre</button>
                            <button v-on:click="create('train')"><i class="material-icons">add</i> Zug</button>
                            <button v-on:click="create('airline')"><i class="material-icons">add</i> Flug</button>
                            <button v-on:click="create('route')"><i class="material-icons">add</i> Routenstichpunkt</button>
                            <button v-on:click="create('free')"><i class="material-icons">add</i> Freitext</button>
                            <button v-on:click="$emit('openDayForm', day)"><i class="material-icons">edit</i> bearbeiten</button>
                            <button v-on:click="openDay(day)"><i class="material-icons">link</i> zum Tageskonzept</button>
                            <button v-if=dayCount v-on:click="$emit('deleteDay')" class="btn-delete"><i class="material-icons">delete</i> löschen</button>
                        </simple-menu>
                    </div>
                </header>
            </template>

            <template slot="content">
                <template v-if="placeholders.length > 0 || !!createNew">
                    <draggable v-model="placeholders" v-bind:options="{ disabled: isDraggableDisabled }">
                        <template v-for="placeholder in placeholders">
                            <component
                                v-if="editMode === placeholder.placeholderType + '-' + placeholder.id"
                                v-bind:key="placeholder.sort"

                                v-bind:is="placeholder.placeholderType + '-form'"
                                v-bind:placeholder="placeholder"
                                v-bind:isLoading = "isLoading.saving"

                                v-bind:options="options"

                                v-on:save="savePlaceholder"
                                v-on:cancel="editMode = ''"
                            />


                            <component
                                v-else
                                v-bind:key="placeholder.sort"

                                class="block--draggable"
                                v-bind:class="{ 'is-selected': isSelected(placeholder) }"

                                v-bind:is="placeholder.placeholderType + '-view'"
                                v-bind:placeholder = "placeholder"
                                v-bind:isRouteView = "isRouteView"
                                isConcept
                                v-bind:showDuration="false"

                                v-on:click.native = "$store.commit('togglePlaceholder', placeholder)"
                            >
                                <simple-menu material icon="more_vert" title="Menu">
                                    <button v-if="placeholder.placeholderType !== 'route'"
                                        v-on:click.stop="openConceptProviderForm({providers: placeholder.providers, placeholder: placeholder, id: placeholder.id})"
                                    ><i class="material-icons">add</i> Leistungsträger auswählen</button>

                                    <button
                                        v-on:click.stop="editPlaceholder(placeholder)"
                                    ><i class="material-icons">mode_edit</i> Bearbeiten</button>

                                    <button
                                        class="btn-delete"
                                        v-on:click.stop="deletePlaceholder({
                                            key: types[placeholder.placeholderType],
                                            id:  placeholder.id
                                        })"
                                    ><i class="material-icons">delete</i> Löschen</button>
                                </simple-menu>

                                <a v-if="crudLink(placeholder)" :href="crudLink(placeholder) ? crudLink(placeholder)  : '#'" target="_blank">
                                    <i class="material-icons">launch</i>
                                </a>
                                <i class="material-icons" v-else>launch</i>
                            </component>

                        </template>

                        <concept-provider-form ref="conceptProviderForm" :options="[]"/>

                        <component
                            v-if = "createNew"

                            slot = "footer"

                            v-bind:is      = "createNew + '-form'"
                            v-bind:isLoading = "isLoading.saving"

                            v-on:save      = "saveNewPlaceholder"
                            v-on:cancel    = "createNew = ''"

                            v-bind:options = "options"
                        />
                    </draggable>
                </template>

                <div class="placeholder is-empty" v-if="showEmptyRow">
                    <div>
                        Bitte
                        <simple-menu>
                            <button v-on:click="create('hotel')">Hotel</button>
                            <button v-on:click="create('other')">Zusatzleistung</button>
                            <button v-on:click="create('ferry')">Fähre</button>
                            <button v-on:click="create('train')">Zug</button>
                            <button v-on:click="create('airline')">Flug</button>
                            <button v-on:click="create('route')">Routenstichpunkt</button>
                            <button v-on:click="create('free')">Freitext</button>
                        </simple-menu>
                        drücken um ein neues Tageselement hinzuzufügen
                    </div>
                </div>
            </template>
        </accordion>


        <loading v-else></loading>


        <div class="is-overlay" v-if="createNew || editMode"></div>
    </div>
</template>



<script>
import axios       from 'axios';
import Draggable   from 'vuedraggable';

import Accordion   from '@components/Accordion';
import Loading     from '@components/Loading';
import SimpleMenu  from '@components/SimpleMenu';
import { notifySuccess, notifyError } from '@components/Notification';
import {listOfDays, prepareForBackend} from '@utilities/functions';

import ConceptProviderForm from "@orders/concepts/days/ConceptProviderForm";
import AirlineView from '@orders/placeholders/components/views/Airline';
import FerryView   from '@orders/placeholders/components/views/Ferry';
import TrainView   from '@orders/placeholders/components/views/Train';
import HotelView   from '@orders/placeholders/components/views/Hotel';
import RouteView   from '@orders/placeholders/components/views/RouteDetail';
import OtherView   from '@orders/placeholders/components/views/Other';
import AirlineForm from '@orders/concepts/days/placeholderForms/Airline';
import FreeView   from '@orders/placeholders/components/views/FreeText';
import FerryForm   from '@orders/concepts/days/placeholderForms/Ferry';
import TrainForm   from '@orders/concepts/days/placeholderForms/Train';
import HotelForm   from '@orders/concepts/days/placeholderForms/Hotel';
import RouteForm   from '@orders/concepts/days/placeholderForms/Route';
import OtherForm   from '@orders/concepts/days/placeholderForms/Other';
import FreeForm   from '@orders/concepts/days/placeholderForms/Free';

import store       from './store';
import Popover from "@components/Popover.vue";
import {getAxios} from "@api";


export default {
    props: {
        day: {
            type: Object,
            required: true,
        },
        options: {},
        isDraggableDisabled: {
            default: true,
        },
        isRouteView: {
            default: true
        },
        dayCount: {
            default: null
        },
        countOrderConcepts: {
            default: 0,
        }
    },

    data: function () {
        return {
            createNew: false,
            editMode: '',

            orderConceptsInfo: null,

            types: {
                airline: 'airlinePlaceholders',
                ferry:   'ferryPlaceholders',
                train:   'trainPlaceholders',
                hotel:   'hotelPlaceholders',
                route:   'routeDetails',
                free:    'freeTextItems',
                other:   'otherPlaceholders'
            },

            isLoading: {
                initialize: false,
                saving:     false,
                deleting:   false
            }
        }
    },

    mounted: function () {
        this.$store.commit('updateDay', this.day);
    },


    computed: {
        placeholders: {
            get: function () {
                if (this.day === null) {
                    return [];
                }


                const airlinePlaceholders = this.day.airlinePlaceholders
                    .map(placeholder => Object.assign({}, placeholder, {
                        placeholderType: 'airline',
                        ...placeholder.id && { sort: `airline-${placeholder.id}` }
                    }));

                const ferryPlaceholders = this.day.ferryPlaceholders
                    .map(placeholder => Object.assign({}, placeholder, {
                        placeholderType: 'ferry',
                        ...placeholder.id && { sort: `ferry-${placeholder.id}` }
                    }));
                const trainPlaceholders = this.day.trainPlaceholders
                    .map(placeholder => Object.assign({}, placeholder, {
                        placeholderType: 'train',
                        ...placeholder.id && { sort: `train-${placeholder.id}` }
                    }));

                const hotelPlaceholders = this.day.hotelPlaceholders
                    .map(placeholder => Object.assign({}, placeholder, {
                        placeholderType: 'hotel',
                        ...placeholder.id && { sort: `hotel-${placeholder.id}`}
                    }));

                const routeDetails = this.day.routeDetails
                    .map(placeholder => Object.assign({}, placeholder, {
                        placeholderType: 'route',
                        ...placeholder.id && { sort: `route-${placeholder.id}` }
                    }));

                const freeTextItems = this.day.freeTextItems
                    .map(placeholder => Object.assign({}, placeholder, {
                        placeholderType: 'free',
                        ...placeholder.id && { sort: `free-${placeholder.id}`}
                    }));


                const otherPlaceholders = this.day.otherPlaceholders
                    .map(placeholder => Object.assign({}, placeholder, {
                        placeholderType: 'other',
                        ...placeholder.id && { sort: `other-${placeholder.id}` }
                    }));

                const placeholders = [
                    ...airlinePlaceholders,
                    ...ferryPlaceholders,
                    ...trainPlaceholders,
                    ...hotelPlaceholders,
                    ...otherPlaceholders,
                    ...routeDetails,
                    ...freeTextItems,
                ];

                let sortHelper = this.day.sortHelper;

                const sortablePlaceholders = [...placeholders]
                    .filter(placeholder => sortHelper.includes(placeholder.sort))

                const newPlaceholders = [...placeholders]
                    .filter(placeholder => !sortHelper.includes(placeholder.sort))

                return [...sortablePlaceholders.sort((a,b) => {
                    return sortHelper.indexOf(a.sort) < sortHelper.indexOf(b.sort) ? -1 : 1;
                }), ...newPlaceholders]
            },

            set: function (newDay) {
                const sortHelper = newDay.map(placeholder => placeholder.sort);
                //this.day.sortHelper = sortHelper;
                this.updateDay({ sortHelper: sortHelper });
            }
        },
        sortHelper: function () {
            return this.placeholders
                .filter(placeholder => placeholder.sort)
                .map(placeholder => placeholder.sort);
        },


        showEmptyRow: function () {
            return !this.createNew && this.placeholders.length === 0
        },


        placeholdersGroupToUpdate: function () { return this.$store.state.placeholdersGroupToUpdate; }
    },


    methods: {
        openDay(day) {
            window.open(`/concepts/days/${day.id}`, '_blank')
        },

        toggleIsHidden() {
            this.updateDay({isHidden: !this.day.isHidden});
            //this.day.isHidden = !this.day.isHidden
        },

        create: function (key) {
            this.createNew = key;

            if (key === 'route' || key === 'free') {
                this.$emit('changeRouteView', true)
            }
        },


        editPlaceholder: function (placeholder) {
            this.editMode = placeholder.placeholderType + '-' + placeholder.id;
        },


        openConceptProviderForm: function (data = {}) {
            this.$refs.conceptProviderForm.open(data);
        },

        getOrderConceptsInfo () {
            if(!this.orderConceptsInfo) {
                this.orderConceptsInfo = [];
                getAxios('day_concepts/' + this.day.id, {
                    _groups: ['concept_day:concept_order_info']
                }).then(response => {
                    this.orderConceptsInfo = response.data.orderConceptsInfo
                })
            }
        },


        updateDay: function (updatedData = null) {
            let data = null;

            if (updatedData) {
                // If we want only a specific property to update
                data = updatedData;

            } else {
                // Update the sorterHelper
                data = {...this.day, sortHelper: this.sortHelper};
            }
            this.$emit('updateDay', data);

            return new Promise((resolve, reject) => {
                axios.put('/api/day_concepts/' + this.day.id, prepareForBackend(data), {
                    params: {
                        _groups: ['predefined_free_text_item']
                    }
                }).then(response => {
                    this.$emit('updateDay', response.data);
                    resolve(response);
                    }, error => { reject(error); });
            });
        },


        savePlaceholder: function (placeholder) {
            let index   = -1,
                copyDay = JSON.parse(JSON.stringify(this.day));

            // Update placeholder with the new value
            index = copyDay[this.types[placeholder.placeholderType]].findIndex(item => item.id === placeholder.id);
            copyDay[this.types[placeholder.placeholderType]].splice(index, 1, placeholder);

            this.updateDay(copyDay)
                .then(response => {
                    this.editMode = false;
                    notifySuccess('Leistung aktualisiert!');

                }, error => {
                    notifyError('Leistung konnte nicht aktualisiert werden! Server Error!');
                    this.editMode = false;
                })
                .then(() => { this.isLoading.saving = false; });
        },


        saveNewPlaceholder(placeholder) {
            let copyDay = JSON.parse(JSON.stringify(this.day));

            // Add the new placeholder
            copyDay[this.types[this.createNew]].push(placeholder);

            this.isLoading.saving = true;

            this.updateDay(copyDay)
                .then(response => {

                    // Update the helper (TODO - this should be done on the backend when adding the placeholder)
                    this.updateDay().then(() => {
                        this.createNew = false;
                        notifySuccess('Leistung aktualisiert!');
                    });

                }, error => {
                    notifyError('Leistung konnte nicht aktualisiert werden! Server Error!');
                    this.createNew = false;
                })
                .then(() => { this.isLoading.saving = false; });
        },


        deletePlaceholder: function ({ key, id }) {
            if (confirm('Möchten Sie diese Leistung löschen?')) {
                this.isLoading.deleting = true;
                // Remove the placeholder
                let day = JSON.parse(JSON.stringify(this.day));
                day[key] = day[key].filter(item => item.id !== id);

                this.updateDay(day)
                    .then(response => {
                        notifySuccess('Leistung gelöscht!');

                    }, error => { notifyError('Leistung konnte nicht gelöscht werden! Server Error!'); })
                    .then(() => { this.isLoading.deleting = false; });
            }
        },


        isSelected: function (placeholder) {
            return !!this.$store.state.placeholdersSelected.find(
                item => item.placeholderType === placeholder.placeholderType &&
                        item.id              === placeholder.id
                )
        },

        crudLink: function(placeholder) {
            const type = placeholder.placeholderType

            if (type === 'other' && placeholder.otherServiceType) {
                return `/other_service_types/${placeholder.otherServiceType.id}`
            } else if (type === 'route' && placeholder.predefinedRouteDetail) {
                return `/routes/${placeholder.predefinedRouteDetail.id}`
            } else if (type === 'free' && placeholder.predefinedFreeTextItem) {
                return `/free_texts}`
            }

            return false;
        }
    },

    watch: {
        placeholdersGroupToUpdate: function () {
            // We specifically check for FALSE as we can  set the group to NULL
            if (this.placeholdersGroupToUpdate !== false) {
                let copyDay = JSON.parse(JSON.stringify(this.day));

                // Modify the group in the selected placeholders
                this.$store.state.placeholdersSelected.forEach(placeholder => {
                    const index = copyDay[this.types[placeholder.placeholderType]].findIndex(item => item.id === placeholder.id);

                    if (index !== -1) {
                        copyDay[this.types[placeholder.placeholderType]][index].sortGroup = this.placeholdersGroupToUpdate;
                    }
                });

                copyDay = prepareForBackend(copyDay);

                // Call to server
                this.updateDay(copyDay)
                    .then(response => {
                        notifySuccess('Platzhalter wurden geändert');

                    }, error => { notifyError('Platzhalter konnten nicht geändert werden! Server Error!'); })
                    .then(() => { this.$store.commit('placeholdersGroupToUpdate', false); });
            }
        }
    },


    store: store,


    components: {
        Popover,
        Accordion,
        Draggable,
        Loading,
        SimpleMenu,
        AirlineView, FerryView, HotelView, RouteView, OtherView, FreeView,TrainView,
        AirlineForm, FerryForm, HotelForm, RouteForm, OtherForm, FreeForm, ConceptProviderForm,TrainForm
    },
}
</script>

