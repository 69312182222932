<template>
    <div v-if="concept">
        <div
            v-for="(day, index) in sortedDays"
            v-bind:key="day.id"
            v-on:click="$emit('select', day)"
        >
            <span
                v-if="day.category"
                class="tag"
            ><span class="is-truncated">{{ dayCategoryName(day) }}</span></span>
            <span v-else class="tag is-placeholder"></span>

            <span class="is-truncated" v-tooltip="{content: day.name, delay: {show: 1000}}">{{`Tag ${(index+1)}`}} - {{ day.name }}</span>
        </div>
    </div>
    <div v-else>
        <div>Wähle ein Gesamtkonzept aus</div>
    </div>
</template>



<script>
export default {
    props: {
        concept: {
            type: [Boolean, Object],
        },

        options: {
            type: Object
        }
    },


    computed: {
        sortedDays: function () {
            if (!this.concept) {
                return []
            }

            const orderOfDays = this.concept.orderOfDays;
            const days = this.concept.days ? JSON.parse(JSON.stringify(this.concept.days)) : []

            if (orderOfDays.length > 0) {
                return days.sort(function (a, b){
                    return orderOfDays.indexOf(String(a.id)) > orderOfDays.indexOf(String(b.id)) ? 1 : -1;
                }.bind(this));
            } else {
                return days;
            }
        },
    },


    methods: {
        dayCategoryName: function (day) {
            const optionCategory = this.options.day.categories &&
                this.options.day.categories.find(c => c.key === day.category);

            return optionCategory ? optionCategory.value : day.category;
        },
    }
}
</script>

