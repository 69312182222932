var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "toggle-switch",
    {
      attrs: {
        "btn-class": _vm.btnClass,
        "btn-style": _vm.btnStyle,
        "div-class": _vm.divClass,
      },
      model: {
        value: _vm.internalValue,
        callback: function ($$v) {
          _vm.internalValue = $$v
        },
        expression: "internalValue",
      },
    },
    _vm._l(_vm.options, function (option) {
      return _c(
        "option",
        { key: option.value, domProps: { value: option.value } },
        [_vm._v(_vm._s(option.label))]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }