<template>
    <toggle-switch
        v-model="internalValue"
        :btn-class="btnClass"
        :btn-style="btnStyle"
        :div-class="divClass"
    >
        <option v-for="option in options" :key="option.value" :value="option.value">{{option.label}}</option>
    </toggle-switch>
</template>

<script>
import ToggleSwitch from "@components/form/ToggleSwitch.vue";
export default {
    props: {
        value: {},
        options: {
            required: true,
            type: Array
        },
        btnClass: {
            type: String,
            default: 'is-in-table'
        },
        divClass: {
            type: String,
            default: ''
        },
        btnStyle: {

        }
    },

    computed: {
        internalValue: {
            get: function () {
                return this.value
            },
            set: function (v) {
                this.$emit('input', v);
            }
        }
    },

    components: {
        ToggleSwitch,
    }
}
</script>

