<template>
    <modal
        v-bind:classes="'placeholder'"
        v-bind:visible="isModalVisible"
        v-on:closeModal="closeModal"
    >
        <template v-if="modalLoading">
            <div slot="content">
                <loading textInFront="Daten werden aktualisiert"></loading>
            </div>
        </template>

        <template v-else>
            <template slot="header">
                <div class="is-saving">
                    <i class="material-icons" v-if="isSaving === 'ok'">check</i>
                    <i class="material-icons icon-spin" v-else-if="isSaving">refresh</i>
                </div>

                <div class="name">
                    <template v-if="localPlaceholder.serviceDescription">{{ localPlaceholder.serviceDescription }}</template>
                    <template v-else>Hotel</template>
                    <div v-if="localPlaceholder.hotelType"
                         v-bind:class="['stars', 'has-' + localPlaceholder.hotelType.stars]">
                        <i class="material-icons">star</i>
                        <i class="material-icons">star</i>
                        <i class="material-icons">star</i>
                        <i class="material-icons">star</i>
                        <i class="material-icons">star</i>
                    </div>
                    <span class="tag is-green"
                          v-if="localPlaceholder.hotelType && localPlaceholder.hotelType.category === 'superior'">Superior</span>
                </div>

                <div class="bp-row-form extra-info">
                    <div>
                        <div>
                            <button
                            v-bind:class="['btn-single-toggle',  onlyFromOrder ? '' : 'is-active']"
                            v-on:click="onlyFromOrder = !onlyFromOrder"
                            v-bind:title = "onlyFromOrder ? 'Ortsangaben werden nur in der Reisedestination gesucht (klicken zum Deaktivieren)' : 'Alle Orte werden gesucht (zum Filtern nach Reise-Destinationen klicken)' "
                            style="border: 1px solid #dddddd; margin-right: 5px; min-width: 22px; min-height: 22px;"
                        ><i class="material-icons" style="margin: 0">flag</i></button>

                            <location-select
                                ref="location"
                                v-bind:value="{
                                area:  localPlaceholder.area,
                                place: localPlaceholder.place}"
                                v-bind:limitedDestinations="destinationsForLocation"
                                v-on:input="value => {
                                localPlaceholder.area  = value.area;
                                localPlaceholder.place = value.place;}"
                            ></location-select>
                        </div>
                    </div>
                    <div>
                        <div>
                            <i class="material-icons">star</i>
                            <type
                                v-model="localPlaceholder.hotelType"
                            ></type>
                        </div>
                    </div>
                    <div>
                        <board v-model="localPlaceholder.board"></board>
                    </div>


                    <div class="is-250 has-time" v-if="!selectedFoodDay">
                        <div>
                            <i class="material-icons">date_range</i>
                            <span class="is-static-date">{{ localPlaceholder.startAt | dayMonth }} -</span>
                            <datepicker
                                class="has-margin-left"
                                v-bind:config="{
                                dateFormat: 'd.m.Y H:i:S',
                                minDate: addDays(localPlaceholder.startAt, 1),
                                defaultDate: today,
                                parseDate:  function (dateStr) { return moment(dateStr, 'DD.MM.YYYY HH:mm:ss').toDate(); },
                                formatDate: function (dateObj) { return moment(dateObj).format('DD.MM.YYYY') + ' 00:00:00'; }}"
                                v-model="localPlaceholder.endAt">
                            </datepicker>
                        </div>
                    </div>
                    <div>
                        <div>
                            <i class="material-icons">access_time</i>
                            <multiselect
                                v-model="selectedFoodDay"
                                class="is-small"
                                :allow-empty="true"
                                placeholder="Datum"
                                v-bind:options="listOfDays"
                            ></multiselect>

                        </div>
                        <template v-for="time in localPlaceholder.times">
                            <div v-if="timeLabel(time)">
                                <i class="material-icons">subdirectory_arrow_right</i>

                                <span style="width: 120px">{{ timeLabel(time) }}</span>
                                <date-time v-model="time.time" style="width: 42px"></date-time>
                            </div>
                        </template>
                    </div>
                    <div>
                        <div>
                            <i title="Set as Additional" class="material-icons">shopping_cart</i>
                            <div class="select">
                                <select v-model="localPlaceholder.clientOffer.status">
                                    <option value="included">inkludiert</option>
                                    <option value="optional">optional</option>
                                    <option value="additional">gesondert</option>
                                    <option value="atLocation">vor Ort</option>
                                </select>
                            </div>
                        </div>
                        <div>
                            <i class="material-icons">directions_bus</i>
                            <toggle-switch v-model="localPlaceholder.driverNotParticipating">
                                <option :value="false">mit</option>
                                <option :value="true">ohne Fahrer</option>
                            </toggle-switch>
                        </div>
                    </div>
                    <div>
                        <div>
                            <i class="material-icons">business</i>
                            <span class="has-margin-right">Hotelbeispiel:</span>

                            <async-select
                                v-model="localPlaceholder.offerProvider"
                                placeholder="tippen, um Hotel zu finden"
                                api="hotels"
                                track-by = "id"
                                label    = "name"
                                v-on:input="handleOfferProviderSelect"
                                :params="locationParams"
                            />
                        </div>
                    </div>
                </div>

                <button class="button is-large is-success is-add-new" v-on:click="addNewRequest" style="top: 33px"><i class="fa fa-plus-circle" aria-hidden="true"></i> Leistungsträger hinzufügen</button>

            </template>


            <div slot="content">

                <request
                    v-for="(request, index) in localPlaceholder.requests"
                    v-bind:key="request.id"
                    v-if="!isNewRequestVisible && request.id"
                    v-bind:placeholderInfo="placeholderInfo"
                    v-model="localPlaceholder.requests[index]"
                    v-on:closeModal="$emit('closeModal', localPlaceholder)"
                    v-bind:calculationRequest="calculationRequest"
                    :selected-request="selectedRequest"
                    v-on:setCalculationRequest="setCalculationRequest"
                    v-on:setSelectRequest="setSelectRequest"
                    v-on:saved="handleRequestSaved"
                ></request>


                <new-request
                    v-if="isNewRequestVisible"
                    v-bind:placeholderID="localPlaceholder.id"
                    v-bind:placeholderInfo="placeholderInfo"
                    v-model="localPlaceholder.requests[localPlaceholder.requests.length - 1]"
                    v-on:close="closeNewRequestModal"
                    v-on:closeModal="$emit('closeModal', localPlaceholder)"
                    v-on:saved="handleRequestSaved"
                ></new-request>
            </div>
        </template>
    </modal>
</template>


<script>
import HotelSelect from '@orders/placeholders/components/forms/HotelSelect';
import {addDays, fullYear, listOfDaysWithoutTime} from '@utilities/functions' ;
import {notifySuccess, notifyError} from '@components/Notification';
import mixins from './mixins';
import Request from './requests/Hotel';
import NewRequest from './newRequests/Hotel';
import Board from "@orders/placeholders/components/forms/HotelBoard";
import moment    from 'moment';
import Type from "@orders/placeholders/components/forms/HotelType";
import ToggleSwitch from "@components/form/ToggleSwitch.vue";

export default {
    mixins: [mixins],
    computed: {
        emptyRequest: function () {
            return {
                agency: null,
                startAt: this.localPlaceholder.startAt,
                endAt: this.localPlaceholder.endAt,
                board: this.localPlaceholder.board ? this.localPlaceholder.board : this.boardList[0].key,
                hotel: null,
                placeholder: '/api/hotel_placeholders/' + this.localPlaceholder.id,
                contingent: {
                    doubleRooms: [
                        {
                            askedAmount: 0,
                            type: {
                                persons: 2,
                                type: 'standard'
                            }
                        }
                    ],
                    singleRooms: [
                        {
                            askedAmount: 0,
                            type: {
                                persons: 1,
                                type: 'standard'
                            }
                        }
                    ],
                    otherRooms: []
                },
                optionTodo: {dueDate: null},
                itemPrices: []
            }
        },
        locationParams: function () {
            if (this.localPlaceholder && this.localPlaceholder.place) {
                return {'place.id': this.localPlaceholder.place.id};
            }
            if (this.localPlaceholder && this.localPlaceholder.area) {
                return {'place.area.id': this.localPlaceholder.area.id};
            }
            return {};
        },
        boardList: function () {
            return this.$store.state.options.boardList;
        },
        placeholderInfo: function () {
            return {
                area: this.localPlaceholder.area,
                place: this.localPlaceholder.place
            };
        },

    },
    data: function () {
        return {
            oldValue: null,
            typeOfPlaceholder: 'hotel',
            apiEndpoint: 'hotel_placeholders',
            hotelSelected: null,
            isTriggerSearch: false,
            onlyFromOrder: true,
            boards: [],
            selectedFoodDay: null,
        }
    },
    methods: {
        timeLabel: function (time) {
            if(moment(time.time, 'DD.MM.YYYY HH:mm:ss').format('DD.MM.YYYY') !== this.selectedFoodDay)
            {
                return '';
            }

            if(time.type === 'breakfast') {
                return 'Frühstück';
            }
            if(time.type === 'lunch') {
                return 'Mittagessen';
            }
            if(time.type === 'dinner'){
                return 'Abendessen';
            }
            if(time.type === 'checkIn') {
                return 'Ankunft im Hotel';
            }
            return '';
        },

        addTime: function(oldTimes, newTime){
            let found = oldTimes.filter(time => time.type === newTime.type &&
                moment(time.time, 'DD.MM.YYYY HH:mm:ss').format('DD.MM.YYYY') + '00:00:00' === newTime.time);
            if(found.length > 0) {
                this.localPlaceholder.times.push(found[0])
            }  else {
                this.localPlaceholder.times.push(newTime)
            }
        },

        updateTimes: function () {
            let oldTimes = this.localPlaceholder.times;
            this.localPlaceholder.times = [];
            for(let t=0; t<this.listOfDays.length-1; t++){

                if (this.localPlaceholder !== 'none'){
                    this.addTime(oldTimes,
                        {
                            time: addDays(this.listOfDays[t], 1) + '00:00:00',
                            type: 'breakfast'
                        });
                }
                if (this.localPlaceholder === 'full' || this.localPlaceholder.board === 'full_extra_breakfast'){
                    this.addTime(oldTimes,
                        {
                            time: this.listOfDays[t] + '00:00:00',
                            type: 'lunch'
                        }
                    );
                }
                if (this.localPlaceholder.board === 'half' || this.localPlaceholder.board === 'full' ||
                    this.localPlaceholder.board === 'half_extra_breakfast' || this.localPlaceholder.board === 'full_extra_breakfast') {
                    this.addTime(oldTimes,
                        {
                            time: this.listOfDays[t] + '00:00:00',
                            type: 'dinner'
                        }
                    );
                }
                this.addTime(oldTimes, {
                    time: this.listOfDays[t] + '00:00:00',
                    type: 'checkIn'
                });

            }
        },

        handleOfferProviderSelect: function (newHotel) {
            this.editPlaceholder({
                type: this.typeOfPlaceholder + '_placeholders',
                id: this.localPlaceholder.id,
                data: {offerProvider: newHotel ? '/api/hotels/' + newHotel.id : null},
                params: '?_groups[]=order_offer_write'
            }).then(() => {
                    notifySuccess('Das Hotelbeispiel wurde geändert!');
                },
                error => {
                    notifyError('Das Hotelbeispiel konnte nicht verändert werden! Server Error!');
                });
        }

    },
    watch : {
        'localPlaceholder': {
            deep: true,
            handler: function (value) {
                value=_.pick(value, 'id', 'area', 'place','startAt', 'endAt', 'hotelType', 'clientOffer.status', 'offerProvider', 'board', 'times', 'driverNotParticipating')
                if (this.oldValue && JSON.stringify(this.oldValue) !== JSON.stringify(value) ) {
                    let reload = this.oldValue.driverNotParticipating !== value.driverNotParticipating;
                    this.savePlaceholder(value, reload)
                }
                if(!this.oldValue){
                    this.updateTimes();
                }
                this.oldValue= JSON.parse(JSON.stringify(value))
            }
        },
        'localPlaceholder.endAt':     function (newValue, oldValue) {
            if (fullYear(newValue) !== fullYear(oldValue)) {
                this.localPlaceholder.requests = this.localPlaceholder.requests.map(request => ({
                    ...request,
                    endAt: newValue
                }))
                this.updateTimes();
            }
        },
        'localPlaceholder.board':     function (newValue) {
            this.updateTimes();
            this.localPlaceholder.requests = this.localPlaceholder.requests.map(request => ({
                ...request,
                board: newValue
            }))
        }
    },

    components: {
        ToggleSwitch,
        Board,
        NewRequest,
        Request,
        HotelSelect,
        Type,
    }
}
</script>
