var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "new-request" },
    [
      _c("div", { staticClass: "overall-buttons" }, [
        _c(
          "button",
          {
            staticClass: "button is-large",
            on: {
              click: function ($event) {
                return _vm.$emit("close")
              },
            },
          },
          [
            _c("i", {
              staticClass: "fa fa-long-arrow-left",
              attrs: { "aria-hidden": "true" },
            }),
            _vm._v(" Zurück zur Übersicht"),
          ]
        ),
      ]),
      _vm._v(" "),
      _c("accordion", { attrs: { isCollapsible: false } }, [
        _c("div", { attrs: { slot: "header" }, slot: "header" }, [
          _c(
            "div",
            [
              _vm.newRequest.ferry
                ? _c("request-score", {
                    attrs: { rating: _vm.newRequest.ferry.buspaketRating },
                  })
                : _c("i", {
                    staticClass: "fa fa-plus-circle",
                    attrs: { "aria-hidden": "true" },
                  }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            [
              _vm._v(
                "\n                LEISTUNGSTRÄGER HINZUFÜGEN\n\n                "
              ),
              _c(
                "provider-info",
                {
                  staticClass: "header-extra-info",
                  attrs: { provider: _vm.newRequest.ferry },
                },
                [
                  _vm.newRequest.type === "day"
                    ? [
                        _c("div", [
                          _c(
                            "i",
                            { staticClass: "material-icons spacing-left" },
                            [_vm._v("perm_identity")]
                          ),
                          _vm._v(
                            " Kundenkontingent:\n                            " +
                              _vm._s(
                                _vm.$store.state.hotelContingent.info
                                  ? _vm.$store.state.hotelContingent.info
                                      .personsAsked
                                  : 0
                              ) +
                              " Tickets\n                        "
                          ),
                        ]),
                      ]
                    : [
                        _c("div", [
                          _c(
                            "i",
                            { staticClass: "material-icons spacing-left" },
                            [_vm._v("perm_identity")]
                          ),
                          _vm._v(
                            " Kundenkontingent:\n                            "
                          ),
                          _c(
                            "i",
                            { staticClass: "material-icons no-spacing" },
                            [_vm._v("directions_boat")]
                          ),
                          _vm._v(" "),
                          _c("i", { staticClass: "material-icons" }, [
                            _vm._v("directions_boat"),
                          ]),
                          _vm._v(
                            "\n                            " +
                              _vm._s(
                                _vm.ferryContingent.info.doubleCabins.totalAsked
                              ) +
                              " Doppelkabinen\n                        "
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", [
                          _c("i", { staticClass: "material-icons" }, [
                            _vm._v("directions_boat"),
                          ]),
                          _vm._v(
                            "\n                            " +
                              _vm._s(
                                _vm.ferryContingent.info.singleCabins.totalAsked
                              ) +
                              " Einzelkabinen\n                        "
                          ),
                        ]),
                        _vm._v(" "),
                        _c("div", [
                          _c(
                            "i",
                            { staticClass: "material-icons no-spacing" },
                            [_vm._v("directions_boat")]
                          ),
                          _vm._v(" "),
                          _c(
                            "i",
                            { staticClass: "material-icons no-spacing" },
                            [_vm._v("directions_boat")]
                          ),
                          _vm._v(" "),
                          _c("i", { staticClass: "material-icons" }, [
                            _vm._v("directions_boat"),
                          ]),
                          _vm._v(
                            "\n                            " +
                              _vm._s(
                                _vm.ferryContingent.info.otherCabins.totalAsked
                              ) +
                              " Mehrbettkabinen\n                        "
                          ),
                        ]),
                      ],
                ],
                2
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", [
            _c(
              "label",
              {
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                  },
                },
              },
              [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.isMainRequest,
                      expression: "isMainRequest",
                    },
                  ],
                  attrs: { type: "checkbox" },
                  domProps: {
                    checked: Array.isArray(_vm.isMainRequest)
                      ? _vm._i(_vm.isMainRequest, null) > -1
                      : _vm.isMainRequest,
                  },
                  on: {
                    change: function ($event) {
                      var $$a = _vm.isMainRequest,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 && (_vm.isMainRequest = $$a.concat([$$v]))
                        } else {
                          $$i > -1 &&
                            (_vm.isMainRequest = $$a
                              .slice(0, $$i)
                              .concat($$a.slice($$i + 1)))
                        }
                      } else {
                        _vm.isMainRequest = $$c
                      }
                    },
                  },
                }),
                _vm._v(
                  "\n                    als Leistung festlegen\n                "
                ),
              ]
            ),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { attrs: { slot: "content" }, slot: "content" }, [
          _c("div", { staticClass: "bp-box is-info" }, [
            _c(
              "main",
              [
                _c(
                  "button",
                  {
                    class: [
                      "btn-single-toggle",
                      _vm.onlyFromOrder ? "" : "is-active",
                    ],
                    staticStyle: {
                      border: "1px solid #dddddd",
                      "margin-right": "5px",
                      "min-width": "22px",
                      "min-height": "22px",
                    },
                    attrs: {
                      title: _vm.onlyFromOrder
                        ? "Suche ist auf obigen Route eingeschränkt"
                        : "Suche ist nicht eingeschränkt",
                    },
                    on: {
                      click: function ($event) {
                        _vm.onlyFromOrder = !_vm.onlyFromOrder
                      },
                    },
                  },
                  [
                    _c(
                      "i",
                      {
                        staticClass: "material-icons",
                        staticStyle: { margin: "0" },
                      },
                      [_vm._v("flag")]
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  [
                    _c("ferry-select", {
                      staticStyle: { width: "400px" },
                      attrs: {
                        placeholderInfo: _vm.onlyFromOrder
                          ? _vm.localPlaceholderInfo
                          : { startPort: null, endPort: null, type: null },
                      },
                      model: {
                        value: _vm.newRequest.ferry,
                        callback: function ($$v) {
                          _vm.$set(_vm.newRequest, "ferry", $$v)
                        },
                        expression: "newRequest.ferry",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("agency-select", {
                  attrs: {
                    provider: _vm.newRequest.ferry,
                    text: "Agentur",
                    prefill: "",
                  },
                  on: { update: (agency) => (_vm.newRequest.agency = agency) },
                }),
                _vm._v(" "),
                _c("div", { staticClass: "is-second-row" }, [
                  _c("div", [
                    _c("i", { staticClass: "material-icons" }, [
                      _vm._v("assignment"),
                    ]),
                    _vm._v(" Schiff:\n                            "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.newRequest.shipName,
                          expression: "newRequest.shipName",
                        },
                      ],
                      staticClass: "input",
                      attrs: { type: "text" },
                      domProps: { value: _vm.newRequest.shipName },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.newRequest,
                            "shipName",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                  _vm._v(" "),
                  _c("div", [
                    _c("i", { staticClass: "material-icons" }, [
                      _vm._v("format_list_numbered"),
                    ]),
                    _vm._v(" Reservierungs-Nr:\n                            "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.newRequest.optionNr,
                          expression: "newRequest.optionNr",
                        },
                      ],
                      staticClass: "input",
                      attrs: { type: "text" },
                      domProps: { value: _vm.newRequest.optionNr },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.newRequest,
                            "optionNr",
                            $event.target.value
                          )
                        },
                      },
                    }),
                  ]),
                ]),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "columns" },
            [
              _vm.newRequest.type === "night"
                ? [
                    _c(
                      "div",
                      { staticClass: "column" },
                      [
                        _vm.newRequest.contingent
                          ? _c("simple-ferry-contingent", {
                              attrs: {
                                type: "ferry",
                                nrOfPersons: 2,
                                types: _vm.ferryTypes,
                                isOnlyAsked: true,
                              },
                              model: {
                                value: _vm.newRequest.contingent.doubleCabins,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.newRequest.contingent,
                                    "doubleCabins",
                                    $$v
                                  )
                                },
                                expression:
                                  "newRequest.contingent.doubleCabins",
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "column" },
                      [
                        _vm.newRequest.contingent
                          ? _c("simple-ferry-contingent", {
                              attrs: {
                                type: "ferry",
                                nrOfPersons: 1,
                                types: _vm.ferryTypes,
                                isOnlyAsked: true,
                              },
                              model: {
                                value: _vm.newRequest.contingent.singleCabins,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.newRequest.contingent,
                                    "singleCabins",
                                    $$v
                                  )
                                },
                                expression:
                                  "newRequest.contingent.singleCabins",
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "column" },
                      [
                        _vm.newRequest.contingent
                          ? _c("ferry-contingent", {
                              attrs: {
                                type: "ferry",
                                types: _vm.ferryTypes,
                                isOnlyAsked: true,
                              },
                              model: {
                                value: _vm.newRequest.contingent.otherCabins,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.newRequest.contingent,
                                    "otherCabins",
                                    $$v
                                  )
                                },
                                expression: "newRequest.contingent.otherCabins",
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]
                : [
                    _c("div", { staticClass: "column" }, [
                      _c("div", { staticClass: "bp-box is-tickets" }, [
                        _c("header", [
                          _c("div", [
                            _c("i", { staticClass: "material-icons" }, [
                              _vm._v("credit_card"),
                            ]),
                            _vm._v(" Tickets Tagfähre"),
                          ]),
                          _vm._v(" "),
                          _c("div", [
                            _c("i", { staticClass: "material-icons" }, [
                              _vm._v("help"),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("div", [
                            _c(
                              "button",
                              {
                                attrs: {
                                  title:
                                    "Extraticket/-kabine für Reisebegleitung",
                                },
                                on: { click: _vm.addExtra },
                              },
                              [
                                _c("i", { staticClass: "material-icons" }, [
                                  _vm._v("person_add"),
                                ]),
                              ]
                            ),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("main", [
                          _c("div", [
                            _c("div", [
                              _c("i", { staticClass: "material-icons" }, [
                                _vm._v("accessibility"),
                              ]),
                              _vm._v("Pax"),
                            ]),
                            _vm._v(" "),
                            _c("div", [
                              _c("input", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model.number",
                                    value:
                                      _vm.newRequest.contingent.ticketsAsked,
                                    expression:
                                      "newRequest.contingent.ticketsAsked",
                                    modifiers: { number: true },
                                  },
                                ],
                                staticClass: "input",
                                attrs: { type: "number" },
                                domProps: {
                                  value: _vm.newRequest.contingent.ticketsAsked,
                                },
                                on: {
                                  focus: function ($event) {
                                    return $event.target.select()
                                  },
                                  input: function ($event) {
                                    if ($event.target.composing) return
                                    _vm.$set(
                                      _vm.newRequest.contingent,
                                      "ticketsAsked",
                                      _vm._n($event.target.value)
                                    )
                                  },
                                  blur: function ($event) {
                                    return _vm.$forceUpdate()
                                  },
                                },
                              }),
                            ]),
                            _vm._v(" "),
                            _c("div"),
                          ]),
                          _vm._v(" "),
                          _vm.hasExtra
                            ? _c("div", { staticClass: "extra-people" }, [
                                _c("div", [
                                  _c("i", { staticClass: "material-icons" }, [
                                    _vm._v("person_add"),
                                  ]),
                                  _vm._v(" "),
                                  _c("input", {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value:
                                          _vm.newRequest.contingent
                                            .ticketsExtraDescription,
                                        expression:
                                          "newRequest.contingent.ticketsExtraDescription",
                                      },
                                    ],
                                    staticClass: "input",
                                    attrs: { type: "text" },
                                    domProps: {
                                      value:
                                        _vm.newRequest.contingent
                                          .ticketsExtraDescription,
                                    },
                                    on: {
                                      focus: function ($event) {
                                        return $event.target.select()
                                      },
                                      input: function ($event) {
                                        if ($event.target.composing) return
                                        _vm.$set(
                                          _vm.newRequest.contingent,
                                          "ticketsExtraDescription",
                                          $event.target.value
                                        )
                                      },
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "button",
                                    {
                                      staticClass: "btn-delete",
                                      attrs: {
                                        title: "Löschen",
                                        tabindex: "-1",
                                      },
                                      on: { click: _vm.deleteExtra },
                                    },
                                    [
                                      _c(
                                        "i",
                                        { staticClass: "material-icons" },
                                        [_vm._v("delete")]
                                      ),
                                    ]
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("div", [
                                  _c("div", { staticClass: "control" }, [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model.number",
                                          value:
                                            _vm.newRequest.contingent
                                              .ticketsExtraAsked,
                                          expression:
                                            "newRequest.contingent.ticketsExtraAsked",
                                          modifiers: { number: true },
                                        },
                                      ],
                                      staticClass: "input",
                                      attrs: { type: "text" },
                                      domProps: {
                                        value:
                                          _vm.newRequest.contingent
                                            .ticketsExtraAsked,
                                      },
                                      on: {
                                        focus: function ($event) {
                                          return $event.target.select()
                                        },
                                        input: function ($event) {
                                          if ($event.target.composing) return
                                          _vm.$set(
                                            _vm.newRequest.contingent,
                                            "ticketsExtraAsked",
                                            _vm._n($event.target.value)
                                          )
                                        },
                                        blur: function ($event) {
                                          return _vm.$forceUpdate()
                                        },
                                      },
                                    }),
                                  ]),
                                ]),
                                _vm._v(" "),
                                _c("div"),
                              ])
                            : _vm._e(),
                        ]),
                      ]),
                    ]),
                  ],
            ],
            2
          ),
          _vm._v(" "),
          _c("div", { staticClass: "columns" }, [
            _c(
              "div",
              { staticClass: "column is-offset-6" },
              [
                _c("item-prices", {
                  attrs: { isWithoutPrice: true, provider: _vm.provider },
                  model: {
                    value: _vm.newRequest.itemPrices,
                    callback: function ($$v) {
                      _vm.$set(_vm.newRequest, "itemPrices", $$v)
                    },
                    expression: "newRequest.itemPrices",
                  },
                }),
              ],
              1
            ),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "button is-large",
          on: {
            click: function ($event) {
              return _vm.$emit("close")
            },
          },
        },
        [
          _c("i", { staticClass: "material-icons" }, [_vm._v("close")]),
          _vm._v(" Abbrechen"),
        ]
      ),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "button is-primary is-large",
          class: { "is-loading": _vm.isLoading },
          on: { click: _vm.saveNewRequest },
        },
        [
          _c("i", { staticClass: "material-icons" }, [_vm._v("check")]),
          _vm._v(" speichern und zur Email"),
        ]
      ),
      _vm._v(" "),
      _c(
        "button",
        {
          staticClass: "button is-primary is-large",
          class: { "is-loading": _vm.isLoading },
          on: {
            click: function ($event) {
              return _vm.saveNewRequest("close")
            },
          },
        },
        [
          _c("i", { staticClass: "material-icons" }, [
            _vm._v("keyboard_arrow_right"),
          ]),
          _vm._v(" speichern und schließen"),
        ]
      ),
      _vm._v(" "),
      _c("confirmation", {
        attrs: {
          isVisible: _vm.isVisibleConfirmation,
          "is-local": "",
          text: "Soll die Beschreibung der Anfrage in den Platzhalter kopiert werden?",
        },
        on: { input: (value) => _vm.$emit("confirm", value) },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }