var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "is-full-width is-full-height" }, [
    _vm.showDeadlineUrgency
      ? _c(
          "div",
          {
            staticClass:
              "bp-deadline is-edit-table is-full-height is-full-width",
            class: [_vm.deadlineUrgency(_vm.internalValue)],
          },
          [
            !_vm.readOnly
              ? _c("input-date", {
                  ref: "date",
                  attrs: { placeholder: "" },
                  model: {
                    value: _vm.internalValue,
                    callback: function ($$v) {
                      _vm.internalValue = $$v
                    },
                    expression: "internalValue",
                  },
                })
              : _c(
                  "span",
                  {
                    staticClass:
                      "is-full-width is-full-height is-flex is-align-content-center is-align-items-center is-justify-content-center",
                  },
                  [_vm._v(_vm._s(_vm.formatDate(_vm.internalValue)))]
                ),
          ],
          1
        )
      : _vm._e(),
    _vm._v(" "),
    !_vm.showDeadlineUrgency
      ? _c(
          "div",
          { staticClass: "is-edit-table is-full-height is-full-width" },
          [
            !_vm.readOnly
              ? _c("input-date", {
                  ref: "date",
                  attrs: { placeholder: "" },
                  model: {
                    value: _vm.internalValue,
                    callback: function ($$v) {
                      _vm.internalValue = $$v
                    },
                    expression: "internalValue",
                  },
                })
              : _c(
                  "span",
                  {
                    staticClass:
                      "is-full-width is-full-height is-flex is-justify-content-center is-align-items-center",
                  },
                  [_vm._v(_vm._s(_vm.formatDate(_vm.internalValue)))]
                ),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }